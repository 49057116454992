import { setSentenceXCanWheel } from '/js/sentenceX/setSentenceXCanWheel.js';
import { killCharacterB0ElementTimelines } from '/js/characterB0/killCharacterB0ElementTimelines.js';
import { setCharacterB0Values } from '/js/characterB0/setCharacterB0Values.js';
import { setCharacterB0Value } from '/js/characterB0/setCharacterB0Value.js';
import { setCharacterB0Index } from '/js/characterB0/setCharacterB0Index.js';
import { setCharacterB0Element } from '/js/characterB0/setCharacterB0Element.js';
import { setCharacterB0ElementInnerHTML } from '/js/characterB0/setCharacterB0ElementInnerHTML.js';
import { addCharacterB0ElementEventListener } from '/js/characterB0/addCharacterB0ElementEventListener.js';
import { appendCharacterB0Element } from '/js/characterB0/appendCharacterB0Element.js';
import { setCharacterB0ToCElementsOpacity } from '/js/characterB0/setCharacterB0ToCElementsOpacity.js';
import { removeCharacterB0ToCElements } from '/js/characterB0/removeCharacterB0ToCElements.js';
import { setCharacterB0Elements } from '/js/characterB0/setCharacterB0Elements.js';
import { setCharacterB0ToCElements } from '/js/characterB0/setCharacterB0ToCElements.js';
import { setSentenceB0Value } from '/js/sentenceB0/setSentenceB0Value.js';
import { setCharacterC0Values } from '/js/characterC0/setCharacterC0Values.js';
import { setCharacterC0Index } from '/js/characterC0/setCharacterC0Index.js';
import { setCharacterC0FromBElements } from '/js/characterC0/setCharacterC0FromBElements.js';
import { appendCharacterC0FromBElements } from '/js/characterC0/appendCharacterC0FromBElements.js';
import { setCharacterC0FromBElementsOpacity } from '/js/characterC0/setCharacterC0FromBElementsOpacity.js';
import { setCharacterC0Elements } from '/js/characterC0/setCharacterC0Elements.js';
import { setCharacterC0ElementsId } from '/js/characterC0/setCharacterC0ElementsId.js';
import { addCharacterC0ElementsEventListener } from '/js/characterC0/addCharacterC0ElementsEventListener.js';
import { setCharacterC0PreviousElements } from '/js/characterC0/setCharacterC0PreviousElements.js';
import { setCharacterC0PreviousElementsOpacity } from '/js/characterC0/setCharacterC0PreviousElementsOpacity.js';

import { setSentenceC0Value } from '/js/sentenceC0/setSentenceC0Value.js';
import { setSentenceC0SplitValues } from '/js/sentenceC0/setSentenceC0SplitValues.js';
import { setSentenceC0SplitCounts } from '/js/sentenceC0/setSentenceC0SplitCounts.js';
import { setSentenceC0SplitInitials } from '/js/sentenceC0/setSentenceC0SplitInitials.js';
import { setCharacterC0ElementsClassName } from '/js/characterC0/setCharacterC0ElementsClassName.js';
import { resetCharacterB0ToCValues } from '/js/characterB0/resetCharacterB0ToCValues.js';
import { transformSentenceAElement } from '/js/sentenceA/transformSentenceAElement.js';
import { transformSentenceBElement } from '/js/sentenceB/transformSentenceBElement.js';
import { transformSentenceCElement } from '/js/sentenceC/transformSentenceCElement.js';
import { setBlankB0ElementHeight } from '/js/blankB0/setBlankB0ElementHeight.js';
import { setSentenceB0ElementHeight } from '/js/sentenceB0/setSentenceB0ElementHeight.js';
import { setSentenceCElementDisplay } from '/js/sentenceC/setSentenceCElementDisplay.js';
import { setSentenceC1ElementFocus } from '/js/sentenceC1/setSentenceC1ElementFocus.js';
import { setSentenceB1ElementValue } from '/js/sentenceB1/setSentenceB1ElementValue.js';
import { setSentenceC1ElementValue } from '/js/sentenceC1/setSentenceC1ElementValue.js';

export const sentenceBStream_A_BTC = (event) => {
  setSentenceXCanWheel(false);
  killCharacterB0ElementTimelines();
  setCharacterB0Values();
  setCharacterB0Value();
  setCharacterB0Index();

  setCharacterB0Element();
  setCharacterB0ElementInnerHTML();
  addCharacterB0ElementEventListener();
  appendCharacterB0Element();
  setCharacterB0ToCElements();
  setCharacterB0ToCElementsOpacity();
  removeCharacterB0ToCElements();
  setCharacterB0Elements();

  setSentenceB0Value(event);
  setBlankB0ElementHeight();
  setSentenceB0ElementHeight();
  setSentenceB1ElementValue();

  setCharacterC0Values();
  setCharacterC0Index();

  setCharacterC0FromBElements();
  appendCharacterC0FromBElements();
  setCharacterC0FromBElementsOpacity();
  setCharacterC0Elements();
  setCharacterC0ElementsId();
  addCharacterC0ElementsEventListener();
  setCharacterC0PreviousElements();
  setCharacterC0PreviousElementsOpacity();

  setSentenceC0Value(event);
  setSentenceC0SplitValues();
  setSentenceC0SplitCounts();
  setSentenceC0SplitInitials();
  setCharacterC0ElementsClassName();

  resetCharacterB0ToCValues(event);

  transformSentenceAElement();
  transformSentenceBElement();
  transformSentenceCElement();
  setSentenceCElementDisplay();
  setSentenceC1ElementValue();
  setSentenceC1ElementFocus();
  setTimeout(() => {
    setSentenceXCanWheel(true);
  }, 1500);
};
