import { userName } from '/js/userName/userName.js';
import { setUserNameA0ElementDisplay } from '/js/userNameA0/setUserNameA0ElementDisplay.js';
import { setPassWordA0ElementDisplay } from '/js/passWordA0/setPassWordA0ElementDisplay.js';
import { setLogInElementDisplay } from '/js/logIn/setLogInElementDisplay.js';
import { setLogInElementOpacity } from '/js/logIn/setLogInElementOpacity.js';
import { setCodeElementDisplay } from '/js/code/setCodeElementDisplay.js';
import { setCodeA1ElementFocus } from '/js/codeA1/setCodeA1ElementFocus.js';
import { setCodeElementOpacity } from '/js/code/setCodeElementOpacity.js';
import { setConfirmElementDisplay } from '/js/Confirm/setConfirmElementDisplay.js';
import { setConfirmElementOpacity } from '/js/Confirm/setConfirmElementOpacity.js';
import { setUserNameA0ElementOpacity } from '/js/userNameA0/setUserNameA0ElementOpacity.js';
import { setPassWordA0ElementOpacity } from '/js/passWordA0/setPassWordA0ElementOpacity.js';

export const logInStream_RV = () => {
  let url = `https://6wpno481ei.execute-api.us-east-1.amazonaws.com/${userName.value}`;
  axios({
    method: 'post',
    url: url,
  }).then((res) => {
    console.log(res);
    setUserNameA0ElementOpacity();
    setUserNameA0ElementDisplay();
    setPassWordA0ElementOpacity();
    setPassWordA0ElementDisplay();
    setLogInElementOpacity();
    setLogInElementDisplay();
    setCodeElementDisplay();
    setCodeElementOpacity();
    setCodeA1ElementFocus();
    setConfirmElementDisplay();
    setConfirmElementOpacity();
  });
};
