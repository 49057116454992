import { passWordState } from '/js/passWordState/passWordState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';

export const setPassWordA0CharacterElement = () => {
  if (passWordState.A === true) {
    passWordA0Character.element = document.createElement('span');
  }
  if (passWordState.D === true) {
    passWordA0Character.element = document.querySelector(
      `#passWordA0Character_${passWordA0Character.index}`
    );
  }
};
