import { userNameState } from '/js/userNameState/userNameState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';

export const setUserNameA0CharacterIndex = () => {
  if (userNameState.A === true) {
    userNameA0Character.index += 1;
  }

  if (userNameState.D === true) {
    userNameA0Character.index -= 1;
  }
};
