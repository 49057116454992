import { gsap } from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';

export const setSentenceSignElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true
  ) {
    let arrA = sentence[theIndex - 1].signElements;
    let arrB = arrA.slice().reverse();
    let arr = [];
    for (let iA = 0; iA < 1; iA++) {
      for (let iB = 0; iB < arrB.length; iB++) {
        let timeline = gsap.timeline();
        let obj = {};
        obj.element = arrB[iB];
        obj.timeline = timeline;
        timeline.to(arrB[iB], {
          opacity: 1,
          duration: 3,
          delay: iB / 2 + (iA * arrB.length) / 2,
          onComplete: () => {
            gsap.to(arrB[iB], {
              opacity: 0.2,
              duration: 1,
            });
          },
        });
        arr.push(obj);
      }
    }
    sentence[theIndex - 1].signElementsTimelines = arr;
  }

  if (
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true ||
    blankAState.W_R_SKA === true ||
    blankBState.W_R_SKA === true ||
    blankCState.W_R_SKA === true ||
    characterAState.W_R_SKA === true ||
    characterBState.W_R_SKA === true ||
    characterCState.W_R_SKA === true ||
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true
  ) {
    let arrA = sentence[theIndex - 1].signElements;
    for (let iA = 0; iA < 1; iA++) {
      for (let iB = 0; iB < arrA.length; iB++) {
        let timeline = gsap.timeline();
        let obj = {};
        obj.element = arrA[iB];
        obj.timeline = timeline;
        timeline.to(arrA[iB], {
          opacity: 1,
          duration: 3,
          delay: iB / 2 + (iA * arrA.length) / 2,
          onComplete: () => {
            gsap.to(arrA[iB], {
              opacity: 0.2,
              duration: 1,
            });
          },
        });
        sentence[theIndex - 1].signElementsTimelines.push(obj);
      }
    }
  }

  if (
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    characterAState.W_L_SKC_G === true ||
    characterBState.W_L_SKC_G === true ||
    characterCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true ||
    characterAState.T_L_SKC_G === true ||
    characterBState.T_L_SKC_G === true ||
    characterCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    characterAState.W_L_SKC === true ||
    characterBState.W_L_SKC === true ||
    characterCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true ||
    characterAState.T_L_SKC === true ||
    characterBState.T_L_SKC === true ||
    characterCState.T_L_SKC === true
  ) {
    let arrA = sentence[theIndex].signElements;
    let arrB = arrA.slice().reverse();
    let arr = [];
    for (let iA = 0; iA < 1; iA++) {
      for (let iB = 0; iB < arrB.length; iB++) {
        let timelineA = gsap.timeline();
        let obj = {};
        timelineA.to(arrB[iB], {
          opacity: 0.2,
          duration: 1,
          delay: iB / 2 + (iA * arrB.length) / 2,
          onComplete: () => {
            let timelineB = gsap.timeline();
            timelineB.to(arrB[iB], {
              opacity: 1,
              duration: 3,
            });
            obj.element = arrB[iB];
            obj.timeline = timelineB;
            arr.push(obj);
          },
        });
        obj.element = arrB[iB];
        obj.timeline = timelineA;
        arr.push(obj);
      }
    }
    sentence[theIndex].signElementsTimelines = arr;
  }

  if (
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    blankAState.W_R_SKC === true ||
    blankBState.W_R_SKC === true ||
    blankCState.W_R_SKC === true ||
    characterAState.W_R_SKC === true ||
    characterBState.W_R_SKC === true ||
    characterCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true ||
    blankAState.T_R_SKC === true ||
    blankBState.T_R_SKC === true ||
    blankCState.T_R_SKC === true ||
    characterAState.T_R_SKC === true ||
    characterBState.T_R_SKC === true ||
    characterCState.T_R_SKC === true
  ) {
    let arrA = sentence[theIndex].signElements.filter(
      (item) =>
        !characterA0[theIndex].selectElements.includes(item) &&
        !characterB0[theIndex].selectElements.includes(item) &&
        !characterC0[theIndex].selectElements.includes(item)
    );
    let arrB = Array.from(
      new Set([
        ...characterA0[theIndex].selectElements,
        ...characterB0[theIndex].selectElements,
        ...characterC0[theIndex].selectElements,
      ])
    );

    for (let iA = 0; iA < 1; iA++) {
      for (let iB = 0; iB < arrA.length; iB++) {
        let timeline = gsap.timeline();
        let obj = {};
        obj.element = arrA[iB];
        obj.timeline = timeline;
        timeline.to(arrA[iB], {
          opacity: 1,
          duration: 3,
          delay: iB / 2 + (iA * arrA.length) / 2,
          onComplete: () => {
            gsap.to(arrA[iB], {
              opacity: 0.2,
              duration: 1,
            });
          },
        });
        sentence[theIndex].signElementsTimelines.push(obj);
      }
    }
    for (let iA = 0; iA < 1; iA++) {
      for (let iB = 0; iB < arrB.length; iB++) {
        let timeline = gsap.timeline();
        let obj = {};
        obj.element = arrB[iB];
        obj.timeline = timeline;
        timeline.to(arrB[iB], {
          opacity: 0.2,
          duration: 3,
          delay: iB / 2 + (iA * arrB.length) / 2,
          onComplete: () => {
            gsap.to(arrB[iB], {
              opacity: 0.2,
              duration: 1,
            });
          },
        });
        sentence[theIndex].signElementsTimelines.push(obj);
      }
    }
  }
};
