import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { characterC2 } from '/js/characterC2/characterC2.js';

export const setCharacterC2SelectElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true
  ) {
    if (characterCState.C === true) {
      let arr = [];
      for (
        let i = characterC0[theIndex].selectInitial;
        i <= characterC0[theIndex].selectEnd;
        i++
      ) {
        arr.push(characterC2[theIndex].elements[i]);
      }
      characterC2[theIndex].selectElements = arr;
    }
  }
};
