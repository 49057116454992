import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { confirm } from '/js/confirm/confirm.js';

export const setConfirmElementOpacity = () => {
  if (logInState.UV === true || logInState.RV === true) {
    gsap.to(confirm.element, {
      opacity: 1,
      duration: 3,
      delay: 1,
    });
  }
  if (confirmState.V === true) {
    gsap.to(confirm.element, {
      opacity: 0,
      duration: 1,
    });
  }
};
