import { setUserNameA0CharacterValue } from '/js/UserNameA0Character/setUserNameA0CharacterValue.js';
import { setUserNameA0CharacterValues } from '/js/UserNameA0Character/setUserNameA0CharacterValues.js';
import { setUserNameA0CharacterIndex } from '/js/UserNameA0Character/setUserNameA0CharacterIndex.js';
import { setUserNameA0CharacterElement } from '/js/UserNameA0Character/setUserNameA0CharacterElement.js';
import { setUserNameA0CharacterElementInnerHTML } from '/js/UserNameA0Character/setUserNameA0CharacterElementInnerHTML.js';
import { addUserNameA0CharacterElementEventListener } from '/js/UserNameA0Character/addUserNameA0CharacterElementEventListener.js';
import { appendUserNameA0CharacterElementToDiv } from '/js/UserNameA0Character/appendUserNameA0CharacterElementToDiv.js';
import { setUserNameA0CharacterElementOpacity } from '/js/UserNameA0Character/setUserNameA0CharacterElementOpacity.js';
import { setUserNameA0CharacterElements } from '/js/UserNameA0Character/setUserNameA0CharacterElements.js';
import { setUserNameA0CharacterElementsId } from '/js/UserNameA0Character/setUserNameA0CharacterElementsId.js';
import { setUserNameValue } from '/js/userName/setUserNameValue.js';

export const userNameStream_A = (event) => {
  setUserNameA0CharacterValue(event);
  setUserNameA0CharacterValues(event);
  setUserNameA0CharacterIndex(event);
  setUserNameA0CharacterElement();
  setUserNameA0CharacterElementInnerHTML(event);
  addUserNameA0CharacterElementEventListener(event);
  appendUserNameA0CharacterElementToDiv(event);
  setUserNameA0CharacterElementOpacity(event);
  setUserNameA0CharacterElements(event);
  setUserNameA0CharacterElementsId(event);
  setUserNameValue();
};
