import { setSentenceXCanClick } from '/js/sentenceX/setSentenceXCanClick.js';
import { setSentenceXCanWheel } from '/js/sentenceX/setSentenceXCanWheel.js';
import { setSentenceXCanTouch } from '/js/sentenceX/setSentenceXCanTouch.js';
import { setSentenceXInputIndex } from '/js/sentenceX/setSentenceXInputIndex.js';
import { setSentenceXSelectIndex } from '/js/sentenceX/setSentenceXSelectIndex.js';
import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0ElementTimelines } from '/js/characterA0/killCharacterA0ElementTimelines.js';
import { killCharacterB0ElementTimelines } from '/js/characterB0/killCharacterB0ElementTimelines.js';
import { killCharacterC0ElementTimelines } from '/js/characterC0/killCharacterC0ElementTimelines.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killCharacterA0PreviousElementTimelines } from '/js/characterA0/killCharacterA0PreviousElementTimelines.js';
import { killCharacterB0PreviousElementTimelines } from '/js/characterB0/killCharacterB0PreviousElementTimelines.js';
import { killCharacterC0PreviousElementTimelines } from '/js/characterC0/killCharacterC0PreviousElementTimelines.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { killSentenceATransformTimeline } from '/js/sentenceA/killSentenceATransformTimeline.js';
import { transformSentenceAElement } from '/js/sentenceA/transformSentenceAElement.js';
import { transformSentenceBElement } from '/js/sentenceB/transformSentenceBElement.js';
import { transformSentenceCElement } from '/js/sentenceC/transformSentenceCElement.js';
import { setSentenceAElementDisplay } from '/js/sentenceA/setSentenceAElementDisplay.js';
import { setSentenceBElementDisplay } from '/js/sentenceB/setSentenceBElementDisplay.js';
import { setSentenceCElementDisplay } from '/js/sentenceC/setSentenceCElementDisplay.js';
import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { setSentenceXCD } from '/js/sentenceX/setSentenceXCD.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';

export const sentenceAStream_T_D = (event) => {
  setSentenceXCanWheel(false);
  // setSentenceXCanTouch(false);
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0ElementTimelines();
  killCharacterB0ElementTimelines();
  killCharacterC0ElementTimelines();
  killCharacterA0PreviousElementTimelines();
  killCharacterB0PreviousElementTimelines();
  killCharacterC0PreviousElementTimelines();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();
  killSentenceATransformTimeline();

  transformSentenceAElement(event);
  transformSentenceBElement(event);
  transformSentenceCElement(event);
  setSentenceAElementDisplay(event);
  setSentenceBElementDisplay(event);
  setSentenceCElementDisplay(event);
  setCharacterA0ElementsOpacity(event);
  setCharacterB0ElementsOpacity(event);
  setCharacterC0ElementsOpacity(event);
  setSentenceXCD(event);
  setTimeout(() => {
    // setSentenceXCanClick(true);
    setSentenceXCanWheel(true);
    // setSentenceXCanTouch(true);
  }, sentenceX.CD * 1000);
};
