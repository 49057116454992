import { setSentenceXGC } from '/js/sentenceX/setSentenceXGC.js';
import { presetCharacterA0Values } from '/js/characterA0/presetCharacterA0Values.js';
import { presetCharacterA0Index } from '/js/characterA0/presetCharacterA0Index.js';
import { setCharacterA0Data } from '/js/characterA0/setCharacterA0Data.js';
import { setCharacterA0PreviousValue } from '/js/characterA0/setCharacterA0PreviousValue.js';
import { setCharacterA0ToBValues } from '/js/characterA0/setCharacterA0ToBValues.js';
import { resetCharacterA0ToBValues } from '/js/characterA0/resetCharacterA0ToBValues.js';
import { resetSentenceXState } from '/js/sentenceXState/resetSentenceXState.js';
import { resetSentenceAState } from '/js/sentenceAState/resetSentenceAState.js';
import { resetSentenceBState } from '/js/sentenceBState/resetSentenceBState.js';
import { resetSentenceCState } from '/js/sentenceCState/resetSentenceCState.js';
import { resetBlankAState } from '/js/blankAState/resetBlankAState.js';
import { resetBlankBState } from '/js/blankBState/resetBlankBState.js';
import { resetBlankCState } from '/js/blankCState/resetBlankCState.js';
import { resetCharacterAState } from '/js/characterAState/resetCharacterAState.js';
import { resetCharacterBState } from '/js/characterBState/resetCharacterBState.js';
import { resetCharacterCState } from '/js/characterCState/resetCharacterCState.js';
import { setSentenceAState } from '/js/sentenceAState/setSentenceAState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceAStream_A } from '/js/sentenceAStream/sentenceAStream_A.js';
import { sentenceAStream_A_C } from '/js/sentenceAStream/sentenceAStream_A_C.js';
import { sentenceAStream_A_ATB } from '/js/sentenceAStream/sentenceAStream_A_ATB.js';
import { sentenceAStream_R_A } from '/js/sentenceAStream/sentenceAStream_R_A.js';
import { sentenceAStream_R_A_C } from '/js/sentenceAStream/sentenceAStream_R_A_C.js';
import { sentenceAStream_R_I_C } from '/js/sentenceAStream/sentenceAStream_R_I_C.js';
import { sentenceAStream_R_I } from '/js/sentenceAStream/sentenceAStream_R_I.js';
import { sentenceAStream_D } from '/js/sentenceAStream/sentenceAStream_D.js';

export const handleSentenceA1ElementInput = (event) => {
  setSentenceXGC(event);
  presetCharacterA0Values(event);
  presetCharacterA0Index(event);
  setCharacterA0Data(event);
  setCharacterA0PreviousValue();
  setCharacterA0ToBValues(event);
  resetSentenceXState(event);
  resetSentenceAState(event);
  resetSentenceBState(event);
  resetSentenceCState(event);
  resetBlankAState(event);
  resetBlankBState(event);
  resetBlankCState(event);
  setSentenceAState(event);
  resetCharacterAState(event);
  resetCharacterBState(event);
  resetCharacterCState(event);
  if (sentenceAState.A === true) {
    sentenceAStream_A(event);
  }
  if (sentenceAState.A_C === true) {
    sentenceAStream_A_C(event);
  }
  if (sentenceAState.A_ATB === true) {
    sentenceAStream_A_ATB(event);
  }
  if (sentenceAState.R_A === true) {
    sentenceAStream_R_A(event);
  }
  if (sentenceAState.R_I === true) {
    sentenceAStream_R_I(event);
  }
  if (sentenceAState.R_A_C === true) {
    sentenceAStream_R_A_C(event);
  }
  if (sentenceAState.R_I_C === true) {
    sentenceAStream_R_I_C(event);
  }
  if (sentenceAState.D === true) {
    sentenceAStream_D(event);
  }
  resetCharacterA0ToBValues(event);
};
