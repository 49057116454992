import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterB0CompleteValues = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];

  if (sentenceBState.A_C === true) {
    let arr = [];
    let index = theCharacterB0.elements.length;
    for (let i = index; i < theCharacterB0.values.length; i++) {
      arr.push(theCharacterB0.values[i]);
    }
    theCharacterB0.completeValues = arr;
  }

  if (sentenceBState.R_A_C === true || sentenceBState.R_I_C === true) {
    let arr = [];
    for (
      let i = theCharacterB0.selectInitial;
      i < theCharacterB0.values.length;
      i++
    ) {
      arr.push(theCharacterB0.values[i]);
    }
    theCharacterB0.completeValues = arr;
  }
};
