import { device } from '/js/device/device.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';

export const setSentenceC0OmitElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  if (
    sentenceXState.T_U === true ||
    sentenceAState.T_U === true ||
    sentenceBState.T_U === true ||
    sentenceCState.T_U === true ||
    blankAState.T_U === true ||
    blankBState.T_U === true ||
    blankCState.T_U === true
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.inputIndex !== undefined) {
        for (const key in sentenceC0) {
          sentenceC0[key].omitElements = [];
        }
        if (theIndex >= 5) {
          for (let i = 1; i < theIndex; i++) {
            sentenceC0[i].omitElements = characterC0[i].elements;
            if (theIndex - i == 3) {
              break;
            }
          }
        }
      }
    }
  }

  if (sentenceXState.T === true) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        for (const key in sentenceC0) {
          sentenceC0[key].omitElements = [];
        }
      } else if (sentenceX.inputIndex !== undefined) {
        for (const key in sentenceC0) {
          sentenceC0[key].omitElements = [];
        }
        if (theIndex > 5) {
          for (let i = 1; i < theIndex; i++) {
            sentenceC0[i].omitElements = characterC0[i].elements;
            if (theIndex - i == 4) {
              break;
            }
          }
        }
      }
    }
  }

  if (
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        for (const key in sentenceC0) {
          sentenceC0[key].omitElements = [];
        }
      } else if (sentenceX.inputIndex !== undefined) {
        for (const key in sentenceC0) {
          sentenceC0[key].omitElements = [];
        }
        let numberA = 0;
        let numberB = 0;
        for (const key in sentenceC0) {
          if (sentenceC0[key].value !== '') {
            numberA = key;
          }
        }
        if (numberA > 5) {
          if (theIndex <= 5) {
            for (let i = numberA; i > 1; i--) {
              numberB += 1;
              sentenceC0[i].omitElements = characterC0[i].elements;
              if (numberA - numberB == 5) {
                break;
              }
            }
          } else if (theIndex > 5) {
            for (let i = 1; i < theIndex; i++) {
              numberB += 1;
              sentenceC0[i].omitElements = characterC0[i].elements;
              if (numberA - numberB == 4) {
                break;
              }
            }
          }
        }
      }
    }
  }
};
