import { logInState } from '/js/logInState/logInState.js';
import { logIn } from '/js/logIn/logIn.js';

export const presetLogInElementOpacity = () => {
  if (logInState.C === true) {
    gsap.to(logIn.element, {
      opacity: 0.2,
      duration: 1,
    });
  }
};
