import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0LastElementTimeline } from '/js/characterA0/killCharacterA0LastElementTimeline.js';
import { killCharacterB0LastElementTimeline } from '/js/characterB0/killCharacterB0LastElementTimeline.js';
import { killCharacterC0LastElementTimeline } from '/js/characterC0/killCharacterC0LastElementTimeline.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { setCharacterC0Values } from '/js/characterC0/setCharacterC0Values.js';
import { setCharacterC0Value } from '/js/characterC0/setCharacterC0Value.js';
import { setCharacterC0Index } from '/js/characterC0/setCharacterC0Index.js';
import { setCharacterC0Element } from '/js/characterC0/setCharacterC0Element.js';
import { setCharacterC0ElementInnerHTML } from '/js/characterC0/setCharacterC0ElementInnerHTML.js';
import { addCharacterC0ElementEventListener } from '/js/characterC0/addCharacterC0ElementEventListener.js';
import { appendCharacterC0Element } from '/js/characterC0/appendCharacterC0Element.js';
import { setCharacterC0ElementOpacity } from '/js/characterC0/setCharacterC0ElementOpacity.js';
import { setCharacterC0Elements } from '/js/characterC0/setCharacterC0Elements.js';
import { setCharacterC0ElementsId } from '/js/characterC0/setCharacterC0ElementsId.js';
import { setSentenceC0Value } from '/js/sentenceC0/setSentenceC0Value.js';
import { setSentenceC0SplitValues } from '/js/sentenceC0/setSentenceC0SplitValues.js';
import { setSentenceC0SplitCounts } from '/js/sentenceC0/setSentenceC0SplitCounts.js';
import { setSentenceC0SplitInitials } from '/js/sentenceC0/setSentenceC0SplitInitials.js';
import { setCharacterC0ElementsClassName } from '/js/characterC0/setCharacterC0ElementsClassName.js';
import { setCharacterC0LastElementOpacity } from '/js/characterC0/setCharacterC0LastElementOpacity.js';
import { setCharacterC0PreviousElement } from '/js/characterC0/setCharacterC0PreviousElement.js';
import { setCharacterC0PreviousElementOpacity } from '/js/characterC0/setCharacterC0PreviousElementOpacity.js';
import { setCharacterC0PreviousElements } from '/js/characterC0/setCharacterC0PreviousElements.js';
import { setCharacterC0PreviousElementsOpacity } from '/js/characterC0/setCharacterC0PreviousElementsOpacity.js';
import { setSentenceValue } from '/js/sentence/setSentenceValue.js';
import { setSentenceXPKA } from '/js/sentenceX/setSentenceXPKA.js';
import { setSentenceXSKA } from '/js/sentenceX/setSentenceXSKA.js';
import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { setSentenceC1ElementValue } from '/js/sentenceC1/setSentenceC1ElementValue.js';

export const sentenceCStream_A = (event) => {
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0LastElementTimeline();
  killCharacterB0LastElementTimeline();
  killCharacterC0LastElementTimeline();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterC0Values();
  setCharacterC0Value();
  setCharacterC0Index();
  setCharacterC0Element();
  setCharacterC0ElementInnerHTML();
  addCharacterC0ElementEventListener();
  appendCharacterC0Element();
  setCharacterC0ElementOpacity();
  setCharacterC0Elements();
  setCharacterC0ElementsId();
  setSentenceC0Value(event);
  setSentenceC0SplitValues();
  setSentenceC0SplitCounts();
  setSentenceC0SplitInitials();
  setCharacterC0ElementsClassName();
  setCharacterC0LastElementOpacity();
  setCharacterC0PreviousElement();
  setCharacterC0PreviousElementOpacity();
  setCharacterC0PreviousElements();
  setCharacterC0PreviousElementsOpacity();

  setSentenceXPKA(event);
  setSentenceXSKA(event);
  // setCharacterA0ElementsOpacity();
  // setCharacterB0ElementsOpacity();
  // setCharacterC0ElementsOpacity();
  setSentenceC1ElementValue();
};
