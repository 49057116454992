import { gsap } from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterB0PreviousElementOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];

  if (sentenceBState.A === true) {
    if (theCharacterB0.selectElements.length == 0) {
      if (theCharacterB0.previousElement !== undefined) {
        let timeline = gsap.timeline();
        let element = theCharacterB0.previousElement;
        const checkOpacity = () => {
          const number = parseFloat(element.style.opacity);
          if (number === 1) {
            timeline.to(element, {
              opacity: 0.4,
              duration: 1,
            });
            theCharacterB0.previousElementTimelines.push(timeline);
            clearInterval(checkInterval);
          }
        };
        const checkInterval = setInterval(checkOpacity, 10);
      }
    }
  }

  if (sentenceBState.D === true) {
    let timeline = gsap.timeline();
    if (theCharacterB0.previousElement !== undefined) {
      timeline.to(theCharacterB0.previousElement, {
        opacity: 1,
        duration: 3,
        delay: 1,
      });
      theCharacterB0.previousElementTimelines.push(timeline);
    }
  }
};
