export const blankBState = {
  A: false,
  A_ATB: false,
  D: false,
  C: false,
  W_U: false,
  W_D: false,
  W_L_SKA: false,
  W_R_SKA: false,
  W_L_SKA_G1: false,
  W_L_SKA_G2: false,
};
