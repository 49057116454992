import { sentenceC1 } from '/js/sentenceC1/sentenceC1.js';
import { handleSentenceC1ElementInput } from '/js/sentenceC1/handleSentenceC1ElementInput.js';

export const addSentenceC1ElementEventListener = () => {
  for (const key in sentenceC1) {
    sentenceC1[key].element.addEventListener(
      'input',
      handleSentenceC1ElementInput
    );
  }
};
