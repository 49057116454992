import { setAuthenticationElementOpacity } from '/js/authentication/setAuthenticationElementOpacity.js';
import { setAuthenticationElementDisplay } from '/js/authentication/setAuthenticationElementDisplay.js';
import { setLogInElementOpacity } from '/js/logIn/setLogInElementOpacity.js';
import { setLogInElementDisplay } from '/js/logIn/setLogInElementDisplay.js';
import { setUserNameA1ElementFocus } from '/js/userNameA1/setUserNameA1ElementFocus.js';
import { setSentenceXElementOpacity } from '/js/sentenceX/setSentenceXElementOpacity.js';
import { setSentenceXElementDisplay } from '/js/sentenceX/setSentenceXElementDisplay.js';

export const sentenceXStream_L = () => {
  setAuthenticationElementOpacity();
  setAuthenticationElementDisplay();
  setLogInElementOpacity();
  setLogInElementDisplay();
  setSentenceXElementOpacity();
  setSentenceXElementDisplay();
  setUserNameA1ElementFocus();
};
