import { userNameState } from '/js/userNameState/userNameState.js';
import { passWordState } from '/js/passWordState/passWordState.js';
import { logInState } from '/js/logInState/logInState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';

export const killUserNameA0CharacterElementsTimeline = () => {
  if (
    userNameState.C === true ||
    passWordState.C === true ||
    logInState.C === true
  ) {
    if (userNameA0Character.elementsTimeline !== undefined) {
      userNameA0Character.elementsTimeline.kill();
    }
  }
};
