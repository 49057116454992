import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { handleCharacterA0ElementClick } from '/js/characterA0/handleCharacterA0ElementClick.js';
import { handleCharacterA0ElementWheel } from '/js/characterA0/handleCharacterA0ElementWheel.js';
import { handleCharacterA0ElementTouchStart } from '/js/characterA0/handleCharacterA0ElementTouchStart.js';
import { handleCharacterA0ElementTouchEnd } from '/js/characterA0/handleCharacterA0ElementTouchEnd.js';

export const addCharacterC0LastElementEventListener = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (sentenceCState.R_I === true || sentenceCState.R_A === true) {
    theCharacterC0.lastElement.addEventListener(
      'click',
      handleCharacterA0ElementClick
    );
    theCharacterC0.lastElement.addEventListener(
      'wheel',
      handleCharacterA0ElementWheel
    );
    theCharacterC0.lastElement.addEventListener(
      'touchstart',
      handleCharacterA0ElementTouchStart
    );
    theCharacterC0.lastElement.addEventListener(
      'touchend',
      handleCharacterA0ElementTouchEnd
    );
  }
};
