import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setSentenceCState = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theSentenceC0 = sentenceC0[theIndex];
  let theCharacterC0 = characterC0[theIndex];

  if (
    event.type === 'click' &&
    event.target.nodeName === 'DIV' &&
    event.target.id[11] === 'C'
  ) {
    sentenceCState.C = true;
  }

  if (
    sentenceX.canInput === true &&
    event.type === 'input' &&
    event.inputType === 'insertText' &&
    event.target.id[11] === 'C' &&
    event.data.length == 1 &&
    theCharacterC0.values.length < 30 &&
    (theCharacterC0.previousValue !== ' ' || theCharacterC0.data !== ' ') &&
    characterBState.C === false &&
    characterBState.T === false
  ) {
    sentenceCState.A = true;
  }

  if (
    sentenceX.canInput === true &&
    event.type === 'input' &&
    (event.inputType === 'insertText' ||
      event.inputType === 'insertReplacementText') &&
    event.data.length > 1 &&
    event.target.id[11] === 'C' &&
    theCharacterC0.values.length < 30 &&
    (theCharacterC0.previousValue !== ' ' || theCharacterC0.data !== ' ') &&
    characterCState.C === false &&
    characterCState.T === false
  ) {
    sentenceCState.A_C = true;
  }

  if (
    event.type === 'input' &&
    event.inputType === 'insertText' &&
    event.target.id[11] === 'C' &&
    event.data.length === 1 &&
    Object.is(theCharacterC0.referenceElement, undefined) === true &&
    (theCharacterC0.previousValue !== ' ' || theCharacterC0.data !== ' ') &&
    (characterCState.C === true || characterCState.T === true)
  ) {
    sentenceCState.R_A = true;
  }

  if (
    event.type === 'input' &&
    event.inputType === 'insertText' &&
    event.target.id[11] === 'C' &&
    event.data.length === 1 &&
    Object.is(theCharacterC0.referenceElement, undefined) === false &&
    (theCharacterC0.previousValue !== ' ' || theCharacterC0.data !== ' ') &&
    (characterCState.C === true || characterCState.T === true)
  ) {
    sentenceCState.R_I = true;
  }

  if (
    event.type === 'input' &&
    (event.inputType === 'insertText' ||
      event.inputType === 'insertReplacementText') &&
    event.target.id[11] === 'C' &&
    event.data.length > 1 &&
    Object.is(theCharacterC0.referenceElement, undefined) === true &&
    (theCharacterC0.previousValue !== ' ' || theCharacterC0.data !== ' ') &&
    (characterCState.C === true || characterCState.T === true)
  ) {
    sentenceCState.R_A_C = true;
  }

  if (
    event.type === 'input' &&
    event.inputType === 'insertText' &&
    event.target.id[11] === 'C' &&
    event.data.length > 1 &&
    Object.is(theCharacterC0.referenceElement, undefined) === false &&
    (theCharacterC0.previousValue !== ' ' || theCharacterC0.data !== ' ') &&
    (characterCState.C === true || characterCState.T === true)
  ) {
    sentenceCState.R_I_C = true;
  }

  if (
    event.type === 'input' &&
    event.inputType === 'deleteContentBackward' &&
    event.target.id[11] === 'C' &&
    theCharacterC0.values.length > 0
  ) {
    sentenceCState.D = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.id[11] === 'C' &&
    event.deltaX === 0 &&
    event.deltaY > 0 &&
    theIndex < 9 &&
    theSentenceC0.value !== ''
  ) {
    sentenceCState.W_U = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.id[11] === 'C' &&
    event.deltaX === 0 &&
    event.deltaY < 0 &&
    theIndex < 9 &&
    theSentenceC0.value !== ''
  ) {
    sentenceCState.W_D = true;
  }

  if (
    event.type === 'wheel' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    event.target.id[11] === 'C' &&
    theIndex === 0 &&
    sentenceX.dataVK.length === 0 &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    sentenceCState.W_L_VK_G = true;
  }

  if (
    event.type === 'wheel' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    event.target.id[11] === 'C' &&
    sentenceX.dataVK.length !== 0 &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let arr = sentenceX.dataVK.filter(
      (item) =>
        item[theIndex] !== sentence[theIndex].value &&
        sentenceX.historyData[theIndex].includes(item[theIndex]) === false
    );
    if (arr.length > 0) {
      sentenceCState.W_L_VK = true;
    } else {
      sentenceCState.W_L_VK = false;
    }
  }

  if (
    event.type === 'wheel' &&
    event.deltaX < 0 &&
    event.deltaY === 0 &&
    event.target.id[11] === 'C' &&
    sentenceX.dataVK.length !== 0 &&
    sentenceX.historyData[theIndex].length > 0
  ) {
    sentenceCState.W_R_VK = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.id[11] === 'C' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    sentenceX.dataSKC[sentenceX.SKC] === undefined &&
    (characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true)
  ) {
    sentenceCState.W_L_SKC_G = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.id[11] === 'C' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined &&
    (characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true)
  ) {
    sentenceCState.W_L_SKC = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.id[11] === 'C' &&
    event.deltaX < 0 &&
    event.deltaY === 0 &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined &&
    sentenceX.historyData[theIndex].length > 0 &&
    (characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true)
  ) {
    sentenceCState.W_R_SKC = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.id[11] === 'C' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] === undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    sentenceCState.W_L_SKA_G1 = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.id[11] === 'C' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
      (item) =>
        item[theIndex] !== sentence[theIndex].value &&
        sentenceX.historyData[theIndex].includes(item[theIndex]) === false
    );
    if (arr.length == 0) {
      sentenceCState.W_L_SKA_G2 = true;
    } else {
      sentenceCState.W_L_SKA_G2 = false;
    }
  }

  if (
    event.type === 'wheel' &&
    event.target.id[11] === 'C' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
      (item) =>
        item[theIndex] !== sentence[theIndex].value &&
        sentenceX.historyData[theIndex].includes(item[theIndex]) === false
    );
    if (arr.length > 0) {
      sentenceCState.W_L_SKA = true;
    } else {
      sentenceCState.W_L_SKA = false;
    }
  }

  if (
    event.type === 'wheel' &&
    event.target.id[11] === 'C' &&
    event.deltaX < 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    sentenceX.historyData[theIndex].length > 0 &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
      (item) => item[theIndex] !== sentence[theIndex].value
    );
    if (arr.length > 0) {
      sentenceCState.W_R_SKA = true;
    } else {
      sentenceCState.W_R_SKA = false;
    }
  }

  if (event.type === 'touchend' && event.target.id[11] === 'C') {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) < 20 && Math.abs(deltaY) < 20) {
      sentenceCState.T = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    theSentenceC0.value !== ''
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY < -20) {
      sentenceCState.T_U = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    theSentenceC0.value !== ''
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY > 20) {
      sentenceCState.T_D = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    theIndex === 0 &&
    sentenceX.dataVK.length === 0
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;

    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      sentenceCState.T_L_VK_G = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    theIndex === 0 &&
    sentenceX.dataVK.length > 0
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      sentenceCState.T_L_VK = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    theIndex === 0 &&
    sentenceX.dataVK.length > 0 &&
    sentenceX.historyData[theIndex].length > 0
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
      let arr = sentenceX.dataVK.filter(
        (item) => item[theIndex] !== sentence[theIndex].value
      );
      if (arr.length > 0) {
        sentenceCState.T_R_VK = true;
      } else {
        sentenceCState.T_R_VK = false;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] === undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      sentenceCState.T_L_SKA_G1 = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
        (item) =>
          item[theIndex] !== sentence[theIndex].value &&
          sentenceX.historyData[theIndex].includes(item[theIndex]) === false
      );
      if (arr.length == 0) {
        sentenceCState.T_L_SKA_G2 = true;
      } else {
        sentenceCState.T_L_SKA_G2 = false;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
        (item) =>
          item[theIndex] !== sentence[theIndex].value &&
          sentenceX.historyData[theIndex].includes(item[theIndex]) === false
      );
      if (arr.length > 0) {
        sentenceCState.T_L_SKA = true;
      } else {
        sentenceCState.T_L_SKA = false;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    sentenceX.historyData[theIndex].length > 0 &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
      let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
        (item) => item[theIndex] !== sentence[theIndex].value
      );
      if (arr.length > 0) {
        sentenceCState.T_R_SKA = true;
      } else {
        sentenceCState.T_R_SKA = false;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    sentenceX.dataSKC[sentenceX.SKC] === undefined &&
    (characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true)
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      sentenceCState.T_L_SKC_G = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined &&
    (characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true)
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      sentenceCState.T_L_SKC = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[11] === 'C' &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined &&
    sentenceX.historyData[theIndex].length > 0 &&
    (characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true)
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
      sentenceCState.T_R_SKC = true;
    }
  }
};
