import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterBState } from '/js/characterBState/characterBState.js';

export const setCharacterB0ReferenceElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  let theCharacterB0 = characterB0[theIndex];
  if (characterBState.C === true || characterBState.T === true) {
    theCharacterB0.referenceElement =
      theCharacterB0.selectElements[
        theCharacterB0.selectElements.length - 1
      ].nextSibling;
    if (theCharacterB0.referenceElement === null) {
      theCharacterB0.referenceElement = undefined;
    }
  }
  if (sentenceBState.R_I === true || sentenceBState.R_I_C === true) {
    setTimeout(() => {
      theCharacterB0.referenceElement = undefined;
    }, 1000);
  }
  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    theCharacterB0.referenceElement = undefined;
  }
};
