import { sentence } from '/js/sentence/sentence.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';

export const presetSentenceA0Value = (event) => {
  if (event.type === 'wheel' || event.type === 'touchend') {
    for (const key in sentenceA0) {
      let str = sentenceA0[key].value;
      if (str[str.length - 1] === ' ') {
        str = str.slice(0, -1);
        sentenceA0[key].value = str;
      }
    }
  }

  if (sentenceXState.W_L_SKC_G === true) {
    for (const key in sentence) {
      let str = '';
      let arr = sentence[key].value.split(' ');
      for (const item of arr) {
        str += item;
        str += ' ';
        if (str.length > 30) {
          break;
        }
      }
      str = str.slice(0, -1);

      if (str.length > 30) {
        for (let i = str.length - 1; i >= 0; i--) {
          if (str[i] === ' ') {
            str = str.substring(0, i);
            break;
          }
        }
      }
      sentenceA0[key].value = str;
    }
  }
};
