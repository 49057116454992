import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

import { handleCharacterA0ElementClick } from '/js/characterA0/handleCharacterA0ElementClick.js';
import { handleCharacterA0ElementWheel } from '/js/characterA0/handleCharacterA0ElementWheel.js';
import { handleCharacterA0ElementTouchStart } from '/js/characterA0/handleCharacterA0ElementTouchStart.js';
import { handleCharacterA0ElementTouchEnd } from '/js/characterA0/handleCharacterA0ElementTouchEnd.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const setCharacterA0CompleteElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  let theSentenceA0 = sentenceA0[theIndex];
  if (
    sentenceAState.A_C === true ||
    sentenceAState.R_A_C === true ||
    sentenceAState.R_I_C === true
  ) {
    let arr = [];
    for (const item of theCharacterA0.completeValues) {
      let element = document.createElement('span');
      if (item === ' ') {
        element.innerHTML = '&nbsp;';
      } else {
        element.innerHTML = item;
      }
      element.addEventListener('click', handleCharacterA0ElementClick);
      element.addEventListener('wheel', handleCharacterA0ElementWheel);
      element.addEventListener(
        'touchstart',
        handleCharacterA0ElementTouchStart
      );
      element.addEventListener('touchend', handleCharacterA0ElementTouchEnd);
      theSentenceA0.element.appendChild(element);
      arr.push(element);
    }
    theCharacterA0.completeElements = arr;
  }
};
