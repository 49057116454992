import { userNameState } from '/js/userNameState/userNameState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';
import { userName } from '/js/userName/userName.js';
export const setUserNameValue = () => {
  if (userNameState.A === true || userNameState.D === true) {
    let str = '';
    for (const item of userNameA0Character.values) {
      str += item;
    }
    userName.value = str;
  }
};
