import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterAState } from '/js/characterAState/characterAState.js';

export const presetCharacterA0Values = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (characterAState.C === true) {
    if (
      event.inputType === 'insertText' ||
      event.inputType === 'insertReplacementText'
    ) {
      let arr = [];
      for (const item of theCharacterA0.elements) {
        if (
          theCharacterA0.selectElements.includes(item) === false &&
          theCharacterA0.nextElements.includes(item) === false
        ) {
          if (item.innerHTML === '&nbsp;') {
            arr.push(' ');
          } else {
            arr.push(item.innerHTML);
          }
        }
      }
      theCharacterA0.values = arr;
    } else if (event.inputType === 'deleteContentBackward') {
      let arr = [];
      for (const item of theCharacterA0.elements) {
        if (theCharacterA0.nextElements.includes(item) === false) {
          if (item.innerHTML === '&nbsp;') {
            arr.push(' ');
          } else {
            arr.push(item.innerHTML);
          }
        }
      }
      theCharacterA0.values = arr;
    }
  }

  if (event.data !== null) {
    if (event.data.length > 1) {
      for (let i = theCharacterA0.values.length - 1; i >= 0; i--) {
        if (theCharacterA0.values[i] === ' ') {
          break;
        }
        theCharacterA0.values.pop();
      }
      for (const item of event.data) {
        theCharacterA0.values.push(item);
      }
    }
  }
};
