import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const setCharacterA0LastElementInnerHTML = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (sentenceAState.R_A === true || sentenceAState.R_I === true) {
    if (theCharacterA0.value === ' ') {
      theCharacterA0.lastElement.innerHTML = '&nbsp;';
    } else {
      theCharacterA0.lastElement.innerHTML = theCharacterA0.value;
    }
  }
};
