import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';

export const setSentenceXPKB = () => {
  let number = 0;
  let str = '';
  for (const key in sentenceA0) {
    if (sentenceA0[key].value !== '') {
      number = key;
    }
  }
  if (sentenceA0[number].value !== '') {
    str =
      sentenceA0[number].splitValues[sentenceA0[number].splitValues.length - 1];
    if (sentenceB0[number].value !== '') {
      str =
        sentenceB0[number].splitValues[
          sentenceB0[number].splitValues.length - 1
        ];
      if (sentenceC0[number].value !== '') {
        str =
          sentenceC0[number].splitValues[
            sentenceC0[number].splitValues.length - 1
          ];
      }
    }
  }

  str = str.split('').reverse().join('');
  str = '&' + str;
  sentenceX.PKB = str;
};
