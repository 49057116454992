import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const removeCharacterA0ToBElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (sentenceAState.A_ATB === true) {
    if (theCharacterA0.selectElements.length > 0) {
      setTimeout(() => {
        let element = theCharacterA0.toBElements[0].previousElementSibling;
        element.remove();
        for (const item of theCharacterA0.toBElements) {
          item.remove();
        }
        theCharacterA0.toBElements = [];
      }, 2000);
    } else {
      setTimeout(() => {
        let element = theCharacterA0.toBElements[0].previousElementSibling;
        element.remove();
        for (const item of theCharacterA0.toBElements) {
          item.remove();
        }
        theCharacterA0.toBElements = [];
      }, 1000);
    }
  }
};
