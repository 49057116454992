import { codeState } from '/js/codeState/codeState.js';
import { codeCharacter } from '/js/codeCharacter/codeCharacter.js';

export const setCodeCharacterValues = () => {
  if (codeState.A === true) {
    codeCharacter.values.splice(
      codeCharacter.index + 1,
      0,
      codeCharacter.data
    );
  }

  if (codeState.D === true) {
    codeCharacter.values.splice(codeCharacter.index, 1);
  }
};
