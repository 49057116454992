import { setPassWordA0CharacterData } from '/js/passWordA0Character/setPassWordA0CharacterData.js';
import { setPassWordState } from '/js/passWordState/setPassWordState.js';
import { passWordState } from '/js/passWordState/passWordState.js';
import { passWordStream_A } from '/js/passWordStream/passWordStream_A.js';
import { passWordStream_D } from '/js/passWordStream/passWordStream_D.js';

export const handlePassWordA1ElementInput = (event) => {
  setPassWordA0CharacterData(event);
  setPassWordState(event);
  if (passWordState.A === true) {
    passWordStream_A(event);
  }
  if (passWordState.D === true) {
    passWordStream_D(event);
  }
};
