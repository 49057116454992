import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';

export const appendCharacterC0LastElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theSentenceC0 = sentenceC0[theIndex];
  let theCharacterC0 = characterC0[theIndex];

  if (sentenceCState.R_A === true) {
    let element = theCharacterC0.lastElement;
    theSentenceC0.element.appendChild(element);
  }
};
