import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterBState } from '/js/characterBState/characterBState.js';

export const setCharacterB0SelectEnd = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (characterBState.C === true || characterBState.T === true) {
    theCharacterB0.selectEnd = parseInt(
      theCharacterB0.selectElements[
        theCharacterB0.selectElements.length - 1
      ].id.slice(15)
    );
  }
  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    theCharacterB0.selectEnd = 0;
  }

  if (sentenceXState.W_L_SKC_G === true) {
    let number = 0;
    for (
      let i = characterB0[theIndex].selectInitial;
      i < sentenceB0[theIndex].value.length;
      i++
    ) {
      if (sentenceB0[theIndex].value[i] == ' ') {
        break;
      }
      number = i;
    }
    characterB0[theIndex].selectEnd = number;
  }
};
