import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentence } from '/js/sentence/sentence.js';

export const resetSentenceXSKA = () => {
  let str = '';
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  for (const key in sentence) {
    if (key < theIndex) {
      str += sentence[key].value;
      str += '&';
    }
  }
  sentenceX.SKA = str;
};
