import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const appendCharacterC0FromBElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theSentenceC0 = sentenceC0[theIndex];
  let theCharacterB0 = characterB0[theIndex];
  let theCharacterC0 = characterC0[theIndex];
  if (sentenceBState.A_BTC === true) {
    if (theCharacterB0.selectElements.length > 0) {
      setTimeout(() => {
        for (const item of theCharacterC0.fromBElements) {
          theSentenceC0.element.appendChild(item);
        }
      }, 1000);
    } else {
      for (const item of theCharacterC0.fromBElements) {
        theSentenceC0.element.appendChild(item);
      }
    }
  }
};
