import { userName } from '/js/userName/userName.js';
import { passWord } from '/js/passWord/passWord.js';
import { code } from '/js/code/code.js';
import { setCodeElementOpacity } from '/js/code/setCodeElementOpacity.js';
import { setCodeElementDisplay } from '/js/code/setCodeElementDisplay.js';
import { setConfirmElementOpacity } from '/js/confirm/setConfirmElementOpacity.js';
import { setConfirmElementDisplay } from '/js/confirm/setConfirmElementDisplay.js';

import { setSentenceXUserId } from '/js/sentenceX/setSentenceXUserId.js';
import { setSentenceXUserName } from '/js/sentenceX/setSentenceXUserName.js';

import { setAuthenticationElementOpacity } from '/js/authentication/setAuthenticationElementOpacity.js';
import { setAuthenticationElementDisplay } from '/js/authentication/setAuthenticationElementDisplay.js';

import { setSentenceXElementOpacity } from '/js/sentenceX/setSentenceXElementOpacity.js';
import { setSentenceXElementDisplay } from '/js/sentenceX/setSentenceXElementDisplay.js';
import { setSentenceA1ElementFocus } from '/js/sentenceA1/setSentenceA1ElementFocus.js';
import { setMessageElementInnerHTML } from '/js/message/setMessageElementInnerHTML.js';
import { setMessageElementOpacity } from '/js/message/setMessageElementOpacity.js';
import { presetConfirmElementOpacity } from '/js/confirm/presetConfirmElementOpacity.js';
import { resetConfirmState } from '/js/confirmState/resetConfirmState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { confirmStream_V } from '/js/confirmStream/confirmStream_V.js';
export const confirmStream_C = () => {
  presetConfirmElementOpacity();
  let url = `https://c0rc1dqmue.execute-api.us-east-1.amazonaws.com/${userName.value}/${passWord.value}/${code.value}`;

  fetch(url, {
    method: 'POST',
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      resetConfirmState(data);
      if (confirmState.V === true) {
        confirmStream_V(data);
      }
      // if (res.data.body === undefined) {
      //   setCodeElementOpacity();
      //   setCodeElementDisplay();
      //   setConfirmElementOpacity();
      //   setConfirmElementDisplay();

      //   setSentenceXUserId(res.data.Username);
      //   setSentenceXUserName();
      //   setAuthenticationElementOpacity();
      //   setAuthenticationElementDisplay();
      //   setSentenceXElementOpacity();
      //   setSentenceXElementDisplay();
      //   setSentenceA1ElementFocus();
      // } else {
      //   if (
      //     JSON.parse(res.data.body) ===
      //     'Invalid verification code provided, please try again.'
      //   ) {
      //     setMessageElementInnerHTML('Invalid verification code');
      //     setMessageElementOpacity();
      //   }
      // }
    })
    .catch((error) => {
      return error;
    });
};
