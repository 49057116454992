import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setCharacterBState = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theSentenceB0 = sentenceB0[theIndex];

  if (
    event.type === 'click' &&
    event.target.nodeName === 'SPAN' &&
    event.target.className !== '' &&
    event.target.id[12] === 'B'
  ) {
    characterBState.C = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.id[12] === 'B' &&
    event.deltaX === 0 &&
    event.deltaY > 0 &&
    theIndex < 9 &&
    theSentenceB0.value !== ''
  ) {
    characterBState.W_U = true;
  }
  if (
    event.type === 'wheel' &&
    event.target.id[12] === 'B' &&
    event.deltaX === 0 &&
    event.deltaY < 0 &&
    theIndex < 9 &&
    theSentenceB0.value !== ''
  ) {
    characterBState.W_D = true;
  }

  // if (
  //   event.type === 'wheel' &&
  //   event.deltaX > 0 &&
  //   event.deltaY === 0 &&
  //   theIndex === 0 &&
  //   sentenceX.dataRA.length === 0
  // ) {
  //   characterBState.W_L_RANDA_G1 = true;
  // }

  if (
    event.type === 'wheel' &&
    event.target.id[12] === 'B' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] === undefined
  ) {
    characterBState.W_L_SKA_G1 = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.id[12] === 'B' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined
  ) {
    let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
      (item) =>
        item[theIndex] !== sentence[theIndex].value &&
        sentenceX.historyData.includes(item[theIndex]) === false
    );
    if (arr.length == 0) {
      characterBState.W_L_SKA_G2 = true;
    } else {
      characterBState.W_L_SKA_G2 = false;
    }
  }
  if (
    event.type === 'wheel' &&
    event.target.id[12] === 'B' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined
  ) {
    let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
      (item) =>
        item[theIndex] !== sentence[theIndex].value &&
        sentenceX.historyData.includes(item[theIndex]) === false
    );
    if (arr.length > 0) {
      characterBState.W_L_SKA = true;
    } else {
      characterBState.W_L_SKA = false;
    }
  }

  if (
    event.type === 'wheel' &&
    event.target.id[12] === 'B' &&
    event.deltaX < 0 &&
    event.deltaY === 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    sentenceX.historyData.length > 0
  ) {
    let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
      (item) => item[theIndex] !== sentence[theIndex].value
    );
    if (arr.length > 0) {
      characterBState.W_R_SKA = true;
    } else {
      characterBState.W_R_SKA = false;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.nodeName === 'SPAN' &&
    event.target.className !== '' &&
    event.target.id[12] === 'B'
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    let numberX = Math.abs(deltaX);
    let numberY = Math.abs(deltaY);
    if (numberX < 20 && numberY < 20) {
      characterBState.T = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[12] === 'B' &&
    theSentenceB0.value !== ''
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        let deltaX = sentenceX.endX - sentenceX.startX;
        let deltaY = sentenceX.endY - sentenceX.startY;
        if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY < -20) {
          characterBState.T_U = true;
        }
      }
    } else {
      let deltaX = sentenceX.endX - sentenceX.startX;
      let deltaY = sentenceX.endY - sentenceX.startY;
      if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY < -20) {
        characterBState.T_U = true;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[12] === 'B' &&
    theSentenceB0.value !== ''
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        let deltaX = sentenceX.endX - sentenceX.startX;
        let deltaY = sentenceX.endY - sentenceX.startY;
        if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY > 20) {
          characterBState.T_D = true;
        }
      }
    } else {
      let deltaX = sentenceX.endX - sentenceX.startX;
      let deltaY = sentenceX.endY - sentenceX.startY;
      if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY > 20) {
        characterBState.T_D = true;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[12] === 'B' &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] === undefined
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        if (
          characterAState.C === false &&
          characterBState.C === false &&
          characterCState.C === false
        ) {
          let deltaX = sentenceX.endX - sentenceX.startX;
          let deltaY = sentenceX.endY - sentenceX.startY;

          if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
            characterBState.T_L_SKA_G1 = true;
          }
        }
      }
    } else {
      if (
        characterAState.C === false &&
        characterBState.C === false &&
        characterCState.C === false
      ) {
        let deltaX = sentenceX.endX - sentenceX.startX;
        let deltaY = sentenceX.endY - sentenceX.startY;

        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
          characterBState.T_L_SKA_G1 = true;
        }
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[12] === 'B' &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        if (
          characterAState.C === false &&
          characterBState.C === false &&
          characterCState.C === false
        ) {
          let deltaX = sentenceX.endX - sentenceX.startX;
          let deltaY = sentenceX.endY - sentenceX.startY;
          if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
            let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
              (item) =>
                item[theIndex] !== sentence[theIndex].value &&
                sentenceX.historyData[theIndex].includes(item[theIndex]) ===
                  false
            );
            if (arr.length == 0) {
              characterBState.T_L_SKA_G2 = true;
            } else {
              characterBState.T_L_SKA_G2 = false;
            }
          }
        }
      }
    } else {
      if (
        characterAState.C === false &&
        characterBState.C === false &&
        characterCState.C === false
      ) {
        let deltaX = sentenceX.endX - sentenceX.startX;
        let deltaY = sentenceX.endY - sentenceX.startY;
        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
          let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
            (item) =>
              item[theIndex] !== sentence[theIndex].value &&
              sentenceX.historyData[theIndex].includes(item[theIndex]) === false
          );
          if (arr.length == 0) {
            characterBState.T_L_SKA_G2 = true;
          } else {
            characterBState.T_L_SKA_G2 = false;
          }
        }
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[12] === 'B' &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        if (
          characterAState.C === false &&
          characterBState.C === false &&
          characterCState.C === false
        ) {
          let deltaX = sentenceX.endX - sentenceX.startX;
          let deltaY = sentenceX.endY - sentenceX.startY;
          if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
            let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
              (item) =>
                item[theIndex] !== sentence[theIndex].value &&
                sentenceX.historyData[theIndex].includes(item[theIndex]) ===
                  false
            );
            if (arr.length > 0) {
              characterBState.T_L_SKA = true;
            } else {
              characterBState.T_L_SKA = false;
            }
          }
        }
      }
    } else {
      if (
        characterAState.C === false &&
        characterBState.C === false &&
        characterCState.C === false
      ) {
        let deltaX = sentenceX.endX - sentenceX.startX;
        let deltaY = sentenceX.endY - sentenceX.startY;
        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
          let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
            (item) =>
              item[theIndex] !== sentence[theIndex].value &&
              sentenceX.historyData[theIndex].includes(item[theIndex]) === false
          );
          if (arr.length > 0) {
            characterBState.T_L_SKA = true;
          } else {
            characterBState.T_L_SKA = false;
          }
        }
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[12] === 'B' &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    sentenceX.historyData[theIndex].length > 0
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        if (
          characterAState.C === false &&
          characterBState.C === false &&
          characterCState.C === false
        ) {
          let deltaX = sentenceX.endX - sentenceX.startX;
          let deltaY = sentenceX.endY - sentenceX.startY;
          if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
            let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
              (item) => item[theIndex] !== sentence[theIndex].value
            );
            if (arr.length > 0) {
              characterBState.T_R_SKA = true;
            } else {
              characterBState.T_R_SKA = false;
            }
          }
        }
      }
    } else {
      if (
        characterAState.C === false &&
        characterBState.C === false &&
        characterCState.C === false
      ) {
        let deltaX = sentenceX.endX - sentenceX.startX;
        let deltaY = sentenceX.endY - sentenceX.startY;
        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
          let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
            (item) => item[theIndex] !== sentence[theIndex].value
          );
          if (arr.length > 0) {
            characterBState.T_R_SKA = true;
          } else {
            characterBState.T_R_SKA = false;
          }
        }
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[12] === 'B' &&
    sentenceX.dataSKC[sentenceX.SKC] === undefined
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        if (
          characterAState.C === true ||
          characterBState.C === true ||
          characterCState.C === true
        ) {
          let deltaX = sentenceX.endX - sentenceX.startX;
          let deltaY = sentenceX.endY - sentenceX.startY;

          if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
            characterBState.T_L_SKC_G = true;
          }
        }
      }
    } else {
      if (
        characterAState.C === true ||
        characterBState.C === true ||
        characterCState.C === true
      ) {
        let deltaX = sentenceX.endX - sentenceX.startX;
        let deltaY = sentenceX.endY - sentenceX.startY;

        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
          characterBState.T_L_SKC_G = true;
        }
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[12] === 'B' &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        if (
          characterAState.C === true ||
          characterBState.C === true ||
          characterCState.C === true
        ) {
          let deltaX = sentenceX.endX - sentenceX.startX;
          let deltaY = sentenceX.endY - sentenceX.startY;
          if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
            characterBState.T_L_SKC = true;
          }
        }
      }
    } else {
      if (
        characterAState.C === true ||
        characterBState.C === true ||
        characterCState.C === true
      ) {
        let deltaX = sentenceX.endX - sentenceX.startX;
        let deltaY = sentenceX.endY - sentenceX.startY;
        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
          characterBState.T_L_SKC = true;
        }
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.id[12] === 'B' &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined &&
    sentenceX.historyData[theIndex].length > 0
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.selectIndex !== undefined) {
        if (
          characterAState.C === true ||
          characterBState.C === true ||
          characterCState.C === true
        ) {
          let deltaX = sentenceX.endX - sentenceX.startX;
          let deltaY = sentenceX.endY - sentenceX.startY;
          if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
            characterBState.T_R_SKC = true;
          }
        }
      }
    } else {
      if (
        characterAState.C === true ||
        characterBState.C === true ||
        characterCState.C === true
      ) {
        let deltaX = sentenceX.endX - sentenceX.startX;
        let deltaY = sentenceX.endY - sentenceX.startY;
        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
          characterBState.T_R_SKC = true;
        }
      }
    }
  }
};
