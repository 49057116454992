import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { sentenceA } from '/js/sentenceA/sentenceA.js';
import { characterA0 } from '/js/characterA0/characterA0.js';

export const setCharacterA0UnSelectElements = () => {
  if (
    characterAState.C === true ||
    characterBState.C === true ||
    characterCState.C === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true
  ) {
    for (const key in characterA0) {
      if (sentenceA[key].display === 'flex') {
        if (characterA0[key].selectElements.length > 0) {
          let arr = characterA0[key].elements.filter(
            (item) => !characterA0[key].selectElements.includes(item)
          );
          characterA0[key].unSelectElements = arr;
        } else {
          characterA0[key].unSelectElements = characterA0[key].elements;
        }
      }
    }
  }
};
