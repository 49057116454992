import { logInMessage } from '/js/logInMessage/logInMessage.js';
import { logInState } from '/js/logInState/logInState.js';

export const setLogInMessageElementDisplay = () => {
  if (logInState.V === true) {
    setTimeout(() => {
      logInMessage.element.style.display = 'none';
    }, 1000);
  }
  if (logInState.I === true) {
    logInMessage.element.style.display = 'flex';
  }
};
