export const sentence = {
  0: {
    element: document.querySelector('#_0_sentence'),
    value: '',
    signElementsTimelines: [],
  },
  1: {
    element: document.querySelector('#_1_sentence'),
    value: '',
    signElementsTimelines: [],
  },
  2: {
    element: document.querySelector('#_2_sentence'),
    value: '',
    signElementsTimelines: [],
  },
  3: {
    element: document.querySelector('#_3_sentence'),
    value: '',
    signElementsTimelines: [],
  },
  4: {
    element: document.querySelector('#_4_sentence'),
    value: '',
    signElementsTimelines: [],
  },
  5: {
    element: document.querySelector('#_5_sentence'),
    value: '',
    signElementsTimelines: [],
  },
  6: {
    element: document.querySelector('#_6_sentence'),
    value: '',
    signElementsTimelines: [],
  },
  7: {
    element: document.querySelector('#_7_sentence'),
    value: '',
    signElementsTimelines: [],
  },
  8: {
    element: document.querySelector('#_8_sentence'),
    value: '',
    signElementsTimelines: [],
  },
  9: {
    element: document.querySelector('#_9_sentence'),
    value: '',
    signElementsTimelines: [],
  },
};
