import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const appendCharacterA0LastElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theSentenceA0 = sentenceA0[theIndex];
  let theCharacterA0 = characterA0[theIndex];

  if (sentenceAState.R_A === true) {
    let element = theCharacterA0.lastElement;
    theSentenceA0.element.appendChild(element);
  }
};
