import { setSentenceXGC } from '/js/sentenceX/setSentenceXGC.js';
import { presetCharacterB0Values } from '/js/characterB0/presetCharacterB0Values.js';
import { presetCharacterB0Index } from '/js/characterB0/presetCharacterB0Index.js';
import { setCharacterB0Data } from '/js/characterB0/setCharacterB0Data.js';
import { setCharacterB0PreviousValue } from '/js/characterB0/setCharacterB0PreviousValue.js';
import { setCharacterB0ToCValues } from '/js/characterB0/setCharacterB0ToCValues.js';
import { resetCharacterB0ToCValues } from '/js/characterB0/resetCharacterB0ToCValues.js';
import { resetSentenceXState } from '/js/sentenceXState/resetSentenceXState.js';
import { resetSentenceAState } from '/js/sentenceAState/resetSentenceAState.js';
import { resetSentenceBState } from '/js/sentenceBState/resetSentenceBState.js';
import { resetSentenceCState } from '/js/sentenceCState/resetSentenceCState.js';
import { resetBlankAState } from '/js/blankAState/resetBlankAState.js';
import { resetBlankBState } from '/js/blankBState/resetBlankBState.js';
import { resetBlankCState } from '/js/blankCState/resetBlankCState.js';
import { resetCharacterAState } from '/js/characterAState/resetCharacterAState.js';
import { resetCharacterBState } from '/js/characterBState/resetCharacterBState.js';
import { resetCharacterCState } from '/js/characterCState/resetCharacterCState.js';
import { setSentenceBState } from '/js/sentenceBState/setSentenceBState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceBStream_A } from '/js/sentenceBStream/sentenceBStream_A.js';
import { sentenceBStream_A_C } from '/js/sentenceBStream/sentenceBStream_A_C.js';
import { sentenceBStream_R_A } from '/js/sentenceBStream/sentenceBStream_R_A.js';
import { sentenceBStream_R_I } from '/js/sentenceBStream/sentenceBStream_R_I.js';
import { sentenceBStream_R_A_C } from '/js/sentenceBStream/sentenceBStream_R_A_C.js';
import { sentenceBStream_R_I_C } from '/js/sentenceBStream/sentenceBStream_R_I_C.js';
import { sentenceBStream_D } from '/js/sentenceBStream/sentenceBStream_D.js';
import { sentenceBStream_A_BTC } from '/js/sentenceBStream/sentenceBStream_A_BTC.js';

export const handleSentenceB1ElementInput = (event) => {
  setSentenceXGC(event);
  presetCharacterB0Values(event);
  presetCharacterB0Index(event);
  setCharacterB0Data(event);
  setCharacterB0PreviousValue();
  setCharacterB0ToCValues();
  resetSentenceXState(event);
  resetSentenceAState(event);
  resetSentenceBState(event);
  resetSentenceCState(event);
  resetBlankAState(event);
  resetBlankBState(event);
  resetBlankCState(event);
  setSentenceBState(event);
  resetCharacterAState(event);
  resetCharacterBState(event);
  resetCharacterCState(event);
  if (sentenceBState.A === true) {
    sentenceBStream_A(event);
  }
  if (sentenceBState.A_C === true) {
    sentenceBStream_A_C(event);
  }
  if (sentenceBState.A_BTC === true) {
    sentenceBStream_A_BTC(event);
  }
  if (sentenceBState.R_A === true) {
    sentenceBStream_R_A(event);
  }
  if (sentenceBState.R_I === true) {
    sentenceBStream_R_I(event);
  }
  if (sentenceBState.R_A_C === true) {
    sentenceBStream_R_A_C(event);
  }
  if (sentenceBState.R_I_C === true) {
    sentenceBStream_R_I_C(event);
  }
  if (sentenceBState.D === true) {
    sentenceBStream_D(event);
  }
  resetCharacterB0ToCValues(event);
};
