import { setSentenceXCanWheel } from '/js/sentenceX/setSentenceXCanWheel.js';
import { killCharacterA0ElementTimelines } from '/js/characterA0/killCharacterA0ElementTimelines.js';
import { setCharacterA0Values } from '/js/characterA0/setCharacterA0Values.js';
import { setCharacterA0Value } from '/js/characterA0/setCharacterA0Value.js';
import { setCharacterA0Index } from '/js/characterA0/setCharacterA0Index.js';
import { setCharacterA0Element } from '/js/characterA0/setCharacterA0Element.js';
import { setCharacterA0ElementInnerHTML } from '/js/characterA0/setCharacterA0ElementInnerHTML.js';
import { addCharacterA0ElementEventListener } from '/js/characterA0/addCharacterA0ElementEventListener.js';
import { appendCharacterA0Element } from '/js/characterA0/appendCharacterA0Element.js';
import { setCharacterA0ToBElementsOpacity } from '/js/characterA0/setCharacterA0ToBElementsOpacity.js';
import { removeCharacterA0ToBElements } from '/js/characterA0/removeCharacterA0ToBElements.js';
import { setCharacterA0Elements } from '/js/characterA0/setCharacterA0Elements.js';
import { setCharacterA0ToBElements } from '/js/characterA0/setCharacterA0ToBElements.js';
import { setSentenceA0Value } from '/js/sentenceA0/setSentenceA0Value.js';
import { setSentenceA0ElementHeight } from '/js/sentenceA0/setSentenceA0ElementHeight.js';
import { setBlankA0ElementHeight } from '/js/blankA0/setBlankA0ElementHeight.js';
import { setSentenceA1ElementValue } from '/js/sentenceA1/setSentenceA1ElementValue.js';
import { setCharacterB0Values } from '/js/characterB0/setCharacterB0Values.js';
import { setCharacterB0Index } from '/js/characterB0/setCharacterB0Index.js';
import { setCharacterB0FromAElements } from '/js/characterB0/setCharacterB0FromAElements.js';
import { appendCharacterB0FromAElements } from '/js/characterB0/appendCharacterB0FromAElements.js';
import { setCharacterB0FromAElementsOpacity } from '/js/characterB0/setCharacterB0FromAElementsOpacity.js';
import { setCharacterB0PreviousElements } from '/js/characterB0/setCharacterB0PreviousElements.js';
import { setCharacterB0PreviousElementsOpacity } from '/js/characterB0/setCharacterB0PreviousElementsOpacity.js';
import { setCharacterB0Elements } from '/js/characterB0/setCharacterB0Elements.js';
import { setCharacterB0ElementsId } from '/js/characterB0/setCharacterB0ElementsId.js';
import { addCharacterB0ElementsEventListener } from '/js/characterB0/addCharacterB0ElementsEventListener.js';
import { setSentenceB0Value } from '/js/sentenceB0/setSentenceB0Value.js';
import { setSentenceB0SplitValues } from '/js/sentenceB0/setSentenceB0SplitValues.js';
import { setSentenceB0SplitCounts } from '/js/sentenceB0/setSentenceB0SplitCounts.js';
import { setSentenceB0SplitInitials } from '/js/sentenceB0/setSentenceB0SplitInitials.js';
import { setCharacterB0ElementsClassName } from '/js/characterB0/setCharacterB0ElementsClassName.js';
import { resetCharacterA0ToBValues } from '/js/characterA0/resetCharacterA0ToBValues.js';
import { transformSentenceAElement } from '/js/sentenceA/transformSentenceAElement.js';
import { transformSentenceBElement } from '/js/sentenceB/transformSentenceBElement.js';
import { transformSentenceCElement } from '/js/sentenceC/transformSentenceCElement.js';
import { setSentenceBElementDisplay } from '/js/sentenceB/setSentenceBElementDisplay.js';
import { setSentenceB1ElementValue } from '/js/sentenceB1/setSentenceB1ElementValue.js';
import { setSentenceB1ElementFocus } from '/js/sentenceB1/setSentenceB1ElementFocus.js';

export const sentenceAStream_A_ATB = (event) => {
  setSentenceXCanWheel(false);
  killCharacterA0ElementTimelines();
  setCharacterA0Values();
  setCharacterA0Value();
  setCharacterA0Index();
  setCharacterA0Element();
  setCharacterA0ElementInnerHTML();
  addCharacterA0ElementEventListener();
  appendCharacterA0Element();
  setCharacterA0ToBElements();
  setCharacterA0ToBElementsOpacity();
  removeCharacterA0ToBElements();
  setCharacterA0Elements();
  setSentenceA0Value(event);
  setSentenceA0ElementHeight();
  setBlankA0ElementHeight();
  setSentenceA1ElementValue();
  setCharacterB0Values();
  setCharacterB0Index();
  setCharacterB0FromAElements();
  appendCharacterB0FromAElements();
  setCharacterB0FromAElementsOpacity();
  setCharacterB0Elements();
  setCharacterB0ElementsId();
  addCharacterB0ElementsEventListener();
  setCharacterB0PreviousElements();
  setCharacterB0PreviousElementsOpacity();
  setSentenceB0Value(event);
  setSentenceB0SplitValues();
  setSentenceB0SplitCounts();
  setSentenceB0SplitInitials();
  setCharacterB0ElementsClassName();
  transformSentenceAElement();
  transformSentenceBElement();
  transformSentenceCElement();
  setSentenceBElementDisplay();
  setSentenceB1ElementValue();
  setSentenceB1ElementFocus();
  setTimeout(() => {
    setSentenceXCanWheel(true);
  }, 1500);
};
