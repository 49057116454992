import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { blankAState } from '/js/blankAState/blankAState.js';

export const setCharacterA0LastElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  let theCharacterB0 = characterB0[theIndex];

  if (
    sentenceAState.A_C === true ||
    sentenceAState.R_A_C === true ||
    sentenceAState.R_I_C === true
  ) {
    theCharacterA0.lastElement =
      theCharacterA0.completeElements[
        theCharacterA0.completeElements.length - 1
      ];
  }

  if (sentenceAState.R_A === true || sentenceAState.R_I === true) {
    theCharacterA0.lastElement = document.createElement('span');
  }

  if (
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    blankAState.C === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    blankAState.T === true
  ) {
    if (sentenceX.inputIndex !== undefined) {
      if (theCharacterB0.elements.length === 0) {
        theCharacterA0.lastElement =
          theCharacterA0.elements[theCharacterA0.elements.length - 1];
      } else {
        theCharacterA0.lastElement = undefined;
      }
    }
  }
};
