import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const setCharacterB0FromAElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceAState.A_ATB === true) {
    let arr = [];
    for (let i = 0; i < theCharacterA0.toBValues.length; i++) {
      theCharacterB0.element = document.createElement('span');
      if (theCharacterA0.toBValues[i] === ' ') {
        theCharacterB0.element.innerHTML = '&nbsp;';
      } else {
        theCharacterB0.element.innerHTML = theCharacterA0.toBValues[i];
      }
      arr.push(theCharacterB0.element);
    }
    theCharacterB0.fromAElements = arr;
  }
};
