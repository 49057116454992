import { setSentenceXInputIndex } from '/js/sentenceX/setSentenceXInputIndex.js';
import { setSentenceXSelectIndex } from '/js/sentenceX/setSentenceXSelectIndex.js';

import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0ElementTimelines } from '/js/characterA0/killCharacterA0ElementTimelines.js';
import { killCharacterB0ElementTimelines } from '/js/characterB0/killCharacterB0ElementTimelines.js';
import { killCharacterC0ElementTimelines } from '/js/characterC0/killCharacterC0ElementTimelines.js';
import { killCharacterA0LastElementTimeline } from '/js/characterA0/killCharacterA0LastElementTimeline.js';
import { killCharacterB0LastElementTimeline } from '/js/characterB0/killCharacterB0LastElementTimeline.js';
import { killCharacterC0LastElementTimeline } from '/js/characterC0/killCharacterC0LastElementTimeline.js';
import { killCharacterA0PreviousElementTimelines } from '/js/characterA0/killCharacterA0PreviousElementTimelines.js';
import { killCharacterB0PreviousElementTimelines } from '/js/characterB0/killCharacterB0PreviousElementTimelines.js';
import { killCharacterC0PreviousElementTimelines } from '/js/characterC0/killCharacterC0PreviousElementTimelines.js';

import { killCharacterA0PreviousElementsTimeline } from '/js/characterA0/killCharacterA0PreviousElementsTimeline.js';
import { killCharacterB0PreviousElementsTimeline } from '/js/characterB0/killCharacterB0PreviousElementsTimeline.js';
import { killCharacterC0PreviousElementsTimeline } from '/js/characterC0/killCharacterC0PreviousElementsTimeline.js';

import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { setCharacterA0SelectElements } from '/js/characterA0/setCharacterA0SelectElements.js';
import { setCharacterB0SelectElements } from '/js/characterB0/setCharacterB0SelectElements.js';
import { setCharacterC0SelectElements } from '/js/characterC0/setCharacterC0SelectElements.js';
import { setCharacterC0SelectElementsOpacity } from '/js/characterC0/setCharacterC0SelectElementsOpacity.js';
import { setCharacterC0NextElements } from '/js/characterC0/setCharacterC0NextElements.js';
import { setCharacterA0UnSelectElements } from '/js/characterA0/setCharacterA0UnSelectElements.js';
import { setCharacterB0UnSelectElements } from '/js/characterB0/setCharacterB0UnSelectElements.js';
import { setCharacterC0UnSelectElements } from '/js/characterC0/setCharacterC0UnSelectElements.js';
import { setCharacterA0UnSelectElementsOpacity } from '/js/characterA0/setCharacterA0UnSelectElementsOpacity.js';
import { setCharacterB0UnSelectElementsOpacity } from '/js/characterB0/setCharacterB0UnSelectElementsOpacity.js';
import { setCharacterC0UnSelectElementsOpacity } from '/js/characterC0/setCharacterC0UnSelectElementsOpacity.js';
import { setCharacterC0SelectInitial } from '/js/characterC0/setCharacterC0SelectInitial.js';
import { setCharacterC0SelectEnd } from '/js/characterC0/setCharacterC0SelectEnd.js';
import { removeCharacterC0Element } from '/js/characterC0/removeCharacterC0Element.js';
import { setCharacterC0ReferenceElement } from '/js/characterC0/setCharacterC0ReferenceElement.js';
import { setSentenceA0SelectElements } from '/js/sentenceA0/setSentenceA0SelectElements.js';
import { setSentenceB0SelectElements } from '/js/sentenceB0/setSentenceB0SelectElements.js';
import { setSentenceC0SelectElements } from '/js/sentenceC0/setSentenceC0SelectElements.js';
import { setSentenceA1ElementFocus } from '/js/sentenceA1/setSentenceA1ElementFocus.js';
import { setSentenceB1ElementFocus } from '/js/sentenceB1/setSentenceB1ElementFocus.js';
import { setSentenceC1ElementFocus } from '/js/sentenceC1/setSentenceC1ElementFocus.js';
import { transformSentenceAElement } from '/js/sentenceA/transformSentenceAElement.js';
import { transformSentenceBElement } from '/js/sentenceB/transformSentenceBElement.js';
import { transformSentenceCElement } from '/js/sentenceC/transformSentenceCElement.js';

export const characterCStream_T = (event) => {
  setSentenceXSelectIndex(event);
  setSentenceXInputIndex(event);

  killCharacterA0ElementTimelines();
  killCharacterB0ElementTimelines();
  killCharacterC0ElementTimelines();
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0LastElementTimeline();
  killCharacterB0LastElementTimeline();
  killCharacterC0LastElementTimeline();
  killCharacterA0PreviousElementTimelines();
  killCharacterB0PreviousElementTimelines();
  killCharacterC0PreviousElementTimelines();
  killCharacterA0PreviousElementsTimeline();
  killCharacterB0PreviousElementsTimeline();
  killCharacterC0PreviousElementsTimeline();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterA0SelectElements(event);
  setCharacterB0SelectElements(event);
  setCharacterC0SelectElements(event);
  setCharacterC0SelectElementsOpacity();
  setCharacterC0NextElements();
  setCharacterA0UnSelectElements(event);
  setCharacterB0UnSelectElements(event);
  setCharacterC0UnSelectElements(event);
  setCharacterA0UnSelectElementsOpacity(event);
  setCharacterB0UnSelectElementsOpacity(event);
  setCharacterC0UnSelectElementsOpacity(event);
  setCharacterC0SelectInitial();
  setCharacterC0SelectEnd();
  removeCharacterC0Element();
  setCharacterC0ReferenceElement();
  setSentenceA0SelectElements();
  setSentenceB0SelectElements();
  setSentenceC0SelectElements();
  setSentenceA1ElementFocus();
  setSentenceB1ElementFocus();
  setSentenceC1ElementFocus();
  transformSentenceAElement();
  transformSentenceBElement();
  transformSentenceCElement();
};
