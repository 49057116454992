import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterB0CompleteElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.A_C === true) {
    if (theCharacterB0.selectElements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterB0.completeElements, {
        opacity: 1,
        duration: 3,
        delay: 1,
      });
    } else {
      let timeline = gsap.timeline();
      timeline.to(theCharacterB0.completeElements, {
        opacity: 1,
        duration: 3,
      });
    }
  }

  if (sentenceBState.R_A_C === true || sentenceBState.R_I_C === true) {
    let timeline = gsap.timeline();
    timeline.to(theCharacterB0.completeElements, {
      opacity: 1,
      duration: 3,
      delay: 1,
    });
  }
};
