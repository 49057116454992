import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { handleSentenceA0ElementClick } from '/js/sentenceA0/handleSentenceA0ElementClick.js';
import { handleSentenceA0ElementWheel } from '/js/sentenceA0/handleSentenceA0ElementWheel.js';
import { handleSentenceA0ElementTouchStart } from '/js/sentenceA0/handleSentenceA0ElementTouchStart.js';
import { handleSentenceA0ElementTouchEnd } from '/js/sentenceA0/handleSentenceA0ElementTouchEnd.js';
export const addSentenceA0ElementEventListener = () => {
  for (const key in sentenceA0) {
    sentenceA0[key].element.addEventListener(
      'click',
      handleSentenceA0ElementClick
    );
    sentenceA0[key].element.addEventListener(
      'wheel',
      handleSentenceA0ElementWheel
    );
    sentenceA0[key].element.addEventListener(
      'touchstart',
      handleSentenceA0ElementTouchStart
    );
    sentenceA0[key].element.addEventListener(
      'touchend',
      handleSentenceA0ElementTouchEnd
    );
  }
};
