import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const appendCharacterB0Element = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theSentenceB0 = sentenceB0[theIndex];
  let theCharacterA0 = characterA0[theIndex];
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.A === true || sentenceBState.A_BTC === true) {
    if (
      theCharacterA0.selectElements.length > 0 ||
      theCharacterB0.selectElements.length > 0
    ) {
      let element = theCharacterB0.element;
      setTimeout(() => {
        theSentenceB0.element.appendChild(element);
      }, 1000);
    } else {
      theSentenceB0.element.appendChild(theCharacterB0.element);
    }
  }

  if (sentenceBState.R_A === true) {
    let element = theCharacterB0.element;
    theSentenceB0.element.appendChild(element);
  }
};
