import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';

export const setCharacterB0PreviousElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  let theCharacterC0 = characterC0[theIndex];
  if (sentenceAState.A_ATB === true) {
    theCharacterB0.previousElements = theCharacterB0.elements;
  }

  if (sentenceBState.A === true) {
    if (theCharacterB0.selectElements.length > 0) {
      setTimeout(() => {
        let index = theCharacterB0.elements.indexOf(theCharacterB0.lastElement);
        let arr = theCharacterB0.elements.slice(
          index + 1,
          theCharacterB0.elements.length - 1
        );
        theCharacterB0.previousElements = arr;
      }, 1000);
    }
  }

  if (
    sentenceBState.A_C === true ||
    sentenceBState.R_A_C === true ||
    sentenceBState.R_I_C === true
  ) {
    let arr = [];
    let index = theCharacterB0.elements.indexOf(theCharacterB0.lastElement);
    for (let i = 0; i < index; i++) {
      arr.push(theCharacterB0.elements[i]);
    }
    theCharacterB0.previousElements = arr;
  }

  if (
    sentenceXState.W === true ||
    sentenceAState.W === true ||
    sentenceBState.W === true ||
    sentenceCState.W === true ||
    blankAState.W === true ||
    blankBState.W === true ||
    blankCState.W === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true ||
    sentenceBState.D === true
  ) {
    let arr = [];
    if (theCharacterC0.elements.length === 0) {
      for (let i = 0; i < theCharacterB0.elements.length - 1; i++) {
        arr.push(theCharacterB0.elements[i]);
      }
      theCharacterB0.previousElements = arr;
    } else {
      theCharacterB0.previousElements = theCharacterB0.elements;
    }
  }
};
