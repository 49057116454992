import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
export const setCharacterA0Value = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (
    sentenceAState.A === true ||
    sentenceAState.R_A === true ||
    sentenceAState.R_I == true
  ) {
    theCharacterA0.value = theCharacterA0.values[theCharacterA0.index + 1];
  }
  if (sentenceAState.A_ATB === true) {
    theCharacterA0.value =
      theCharacterA0.toBValues[theCharacterA0.toBValues.length - 1];
  }
};
