import { device } from '/js/device/device.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';

export const presetSentenceXInputIndex = () => {
  if (device.target === 'Desk') {
    sentenceX.inputIndex = 0;
  } else if (device.target === 'Mobile') {
    sentenceX.inputIndex = undefined;
  }
};
