import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { characterAState } from '/js/characterAState/characterAState.js';

export const setCharacterA0SelectElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (
    sentenceAState.R_A === true ||
    sentenceAState.R_I === true ||
    sentenceAState.R_A_C === true ||
    sentenceAState.R_I_C === true
  ) {
    gsap.to(theCharacterA0.selectElements, {
      opacity: 0,
      duration: 1,
    });
  }

  if (characterAState.C === true || characterAState.T === true) {
    if (sentenceX.selectIndex !== undefined) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterA0.selectElements, {
        opacity: 1,
        duration: 3,
      });
      theCharacterA0.selectElementsTimeline = timeline;
    }
    if (sentenceX.inputIndex !== undefined) {
      let timeline = gsap.timeline();
      let elements = theCharacterA0.selectElements.slice(0, -1);
      timeline.to(elements, {
        opacity: 0.4,
        duration: 1,
      });
      theCharacterA0.selectElementsTimeline = timeline;
    }
  }
};
