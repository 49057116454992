import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const removeCharacterC0Element = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (sentenceCState.D === true) {
    let element = theCharacterC0.element;
    if (element !== null) {
      setTimeout(() => {
        element.remove();
      }, 1000);
    }
  }
  if (characterCState.C === true || characterCState.T === true) {
    if (
      theCharacterC0.elements[theCharacterC0.elements.length - 1].innerHTML ===
      '&nbsp;'
    ) {
      theCharacterC0.element.remove();
    }
  }
};
