import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';

export const setSentenceXElementOpacity = () => {
  if (sentenceXState.L === true) {
    if (sentence[0].value === '') {
      gsap.to(sentenceX.element, {
        opacity: 0,
        duration: 0,
      });
    } else {
      gsap.to(sentenceX.element, {
        opacity: 0,
        duration: 1,
      });
    }
  }
  if (logInState.V === true || confirmState.V === true) {
    gsap.to(sentenceX.element, {
      opacity: 1,
      duration: 3,
    });
  }
};
