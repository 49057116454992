import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentence } from '/js/sentence/sentence.js';
import { userNameA1 } from '/js/userNameA1/userNameA1.js';
import { userNameState } from '/js/userNameState/userNameState.js';

export const setUserNameA1ElementFocus = () => {
  if (sentenceXState.L === true) {
    if (sentence[0].value === '') {
      userNameA1.element.focus();
    } else {
      setTimeout(() => {
        userNameA1.element.focus();
      }, 1000);
    }
  }

  if (userNameState.C === true) {
    userNameA1.element.focus();
  }
};
