import { passWordState } from '/js/passWordState/passWordState.js';
import { userNameState } from '/js/userNameState/userNameState.js';
import { logInState } from '/js/logInState/logInState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';

export const killPassWordA0CharacterElementTimelines = () => {
  if (
    userNameState.C === true ||
    passWordState.C === true ||
    passWordState.D === true ||
    logInState.C === true
  ) {
    for (const item of passWordA0Character.elementTimelines) {
      item.kill();
    }
  }
};
