import { device } from '/js/device/device.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setBlankAState = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  let theSentenceA0 = sentenceA0[theIndex];

  for (const key in blankAState) {
    blankAState[key] = false;
  }

  if (
    event.type === 'click' &&
    event.target.nodeName === 'DIV' &&
    event.target.className === 'blankA0'
  ) {
    blankAState.C = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.className === 'blankA0' &&
    event.deltaX === 0 &&
    event.deltaY > 0 &&
    theIndex < 9 &&
    theSentenceA0.value !== ''
  ) {
    blankAState.W_U = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.className === 'blankA0' &&
    event.deltaX === 0 &&
    event.deltaY < 0 &&
    theIndex < 9 &&
    theSentenceA0.value !== ''
  ) {
    blankAState.W_D = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.className === 'blankA0' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] === undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    blankAState.W_L_SKA_G1 = true;
  }

  if (
    event.type === 'wheel' &&
    event.target.className === 'blankA0' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
      (item) =>
        item[theIndex] !== sentence[theIndex].value &&
        sentenceX.historyData[theIndex].includes(item[theIndex]) === false
    );
    if (arr.length == 0) {
      blankAState.W_L_SKA_G2 = true;
    } else {
      blankAState.W_L_SKA_G2 = false;
    }
  }

  if (
    event.type === 'wheel' &&
    event.target.className === 'blankA0' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
      (item) =>
        item[theIndex] !== sentence[theIndex].value &&
        sentenceX.historyData[theIndex].includes(item[theIndex]) === false
    );
    if (arr.length > 0) {
      blankAState.W_L_SKA = true;
    } else {
      blankAState.W_L_SKA = false;
    }
  }

  if (
    event.type === 'wheel' &&
    event.target.className === 'blankA0' &&
    event.deltaX < 0 &&
    event.deltaY === 0 &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    sentenceX.historyData[theIndex].length > 0 &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
      (item) => item[theIndex] !== sentence[theIndex].value
    );
    if (arr.length > 0) {
      blankAState.W_R_SKA = true;
    } else {
      blankAState.W_R_SKA = false;
    }
  }

  if (
    event.type === 'wheel' &&
    event.target.className === 'blankA0' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    sentenceX.dataSKC[sentenceX.SKC] === undefined
  ) {
    if (
      characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true
    ) {
      blankAState.W_L_SKC_G = true;
    }
  }

  if (
    event.type === 'wheel' &&
    event.target.className === 'blankA0' &&
    event.deltaX > 0 &&
    event.deltaY === 0 &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined
  ) {
    if (
      characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true
    ) {
      blankAState.W_L_SKC = true;
    }
  }

  if (
    event.type === 'wheel' &&
    event.target.className === 'blankA0' &&
    event.deltaX < 0 &&
    event.deltaY === 0 &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined &&
    sentenceX.historyData[theIndex].length > 0
  ) {
    if (
      characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true
    ) {
      blankAState.W_R_SKC = true;
    }
  }

  if (event.type === 'touchend' && event.target.className === 'blankA0') {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    let numberX = Math.abs(deltaX);
    let numberY = Math.abs(deltaY);
    if (numberX < 20 && numberY < 20) {
      blankAState.T = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    theSentenceA0.value !== ''
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY < -20) {
      blankAState.T_U = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    theSentenceA0.value !== ''
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY > 20) {
      blankAState.T_D = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    theIndex === 0 &&
    sentenceX.dataVK.length === 0
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;

    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      blankAState.T_L_VK_G = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    theIndex === 0 &&
    sentenceX.dataVK.length > 0
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      blankAState.T_L_VK = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    theIndex === 0 &&
    sentenceX.dataVK.length > 0 &&
    sentenceX.historyData[theIndex].length > 0
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
      let arr = sentenceX.dataVK.filter(
        (item) => item[theIndex] !== sentence[theIndex].value
      );
      if (arr.length > 0) {
        blankAState.T_R_VK = true;
      } else {
        blankAState.T_R_VK = false;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] === undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      blankAState.T_L_SKA_G1 = true;
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
        (item) =>
          item[theIndex] !== sentence[theIndex].value &&
          sentenceX.historyData[theIndex].includes(item[theIndex]) === false
      );
      if (arr.length == 0) {
        blankAState.T_L_SKA_G2 = true;
      } else {
        blankAState.T_L_SKA_G2 = false;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    characterAState.C === false &&
    characterBState.C === false &&
    characterCState.C === false
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
        (item) =>
          item[theIndex] !== sentence[theIndex].value &&
          sentenceX.historyData[theIndex].includes(item[theIndex]) === false
      );
      if (arr.length > 0) {
        blankAState.T_L_SKA = true;
      } else {
        blankAState.T_L_SKA = false;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    theIndex > 0 &&
    sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
    sentenceX.historyData[theIndex].length > 0
  ) {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
      let arr = sentenceX.dataSKA[sentenceX.SKA].filter(
        (item) => item[theIndex] !== sentence[theIndex].value
      );
      if (arr.length > 0) {
        blankAState.T_R_SKA = true;
      } else {
        blankAState.T_R_SKA = false;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    sentenceX.dataSKC[sentenceX.SKC] === undefined
  ) {
    if (
      characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true
    ) {
      let deltaX = sentenceX.endX - sentenceX.startX;
      let deltaY = sentenceX.endY - sentenceX.startY;

      if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
        blankAState.T_L_SKC_G = true;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined
  ) {
    if (
      characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true
    ) {
      let deltaX = sentenceX.endX - sentenceX.startX;
      let deltaY = sentenceX.endY - sentenceX.startY;
      if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
        blankAState.T_L_SKC = true;
      }
    }
  }

  if (
    event.type === 'touchend' &&
    event.target.className === 'blankA0' &&
    sentenceX.dataSKC[sentenceX.SKC] !== undefined &&
    sentenceX.historyData[theIndex].length > 0
  ) {
    if (
      characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true
    ) {
      let deltaX = sentenceX.endX - sentenceX.startX;
      let deltaY = sentenceX.endY - sentenceX.startY;
      if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > -20) {
        blankAState.T_R_SKC = true;
      }
    }
  }
};
