import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { sentenceC } from '/js/sentenceC/sentenceC.js';
import { characterC0 } from '/js/characterC0/characterC0.js';

export const setCharacterC0UnSelectElements = () => {
  if (
    characterAState.C === true ||
    characterBState.C === true ||
    characterCState.C === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true
  ) {
    for (const key in characterC0) {
      if (sentenceC[key].display === 'flex') {
        if (characterC0[key].selectElements.length > 0) {
          let arr = characterC0[key].elements.filter(
            (item) => !characterC0[key].selectElements.includes(item)
          );
          characterC0[key].unSelectElements = arr;
        } else {
          characterC0[key].unSelectElements = characterC0[key].elements;
        }
      }
    }
  }
};
