export const sentenceC2 = {
  0: {
    element: document.querySelector('#_0_sentenceC2'),
    value: '',
  },
  1: {
    element: document.querySelector('#_1_sentenceC2'),
    value: '',
  },
  2: {
    element: document.querySelector('#_2_sentenceC2'),
    value: '',
  },
  3: {
    element: document.querySelector('#_3_sentenceC2'),
    value: '',
  },
  4: {
    element: document.querySelector('#_4_sentenceC2'),
    value: '',
  },
  5: {
    element: document.querySelector('#_5_sentenceC2'),
    value: '',
  },
  6: {
    element: document.querySelector('#_6_sentenceC2'),
    value: '',
  },
  7: {
    element: document.querySelector('#_7_sentenceC2'),
    value: '',
  },
  8: {
    element: document.querySelector('#_8_sentenceC2'),
    value: '',
  },
  9: {
    element: document.querySelector('#_9_sentenceC2'),
    value: '',
  },
};
