export const sentenceC = {
  0: {
    element: document.querySelector('#_0_sentenceC'),
    endY: 0,
    display: 'none',
  },
  1: {
    element: document.querySelector('#_1_sentenceC'),
    endY: 0,
    display: 'none',
  },
  2: {
    element: document.querySelector('#_2_sentenceC'),
    endY: 0,
    display: 'none',
  },
  3: {
    element: document.querySelector('#_3_sentenceC'),
    endY: 0,
    display: 'none',
  },
  4: {
    element: document.querySelector('#_4_sentenceC'),
    endY: 0,
    display: 'none',
  },
  5: {
    element: document.querySelector('#_5_sentenceC'),
    endY: 0,
    display: 'none',
  },
  6: {
    element: document.querySelector('#_6_sentenceC'),
    endY: 0,
    display: 'none',
  },
  7: {
    element: document.querySelector('#_7_sentenceC'),
    endY: 0,
    display: 'none',
  },
  8: {
    element: document.querySelector('#_8_sentenceC'),
    endY: 0,
    display: 'none',
  },
  9: {
    element: document.querySelector('#_9_sentenceC'),
    endY: 0,
    display: 'none',
  },
};
