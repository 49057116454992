import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';

export const setCharacterA0PreviousElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  let theCharacterB0 = characterB0[theIndex];

  if (sentenceAState.A === true) {
    if (theCharacterA0.selectElements.length > 0) {
      setTimeout(() => {
        let index = theCharacterA0.elements.indexOf(theCharacterA0.lastElement);
        let arr = theCharacterA0.elements.slice(
          index + 1,
          theCharacterA0.elements.length - 1
        );
        theCharacterA0.previousElements = arr;
      }, 1000);
    }
  }

  if (
    sentenceAState.A_C === true ||
    sentenceAState.R_A_C === true ||
    sentenceAState.R_I_C === true
  ) {
    let arr = [];
    let index = theCharacterA0.elements.indexOf(theCharacterA0.lastElement);
    for (let i = 0; i < index; i++) {
      arr.push(theCharacterA0.elements[i]);
    }
    theCharacterA0.previousElements = arr;
  }

  if (
    sentenceXState.W === true ||
    sentenceAState.W === true ||
    sentenceBState.W === true ||
    sentenceCState.W === true ||
    blankAState.W === true ||
    blankBState.W === true ||
    blankCState.W === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true ||
    sentenceAState.D === true
  ) {
    if (theCharacterB0.elements.length === 0) {
      let arr = [];
      for (let i = 0; i < theCharacterA0.elements.length - 1; i++) {
        arr.push(theCharacterA0.elements[i]);
      }
      theCharacterA0.previousElements = arr;
    } else {
      theCharacterA0.previousElements = theCharacterA0.elements;
    }
  }
};
