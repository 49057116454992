import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentence } from '/js/sentence/sentence.js';
import { logIn } from '/js/logIn/logIn.js';
import { userNameState } from '/js/userNameState/userNameState.js';
import { passWordState } from '/js/passWordState/passWordState.js';
import { logInState } from '/js/logInState/logInState.js';

export const setLogInElementOpacity = () => {
  if (sentenceXState.L === true) {
    if (sentence[0].value === '') {
      gsap.to(logIn.element, {
        opacity: 1,
        duration: 3,
      });
    } else {
      gsap.to(logIn.element, {
        opacity: 1,
        duration: 3,
        delay: 1,
      });
    }
  }

  if (userNameState.C === true || passWordState.C === true) {
    gsap.to(logIn.element, {
      opacity: 1,
      duration: 3,
    });
  }

  if (
    logInState.V === true ||
    logInState.UV === true ||
    logInState.RV === true
  ) {
    gsap.to(logIn.element, {
      opacity: 0,
      duration: 1,
    });
  }
};
