import gsap from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
export const setCharacterB0LastElementOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];

  if (sentenceBState.A === true) {
    let timeline = gsap.timeline();
    if (theCharacterB0.selectElements.length > 0) {
      if (theCharacterB0.lastElement !== undefined) {
        timeline.to(theCharacterB0.lastElement, {
          opacity: 0.4,
          duration: 1,
          delay: 1,
        });
        theCharacterB0.lastElementTimeline = timeline;
      }
    } else {
      if (theCharacterB0.lastElement !== undefined) {
        timeline.to(theCharacterB0.lastElement, {
          opacity: 0.4,
          duration: 1,
        });
        theCharacterB0.lastElementTimeline = timeline;
      }
    }
  }

  if (sentenceBState.R_A === true || sentenceBState.R_I === true) {
    let timeline = gsap.timeline();
    timeline.fromTo(
      theCharacterB0.lastElement,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
        delay: 1,
      }
    );
    theCharacterB0.lastElementTimeline = timeline;
  }

  if (
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    blankAState.T === true ||
    blankBState.T === true
  ) {
    if (sentenceX.inputIndex !== undefined) {
      if (theCharacterB0.lastElement !== undefined) {
        let timeline = gsap.timeline();
        timeline.to(theCharacterB0.lastElement, {
          opacity: 1,
          duration: 3,
        });
        theCharacterB0.lastElementTimeline = timeline;
      }
    }
  }
};
