import gsap from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';

export const setCharacterC0PreviousElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];

  if (sentenceBState.A_BTC === true) {
    if (theCharacterC0.previousElements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterC0.previousElements, {
        opacity: 0.4,
        duration: 1,
        delay: 3,
      });
      theCharacterC0.previousElementsTimeline = timeline;
    }
  }

  if (sentenceCState.A === true) {
    if (theCharacterC0.selectElements.length > 0) {
      let timeline = gsap.timeline();
      if (theCharacterC0.previousElements.length > 0) {
        timeline.to(theCharacterC0.previousElements, {
          opacity: 0.4,
          duration: 1,
          delay: 3,
        });
        theCharacterC0.previousElementsTimeline = timeline;
      }
    }
  }

  if (
    sentenceCState.A_C === true ||
    sentenceCState.R_A_C === true ||
    sentenceCState.R_I_C === true
  ) {
    if (theCharacterC0.previousElements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterC0.previousElements, {
        opacity: 0.4,
        duration: 1,
        delay: 3,
        stagger: {
          amount: 1,
          each: 0.1,
        },
      });
      theCharacterC0.previousElementsTimeline = timeline;
    }
  }

  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    if (sentenceX.inputIndex !== undefined) {
      if (theCharacterC0.previousElements.length > 0) {
        let timeline = gsap.timeline();
        timeline.to(theCharacterC0.previousElements, {
          opacity: 0.4,
          duration: 1,
          stagger: {
            amount: 1,
            each: 0.1,
            from: 'random',
          },
        });
        theCharacterC0.previousElementsTimeline = timeline;
      }
    }
  }

  if (
    sentenceXState.W === true ||
    sentenceAState.W === true ||
    sentenceBState.W === true ||
    sentenceCState.W === true ||
    blankAState.W === true ||
    blankBState.W === true ||
    blankCState.W === true ||
    sentenceCState.D === true
  ) {
    let timeline = gsap.timeline();
    if (sentenceX.inputIndex !== undefined) {
      if (theCharacterC0.previousElements.length > 0) {
        timeline.to(theCharacterC0.previousElements, {
          opacity: 0.4,
          duration: 1,
        });
        theCharacterC0.previousElementsTimeline = timeline;
      }
    }
  }
};
