import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setSentenceXData = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true ||
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true
  ) {
    let arrA = [];
    let arrB = [];
    if (sentenceX.dataSKA[sentenceX.SKA] !== undefined) {
      for (const item of sentenceX.dataSKA[sentenceX.SKA]) {
        arrA.push(item[theIndex]);
      }
      arrA = [...new Set(arrA)];
      if (sentenceX.historyData[theIndex] !== undefined) {
        arrB = arrA.filter(
          (item) => !sentenceX.historyData[theIndex].includes(item)
        );
      }
      sentenceX.data = arrB;
    }
  }

  if (
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true
  ) {
    let arrA = [];
    let arrB = [];
    let arrC = [];
    let str = '';
    if (sentenceX.dataSKC[sentenceX.SKC] !== undefined) {
      for (const item of sentenceX.dataSKC[sentenceX.SKC]) {
        arrA.push(item[theIndex]);
      }
      arrA = [...new Set(arrA)];
      if (characterAState.C === true || characterAState.T === true) {
        for (let i = 0; i < characterA0[theIndex].elements.length; i++) {
          let element = characterA0[theIndex].elements[i];
          if (element.innerHTML == '&nbsp;') {
            str += ' ';
          } else {
            str += element.innerHTML;
          }
          if (i == characterA0[theIndex].selectEnd) {
            break;
          }
        }
        str += ' ';
        for (let i = 1; i < characterA0[theIndex].nextElements.length; i++) {
          let element = characterA0[theIndex].nextElements[i];
          if (element.innerHTML == '&nbsp;') {
            break;
          }
          if (element.innerHTML == '&nbsp;') {
            str += ' ';
          } else {
            str += element.innerHTML;
          }
        }
      }
      if (characterBState.C === true || characterBState.T === true) {
        str += sentenceA0[theIndex].value;
        for (let i = 0; i < characterB0[theIndex].elements.length; i++) {
          let element = characterB0[theIndex].elements[i];
          if (element.innerHTML == '&nbsp;') {
            str += ' ';
          } else {
            str += element.innerHTML;
          }
          if (i == characterB0[theIndex].selectEnd) {
            break;
          }
        }
        str += ' ';
        for (let i = 1; i < characterB0[theIndex].nextElements.length; i++) {
          let element = characterB0[theIndex].nextElements[i];
          if (element.innerHTML == '&nbsp;') {
            break;
          }
          if (element.innerHTML == '&nbsp;') {
            str += ' ';
          } else {
            str += element.innerHTML;
          }
        }
      }
      if (characterCState.C === true || characterCState.T === true) {
        str += sentenceA0[theIndex].value;
        str += sentenceB0[theIndex].value;
        for (let i = 0; i < characterC0[theIndex].elements.length; i++) {
          let element = characterC0[theIndex].elements[i];
          if (element.innerHTML == '&nbsp;') {
            str += ' ';
          } else {
            str += element.innerHTML;
          }
          if (i == characterC0[theIndex].selectEnd) {
            break;
          }
        }
        str += ' ';
        for (let i = 1; i < characterC0[theIndex].nextElements.length; i++) {
          let element = characterC0[theIndex].nextElements[i];
          if (element.innerHTML == '&nbsp;') {
            break;
          }
          if (element.innerHTML == '&nbsp;') {
            str += ' ';
          } else {
            str += element.innerHTML;
          }
        }
      }
      arrB = arrA.filter((item) => item !== str);
      if (sentenceX.historyData[theIndex] !== undefined) {
        arrC = arrB.filter(
          (item) => !sentenceX.historyData[theIndex].includes(item)
        );
      }
      sentenceX.data = arrC;
    }
  }

  if (
    sentenceXState.W_L_VK_G === true ||
    sentenceAState.W_L_VK_G === true ||
    sentenceBState.W_L_VK_G === true ||
    sentenceCState.W_L_VK_G === true ||
    sentenceXState.T_L_VK_G === true ||
    sentenceAState.T_L_VK_G === true ||
    sentenceBState.T_L_VK_G === true ||
    sentenceCState.T_L_VK_G === true ||
    sentenceXState.W_L_VK === true ||
    sentenceAState.W_L_VK === true ||
    sentenceBState.W_L_VK === true ||
    sentenceCState.W_L_VK === true ||
    sentenceXState.T_L_VK === true ||
    sentenceAState.T_L_VK === true ||
    sentenceBState.T_L_VK === true ||
    sentenceCState.T_L_VK === true
  ) {
    let arrA = [];
    let arrB = [];
    for (const item of sentenceX.dataVK) {
      arrA.push(item[theIndex]);
    }
    arrA = [...new Set(arrA)];
    arrB = arrA.filter(
      (item) => !sentenceX.historyData[theIndex].includes(item)
    );
    sentenceX.data = arrB;
  }
};
