import { setLogInState } from '/js/logInState/setLogInState.js';
import { setConfirmState } from '/js/confirmState/setConfirmState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { confirmStream_C } from '/js/confirmStream/confirmStream_C.js';
export const handleConfirmElementClick = (event) => {
  setLogInState(event);
  setConfirmState(event);
  if (confirmState.C === true) {
    confirmStream_C(event);
  }
};
