export const codeCharacter = {
  data: '',
  value: '',
  values: [],
  index: -1,
  element: undefined,
  elementTimelines: [],
  elements: Array.from(document.querySelectorAll(`.codeA0 span`)),
  elementsTimeline: undefined,
  deleteElementTimelines: [],
};
