import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { setSentenceXStartX } from '/js/sentenceX/setSentenceXStartX.js';
import { setSentenceXStartY } from '/js/sentenceX/setSentenceXStartY.js';

export const handleBlankB0ElementTouchStart = (event) => {
  event.preventDefault();
  event.stopPropagation();
  if (sentenceX.canTouch === true) {
    setSentenceXStartX(event);
    setSentenceXStartY(event);
  }
};
