import { userNameState } from '/js/userNameState/userNameState.js';
import { passWordState } from '/js/passWordState/passWordState.js';
import { logInState } from '/js/logInState/logInState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';

export const setUserNameA0CharacterElementsOpacity = () => {
  if (userNameState.C === true) {
    if (userNameA0Character.elements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(userNameA0Character.elements, {
        opacity: 1,
        duration: 3,
      });
      userNameA0Character.elementsTimeline = timeline;
    }
  }

  if (passWordState.C === true) {
    if (userNameA0Character.elements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(userNameA0Character.elements, {
        opacity: 0.2,
        duration: 1,
      });
      userNameA0Character.elementsTimeline = timeline;
    }
  }

  if (logInState.C === true) {
    if (userNameA0Character.elements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(userNameA0Character.elements, {
        opacity: 0.2,
        duration: 1,
      });
      userNameA0Character.elementsTimeline = timeline;
    }
  }
};
