import { setSentenceXCanInput } from '/js/sentenceX/setSentenceXCanInput.js';
import { killUserNameA0CharacterElementTimelines } from '/js/UserNameA0Character/killUserNameA0CharacterElementTimelines.js';
import { setUserNameA0CharacterElement } from '/js/UserNameA0Character/setUserNameA0CharacterElement.js';
import { setUserNameA0CharacterElementOpacity } from '/js/UserNameA0Character/setUserNameA0CharacterElementOpacity.js';
import { removeUserNameA0Character } from '/js/UserNameA0Character/removeUserNameA0Character.js';
import { setUserNameA0CharacterElements } from '/js/UserNameA0Character/setUserNameA0CharacterElements.js';
import { setUserNameA0CharacterValues } from '/js/UserNameA0Character/setUserNameA0CharacterValues.js';
import { setUserNameA0CharacterIndex } from '/js/UserNameA0Character/setUserNameA0CharacterIndex.js';
import { setUserNameValue } from '/js/userName/setUserNameValue.js';

export const userNameStream_D = (event) => {
  setSentenceXCanInput(false);
  killUserNameA0CharacterElementTimelines(event);
  setUserNameA0CharacterElement();
  setUserNameA0CharacterElementOpacity(event);
  removeUserNameA0Character();
  setUserNameA0CharacterElements(event);
  setUserNameA0CharacterValues(event);
  setUserNameA0CharacterIndex();
  setUserNameValue();
};
