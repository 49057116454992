export const sentenceA2 = {
  0: {
    element: document.querySelector('#_0_sentenceA2'),
    value: '',
  },
  1: {
    element: document.querySelector('#_1_sentenceA2'),
    value: '',
  },
  2: {
    element: document.querySelector('#_2_sentenceA2'),
    value: '',
  },
  3: {
    element: document.querySelector('#_3_sentenceA2'),
    value: '',
  },
  4: {
    element: document.querySelector('#_4_sentenceA2'),
    value: '',
  },
  5: {
    element: document.querySelector('#_5_sentenceA2'),
    value: '',
  },
  6: {
    element: document.querySelector('#_6_sentenceA2'),
    value: '',
  },
  7: {
    element: document.querySelector('#_7_sentenceA2'),
    value: '',
  },
  8: {
    element: document.querySelector('#_8_sentenceA2'),
    value: '',
  },
  9: {
    element: document.querySelector('#_9_sentenceA2'),
    value: '',
  },
};
