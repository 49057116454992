import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setSentenceXVK = (event) => {
  let str = '';
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (event.type === 'wheel' || event.type === 'touchend') {
    if (
      characterAState.C === false &&
      characterBState.C === false &&
      characterCState.C === false
    ) {
      for (const key in sentenceA0) {
        if (sentenceA0[key].value !== '') {
          str += sentenceA0[key].value;
          if (sentenceB0[key].value !== '') {
            str += ' ';
            str += sentenceB0[key].value;
          }
          if (sentenceC0[key].value !== '') {
            str += ' ';
            str += sentenceC0[key].value;
          }
          str += '&';
        }
      }
      sentenceX.VK = str;
    }
  }
};
