import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { handleCharacterB0ElementClick } from '/js/characterB0/handleCharacterB0ElementClick.js';
import { handleCharacterB0ElementWheel } from '/js/characterB0/handleCharacterB0ElementWheel.js';
import { handleCharacterB0ElementTouchStart } from '/js/characterB0/handleCharacterB0ElementTouchStart.js';
import { handleCharacterB0ElementTouchEnd } from '/js/characterB0/handleCharacterB0ElementTouchEnd.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterB0CompleteElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  let theSentenceB0 = sentenceB0[theIndex];
  if (
    sentenceBState.A_C === true ||
    sentenceBState.R_A_C === true ||
    sentenceBState.R_I_C === true
  ) {
    let arr = [];
    for (const item of theCharacterB0.completeValues) {
      let element = document.createElement('span');
      if (item === ' ') {
        element.innerHTML = '&nbsp;';
      } else {
        element.innerHTML = item;
      }
      element.addEventListener('click', handleCharacterB0ElementClick);
      element.addEventListener('wheel', handleCharacterB0ElementWheel);
      element.addEventListener(
        'touchstart',
        handleCharacterB0ElementTouchStart
      );
      element.addEventListener('touchend', handleCharacterB0ElementTouchEnd);
      theSentenceB0.element.appendChild(element);
      arr.push(element);
    }
    theCharacterB0.completeElements = arr;
  }
};
