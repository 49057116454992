import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const resetSentenceXSKC = () => {
  let str = '';
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  for (const key in sentence) {
    let str = '';
    if (key <= theIndex) {
      if (characterAState.C === true) {
        for (let i = 0; i < sentenceA0[theIndex].value.length; i++) {
          str += sentenceA0[theIndex].value[i];
          if (i == characterA0[theIndex].selectEnd) {
            break;
          }
        }
      }
      if (characterBState.C === true) {
        str += sentenceA0[theIndex].value;
        str += ' ';
        for (let i = 0; i < sentenceB0[theIndex].value.length; i++) {
          str += sentenceB0[theIndex].value[i];
          if (i == characterB0[theIndex].selectEnd) {
            break;
          }
        }
      }
      if (characterCState.C === true) {
        str += sentenceA0[theIndex].value;
        str += ' ';
        str += sentenceB0[theIndex].value;
        for (let i = 0; i < sentenceC0[theIndex].value.length; i++) {
          str += sentenceC0[theIndex].value[i];
          if (i == characterC0[theIndex].selectEnd) {
            break;
          }
        }
      }
      sentenceX.SKC = str;
      sentenceX.SKC += ' ';
    }
  }
};
