import { confirmState } from '/js/confirmState/confirmState.js';

export const resetConfirmState = (data) => {
  if (data.Username !== undefined) {
    confirmState.V = true;
  }
  if (data.body !== undefined) {
    if (
      JSON.parse(data.body) ===
      'Invalid verification code provided, please try again.'
    ) {
      confirmState.I = true;
    }
  }
};
