import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';

export const resetSentenceC0Value = (event) => {
  if (sentenceXState.W_L_SKC_G === true) {
    for (const key in sentence) {
      let str = sentence[key].value
        .replace(sentenceA0[key].value, '')
        .replace(sentenceB0[key].value, '')
        .trim();
      sentenceC0[key].value = str;
    }
  }
};
