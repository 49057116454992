import gsap from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
export const setCharacterC0LastElementOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];

  if (sentenceCState.A === true) {
    let timeline = gsap.timeline();
    if (theCharacterC0.selectElements.length > 0) {
      if (theCharacterC0.lastElement !== undefined) {
        timeline.to(theCharacterC0.lastElement, {
          opacity: 0.4,
          duration: 1,
          delay: 1,
        });
        theCharacterC0.lastElementTimeline = timeline;
      }
    } else {
      if (theCharacterC0.lastElement !== undefined) {
        timeline.to(theCharacterC0.lastElement, {
          opacity: 0.4,
          duration: 1,
        });
        theCharacterC0.lastElementTimeline = timeline;
      }
    }
  }

  if (sentenceCState.R_A === true || sentenceCState.R_I === true) {
    let timeline = gsap.timeline();
    timeline.fromTo(
      theCharacterC0.lastElement,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
        delay: 1,
      }
    );
    theCharacterC0.lastElementTimeline = timeline;
  }

  if (
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true
  ) {
    let timeline = gsap.timeline();
    if (sentenceX.inputIndex !== undefined) {
      if (theCharacterC0.lastElement !== undefined) {
        timeline.to(theCharacterC0.lastElement, {
          opacity: 1,
          duration: 3,
        });
        theCharacterC0.lastElementTimeline = timeline;
      }
    }
  }
};
