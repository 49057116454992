import { setSentenceXCanInput } from '/js/sentenceX/setSentenceXCanInput.js';
import { killCodeCharacterElementTimelines } from '/js/codeCharacter/killCodeCharacterElementTimelines.js';
import { setCodeCharacterElement } from '/js/codeCharacter/setCodeCharacterElement.js';
import { setCodeCharacterElementOpacity } from '/js/codeCharacter/setCodeCharacterElementOpacity.js';
import { removeCodeCharacter } from '/js/codeCharacter/removeCodeCharacter.js';
import { setCodeCharacterElements } from '/js/codeCharacter/setCodeCharacterElements.js';
import { setCodeCharacterValues } from '/js/codeCharacter/setCodeCharacterValues.js';
import { setCodeCharacterIndex } from '/js/codeCharacter/setCodeCharacterIndex.js';
import { setCodeValue } from '/js/code/setCodeValue.js';

export const codeStream_D = (event) => {
  setSentenceXCanInput(false);
  killCodeCharacterElementTimelines(event);
  setCodeCharacterElement();
  setCodeCharacterElementOpacity(event);
  removeCodeCharacter();
  setCodeCharacterElements(event);
  setCodeCharacterValues(event);
  setCodeCharacterIndex();
  setCodeValue();
};
