import { blankB0 } from '/js/blankB0/blankB0.js';
import { handleBlankB0ElementClick } from '/js/blankB0/handleBlankB0ElementClick.js';
import { handleBlankB0ElementWheel } from '/js/blankB0/handleBlankB0ElementWheel.js';
import { handleBlankB0ElementTouchStart } from '/js/blankB0/handleBlankB0ElementTouchStart.js';
import { handleBlankB0ElementTouchEnd } from '/js/blankB0/handleBlankB0ElementTouchEnd.js';

export const addBlankB0ElementEventListener = () => {
  for (const key in blankB0) {
    blankB0[key].element.addEventListener(
      'click',
      handleBlankB0ElementClick
    );
    blankB0[key].element.addEventListener(
      'wheel',
      handleBlankB0ElementWheel
    );
    blankB0[key].element.addEventListener(
      'touchstart',
      handleBlankB0ElementTouchStart
    );
    blankB0[key].element.addEventListener(
      'touchend',
      handleBlankB0ElementTouchEnd
    );
  }
};
