export const sentenceC1 = {
  0: {
    element: document.querySelector('#_0_sentenceC1'),
    focus: false,
  },
  1: {
    element: document.querySelector('#_1_sentenceC1'),
    focus: false,
  },
  2: {
    element: document.querySelector('#_2_sentenceC1'),
    focus: false,
  },
  3: {
    element: document.querySelector('#_3_sentenceC1'),
    focus: false,
  },
  4: {
    element: document.querySelector('#_4_sentenceC1'),
    focus: false,
  },
  5: {
    element: document.querySelector('#_5_sentenceC1'),
    focus: false,
  },
  6: {
    element: document.querySelector('#_6_sentenceC1'),
    focus: false,
  },
  7: {
    element: document.querySelector('#_7_sentenceC1'),
    focus: false,
  },
  8: {
    element: document.querySelector('#_8_sentenceC1'),
    focus: false,
  },
  9: {
    element: document.querySelector('#_9_sentenceC1'),
    focus: false,
  },
};
