import { setSentenceXCanInput } from '/js/sentenceX/setSentenceXCanInput.js';
import { killPassWordA0CharacterElementTimelines } from '/js/passWordA0Character/killPassWordA0CharacterElementTimelines.js';
import { setPassWordA0CharacterElement } from '/js/passWordA0Character/setPassWordA0CharacterElement.js';
import { setPassWordA0CharacterElementOpacity } from '/js/passWordA0Character/setPassWordA0CharacterElementOpacity.js';
import { removePassWordA0Character } from '/js/passWordA0Character/removePassWordA0Character.js';
import { setPassWordA0CharacterElements } from '/js/passWordA0Character/setPassWordA0CharacterElements.js';
import { setPassWordA0CharacterValues } from '/js/passWordA0Character/setPassWordA0CharacterValues.js';
import { setPassWordA0CharacterIndex } from '/js/passWordA0Character/setPassWordA0CharacterIndex.js';
import { setPassWordValue } from '/js/passWord/setPassWordValue.js';

export const passWordStream_D = (event) => {
  setSentenceXCanInput(false);
  killPassWordA0CharacterElementTimelines(event);
  setPassWordA0CharacterElement();
  setPassWordA0CharacterElementOpacity(event);
  removePassWordA0Character();
  setPassWordA0CharacterElements(event);
  setPassWordA0CharacterValues(event);
  setPassWordA0CharacterIndex();
  setPassWordValue();
};
