import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';

export const setCharacterC0CompleteValues = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];

  if (sentenceCState.A_C === true) {
    let arr = [];
    let index = theCharacterC0.elements.length;
    for (let i = index; i < theCharacterC0.values.length; i++) {
      arr.push(theCharacterC0.values[i]);
    }
    theCharacterC0.completeValues = arr;
    console.log(theCharacterC0.completeValues);
  }

  if (sentenceCState.R_A_C === true || sentenceCState.R_I_C === true) {
    let arr = [];
    for (
      let i = theCharacterC0.selectInitial;
      i < theCharacterC0.values.length;
      i++
    ) {
      arr.push(theCharacterC0.values[i]);
    }
    theCharacterC0.completeValues = arr;
  }
};
