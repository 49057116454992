import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const killCharacterA0PreviousElementsTimeline = (event) => {
  if (
    sentenceAState.D === true ||
    sentenceBState.D === true ||
    sentenceCState.D === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true ||
    characterAState.C === true ||
    characterBState.C === true ||
    characterCState.C === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true ||
    sentenceXState.W_U === true ||
    sentenceAState.W_U === true ||
    sentenceBState.W_U === true ||
    sentenceCState.W_U === true ||
    blankAState.W_U === true ||
    blankBState.W_U === true ||
    blankCState.W_U === true ||
    characterAState.W_U === true ||
    characterBState.W_U === true ||
    characterCState.W_U === true ||
    sentenceXState.T_U === true ||
    sentenceAState.T_U === true ||
    sentenceBState.T_U === true ||
    sentenceCState.T_U === true ||
    blankAState.T_U === true ||
    blankBState.T_U === true ||
    blankCState.T_U === true ||
    characterAState.T_U === true ||
    characterBState.T_U === true ||
    characterCState.T_U === true ||
    sentenceXState.W_D === true ||
    sentenceAState.W_D === true ||
    sentenceBState.W_D === true ||
    sentenceCState.W_D === true ||
    blankAState.W_D === true ||
    blankBState.W_D === true ||
    blankCState.W_D === true ||
    characterAState.W_D === true ||
    characterBState.W_D === true ||
    characterCState.W_D === true ||
    sentenceXState.T_D === true ||
    sentenceAState.T_D === true ||
    sentenceBState.T_D === true ||
    sentenceCState.T_D === true ||
    blankAState.T_D === true ||
    blankBState.T_D === true ||
    blankCState.T_D === true ||
    characterAState.T_D === true ||
    characterBState.T_D === true ||
    characterCState.T_D === true ||
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true
  ) {
    for (const key in characterA0) {
      if (characterA0[key].previousElementsTimeline !== undefined) {
        characterA0[key].previousElementsTimeline.kill();
      }
    }
  }
};
