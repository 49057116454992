import { setSentenceXVK } from '/js/sentenceX/setSentenceXVK.js';
import { setSentenceXPKA } from '/js/sentenceX/setSentenceXPKA.js';
import { setSentenceXPKB } from '/js/sentenceX/setSentenceXPKB.js';
import { setSentenceXSKA } from '/js/sentenceX/setSentenceXSKA.js';
import { setSentenceXSKB } from '/js/sentenceX/setSentenceXSKB.js';
import { resetSentenceXState } from '/js/sentenceXState/resetSentenceXState.js';
import { resetSentenceAState } from '/js/sentenceAState/resetSentenceAState.js';
import { resetSentenceBState } from '/js/sentenceBState/resetSentenceBState.js';
import { resetSentenceCState } from '/js/sentenceCState/resetSentenceCState.js';
import { resetBlankAState } from '/js/blankAState/resetBlankAState.js';
import { resetBlankBState } from '/js/blankBState/resetBlankBState.js';
import { resetBlankCState } from '/js/blankCState/resetBlankCState.js';
import { resetCharacterAState } from '/js/characterAState/resetCharacterAState.js';
import { resetCharacterBState } from '/js/characterBState/resetCharacterBState.js';
import { resetCharacterCState } from '/js/characterCState/resetCharacterCState.js';
import { setSentenceXState } from '/js/sentenceXState/setSentenceXState.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceXStream_L } from '/js/sentenceXStream/sentenceXStream_L.js';
import { sentenceXStream_P } from '/js/sentenceXStream/sentenceXStream_P.js';
import { sentenceXStream_RP } from '/js/sentenceXStream/sentenceXStream_RP.js';
import { sentenceXStream_D } from '/js/sentenceXStream/sentenceXStream_D.js';
export const handleSentenceXElementKeyDown = (event) => {
  // setSentenceXVK(event);
  // setSentenceXPKA();
  // setSentenceXPKB();
  // setSentenceXSKA(event);
  // setSentenceXSKB(event);
  // resetSentenceXState(event);
  // resetSentenceAState(event);
  // resetSentenceBState(event);
  // resetSentenceCState(event);
  // resetBlankAState(event);
  // resetBlankBState(event);
  // resetBlankCState(event);
  // resetCharacterAState(event);
  // resetCharacterBState(event);
  // resetCharacterCState(event);
  // setSentenceXState(event);
  // if (sentenceXState.L === true) {
  //   sentenceXStream_L(event);
  // }
  // if (sentenceXState.P === true) {
  //   sentenceXStream_P(event);
  // }
  // if (sentenceXState.RP === true) {
  //   sentenceXStream_RP(event);
  // }
  // if (sentenceXState.D === true) {
  //   sentenceXStream_D(event);
  // }
};
