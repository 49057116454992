import { passWordState } from '/js/passWordState/passWordState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';
import { handlePassWordA0CharacterElementClick } from '/js/passWordA0Character/handlePassWordA0CharacterElementClick.js';

export const addPassWordA0CharacterElementEventListener = () => {
  if (passWordState.A === true) {
    passWordA0Character.element.addEventListener(
      'click',
      handlePassWordA0CharacterElementClick
    );
  }
};
