import { gsap } from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterB0ToCElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.A_BTC == true) {
    if (theCharacterB0.selectElements.length > 0) {
      setTimeout(() => {
        gsap.to(theCharacterB0.toCElements, {
          opacity: 0,
          duration: 1,
        });
      }, 1000);
    } else {
      gsap.to(theCharacterB0.toCElements, {
        opacity: 0,
        duration: 1,
      });
    }
  }
};
