import { killUserNameA0CharacterElementTimelines } from '/js/userNameA0Character/killUserNameA0CharacterElementTimelines.js';
import { killPassWordA0CharacterElementTimelines } from '/js/passWordA0Character/killPassWordA0CharacterElementTimelines.js';
import { killUserNameA0CharacterElementsTimeline } from '/js/userNameA0Character/killUserNameA0CharacterElementsTimeline.js';
import { killPassWordA0CharacterElementsTimeline } from '/js/passWordA0Character/killPassWordA0CharacterElementsTimeline.js';
import { presetLogInElementOpacity } from '/js/logIn/presetLogInElementOpacity.js';
import { setUserNameA0CharacterElementsOpacity } from '/js/userNameA0Character/setuserNameA0CharacterElementsOpacity.js';
import { setPassWordA0CharacterElementsOpacity } from '/js/passWordA0Character/setPassWordA0CharacterElementsOpacity.js';
import { resetLogInState } from '/js/logInState/resetLogInState.js';
import { userName } from '/js/userName/userName.js';
import { passWord } from '/js/passWord/passWord.js';
import { logInStream_UV } from '/js/logInStream/logInStream_UV.js';
import { logInStream_V } from '/js/logInStream/logInStream_V.js';
import { logInStream_I } from '/js/logInStream/logInStream_I.js';
import { logInStream_RV } from '/js/logInStream/logInStream_RV.js';
import { logInState } from '/js/logInState/logInState.js';
import { setSentenceXCanClick } from '/js/sentenceX/setSentenceXCanClick.js';

export const logInStream_C = () => {
  let url = `https://xxihc2smv7.execute-api.us-east-1.amazonaws.com/${userName.value}/${passWord.value}`;
  killUserNameA0CharacterElementTimelines();
  killPassWordA0CharacterElementTimelines();
  killUserNameA0CharacterElementsTimeline();
  killPassWordA0CharacterElementsTimeline();
  setUserNameA0CharacterElementsOpacity();
  setPassWordA0CharacterElementsOpacity();
  presetLogInElementOpacity();

  fetch(url, {
    method: 'POST',
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      resetLogInState(data);
      if (logInState.UV === true) {
        logInStream_UV();
      }
      if (logInState.V === true) {
        logInStream_V(data);
      }
      if (logInState.I === true) {
        logInStream_I();
      }
      if (logInState.RV === true) {
        logInStream_RV();
      }
      setSentenceXCanClick(true);
    })
    .catch((error) => {
      return error;
    });
};
