import { userNameState } from '/js/userNameState/userNameState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';
import { handleUserNameA0CharacterElementClick } from '/js/userNameA0Character/handleUserNameA0CharacterElementClick.js';

export const addUserNameA0CharacterElementEventListener = () => {
  if (userNameState.A === true) {
    userNameA0Character.element.addEventListener(
      'click',
      handleUserNameA0CharacterElementClick
    );
  }
};
