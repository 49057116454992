import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const appendCharacterB0FromAElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theSentenceB0 = sentenceB0[theIndex];
  let theCharacterA0 = characterA0[theIndex];
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceAState.A_ATB === true) {
    if (theCharacterA0.selectElements.length > 0) {
      setTimeout(() => {
        for (const item of theCharacterB0.fromAElements) {
          theSentenceB0.element.appendChild(item);
        }
      }, 1000);
    } else {
      for (const item of theCharacterB0.fromAElements) {
        theSentenceB0.element.appendChild(item);
      }
    }
  }
  if (sentenceBState.A === true) {
    theSentenceB0.element.appendChild(theCharacterB0.element);
  }
};

// if (theCharacterB0.elements.length === 0) {
//   for (const item of theCharacterB0.fromAElements) {
//     theSentenceB0.element.appendChild(item);
//   }
// } else {
//   let referenceElement = undefined;
//   let element = document.createElement('span');
//   element.innerHTML = '&nbsp;';
//   theSentenceB0.element.insertBefore(element, theCharacterB0.elements[0]);
//   for (const item of theCharacterB0.fromAElements) {
//     theSentenceB0.element.insertBefore(item, element);
//     referenceElement = item;
//   }
// }
// if (theCharacterB0.elements.length === 0) {
//   for (const item of theCharacterB0.fromAElements) {
//     theSentenceB0.element.appendChild(item);
//   }
// } else {
//   let referenceElement = undefined;
//   let element = document.createElement('span');
//   element.innerHTML = '&nbsp;';
//   theSentenceB0.element.insertBefore(
//     element,
//     theCharacterB0.elements[0]
//   );
//   for (const item of theCharacterB0.fromAElements) {
//     theSentenceB0.element.insertBefore(item, element);
//     referenceElement = item;
//   }
// }
