import { userNameState } from '/js/userNameState/userNameState.js';
import { passWordState } from '/js/passWordState/passWordState.js';
import { logInState } from '/js/logInState/logInState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';

export const killUserNameA0CharacterElementTimelines = () => {
  if (
    userNameState.C === true ||
    userNameState.D === true ||
    passWordState.C === true ||
    logInState.C === true
  ) {
    for (const item of userNameA0Character.elementTimelines) {
      item.kill();
    }
  }
};
