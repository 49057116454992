import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';

export const setSentenceXUserId = (data) => {
  if (logInState.V === true || confirmState.V === true) {
    sentenceX.userId = data.Username;
    localStorage.setItem('sentencex_userId', sentenceX.userId);
  }
};
