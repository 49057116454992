import { passWordState } from '/js/passWordState/passWordState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';
import { passWord } from '/js/passWord/passWord.js';
export const setPassWordValue = () => {
  if (passWordState.A === true || passWordState.D === true) {
    let str = '';
    for (const item of passWordA0Character.values) {
      str += item;
    }
    passWord.value = str;
  }
};
