import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';

export const resetSentenceB0Value = (event) => {
  if (sentenceXState.W_L_SKC_G === true) {
    for (const key in sentence) {
      let strA = sentence[key].value.replace(sentenceA0[key].value, '').trim();
      let strB = '';
      let arr = strA.split(' ');
      for (const item of arr) {
        strB += item;
        strB += ' ';
        if (strB.length > 30) {
          break;
        }
      }
      strB = strB.slice(0, -1);
      if (strB.length > 30) {
        for (let i = strB.length - 1; i >= 0; i--) {
          if (strB[i] === ' ') {
            strB = strB.substring(0, i);
            break;
          }
        }
      }
      sentenceB0[key].value = strB;
    }
  }
};
