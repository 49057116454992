import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterBState } from '/js/characterBState/characterBState.js';

export const presetCharacterB0Index = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  // if (characterBState.C === true) {
  //   if (event.inputType === 'insertText' && event.data !== ' ') {
  //     theCharacterB0.index = theCharacterB0.selectInitial - 1;
  //   } else if (
  //     (event.inputType === 'insertText' && event.data === ' ') ||
  //     event.inputType === 'deleteContentBackward'
  //   ) {
  //     theCharacterB0.index = theCharacterB0.selectEnd;
  //   }
  // }
  if (event.data !== null) {
    theCharacterB0.index = theCharacterB0.values.length - 1;
  }
};
