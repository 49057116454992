import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { blankBState } from '/js/blankBState/blankBState.js';

export const setCharacterB0Element = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  let theCharacterC0 = characterC0[theIndex];

  if (sentenceAState.A_ATB === true) {
    theCharacterB0.element =
      theCharacterB0.elements[theCharacterB0.elements.length - 1];
  }

  if (sentenceBState.A === true || sentenceBState.A_BTC === true) {
    theCharacterB0.element = document.createElement('span');
  }

  if (sentenceBState.D === true) {
    theCharacterB0.element = document.querySelector(
      `#_${theIndex}_characterB0_${theCharacterB0.index}`
    );
  }
};
