import { codeState } from '/js/codeState/codeState.js';
import { codeCharacter } from '/js/codeCharacter/codeCharacter.js';
import { setSentenceXCanInput } from '/js/sentenceX/setSentenceXCanInput.js';

export const setCodeCharacterElementOpacity = () => {
  if (codeState.A === true) {
    let timeline = gsap.timeline();
    timeline.fromTo(
      codeCharacter.element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
      }
    );
    codeCharacter.elementTimelines.push(timeline);
  }
  if (codeState.D === true) {
    let timeline = gsap.timeline();
    timeline.to(codeCharacter.element, {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        codeCharacter.deleteElementTimelines.pop();
        if (codeCharacter.deleteElementTimelines.length === 0) {
          setSentenceXCanInput(true);
        }
      },
    });
    codeCharacter.deleteElementTimelines.push(timeline);
  }
};
