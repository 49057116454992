import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { setCharacterC0CompleteValues } from '/js/characterC0/setCharacterC0CompleteValues.js';
import { setCharacterC0CompleteElements } from '/js/characterC0/setCharacterC0CompleteElements.js';
import { setCharacterC0CompleteElementsOpacity } from '/js/characterC0/setCharacterC0CompleteElementsOpacity.js';

import { setCharacterC0Elements } from '/js/characterC0/setCharacterC0Elements.js';
import { setCharacterC0ElementsId } from '/js/characterC0/setCharacterC0ElementsId.js';
import { setSentenceC0Value } from '/js/sentenceC0/setSentenceC0Value.js';
import { setSentenceC0SplitValues } from '/js/sentenceC0/setSentenceC0SplitValues.js';
import { setSentenceC0SplitCounts } from '/js/sentenceC0/setSentenceC0SplitCounts.js';
import { setSentenceC0SplitInitials } from '/js/sentenceC0/setSentenceC0SplitInitials.js';
import { setCharacterC0ElementsClassName } from '/js/characterC0/setCharacterC0ElementsClassName.js';
import { setCharacterC0LastElement } from '/js/characterC0/setCharacterC0LastElement.js';
import { setCharacterC0PreviousElements } from '/js/characterC0/setCharacterC0PreviousElements.js';
import { setCharacterC0PreviousElementsOpacity } from '/js/characterC0/setCharacterC0PreviousElementsOpacity.js';
import { setSentenceXPKA } from '/js/sentenceX//setSentenceXPKA.js';
import { setSentenceXSKA } from '/js/sentenceX//setSentenceXSKA.js';
import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { setSentenceC1ElementValue } from '/js/sentenceC1/setSentenceC1ElementValue.js';

export const sentenceCStream_A_C = (event) => {
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterC0CompleteValues(event);
  setCharacterC0CompleteElements();
  setCharacterC0CompleteElementsOpacity();
  setCharacterC0Elements();
  setCharacterC0ElementsId();
  setSentenceC0Value();
  setSentenceC0SplitValues();
  setSentenceC0SplitCounts();
  setSentenceC0SplitInitials();
  setCharacterC0ElementsClassName();
  setCharacterC0LastElement();
  setCharacterC0PreviousElements();
  setCharacterC0PreviousElementsOpacity();
  setSentenceXPKA();
  setSentenceXSKA(event);
  // // setCharacterA0ElementsOpacity(event);
  // // setCharacterB0ElementsOpacity(event);
  // // setCharacterC0ElementsOpacity(event);
  setSentenceC1ElementValue();
};
