import { device } from '/js/device/device.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';

export const setSentenceXIndex = () => {
  let number = 0;
  if (sentenceX.selectIndex !== undefined) {
    number = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    number = sentenceX.inputIndex;
  }

  if (sentenceXState.C === true || sentenceXState.T === true) {
    sentenceX.index = number;
  }

  if (sentenceXState.W_U === true || sentenceXState.T_U === true) {
    if (device.target === 'Mobile') {
      sentenceX.index = number + 1;
    }
  }
};
