import { blankA0 } from '/js/blankA0/blankA0.js';
import { handleBlankA0ElementClick } from '/js/blankA0/handleBlankA0ElementClick.js';
import { handleBlankA0ElementWheel } from '/js/blankA0/handleBlankA0ElementWheel.js';
import { handleBlankA0ElementTouchStart } from '/js/blankA0/handleBlankA0ElementTouchStart.js';
import { handleBlankA0ElementTouchEnd } from '/js/blankA0/handleBlankA0ElementTouchEnd.js';

export const addBlankA0ElementEventListener = () => {
  for (const key in blankA0) {
    blankA0[key].element.addEventListener('click', handleBlankA0ElementClick);
    blankA0[key].element.addEventListener('wheel', handleBlankA0ElementWheel);
    blankA0[key].element.addEventListener(
      'touchstart',
      handleBlankA0ElementTouchStart
    );
    blankA0[key].element.addEventListener(
      'touchend',
      handleBlankA0ElementTouchEnd
    );
  }
};
