import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterB0ToCElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.A_BTC === true) {
    let arrA = theCharacterB0.toCValues;
    if (theCharacterB0.selectElements.length > 0) {
      setTimeout(() => {
        let arrB = Array.from(
          document.querySelectorAll(`#_${theIndex}_sentenceB0 span`)
        );
        let arrC = arrB.slice(-arrA.length);
        theCharacterB0.toCElements = arrC;
      }, 1000);
    } else {
      let arrB = Array.from(
        document.querySelectorAll(`#_${theIndex}_sentenceB0 span`)
      );
      let arrC = arrB.slice(-arrA.length);
      theCharacterB0.toCElements = arrC;
    }
  }
};
