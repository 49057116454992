import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';

export const setCharacterA0PreviousValue = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  theCharacterA0.previousValue = theCharacterA0.values[theCharacterA0.index];
  if (theCharacterA0.previousValue === undefined) {
    theCharacterA0.previousValue = ' ';
  }
};
