import { gsap } from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { setSentenceXCanInput } from '/js/sentenceX/setSentenceXCanInput.js';
export const setCharacterB0ElementOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  let theCharacterB0 = characterB0[theIndex];
  let theCharacterC0 = characterC0[theIndex];
  if (sentenceAState.A_ATB === true) {
    let timeline = gsap.timeline();
    timeline.fromTo(
      theCharacterB0.element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
      }
    );
  }
  if (sentenceBState.A === true) {
    let timeline = gsap.timeline();
    if (
      theCharacterA0.selectElements.length > 0 ||
      theCharacterB0.selectElements.length > 0
    ) {
      timeline.fromTo(
        theCharacterB0.element,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 3,
          delay: 1,
        }
      );
    } else {
      timeline.fromTo(
        theCharacterB0.element,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 3,
        }
      );
    }
    theCharacterB0.elementTimelines.push(timeline);
  }
  if (sentenceBState.D === true) {
    let timeline = gsap.timeline();
    timeline.to(theCharacterB0.element, {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        theCharacterB0.deleteTimelines.pop();
        if (
          theCharacterA0.deleteTimelines.length === 0 &&
          theCharacterB0.deleteTimelines.length === 0 &&
          theCharacterC0.deleteTimelines.length === 0
        ) {
          setSentenceXCanInput(true);
        }
      },
    });
    theCharacterB0.deleteTimelines.push(timeline);
  }
};
