import { code } from '/js/code/code.js';
import { confirmState } from '/js/confirmState/confirmState.js';

export const setConfirmState = (event) => {
  for (const key in confirmState) {
    confirmState[key] = false;
  }
  if (event.target.className === 'confirm' && code.value !== '') {
    confirmState.C = true;
  }
};
