import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
export const setCharacterB0ToCValues = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (theCharacterB0.values.length === 30) {
    let arrA = [];
    let arrB = [];
    let arrC = [];
    arrA = [...theCharacterB0.values];
    arrA.splice(theCharacterB0.index + 1, 0, theCharacterB0.data);
    if (theCharacterB0.data === ' ') {
      arrB = arrA.slice();
      arrC.push(arrB.pop());
      for (let i = arrB.length - 1; i >= 0; i--) {
        if (arrB[i] === ' ') {
          break;
        }
        arrC.push(arrB[i]);
      }
    } else {
      for (let i = arrA.length - 1; i >= 0; i--) {
        if (arrA[i] === ' ') {
          break;
        }
        arrC.push(arrA[i]);
      }
    }
    arrC.reverse();
    theCharacterB0.toCValues = arrC;
  }
};
