import { gsap } from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';

export const setCharacterC0PreviousElementOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];

  if (sentenceCState.A === true) {
    if (theCharacterC0.selectElements.length == 0) {
      if (theCharacterC0.previousElement !== undefined) {
        let timeline = gsap.timeline();
        let element = theCharacterC0.previousElement;
        const checkOpacity = () => {
          const number = parseFloat(element.style.opacity);
          if (number === 1) {
            timeline.to(element, {
              opacity: 0.4,
              duration: 1,
            });
            theCharacterC0.previousElementTimelines.push(timeline);
            clearInterval(checkInterval);
          }
        };
        const checkInterval = setInterval(checkOpacity, 10);
      }
    }
    // if (theCharacterC0.selectElements.length == 0) {
    //   let timeline = gsap.timeline();
    //   if (theCharacterC0.previousElement !== undefined) {
    //     let elementA =
    //       theCharacterC0.fromBElements[theCharacterC0.fromBElements.length - 1];
    //     let elementB = theCharacterC0.previousElement;

    //     const checkOpacityA = () => {
    //       const opacityA = parseFloat(elementA.style.opacity);
    //       if (opacityA === 1) {
    //         timeline.to(theCharacterC0.fromBElements, {
    //           opacity: 0.4,
    //           duration: 1,
    //         });
    //         theCharacterC0.previousElementTimelines.push(timeline);
    //         clearInterval(checkIntervalA);
    //       }
    //     };
    //     const checkOpacityB = () => {
    //       const opacityB = parseFloat(elementB.style.opacity);
    //       if (opacityB === 1) {
    //         timeline.to(elementB, {
    //           opacity: 0.4,
    //           duration: 1,
    //         });
    //         theCharacterC0.previousElementTimelines.push(timeline);
    //         clearInterval(checkIntervalB);
    //       }
    //     };
    //     const checkIntervalA = setInterval(checkOpacityA, 10);
    //     const checkIntervalB = setInterval(checkOpacityB, 10);
    //   }
    // }
  }

  if (sentenceCState.D === true) {
    let timeline = gsap.timeline();
    if (theCharacterC0.previousElement !== undefined) {
      timeline.to(theCharacterC0.previousElement, {
        opacity: 1,
        duration: 3,
        delay: 1,
      });
      theCharacterC0.elementTimelines.push(timeline);
    }
  }
};
