import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { userName } from '/js/userName/userName.js';

export const setSentenceXUserName = () => {
  if (logInState.V === true || confirmState.V === true) {
    sentenceX.userName = userName.value;
    localStorage.setItem('sentencex_userName', sentenceX.userName);
  }
};
