import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
export const setCharacterB0PreviousValue = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  theCharacterB0.previousValue = theCharacterB0.values[theCharacterB0.index];
};
