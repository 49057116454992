import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setSentenceValue = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true
  ) {
    let obj = {};
    if (
      sentenceX.dataSKA[sentenceX.SKA] !== undefined &&
      sentenceX.data.length > 0
    ) {
      for (const item of sentenceX.dataSKA[sentenceX.SKA]) {
        if (item[theIndex] == sentenceX.data[0]) {
          obj = item;
        }
      }
      for (const key in sentence) {
        if (obj[key] !== undefined) {
          sentence[key].value = obj[key];
        } else {
          sentence[key].value = '';
        }
      }
    } else {
      for (const key in sentenceA0) {
        let str = '';
        str += sentenceA0[key].value;
        if (sentenceB0[key].value !== '') {
          str += ' ';
          str += sentenceB0[key].value;
        }
        if (sentenceC0[key].value !== '') {
          str += ' ';
          str += sentenceC0[key].value;
        }
        sentence[key].value = str;
      }
    }
  }

  if (
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true
  ) {
    let obj = {};
    if (
      sentenceX.dataSKC[sentenceX.SKC] !== undefined &&
      sentenceX.data.length > 0
    ) {
      for (const item of sentenceX.dataSKC[sentenceX.SKC]) {
        if (item[theIndex] == sentenceX.data[0]) {
          obj = item;
        }
      }
      for (const key in sentence) {
        if (obj[key] !== undefined) {
          sentence[key].value = obj[key];
        } else {
          sentence[key].value = '';
        }
      }
    } else {
      for (const key in sentenceA0) {
        let str = '';
        str += sentenceA0[key].value;
        if (sentenceB0[key].value !== '') {
          str += ' ';
          str += sentenceB0[key].value;
        }
        if (sentenceC0[key].value !== '') {
          str += ' ';
          str += sentenceC0[key].value;
        }
        sentence[key].value = str;
      }
    }
  }

  if (
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true ||
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true ||
    blankAState.W_R_SKA === true ||
    blankBState.W_R_SKA === true ||
    blankCState.W_R_SKA === true ||
    characterAState.W_R_SKA === true ||
    characterBState.W_R_SKA === true ||
    characterCState.W_R_SKA === true
  ) {
    let obj = {};
    for (const item of sentenceX.dataSKA[sentenceX.SKA]) {
      if (
        item[theIndex] ==
        sentenceX.historyData[theIndex][
          sentenceX.historyData[theIndex].length - 1
        ]
      ) {
        obj = item;
      }
    }
    for (const key in sentence) {
      if (obj[key] !== undefined) {
        sentence[key].value = obj[key];
      } else {
        sentence[key].value = '';
      }
    }
  }

  if (
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true
  ) {
    let obj = {};
    for (const item of sentenceX.dataSKC[sentenceX.SKC]) {
      if (
        item[theIndex] ==
        sentenceX.historyData[theIndex][
          sentenceX.historyData[theIndex].length - 1
        ]
      ) {
        obj = item;
      }
    }
    for (const key in sentence) {
      if (obj[key] !== undefined) {
        sentence[key].value = obj[key];
      } else {
        sentence[key].value = '';
      }
    }
  }

  if (
    sentenceXState.W_L_VK_G === true ||
    sentenceAState.W_L_VK_G === true ||
    sentenceBState.W_L_VK_G === true ||
    sentenceCState.W_L_VK_G === true ||
    sentenceXState.T_L_VK_G === true ||
    sentenceAState.T_L_VK_G === true ||
    sentenceBState.T_L_VK_G === true ||
    sentenceCState.T_L_VK_G === true ||
    sentenceXState.W_L_VK === true ||
    sentenceAState.W_L_VK === true ||
    sentenceBState.W_L_VK === true ||
    sentenceCState.W_L_VK === true ||
    sentenceXState.T_L_VK === true ||
    sentenceAState.T_L_VK === true ||
    sentenceBState.T_L_VK === true ||
    sentenceCState.T_L_VK === true
  ) {
    let obj = {};
    if (sentenceX.dataVK.length > 0) {
      for (const item of sentenceX.dataVK) {
        if (item[theIndex] == sentenceX.data[0]) {
          obj = item;
        }
      }
      for (const key in sentence) {
        if (obj[key] !== undefined) {
          sentence[key].value = obj[key];
        } else {
          sentence[key].value = '';
        }
      }
    } else {
      for (const key in sentenceA0) {
        let str = '';
        str += sentenceA0[key].value;
        if (sentenceB0[key].value !== '') {
          str += ' ';
          str += sentenceB0[key].value;
        }
        if (sentenceC0[key].value !== '') {
          str += ' ';
          str += sentenceC0[key].value;
        }
        sentence[key].value = str;
      }
    }
  }
  if (
    sentenceXState.W_R_VK === true ||
    sentenceAState.W_R_VK === true ||
    sentenceBState.W_R_VK === true ||
    sentenceCState.W_R_VK === true ||
    sentenceXState.T_R_VK === true ||
    sentenceAState.T_R_VK === true ||
    sentenceBState.T_R_VK === true ||
    sentenceCState.T_R_VK === true
  ) {
    let obj = {};
    for (const item of sentenceX.dataVK) {
      if (
        item[theIndex] ==
        sentenceX.historyData[theIndex][
          sentenceX.historyData[theIndex].length - 1
        ]
      ) {
        obj = item;
      }
    }
    for (const key in sentence) {
      if (obj[key] !== undefined) {
        sentence[key].value = obj[key];
      } else {
        sentence[key].value = '';
      }
    }
  }
};
