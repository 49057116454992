import { setCodeState } from '/js/codeState/setCodeState.js';
import { codeState } from '/js/codeState/codeState.js';
import { codeStream_C } from '/js/codeStream/codeStream_C.js';

export const handleCodeA0ElementClick = (event) => {
  setCodeState(event);
  if (codeState.C === true) {
    codeStream_C(event);
  }
};
