import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';

export const setCharacterA0ToBValues = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (event.type === 'input' && event.inputType === 'insertText') {
    if (theCharacterA0.values.length === 30) {
      let arrA = [];
      let arrB = [];
      let arrC = [];
      arrA = [...theCharacterA0.values];
      arrA.splice(theCharacterA0.index + 1, 0, theCharacterA0.data);
      if (theCharacterA0.data === ' ') {
        arrB = arrA.slice();
        arrC.push(arrB.pop());
        for (let i = arrB.length - 1; i >= 0; i--) {
          if (arrB[i] === ' ') {
            break;
          }
          arrC.push(arrB[i]);
        }
      } else {
        for (let i = arrA.length - 1; i >= 0; i--) {
          if (arrA[i] === ' ') {
            break;
          }
          arrC.push(arrA[i]);
        }
      }
      arrC.reverse();
      theCharacterA0.toBValues = arrC;
    }
  }
};
