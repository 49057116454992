import { passWordState } from '/js/passWordState/passWordState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';

export const setPassWordA0CharacterElements = () => {
  if (passWordState.A === true) {
    let arr = Array.from(document.querySelectorAll(`.passWordA0 span`));
    passWordA0Character.elements = arr;
  }

  if (passWordState.D === true) {
    let arr = Array.from(document.querySelectorAll(`.passWordA0 span`));
    arr.pop();
    passWordA0Character.elements = arr;
  }
};
