import { passWordState } from '/js/passWordState/passWordState.js';

export const setPassWordState = (event) => {
  if (
    event.type === 'input' &&
    event.inputType === 'insertText' &&
    event.target.className === 'passWordA1'
  ) {
    passWordState.A = true;
  } else {
    passWordState.A = false;
  }

  if (
    event.type === 'click' &&
    event.target.nodeName === 'DIV' &&
    event.target.className === 'passWordA0'
  ) {
    passWordState.C = true;
  } else {
    passWordState.C = false;
  }

  if (
    event.type === 'input' &&
    event.inputType === 'deleteContentBackward' &&
    event.target.className === 'passWordA1'
  ) {
    passWordState.D = true;
  } else {
    passWordState.D = false;
  }
};
