import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { setSentenceARunTime } from '/js/sentenceA/setSentenceARunTime.js';
import { setSentenceBRunTime } from '/js/sentenceB/setSentenceBRunTime.js';
import { setSentenceCRunTime } from '/js/sentenceC/setSentenceCRunTime.js';
import { setCharacterA0RunTime } from '/js/characterA0/setCharacterA0RunTime.js';
import { setCharacterB0RunTime } from '/js/characterB0/setCharacterB0RunTime.js';
import { setCharacterC0RunTime } from '/js/characterC0/setCharacterC0RunTime.js';
import { setCharacterA2RunTime } from '/js/characterA2/setCharacterA2RunTime.js';
import { setCharacterB2RunTime } from '/js/characterB2/setCharacterB2RunTime.js';
import { setCharacterC2RunTime } from '/js/characterC2/setCharacterC2RunTime.js';
import { presetSentenceA0Value } from '/js/sentenceA0/presetSentenceA0Value.js';
import { presetSentenceB0Value } from '/js/sentenceB0/presetSentenceB0Value.js';
import { presetSentenceC0Value } from '/js/sentenceC0/presetSentenceC0Value.js';
import { setSentenceXVK } from '/js/sentenceX/setSentenceXVK.js';
import { setSentenceXPKA } from '/js/sentenceX/setSentenceXPKA.js';
import { setSentenceXSKA } from '/js/sentenceX/setSentenceXSKA.js';
import { setSentenceXPKC } from '/js/sentenceX/setSentenceXPKC.js';
import { setSentenceXSKC } from '/js/sentenceX/setSentenceXSKC.js';
import { setSentenceXState } from '/js/sentenceXState/setSentenceXState.js';
import { setSentenceAState } from '/js/sentenceAState/setSentenceAState.js';
import { setSentenceBState } from '/js/sentenceBState/setSentenceBState.js';
import { setSentenceCState } from '/js/sentenceCState/setSentenceCState.js';
import { setBlankAState } from '/js/blankAState/setBlankAState.js';
import { setBlankBState } from '/js/blankBState/setBlankBState.js';
import { setBlankCState } from '/js/blankCState/setBlankCState.js';
import { setCharacterAState } from '/js/characterAState/setCharacterAState.js';
import { setCharacterBState } from '/js/characterBState/setCharacterBState.js';
import { setCharacterCState } from '/js/characterCState/setCharacterCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterAStream_W_U } from '/js/characterAStream/characterAStream_W_U.js';
import { characterAStream_W_D } from '/js/characterAStream/characterAStream_W_D.js';
import { characterAStream_W_L_VK_G } from '/js/characterAStream/characterAStream_W_L_VK_G.js';
import { characterAStream_W_L_VK } from '/js/characterAStream/characterAStream_W_L_VK.js';
import { characterAStream_W_R_VK } from '/js/characterAStream/characterAStream_W_R_VK.js';
import { characterAStream_W_L_SKA_G1 } from '/js/characterAStream/characterAStream_W_L_SKA_G1.js';
import { characterAStream_W_L_SKA_G2 } from '/js/characterAStream/characterAStream_W_L_SKA_G2.js';
import { characterAStream_W_L_SKA } from '/js/characterAStream/characterAStream_W_L_SKA.js';
import { characterAStream_W_R_SKA } from '/js/characterAStream/characterAStream_W_R_SKA.js';
import { characterAStream_W_L_SKC_G } from '/js/characterAStream/characterAStream_W_L_SKC_G.js';
import { characterAStream_W_L_SKC } from '/js/characterAStream/characterAStream_W_L_SKC.js';
import { characterAStream_W_R_SKC } from '/js/characterAStream/characterAStream_W_R_SKC.js';

export const handleCharacterA0ElementWheel = (event) => {
  event.stopPropagation();
  if (sentenceX.canWheel === true) {
    setSentenceARunTime();
    setSentenceBRunTime();
    setSentenceCRunTime();
    setCharacterA0RunTime();
    setCharacterB0RunTime();
    setCharacterC0RunTime();
    setCharacterA2RunTime();
    setCharacterB2RunTime();
    setCharacterC2RunTime();
    presetSentenceA0Value(event);
    presetSentenceB0Value(event);
    presetSentenceC0Value(event);
    setSentenceXVK(event);
    setSentenceXPKA();
    setSentenceXPKC();
    setSentenceXSKA(event);
    setSentenceXSKC(event);
    setSentenceXState(event);
    setSentenceAState(event);
    setSentenceBState(event);
    setSentenceCState(event);
    setBlankAState(event);
    setBlankBState(event);
    setBlankCState(event);
    setCharacterAState(event);
    setCharacterBState(event);
    setCharacterCState(event);
    if (characterAState.W_U === true) {
      console.log('W_U');
      characterAStream_W_U(event);
    }
    if (characterAState.W_D === true) {
      console.log('W_D');
      characterAStream_W_D(event);
    }
    if (characterAState.W_L_VA_G === true) {
      characterAStream_W_L_VK_G(event);
    }
    if (characterAState.W_L_VK === true) {
      characterAStream_W_L_VK(event);
    }
    if (characterAState.W_R_VK === true) {
      characterAStream_W_R_VK(event);
    }
    if (characterAState.W_L_SKA_G1 === true) {
      console.log('W_L_SKA_G1');
      characterAStream_W_L_SKA_G1(event);
    }
    if (characterAState.W_L_SKA_G2 === true) {
      console.log('W_L_SKA_G2');
      characterAStream_W_L_SKA_G2(event);
    }
    if (characterAState.W_L_SKA === true) {
      console.log('W_L_SKA');
      characterAStream_W_L_SKA(event);
    }
    if (characterAState.W_R_SKA === true) {
      console.log('W_R_SKA');
      characterAStream_W_R_SKA(event);
    }
    if (characterAState.W_L_SKC_G === true) {
      console.log('W_L_SKC_G');
      characterAStream_W_L_SKC_G(event);
    }
    if (characterAState.W_L_SKC === true) {
      characterAStream_W_L_SKC(event);
    }
    if (characterAState.W_R_SKC === true) {
      characterAStream_W_R_SKC(event);
    }
  }
};
