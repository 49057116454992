export const sentenceB2 = {
  0: {
    element: document.querySelector('#_0_sentenceB2'),
    value: '',
  },
  1: {
    element: document.querySelector('#_1_sentenceB2'),
    value: '',
  },
  2: {
    element: document.querySelector('#_2_sentenceB2'),
    value: '',
  },
  3: {
    element: document.querySelector('#_3_sentenceB2'),
    value: '',
  },
  4: {
    element: document.querySelector('#_4_sentenceB2'),
    value: '',
  },
  5: {
    element: document.querySelector('#_5_sentenceB2'),
    value: '',
  },
  6: {
    element: document.querySelector('#_6_sentenceB2'),
    value: '',
  },
  7: {
    element: document.querySelector('#_7_sentenceB2'),
    value: '',
  },
  8: {
    element: document.querySelector('#_8_sentenceB2'),
    value: '',
  },
  9: {
    element: document.querySelector('#_9_sentenceB2'),
    value: '',
  },
};
