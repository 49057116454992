import { passWordState } from '/js/passWordState/passWordState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';
import { setSentenceXCanInput } from '/js/sentenceX/setSentenceXCanInput.js';

export const setPassWordA0CharacterElementOpacity = () => {
  if (passWordState.A === true) {
    let timeline = gsap.timeline();
    timeline.fromTo(
      passWordA0Character.element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
      }
    );
    passWordA0Character.elementTimelines.push(timeline);
  }
  if (passWordState.D === true) {
    let timeline = gsap.timeline();
    timeline.to(passWordA0Character.element, {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        passWordA0Character.deleteElementTimelines.pop();
        if (passWordA0Character.deleteElementTimelines.length === 0) {
          setSentenceXCanInput(true);
        }
      },
    });
    passWordA0Character.deleteElementTimelines.push(timeline);
  }
};
