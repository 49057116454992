import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const setCharacterA0ToBElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (sentenceAState.A_ATB === true) {
    let arrA = theCharacterA0.toBValues;
    if (theCharacterA0.selectElements.length > 0) {
      setTimeout(() => {
        let arrB = Array.from(
          document.querySelectorAll(`#_${theIndex}_sentenceA0 span`)
        );
        let arrC = arrB.slice(-arrA.length);
        theCharacterA0.toBElements = arrC;
      }, 1000);
    } else {
      let arrB = Array.from(
        document.querySelectorAll(`#_${theIndex}_sentenceA0 span`)
      );
      let arrC = arrB.slice(-arrA.length);
      theCharacterA0.toBElements = arrC;
    }
  }
};
