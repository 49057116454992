import gsap from 'gsap';
import { device } from '/js/device/device.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceA } from '/js/sentenceA/sentenceA.js';
import { sentenceB } from '/js/sentenceB/sentenceB.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const transformSentenceBElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true
  ) {
    if (device.target === 'Mobile') {
      for (let i = 0; i < 10; i++) {
        if (sentenceB[i].display === 'flex') {
          sentenceB[i].runTime = sentenceA[i].runTime;
          sentenceB[i].endY = sentenceA[i].endY + 3;
          gsap.to(sentenceB[i].element, {
            y: `${sentenceB[i].endY}vh`,
            duration: sentenceB[i].runTime,
          });
        }
      }
      // if (sentenceX.inputIndex !== undefined) {
      //   let number = 0;
      //   for (const key in sentenceA) {
      //     if (sentenceA[key].display === 'flex') {
      //       number -= 3;
      //     }
      //   }
      //   sentenceA[0].endY = number;
      //   if (sentenceA0[0].value === '') {
      //     gsap.to(sentenceA[0].element, {
      //       y: `${sentenceA[0].endY}vh`,
      //       duration: 0,
      //     });
      //   } else {
      //     gsap.to(sentenceA[0].element, {
      //       y: `${sentenceA[0].endY}vh`,
      //       duration: 6,
      //     });
      //   }
      //   for (let i = 1; i < 10; i++) {
      //     if (sentenceA[i].display === 'flex') {
      //       sentenceA[i].endY = sentenceA[i - 1].endY + 6;
      //       console.log(sentenceA[i].endY);
      //       gsap.to(sentenceA[i].element, {
      //         y: `${sentenceA[i].endY}vh`,
      //         duration: 6,
      //       });
      //     }
      //   }
      // }
      // if (sentenceX.selectIndex !== undefined) {
      //   let number = 0;
      //   for (const key in sentenceA) {
      //     if (sentenceA[key].display === 'flex') {
      //       number -= 3;
      //     }
      //   }
      //   sentenceA[0].endY = number + 3;
      //   gsap.to(sentenceA[0].element, {
      //     y: `${sentenceA[0].endY}vh`,
      //     duration: 6,
      //   });
      //   for (let i = 1; i < 10; i++) {
      //     if (sentenceA[i].display === 'flex') {
      //       sentenceA[i].endY = sentenceA[i - 1].endY + 6;
      //       console.log(sentenceA[i].endY);
      //       gsap.to(sentenceA[i].element, {
      //         y: `${sentenceA[i].endY}vh`,
      //         duration: 6,
      //       });
      //     }
      //   }
      // }
    }
  }

  if (
    sentenceAState.R_A === true ||
    sentenceAState.R_I === true ||
    sentenceAState.R_A_C === true ||
    sentenceAState.R_I_C === true
  ) {
    for (let i = 0; i <= theIndex; i++) {
      if (sentenceB[i].display === 'flex') {
        sentenceB[i].runTime = sentenceA[i].runTime;
        sentenceB[i].endY = sentenceA[i].endY + 3;
        gsap.to(sentenceB[i].element, {
          y: `${sentenceB[i].endY}vh`,
          duration: sentenceB[i].runTime,
        });
      }
    }
  }

  if (
    sentenceBState.R_A === true ||
    sentenceBState.R_I === true ||
    sentenceBState.R_A_C === true ||
    sentenceBState.R_I_C === true
  ) {
    for (let i = 0; i <= theIndex; i++) {
      if (sentenceB[i].display === 'flex') {
        sentenceB[i].runTime = sentenceA[i].runTime;
        sentenceB[i].endY = sentenceA[i].endY + 3;
        if (i == theIndex) {
          gsap.to(sentenceB[i].element, {
            y: `${sentenceB[i].endY}vh`,
            duration: sentenceB[i].runTime,
            delay: 1,
          });
        } else {
          gsap.to(sentenceB[i].element, {
            y: `${sentenceB[i].endY}vh`,
            duration: sentenceB[i].runTime,
          });
        }
      }
    }
  }

  if (
    sentenceCState.R_A === true ||
    sentenceCState.R_I === true ||
    sentenceCState.R_A_C === true ||
    sentenceCState.R_I_C === true
  ) {
    for (let i = 0; i <= theIndex; i++) {
      if (sentenceB[i].display === 'flex') {
        sentenceB[i].runTime = sentenceA[i].runTime;
        sentenceB[i].endY = sentenceA[i].endY + 3;
        if (i == theIndex) {
          gsap.to(sentenceB[i].element, {
            y: `${sentenceB[i].endY}vh`,
            duration: sentenceB[i].runTime,
            delay: 1,
          });
        } else {
          gsap.to(sentenceB[i].element, {
            y: `${sentenceB[i].endY}vh`,
            duration: sentenceB[i].runTime,
          });
        }
      }
    }
  }

  if (sentenceAState.A_ATB === true) {
    for (const key in sentenceB) {
      if (key == theIndex) {
        sentenceB[key].endY = sentenceA[key].endY + 3;
        gsap.to(sentenceB[key].element, {
          y: `${sentenceB[key].endY}vh`,
          duration: 0,
        });
      } else {
        if (sentenceB0[key].value !== '') {
          sentenceB[key].endY = sentenceA[key].endY + 3;
          gsap.to(sentenceB[key].element, {
            y: `${sentenceB[key].endY}vh`,
            duration: 1.5,
          });
        }
      }
    }
  }

  if (sentenceBState.A_BTC === true) {
    for (const key in sentenceB) {
      if (sentenceB0[key].value !== '') {
        sentenceB[key].endY = sentenceA[key].endY + 3;
        gsap.to(sentenceB[key].element, {
          y: `${sentenceB[key].endY}vh`,
          duration: 1.5,
        });
      }
    }
  }

  if (
    sentenceAState.D === true ||
    sentenceBState.D === true ||
    sentenceCState.D === true
  ) {
    for (let i = 0; i <= theIndex; i++) {
      if (sentenceB[i].display === 'flex') {
        sentenceB[i].runTime = sentenceA[i].runTime;
        sentenceB[i].endY = sentenceA[i].endY + 3;
        gsap.to(sentenceB[i].element, {
          y: `${sentenceB[i].endY}vh`,
          duration: sentenceB[i].runTime,
        });
      }
    }
  }
  if (
    sentenceXState.W_U === true ||
    sentenceAState.W_U === true ||
    sentenceBState.W_U === true ||
    sentenceCState.W_U === true ||
    blankAState.W_U === true ||
    blankBState.W_U === true ||
    blankCState.W_U === true ||
    characterAState.W_U === true ||
    characterBState.W_U === true ||
    characterCState.W_U === true ||
    sentenceXState.T_U === true ||
    sentenceAState.T_U === true ||
    sentenceBState.T_U === true ||
    sentenceCState.T_U === true ||
    blankAState.T_U === true ||
    blankBState.T_U === true ||
    blankCState.T_U === true ||
    characterAState.T_U === true ||
    characterBState.T_U === true ||
    characterCState.T_U === true
  ) {
    for (const key in sentenceB) {
      if (sentenceB0[key].value !== '') {
        sentenceB[key].runTime = sentenceA[key].runTime;
        sentenceB[key].endY = sentenceA[key].endY + 3;
        gsap.to(sentenceB[key].element, {
          y: `${sentenceB[key].endY}vh`,
          duration: sentenceB[key].runTime,
        });
      }
    }
  }
  if (
    sentenceXState.W_D === true ||
    sentenceAState.W_D === true ||
    sentenceBState.W_D === true ||
    sentenceCState.W_D === true ||
    blankAState.W_D === true ||
    blankBState.W_D === true ||
    blankCState.W_D === true ||
    characterAState.W_D === true ||
    characterBState.W_D === true ||
    characterCState.W_D === true ||
    sentenceXState.T_D === true ||
    sentenceAState.T_D === true ||
    sentenceBState.T_D === true ||
    sentenceCState.T_D === true ||
    blankAState.T_D === true ||
    blankBState.T_D === true ||
    blankCState.T_D === true ||
    characterAState.T_D === true ||
    characterBState.T_D === true ||
    characterCState.T_D === true
  ) {
    for (let i = 0; i <= theIndex; i++) {
      if (sentenceB[i].display === 'flex') {
        sentenceB[i].runTime = sentenceA[i].runTime;
        sentenceB[i].endY = sentenceA[i].endY + 3;
        gsap.to(sentenceB[i].element, {
          y: `${sentenceB[i].endY}vh`,
          duration: sentenceB[i].runTime,
        });
      }
    }
  }

  if (
    sentenceXState.W_L_VK_G === true ||
    sentenceAState.W_L_VK_G === true ||
    sentenceBState.W_L_VK_G === true ||
    sentenceCState.W_L_VK_G === true ||
    sentenceXState.T_L_VK_G === true ||
    sentenceAState.T_L_VK_G === true ||
    sentenceBState.T_L_VK_G === true ||
    sentenceCState.T_L_VK_G === true ||
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.W_L_VK === true ||
    sentenceAState.W_L_VK === true ||
    sentenceBState.W_L_VK === true ||
    sentenceCState.W_L_VK === true ||
    sentenceXState.T_L_VK === true ||
    sentenceAState.T_L_VK === true ||
    sentenceBState.T_L_VK === true ||
    sentenceCState.T_L_VK === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true ||
    sentenceXState.W_R_VK === true ||
    sentenceAState.W_R_VK === true ||
    sentenceBState.W_R_VK === true ||
    sentenceCState.W_R_VK === true ||
    sentenceXState.T_R_VK === true ||
    sentenceAState.T_R_VK === true ||
    sentenceBState.T_R_VK === true ||
    sentenceCState.T_R_VK === true ||
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true ||
    blankAState.W_R_SKA === true ||
    blankBState.W_R_SKA === true ||
    blankCState.W_R_SKA === true ||
    characterAState.W_R_SKA === true ||
    characterBState.W_R_SKA === true ||
    characterCState.W_R_SKA === true ||
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true ||
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    blankAState.W_R_SKC === true ||
    blankBState.W_R_SKC === true ||
    blankCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true ||
    blankAState.T_R_SKC === true ||
    blankBState.T_R_SKC === true ||
    blankCState.T_R_SKC === true
  ) {
    if (device.target === 'Desk') {
      for (const key in sentenceB) {
        sentenceB[key].endY = sentenceA[key].endY + 3;
        sentenceB[key].runTime = sentenceA[key].runTime;
        gsap.to(sentenceB[key].element, {
          y: `${sentenceB[key].endY}vh`,
          duration: sentenceB[key].runTime,
        });
      }
    } else if (device.target === 'Mobile') {
      for (const key in sentenceB) {
        
        sentenceB[key].endY = sentenceA[key].endY + 3;
        sentenceB[key].runTime = sentenceA[key].runTime;
        gsap.to(sentenceB[key].element, {
          y: `${sentenceB[key].endY}vh`,
          duration: sentenceB[key].runTime,
        });
      }
      // if (sentenceX.inputIndex !== undefined) {
      // }
      // if (sentenceX.selectIndex !== undefined) {
      // }
    }
  }
};
