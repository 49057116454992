import { setCodeCharacterData } from '/js/codeCharacter/setCodeCharacterData.js';
import { setCodeState } from '/js/codeState/setCodeState.js';
import { codeState } from '/js/codeState/codeState.js';
import { codeStream_A } from '/js/codeStream/codeStream_A.js';
import { codeStream_D } from '/js/codeStream/codeStream_D.js';

export const handleCodeA1ElementInput = (event) => {
  setCodeCharacterData(event);
  setCodeState(event);
  if (codeState.A === true) {
    codeStream_A(event);
  }
  if (codeState.D === true) {
    codeStream_D(event);
  }
};
