import { passWordState } from '/js/passWordState/passWordState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';

export const setPassWordA0CharacterIndex = () => {
  if (passWordState.A === true) {
    passWordA0Character.index += 1;
  }

  if (passWordState.D === true) {
    passWordA0Character.index -= 1;
  }
};
