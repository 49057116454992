import { device } from '/js/device/device.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';

export const presetSentenceXSelectIndex = (event) => {
  if (device.target === 'Desk') {
    sentenceX.selectIndex = undefined;
  }
  if (device.target === 'Mobile') {
    sentenceX.selectIndex = 0;
  }
};
