import gsap from 'gsap';
import { device } from '/js/device/device.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceA } from '/js/sentenceA/sentenceA.js';
import { sentenceB } from '/js/sentenceB/sentenceB.js';
import { sentenceC } from '/js/sentenceC/sentenceC.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { sentenceA2 } from '/js/sentenceA2/sentenceA2.js';
import { sentenceB2 } from '/js/sentenceB2/sentenceB2.js';
import { sentenceC2 } from '/js/sentenceC2/sentenceC2.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
export const transformSentenceAElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true
  ) {
    if (device.target === 'Mobile') {
      if (sentenceX.inputIndex !== undefined) {
        let number = 0;
        for (const key in sentenceA) {
          if (sentenceA[key].display === 'flex') {
            number = parseInt(key);
          }
        }
        let timeline = gsap.timeline();
        sentenceA[number].endY = -3;
        if (number == 0 && sentenceA0[number].value == '') {
          sentenceA[number].runTime = 0;
          gsap.to(sentenceA[number].element, {
            y: `${sentenceA[number].endY}vh`,
            duration: sentenceA[number].runTime,
          });
        } else {
          sentenceA[number].runTime = 6;
          timeline.to(sentenceA[number].element, {
            y: `${sentenceA[number].endY}vh`,
            duration: sentenceA[number].runTime,
          });
          sentenceA[number].transformTimeline = timeline;
        }

        for (let i = number; i >= 0; i--) {
          if (sentenceA[i - 1] !== undefined) {
            let timeline = gsap.timeline();
            sentenceA[i - 1].endY = sentenceA[i].endY - 6;
            if (sentenceB0[i].value !== '') {
              sentenceA[i - 1].endY -= 1.5;
            }
            if (sentenceB0[i - 1].value !== '') {
              sentenceA[i - 1].endY -= 1.5;
            }
            if (sentenceC0[i - 1].value !== '') {
              sentenceA[i - 1].endY -= 1.5;
            }
            sentenceA[i - 1].runTime = 6;
            timeline.to(sentenceA[i - 1].element, {
              y: `${sentenceA[i - 1].endY}vh`,
              duration: sentenceA[i - 1].runTime,
            });
            sentenceA[i - 1].transformTimeline = timeline;
          }
        }
      }

      if (sentenceX.selectIndex !== undefined) {
        let number = 0;
        for (const key in sentenceA) {
          if (sentenceA[key].display === 'flex') {
            number -= 3;
          }
        }
        for (let i = 1; i < 10; i++) {
          if (sentenceA2[i].value !== '') {
            if (sentenceB2[i - 1].value !== '') {
              number -= 1.5;
            }
            if (sentenceB2[i].value !== '') {
              number -= 1.5;
            }
            if (sentenceC2[i].value !== '') {
              number -= 1.5;
            }
          }
        }
        sentenceA[0].endY = number;
        sentenceA[0].endY += 3;
        sentenceA[0].runTime = 6;
        let timeline = gsap.timeline();
        timeline.to(sentenceA[0].element, {
          y: `${sentenceA[0].endY}vh`,
          duration: sentenceA[0].runTime,
        });
        sentenceA[0].transformTimeline = timeline;
        for (let i = 1; i < 10; i++) {
          if (sentenceA[i].display === 'flex') {
            sentenceA[i].endY = sentenceA[i - 1].endY + 6;
            if (sentenceB0[i].value !== '') {
              sentenceA[i].endY += 1.5;
            }
            if (sentenceB0[i - 1].value !== '') {
              sentenceA[i].endY += 1.5;
            }
            sentenceA[i].runTime = 6;
            let timeline = gsap.timeline();
            timeline.to(sentenceA[i].element, {
              y: `${sentenceA[i].endY}vh`,
              duration: sentenceA[i].runTime,
            });
            sentenceA[i].transformTimeline = timeline;
          }
        }
      }
    }
  }

  if (
    sentenceAState.R_A === true ||
    sentenceAState.R_I === true ||
    sentenceAState.R_A_C === true ||
    sentenceAState.R_I_C === true
  ) {
    if (device.target === 'Desk') {
      let time = 3;
      let timeA = 0;
      let timeB = 0;
      let timeC = 0;
      let number = 0;
      for (const key in sentenceA0) {
        if (
          sentenceA0[key].value !== '' &&
          sentenceA0[key].element.id[1] > theIndex
        ) {
          timeA += 1;
        }
      }
      for (const key in sentenceB0) {
        if (
          sentenceB0[key].value !== '' &&
          sentenceB0[key].element.id[1] > theIndex
        ) {
          timeB += 1;
        }
      }
      for (const key in sentenceC0) {
        if (
          sentenceC0[key].value !== '' &&
          sentenceC0[key].element.id[1] > theIndex
        ) {
          timeC += 1;
        }
      }
      time += timeA - 1;
      time += timeB / 2;
      time += timeC / 2;

      sentenceA[0].endY = -3 * theIndex;

      for (let i = 1; i < theIndex; i++) {
        if (sentenceA0[i].height === '3vh') {
          number += 1;
        }
      }
      for (let i = 0; i < theIndex; i++) {
        if (sentenceB0[i].height === '3vh') {
          number += 1;
        }
      }
      sentenceA[0].endY -= 1.5 * number;
      gsap.to(sentenceA[0].element, {
        y: `${sentenceA[0].endY}vh`,
        duration: time,
      });
      sentenceA[0].runTime = time;

      for (let i = 1; i < theIndex; i++) {
        sentenceA[i].endY = sentenceA[i - 1].endY + 6;
        if (sentenceA0[i - 1].height === '3vh') {
          sentenceA[i].endY += 1.5;
        }
        if (sentenceB0[i - 1].height === '3vh') {
          sentenceA[i].endY += 3;
        }
        if (sentenceA0[i].height === '3vh') {
          sentenceA[i].endY += 1.5;
        }
        gsap.to(sentenceA[i].element, {
          y: `${sentenceA[i].endY}vh`,
          duration: time,
        });
        sentenceA[i].runTime = time;
      }

      if (theIndex > 0) {
        sentenceA[theIndex].endY = sentenceA[theIndex - 1].endY + 6;
        if (sentenceA0[theIndex - 1].height === '3vh') {
          sentenceA[theIndex].endY += 1.5;
        }
        if (sentenceB0[theIndex - 1].height === '3vh') {
          sentenceA[theIndex].endY += 3;
        }
        gsap.to(sentenceA[theIndex].element, {
          y: `${sentenceA[theIndex].endY}vh`,
          duration: time,
        });
        sentenceA[theIndex].runTime = time;
      }
    } else if (device.target === 'Mobile') {
      let time = 6;
      let timeA = 0;
      let timeB = 0;
      let timeC = 0;
      for (const key in sentenceA0) {
        if (
          sentenceA0[key].value !== '' &&
          sentenceA0[key].element.id[1] > theIndex
        ) {
          timeA += 1;
        }
      }
      for (const key in sentenceB0) {
        if (
          sentenceB0[key].value !== '' &&
          sentenceB0[key].element.id[1] > theIndex
        ) {
          timeB += 1;
        }
      }
      for (const key in sentenceC0) {
        if (
          sentenceC0[key].value !== '' &&
          sentenceC0[key].element.id[1] > theIndex
        ) {
          timeC += 1;
        }
      }
      time += timeA - 1;
      time += timeB / 2;
      time += timeC / 2;

      sentenceA[theIndex].endY = -3;
      gsap.to(sentenceA[theIndex].element, {
        y: `${sentenceA[theIndex].endY}vh`,
        duration: time,
      });

      for (let i = theIndex; i > 0; i--) {
        let timeline = gsap.timeline();
        sentenceA[i - 1].endY = sentenceA[i].endY - 6;
        if (sentenceB0[i - 1].value !== '') {
          sentenceA[i - 1].endY -= 1.5;
        }
        if (sentenceC0[i - 1].value !== '') {
          sentenceA[i - 1].endY -= 1.5;
        }
        sentenceA[i - 1].runTime = time;
        timeline.to(sentenceA[i - 1].element, {
          y: `${sentenceA[i - 1].endY}vh`,
          duration: sentenceA[i - 1].runTime,
        });
        sentenceA[i - 1].transformTimeline = timeline;
      }
    }
  }

  if (
    sentenceBState.R_A === true ||
    sentenceBState.R_I === true ||
    sentenceBState.R_A_C === true ||
    sentenceBState.R_I_C === true
  ) {
    if (device.target === 'Desk') {
      let time = 3;
      let timeA = 0;
      let timeB = 0;
      let timeC = 0;
      let number = 0;
      for (const key in sentenceA0) {
        if (
          sentenceA0[key].value !== '' &&
          sentenceA0[key].element.id[1] > theIndex
        ) {
          timeA += 1;
        }
      }
      for (const key in sentenceB0) {
        if (
          sentenceB0[key].value !== '' &&
          sentenceB0[key].element.id[1] > theIndex
        ) {
          timeB += 1;
        }
      }
      for (const key in sentenceC0) {
        if (
          sentenceC0[key].value !== '' &&
          sentenceC0[key].element.id[1] > theIndex
        ) {
          timeC += 1;
        }
      }
      time += timeA - 1;
      time += timeB / 2;
      time += timeC / 2;

      sentenceA[0].endY = -3 * theIndex;

      for (let i = 1; i <= theIndex; i++) {
        if (sentenceA0[i].height === '3vh') {
          number += 1;
        }
      }
      for (let i = 0; i < theIndex; i++) {
        if (sentenceB0[i].height === '3vh') {
          number += 1;
        }
      }
      sentenceA[0].endY -= 1.5 * number;

      if (theIndex == 0) {
        gsap.to(sentenceA[0].element, {
          y: `${sentenceA[0].endY}vh`,
          duration: time,
          delay: 1,
        });
        sentenceA[0].runTime = time;
      } else {
        gsap.to(sentenceA[0].element, {
          y: `${sentenceA[0].endY}vh`,
          duration: time,
        });
        sentenceA[0].runTime = time;
      }

      for (let i = 1; i < theIndex; i++) {
        sentenceA[i].endY = sentenceA[i - 1].endY + 6;
        if (sentenceA0[i - 1].height === '3vh') {
          sentenceA[i].endY += 1.5;
        }
        if (sentenceB0[i - 1].height === '3vh') {
          sentenceA[i].endY += 3;
        }
        if (sentenceA0[i].height === '3vh') {
          sentenceA[i].endY += 1.5;
        }
        gsap.to(sentenceA[i].element, {
          y: `${sentenceA[i].endY}vh`,
          duration: time,
        });
        sentenceA[i].runTime = time;
      }

      if (theIndex > 0) {
        sentenceA[theIndex].endY = sentenceA[theIndex - 1].endY + 6;
        sentenceA[theIndex].endY += 1.5;
        if (sentenceA0[theIndex - 1].height === '3vh') {
          sentenceA[theIndex].endY += 1.5;
        }
        if (sentenceB0[theIndex - 1].height === '3vh') {
          sentenceA[theIndex].endY += 3;
        }
        gsap.to(sentenceA[theIndex].element, {
          y: `${sentenceA[theIndex].endY}vh`,
          duration: time,
          delay: 1,
        });
        sentenceA[theIndex].runTime = time;
      }
    }
  }

  if (
    sentenceCState.R_A === true ||
    sentenceCState.R_I === true ||
    sentenceCState.R_A_C === true ||
    sentenceCState.R_I_C === true
  ) {
    if (device.target === 'Desk') {
      let time = 3;
      let timeA = 0;
      let timeB = 0;
      let timeC = 0;
      let number = 0;
      for (const key in sentenceA0) {
        if (
          sentenceA0[key].value !== '' &&
          sentenceA0[key].element.id[1] > theIndex
        ) {
          timeA += 1;
        }
      }
      for (const key in sentenceB0) {
        if (
          sentenceB0[key].value !== '' &&
          sentenceB0[key].element.id[1] > theIndex
        ) {
          timeB += 1;
        }
      }
      for (const key in sentenceC0) {
        if (
          sentenceC0[key].value !== '' &&
          sentenceC0[key].element.id[1] > theIndex
        ) {
          timeC += 1;
        }
      }
      time += timeA - 1;
      time += timeB / 2;
      time += timeC / 2;

      sentenceA[0].endY = -3 * theIndex;

      for (let i = 1; i <= theIndex; i++) {
        if (sentenceA0[i].height === '3vh') {
          number += 1;
        }
      }
      for (let i = 0; i <= theIndex; i++) {
        if (sentenceB0[i].height === '3vh') {
          number += 1;
        }
      }
      sentenceA[0].endY -= 1.5 * number;
      gsap.to(sentenceA[0].element, {
        y: `${sentenceA[0].endY}vh`,
        duration: time,
      });
      sentenceA[0].runTime = time;

      for (let i = 1; i < theIndex; i++) {
        sentenceA[i].endY = sentenceA[i - 1].endY + 6;
        if (sentenceA0[i - 1].height === '3vh') {
          sentenceA[i].endY += 1.5;
        }
        if (sentenceB0[i - 1].height === '3vh') {
          sentenceA[i].endY += 3;
        }
        if (sentenceA0[i].height === '3vh') {
          sentenceA[i].endY += 1.5;
        }
        gsap.to(sentenceA[i].element, {
          y: `${sentenceA[i].endY}vh`,
          duration: time,
        });
        sentenceA[i].runTime = time;
      }

      if (theIndex > 0) {
        sentenceA[theIndex].endY = sentenceA[theIndex - 1].endY + 6;
        sentenceA[theIndex].endY += 1.5;
        if (sentenceA0[theIndex - 1].height === '3vh') {
          sentenceA[theIndex].endY += 1.5;
        }
        if (sentenceB0[theIndex - 1].height === '3vh') {
          sentenceA[theIndex].endY += 3;
        }
        gsap.to(sentenceA[theIndex].element, {
          y: `${sentenceA[theIndex].endY}vh`,
          duration: time,
          delay: 1,
        });
        sentenceA[theIndex].runTime = time;
      }
    }
  }

  if (sentenceAState.A_ATB === true) {
    if (device.target === 'Desk') {
      for (let i = 0; i < 10; i++) {
        if (sentenceA[i].display === 'flex') {
          if (i < theIndex) {
            sentenceA[i].endY -= 1.5;
          } else if (i > theIndex) {
            sentenceA[i].endY += 1.5;
          }
          gsap.to(sentenceA[i].element, {
            y: `${sentenceA[i].endY}vh`,
            duration: 1.5,
          });
        }
      }
    } else if (device.target === 'Mobile') {
      let timeline = gsap.timeline();
      sentenceA[theIndex].endY = -3;
      sentenceA[theIndex].runTime = 3;
      timeline.to(sentenceA[theIndex].element, {
        y: `${sentenceA[theIndex].endY}vh`,
        duration: sentenceA[theIndex].runTime,
      });

      for (let i = theIndex; i >= 0; i--) {
        if (sentenceA[i - 1] !== undefined) {
          let timeline = gsap.timeline();
          sentenceA[i - 1].endY = sentenceA[i].endY - 6;
          if (sentenceB0[i].value !== '') {
            sentenceA[i - 1].endY -= 1.5;
          }
          if (sentenceB0[i - 1].value !== '') {
            sentenceA[i - 1].endY -= 1.5;
          }
          if (sentenceC0[i - 1].value !== '') {
            sentenceA[i - 1].endY -= 1.5;
          }
          sentenceA[i - 1].runTime = 3;
          timeline.to(sentenceA[i - 1].element, {
            y: `${sentenceA[i - 1].endY}vh`,
            duration: sentenceA[i - 1].runTime,
          });
          sentenceA[i - 1].transformTimeline = timeline;
        }
      }
    }
  }

  if (sentenceBState.A_BTC === true) {
    if (device.target === 'Desk') {
      for (let i = 0; i < 10; i++) {
        if (sentenceA[i].display === 'flex') {
          if (i <= theIndex) {
            sentenceA[i].endY -= 1.5;
          } else if (i > theIndex) {
            sentenceA[i].endY += 1.5;
          }
          gsap.to(sentenceA[i].element, {
            y: `${sentenceA[i].endY}vh`,
            duration: 1.5,
          });
        }
      }
    }
  }

  if (
    sentenceAState.D === true ||
    sentenceBState.D === true ||
    sentenceCState.D === true
  ) {
    if (device.target === 'Desk') {
      let time = 3;
      let timeA = 0;
      let timeB = 0;
      let timeC = 0;
      let number = 0;
      for (const key in sentenceA0) {
        if (
          sentenceA0[key].value !== '' &&
          sentenceA0[key].element.id[1] > theIndex
        ) {
          timeA += 1;
        }
      }
      for (const key in sentenceB0) {
        if (
          sentenceB0[key].value !== '' &&
          sentenceB0[key].element.id[1] > theIndex
        ) {
          timeB += 1;
        }
      }
      for (const key in sentenceC0) {
        if (
          sentenceC0[key].value !== '' &&
          sentenceC0[key].element.id[1] > theIndex
        ) {
          timeC += 1;
        }
      }
      time += timeA - 1;
      time += timeB / 2;
      time += timeC / 2;

      sentenceA[0].endY = -3 * theIndex;
      for (let i = 1; i <= theIndex; i++) {
        if (sentenceA0[i].height === '3vh') {
          number += 1;
        }
      }
      for (let i = 0; i <= theIndex; i++) {
        if (sentenceB0[i].height === '3vh') {
          number += 1;
        }
      }
      sentenceA[0].endY -= 1.5 * number;

      if (sentenceC[theIndex].display === 'flex') {
        if (sentenceC0[theIndex].value === '') {
          sentenceA[0].endY += 1.5;
        }
      }
      gsap.to(sentenceA[0].element, {
        y: `${sentenceA[0].endY}vh`,
        duration: time,
      });

      sentenceA[0].runTime = time;

      for (let i = 1; i <= theIndex; i++) {
        sentenceA[i].endY = sentenceA[i - 1].endY + 6;
        if (sentenceA0[i].height === '3vh') {
          if (sentenceA0[i - 1].height === '6vh') {
            sentenceA[i].endY += 1.5;
          }
          if (sentenceA0[i - 1].height === '3vh') {
            sentenceA[i].endY += 3;
          }
          if (sentenceB0[i - 1].height === '3vh') {
            sentenceA[i].endY += 3;
          }
        } else if (sentenceA0[i].height === '6vh') {
          if (sentenceA0[i - 1].height === '3vh') {
            sentenceA[i].endY += 1.5;
          }
          if (sentenceB0[i - 1].height === '3vh') {
            sentenceA[i].endY += 3;
          }
        }
        gsap.to(sentenceA[i].element, {
          y: `${sentenceA[i].endY}vh`,
          duration: time,
        });
        sentenceA[i].runTime = time;
      }
    } else if (device.target === 'Mobile') {
      let time = 6;
      let timeA = 0;
      let timeB = 0;
      let timeC = 0;
      for (const key in sentenceA0) {
        if (
          sentenceA0[key].value !== '' &&
          sentenceA0[key].element.id[1] > theIndex
        ) {
          timeA += 1;
        }
      }
      for (const key in sentenceB0) {
        if (
          sentenceB0[key].value !== '' &&
          sentenceB0[key].element.id[1] > theIndex
        ) {
          timeB += 1;
        }
      }
      for (const key in sentenceC0) {
        if (
          sentenceC0[key].value !== '' &&
          sentenceC0[key].element.id[1] > theIndex
        ) {
          timeC += 1;
        }
      }
      time += timeA - 1;
      time += timeB / 2;
      time += timeC / 2;

      sentenceA[theIndex].endY = -3;
      gsap.to(sentenceA[theIndex].element, {
        y: `${sentenceA[theIndex].endY}vh`,
        duration: time,
      });

      for (let i = theIndex; i > 0; i--) {
        let timeline = gsap.timeline();
        sentenceA[i - 1].endY = sentenceA[i].endY - 6;
        if (sentenceB0[i].value !== '') {
          sentenceA[i - 1].endY -= 1.5;
        }
        if (sentenceB0[i - 1].value !== '') {
          sentenceA[i - 1].endY -= 1.5;
        }
        if (sentenceC0[i - 1].value !== '') {
          sentenceA[i - 1].endY -= 1.5;
        }
        sentenceA[i - 1].runTime = time;
        timeline.to(sentenceA[i - 1].element, {
          y: `${sentenceA[i - 1].endY}vh`,
          duration: sentenceA[i - 1].runTime,
        });
        sentenceA[i - 1].transformTimeline = timeline;
      }
    }
  }

  if (
    sentenceXState.W_U === true ||
    sentenceAState.W_U === true ||
    sentenceBState.W_U === true ||
    sentenceCState.W_U === true ||
    blankAState.W_U === true ||
    blankBState.W_U === true ||
    blankCState.W_U === true ||
    characterAState.W_U === true ||
    characterBState.W_U === true ||
    characterCState.W_U === true ||
    sentenceXState.T_U === true ||
    sentenceAState.T_U === true ||
    sentenceBState.T_U === true ||
    sentenceCState.T_U === true ||
    blankAState.T_U === true ||
    blankBState.T_U === true ||
    blankCState.T_U === true ||
    characterAState.T_U === true ||
    characterBState.T_U === true ||
    characterCState.T_U === true
  ) {
    if (device.target === 'Desk') {
      if (sentenceA0[theIndex + 1].value === '') {
        for (let i = 0; i <= theIndex; i++) {
          sentenceA[i].endY -= 3;
          sentenceA[i].runTime = 3;
          gsap.to(sentenceA[i].element, {
            y: `${sentenceA[i].endY}vh`,
            duration: sentenceA[i].runTime,
          });
        }
        sentenceA[theIndex + 1].endY = -sentenceA[0].endY;
        if (sentenceA0[0].height === '3vh') {
          sentenceA[theIndex + 1].endY += 1.5;
        }
        gsap.to(sentenceA[theIndex + 1].element, {
          y: `${sentenceA[theIndex + 1].endY}vh`,
          duration: 0,
        });
      } else if (sentenceA0[theIndex + 1].value !== '') {
        let time = 3;
        let timeA = 0;
        let timeB = 0;
        let timeC = 0;
        let number = 0;
        for (const key in sentenceA0) {
          if (sentenceA0[key].value !== '') {
            number = parseInt(key);
          }
        }
        number -= 1;

        for (const key in sentenceA0) {
          if (
            sentenceA0[key].value !== '' &&
            sentenceA0[key].element.id[1] > theIndex
          ) {
            timeA += 1;
          }
        }
        for (const key in sentenceB0) {
          if (
            sentenceB0[key].value !== '' &&
            sentenceB0[key].element.id[1] > theIndex
          ) {
            timeB += 1;
          }
        }
        for (const key in sentenceC0) {
          if (
            sentenceC0[key].value !== '' &&
            sentenceC0[key].element.id[1] > theIndex
          ) {
            timeC += 1;
          }
        }
        time += timeA - 1;
        time += timeB / 2;
        time += timeC / 2;

        for (let i = number; i >= 0; i--) {
          sentenceA[i].endY = sentenceA[i + 1].endY - 6;
          if (sentenceB0[i].value !== '') {
            sentenceA[i].endY -= 1.5;
          }
          if (sentenceB0[i + 1].value !== '') {
            sentenceA[i].endY -= 1.5;
          }
          if (sentenceC0[i].value !== '') {
            sentenceA[i].endY -= 3;
          }
          gsap.to(sentenceA[i].element, {
            y: `${sentenceA[i].endY}vh`,
            duration: time,
          });
          sentenceA[i].runTime = time;
        }
      }
    } else if (device.target === 'Mobile') {
      if (sentenceA0[theIndex + 1].value === '') {
        sentenceA[theIndex + 1].endY = -3;
        gsap.to(sentenceA[theIndex + 1].element, {
          y: `${sentenceA[theIndex + 1].endY}vh`,
          duration: 0,
        });

        for (let i = theIndex; i >= 0; i--) {
          let timeline = gsap.timeline();
          sentenceA[i].endY = sentenceA[i + 1].endY - 6;
          if (sentenceB0[i].value !== '') {
            sentenceA[i].endY -= 1.5;
          }
          if (sentenceC0[i].value !== '') {
            sentenceA[i].endY -= 1.5;
          }
          if (sentenceB0[i + 1] !== undefined) {
            if (sentenceB0[i + 1].value !== '') {
              sentenceA[i].endY -= 1.5;
            }
          }
          sentenceA[i].runTime = 3;
          timeline.to(sentenceA[i].element, {
            y: `${sentenceA[i].endY}vh`,
            duration: sentenceA[i].runTime,
          });
          sentenceA[i].transformTimeline = timeline;
        }
      } else {
        let index = 0;
        let time = 6;
        let timeA = 0;
        let timeB = 0;
        let timeC = 0;
        for (const key in sentenceA0) {
          if (sentenceA0[key].value !== '') {
            index = parseInt(key);
          }
        }
        for (const key in sentenceA0) {
          if (
            sentenceA0[key].value !== '' &&
            sentenceA0[key].element.id[1] > theIndex
          ) {
            timeA += 1;
          }
        }
        for (const key in sentenceB0) {
          if (
            sentenceB0[key].value !== '' &&
            sentenceB0[key].element.id[1] > theIndex
          ) {
            timeB += 1;
          }
        }
        for (const key in sentenceC0) {
          if (
            sentenceC0[key].value !== '' &&
            sentenceC0[key].element.id[1] > theIndex
          ) {
            timeC += 1;
          }
        }
        time += timeA - 1;
        time += timeB / 2;
        time += timeC / 2;

        sentenceA[theIndex].endY = -3;
        gsap.to(sentenceA[theIndex].element, {
          y: `${sentenceA[theIndex].endY}vh`,
          duration: 0,
        });

        for (let i = index; i > 0; i--) {
          let timeline = gsap.timeline();
          sentenceA[i - 1].endY = sentenceA[i].endY - 6;
          if (sentenceB0[i].value !== '') {
            sentenceA[i - 1].endY -= 1.5;
          }
          if (sentenceB0[i - 1].value !== '') {
            sentenceA[i - 1].endY -= 1.5;
          }
          if (sentenceC0[i - 1].value !== '') {
            sentenceA[i - 1].endY -= 1.5;
          }
          sentenceA[i - 1].runTime = time;
          timeline.to(sentenceA[i - 1].element, {
            y: `${sentenceA[i - 1].endY}vh`,
            duration: sentenceA[i - 1].runTime,
          });
          sentenceA[i - 1].transformTimeline = timeline;
        }
      }
    }
  }

  if (
    sentenceXState.W_D === true ||
    sentenceAState.W_D === true ||
    sentenceBState.W_D === true ||
    sentenceCState.W_D === true ||
    blankAState.W_D === true ||
    blankBState.W_D === true ||
    blankCState.W_D === true ||
    characterAState.W_D === true ||
    characterBState.W_D === true ||
    characterCState.W_D === true ||
    sentenceXState.T_D === true ||
    sentenceAState.T_D === true ||
    sentenceBState.T_D === true ||
    sentenceCState.T_D === true ||
    blankAState.T_D === true ||
    blankBState.T_D === true ||
    blankCState.T_D === true ||
    characterAState.T_D === true ||
    characterBState.T_D === true ||
    characterCState.T_D === true
  ) {
    if (device.target === 'Desk') {
      let time = 3;
      let timeA = 0;
      let timeB = 0;
      let timeC = 0;
      let number = 0;
      for (const key in sentenceA0) {
        if (
          sentenceA0[key].value !== '' &&
          sentenceA0[key].element.id[1] > theIndex
        ) {
          timeA += 1;
        }
      }
      for (const key in sentenceB0) {
        if (
          sentenceB0[key].value !== '' &&
          sentenceB0[key].element.id[1] > theIndex
        ) {
          timeB += 1;
        }
      }
      for (const key in sentenceC0) {
        if (
          sentenceC0[key].value !== '' &&
          sentenceC0[key].element.id[1] > theIndex
        ) {
          timeC += 1;
        }
      }
      time += timeA - 1;
      time += timeB / 2;
      time += timeC / 2;
      sentenceA[0].endY = -3 * theIndex;
      for (let i = 1; i <= theIndex; i++) {
        if (sentenceA0[i].height === '3vh') {
          number += 1;
        }
      }
      for (let i = 0; i <= theIndex; i++) {
        if (sentenceB0[i].height === '3vh') {
          number += 1;
        }
      }
      sentenceA[0].endY -= 1.5 * number;
      gsap.to(sentenceA[0].element, {
        y: `${sentenceA[0].endY}vh`,
        duration: time,
      });
      sentenceA[0].runTime = time;
      for (let i = 1; i <= theIndex; i++) {
        sentenceA[i].endY = sentenceA[i - 1].endY + 6;
        if (sentenceA0[i].height === '3vh') {
          if (sentenceA0[i - 1].height === '6vh') {
            sentenceA[i].endY += 1.5;
          }
          if (sentenceA0[i - 1].height === '3vh') {
            sentenceA[i].endY += 3;
          }
          if (sentenceB0[i - 1].height === '3vh') {
            sentenceA[i].endY += 3;
          }
        } else if (sentenceA0[i].height === '6vh') {
          if (sentenceA0[i - 1].height === '3vh') {
            sentenceA[i].endY += 1.5;
          }
          if (sentenceB0[i - 1].height === '3vh') {
            sentenceA[i].endY += 3;
          }
        }
        gsap.to(sentenceA[i].element, {
          y: `${sentenceA[i].endY}vh`,
          duration: time,
        });
        sentenceA[i].runTime = time;
      }
    } else if (device.target === 'Mobile') {
      let time = 6;
      let timeA = 0;
      let timeB = 0;
      let timeC = 0;
      for (const key in sentenceA0) {
        if (
          sentenceA0[key].value !== '' &&
          sentenceA0[key].element.id[1] > theIndex
        ) {
          timeA += 1;
        }
      }
      for (const key in sentenceB0) {
        if (
          sentenceB0[key].value !== '' &&
          sentenceB0[key].element.id[1] > theIndex
        ) {
          timeB += 1;
        }
      }
      for (const key in sentenceC0) {
        if (
          sentenceC0[key].value !== '' &&
          sentenceC0[key].element.id[1] > theIndex
        ) {
          timeC += 1;
        }
      }
      time += timeA - 1;
      time += timeB / 2;
      time += timeC / 2;
      sentenceA[theIndex].endY = -3;
      sentenceA[theIndex].runTime = time;

      let timeline = gsap.timeline();
      timeline.to(sentenceA[theIndex].element, {
        y: `${sentenceA[theIndex].endY}vh`,
        duration: sentenceA[theIndex].runTime,
      });
      sentenceA[theIndex].transformTimeline = timeline;

      for (let i = theIndex; i > 0; i--) {
        let timeline = gsap.timeline();
        sentenceA[i - 1].endY = sentenceA[i].endY - 6;
        if (sentenceB0[i].value !== '') {
          sentenceA[i - 1].endY -= 1.5;
        }
        if (sentenceB0[i - 1].value !== '') {
          sentenceA[i - 1].endY -= 1.5;
        }
        if (sentenceC0[i - 1].value !== '') {
          sentenceA[i - 1].endY -= 1.5;
        }
        sentenceA[i - 1].runTime = time;
        timeline.to(sentenceA[i - 1].element, {
          y: `${sentenceA[i - 1].endY}vh`,
          duration: sentenceA[i - 1].runTime,
        });
        sentenceA[i - 1].transformTimeline = timeline;
      }
    }
  }

  if (
    sentenceXState.W_L_VK_G === true ||
    sentenceAState.W_L_VK_G === true ||
    sentenceBState.W_L_VK_G === true ||
    sentenceCState.W_L_VK_G === true ||
    sentenceXState.T_L_VK_G === true ||
    sentenceAState.T_L_VK_G === true ||
    sentenceBState.T_L_VK_G === true ||
    sentenceCState.T_L_VK_G === true ||
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.W_L_VK === true ||
    sentenceAState.W_L_VK === true ||
    sentenceBState.W_L_VK === true ||
    sentenceCState.W_L_VK === true ||
    sentenceXState.T_L_VK === true ||
    sentenceAState.T_L_VK === true ||
    sentenceBState.T_L_VK === true ||
    sentenceCState.T_L_VK === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true ||
    sentenceXState.W_R_VK === true ||
    sentenceAState.W_R_VK === true ||
    sentenceBState.W_R_VK === true ||
    sentenceCState.W_R_VK === true ||
    sentenceXState.T_R_VK === true ||
    sentenceAState.T_R_VK === true ||
    sentenceBState.T_R_VK === true ||
    sentenceCState.T_R_VK === true ||
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true ||
    blankAState.W_R_SKA === true ||
    blankBState.W_R_SKA === true ||
    blankCState.W_R_SKA === true ||
    characterAState.W_R_SKA === true ||
    characterBState.W_R_SKA === true ||
    characterCState.W_R_SKA === true ||
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true ||
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    blankAState.W_R_SKC === true ||
    blankBState.W_R_SKC === true ||
    blankCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true ||
    blankAState.T_R_SKC === true ||
    blankBState.T_R_SKC === true ||
    blankCState.T_R_SKC === true
  ) {
    if (device.target === 'Desk') {
      let numberA = 0;
      let numberB = 0;
      for (let i = 1; i <= 9; i++) {
        if (sentenceA2[i].value !== '') {
          numberA += 1;
        }
      }
      sentenceA[0].endY = -3 * numberA;
      for (let i = 1; i <= 9; i++) {
        if (sentenceB2[i].value !== '') {
          numberB += 1;
        }
      }
      for (let i = 0; i <= 9; i++) {
        if (sentenceC2[i].value !== '') {
          numberB += 1;
        }
      }
      sentenceA[0].endY -= 1.5 * numberB;

      let numberA2 = 0;
      let numberB2 = 0;
      let numberC2 = 0;
      for (const key in sentenceA2) {
        if (sentenceA2[key].value !== '') {
          numberA2 += 1;
        }
      }
      for (const key in sentenceB2) {
        if (sentenceB2[key].value !== '') {
          numberB2 += 1;
        }
      }
      for (const key in sentenceC2) {
        if (sentenceC2[key].value !== '') {
          numberC2 += 1;
        }
      }

      let numberA0 = 0;
      let numberB0 = 0;
      let numberC0 = 0;
      for (const key in sentenceA0) {
        if (sentenceA0[key].value !== '') {
          numberA0 += 1;
        }
      }
      if (sentenceA0[theIndex].value === '') {
        numberA0 += 1;
      }
      for (const key in sentenceB0) {
        if (sentenceB0[key].value !== '') {
          numberB0 += 1;
        }
      }
      for (const key in sentenceC0) {
        if (sentenceC0[key].value !== '') {
          numberC0 += 1;
        }
      }

      let time = 0;
      let timeA = Math.abs(numberA2 - numberA0);
      let timeB = Math.abs(numberB2 - numberB0);
      let timeC = Math.abs(numberC2 - numberC0);
      if (timeA == 0) {
        time += timeB * 1.5;
        time += timeC * 1.5;
      } else if (timeA == 1) {
        time += timeA * 3;
        time += timeB * 0.5;
        time += timeC * 0.5;
      } else {
        time += 3;
        time += timeA - 1;
        time += timeB * 0.5;
        time += timeC * 0.5;
      }
      if (time == 0) {
        time = 1.5;
      }

      for (let i = 1; i <= 9; i++) {
        if (sentenceA2[i].value !== '') {
          sentenceA[i].endY = sentenceA[i - 1].endY + 6;
          if (sentenceB2[i].value !== '') {
            if (sentenceB2[i - 1].value === '') {
              sentenceA[i].endY += 1.5;
            }
            if (sentenceB2[i - 1].value !== '') {
              sentenceA[i].endY += 3;
            }
            if (sentenceC2[i - 1].value !== '') {
              sentenceA[i].endY += 3;
            }
          }
          if (sentenceB2[i].value === '') {
            if (sentenceB2[i - 1].value !== '') {
              sentenceA[i].endY += 1.5;
            }
            if (sentenceC2[i - 1].value !== '') {
              sentenceA[i].endY += 3;
            }
          }
        }
      }

      for (let i = 0; i <= 9; i++) {
        if (sentenceA[i].display === 'none') {
          gsap.to(sentenceA[i].element, {
            y: `${sentenceA[i].endY}vh`,
            duration: 0,
          });
          sentenceA[i].runTime = 0;
        } else {
          gsap.to(sentenceA[i].element, {
            y: `${sentenceA[i].endY}vh`,
            duration: time,
          });
          sentenceA[i].runTime = time;
        }
      }
    } else if (device.target === 'Mobile') {
      if (sentenceX.inputIndex !== undefined) {
        let number = 0;
        for (const key in sentenceA2) {
          if (sentenceA2[key].value !== '') {
            number = parseInt(key);
          }
        }
        let timeline = gsap.timeline();
        sentenceA[number].endY = -3;
        if (number == 0 && sentenceA0[number].value == '') {
          sentenceA[number].runTime = 0;
          gsap.to(sentenceA[number].element, {
            y: `${sentenceA[number].endY}vh`,
            duration: sentenceA[number].runTime,
          });
        } else {
          sentenceA[number].runTime = 6;
          timeline.to(sentenceA[number].element, {
            y: `${sentenceA[number].endY}vh`,
            duration: sentenceA[number].runTime,
          });
          sentenceA[number].transformTimeline = timeline;
        }

        for (let i = number; i >= 0; i--) {
          if (sentenceA[i - 1] !== undefined) {
            let timeline = gsap.timeline();
            sentenceA[i - 1].endY = sentenceA[i].endY - 6;
            if (sentenceB0[i].value !== '') {
              sentenceA[i - 1].endY -= 1.5;
            }
            if (sentenceB0[i - 1].value !== '') {
              sentenceA[i - 1].endY -= 1.5;
            }
            if (sentenceC0[i - 1].value !== '') {
              sentenceA[i - 1].endY -= 1.5;
            }
            sentenceA[i - 1].runTime = 6;
            timeline.to(sentenceA[i - 1].element, {
              y: `${sentenceA[i - 1].endY}vh`,
              duration: sentenceA[i - 1].runTime,
            });
            sentenceA[i - 1].transformTimeline = timeline;
          }
        }
      }

      if (sentenceX.selectIndex !== undefined) {
        let number = 0;
        for (const key in sentenceA) {
          if (sentenceA2[key].value !== '') {
            number -= 3;
          }
        }
        for (let i = 1; i < 10; i++) {
          if (sentenceA2[i].value !== '') {
            if (sentenceB2[i - 1].value !== '') {
              number -= 1.5;
            }
            if (sentenceB2[i].value !== '') {
              number -= 1.5;
            }
            if (sentenceC2[i].value !== '') {
              number -= 1.5;
            }
          }
        }
        sentenceA[0].endY = number;
        sentenceA[0].endY += 3;
        sentenceA[0].runTime = 6;
        let timeline = gsap.timeline();
        timeline.to(sentenceA[0].element, {
          y: `${sentenceA[0].endY}vh`,
          duration: sentenceA[0].runTime,
        });
        sentenceA[0].transformTimeline = timeline;

        let numberA2 = 0;
        let numberB2 = 0;
        let numberC2 = 0;
        for (const key in sentenceA2) {
          if (sentenceA2[key].value !== '') {
            numberA2 += 1;
          }
        }
        for (const key in sentenceB2) {
          if (sentenceB2[key].value !== '') {
            numberB2 += 1;
          }
        }
        for (const key in sentenceC2) {
          if (sentenceC2[key].value !== '') {
            numberC2 += 1;
          }
        }

        let numberA0 = 0;
        let numberB0 = 0;
        let numberC0 = 0;
        for (const key in sentenceA0) {
          if (sentenceA0[key].value !== '') {
            numberA0 += 1;
          }
        }
        if (sentenceA0[theIndex].value === '') {
          numberA0 += 1;
        }
        for (const key in sentenceB0) {
          if (sentenceB0[key].value !== '') {
            numberB0 += 1;
          }
        }
        for (const key in sentenceC0) {
          if (sentenceC0[key].value !== '') {
            numberC0 += 1;
          }
        }

        let time = 0;
        let timeA = Math.abs(numberA2 - numberA0);
        let timeB = Math.abs(numberB2 - numberB0);
        let timeC = Math.abs(numberC2 - numberC0);
        if (timeA == 0) {
          time += timeB * 1.5;
          time += timeC * 1.5;
        } else if (timeA == 1) {
          time += timeA * 3;
          time += timeB * 0.5;
          time += timeC * 0.5;
        } else {
          time += 3;
          time += timeA - 1;
          time += timeB * 0.5;
          time += timeC * 0.5;
        }
        if (time == 0) {
          time = 1.5;
        }

        for (let i = 1; i < 10; i++) {
          if (sentenceA2[i].value !== '') {
            sentenceA[i].endY = sentenceA[i - 1].endY + 6;
            if (sentenceB2[i - 1].value !== '') {
              sentenceA[i].endY += 1.5;
            }
            if (sentenceB2[i].value !== '') {
              sentenceA[i].endY += 1.5;
            }
            if (sentenceC2[i].value !== '') {
              sentenceA[i].endY += 1.5;
            }
            sentenceA[i].runTime = time;
            let timeline = gsap.timeline();
            timeline.to(sentenceA[i].element, {
              y: `${sentenceA[i].endY}vh`,
              duration: sentenceA[i].runTime,
            });
            sentenceA[i].transformTimeline = timeline;
          }
        }
      }
    }
  }
};
