import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { handleCharacterB0ElementClick } from '/js/characterB0/handleCharacterB0ElementClick.js';
import { handleCharacterB0ElementWheel } from '/js/characterB0/handleCharacterB0ElementWheel.js';
import { handleCharacterB0ElementTouchStart } from '/js/characterB0/handleCharacterB0ElementTouchStart.js';
import { handleCharacterB0ElementTouchEnd } from '/js/characterB0/handleCharacterB0ElementTouchEnd.js';

export const addCharacterB0ElementEventListener = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.A === true || sentenceBState.A_BTC === true) {
    theCharacterB0.element.addEventListener(
      'click',
      handleCharacterB0ElementClick
    );
    theCharacterB0.element.addEventListener(
      'wheel',
      handleCharacterB0ElementWheel
    );
    theCharacterB0.element.addEventListener(
      'touchstart',
      handleCharacterB0ElementTouchStart
    );
    theCharacterB0.element.addEventListener(
      'touchend',
      handleCharacterB0ElementTouchEnd
    );
  }
};
