import gsap from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceB } from '/js/sentenceB/sentenceB.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setCharacterB0PreviousElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];

  if (sentenceAState.A_ATB === true) {
    if (theCharacterB0.previousElements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterB0.previousElements, {
        opacity: 0.4,
        duration: 1,
        delay: 3,
      });
      theCharacterB0.previousElementsTimeline = timeline;
    }
  }

  if (sentenceBState.A === true) {
    if (theCharacterB0.selectElements.length > 0) {
      let timeline = gsap.timeline();
      if (theCharacterB0.previousElements.length > 0) {
        timeline.to(theCharacterB0.previousElements, {
          opacity: 0.4,
          duration: 1,
          delay: 3,
        });
        theCharacterB0.previousElementsTimeline = timeline;
      }
    }
  }

  if (
    sentenceBState.A_C === true ||
    sentenceBState.R_A_C === true ||
    sentenceBState.R_I_C === true
  ) {
    if (theCharacterB0.previousElements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterB0.previousElements, {
        opacity: 0.4,
        duration: 1,
        delay: 3,
        stagger: {
          amount: 1,
          each: 0.1,
        },
      });
      theCharacterB0.previousElementsTimeline = timeline;
    }
  }

  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true
  ) {
    if (theCharacterB0.previousElements.length > 0) {
      if (theIndex <= 5) {
        let timeline = gsap.timeline();
        timeline.to(theCharacterB0.previousElements, {
          opacity: 0.4,
          duration: 1,
          stagger: {
            amount: 1,
            each: 0.1,
            from: 'random',
          },
        });
        theCharacterB0.previousElementsTimeline = timeline;
      } else {
        let timeline = gsap.timeline();
        timeline.to(theCharacterB0.previousElements, {
          opacity: 0,
          duration: 1,
          onComplete: () => {
            gsap.to(theCharacterB0.previousElements, {
              opacity: 0.4,
              duration: 1,
              stagger: {
                amount: 1,
                each: 0.1,
                from: 'random',
              },
              delay: 2,
            });
          },
        });
        theCharacterB0.previousElementsTimeline = timeline;
      }
    }
  }

  if (
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    if (theCharacterB0.previousElements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterB0.previousElements, {
        opacity: 0.4,
        duration: 1,
        stagger: {
          amount: 1,
          each: 0.1,
          from: 'random',
        },
      });
      theCharacterB0.previousElementsTimeline = timeline;
    }
  }

  if (sentenceBState.D === true) {
    let timeline = gsap.timeline();
    if (sentenceX.inputIndex !== undefined) {
      if (theCharacterB0.previousElements.length > 0) {
        timeline.to(theCharacterB0.previousElements, {
          opacity: 0.4,
          duration: 1,
        });
        theCharacterB0.previousElementsTimeline = timeline;
      }
    }
  }

  if (
    sentenceXState.W_U === true ||
    sentenceAState.W_U === true ||
    sentenceBState.W_U === true ||
    sentenceCState.W_U === true ||
    blankAState.W_U === true ||
    blankBState.W_U === true ||
    blankCState.W_U === true ||
    characterAState.W_U === true ||
    characterBState.W_U === true ||
    characterCState.W_U === true ||
    sentenceXState.T_U === true ||
    sentenceAState.T_U === true ||
    sentenceBState.T_U === true ||
    sentenceCState.T_U === true ||
    blankAState.T_U === true ||
    blankBState.T_U === true ||
    blankCState.T_U === true ||
    characterAState.T_U === true ||
    characterBState.T_U === true ||
    characterCState.T_U === true ||
    sentenceXState.W_D === true ||
    sentenceAState.W_D === true ||
    sentenceBState.W_D === true ||
    sentenceCState.W_D === true ||
    blankAState.W_D === true ||
    blankBState.W_D === true ||
    blankCState.W_D === true ||
    characterAState.W_D === true ||
    characterBState.W_D === true ||
    characterCState.W_D === true ||
    sentenceXState.T_D === true ||
    sentenceAState.T_D === true ||
    sentenceBState.T_D === true ||
    sentenceCState.T_D === true ||
    blankAState.T_D === true ||
    blankBState.T_D === true ||
    blankCState.T_D === true ||
    characterAState.T_D === true ||
    characterBState.T_D === true ||
    characterCState.T_D === true
  ) {
    if (sentenceX.inputIndex !== undefined) {
      for (const key in characterB0) {
        if (characterB0[key].previousElements.length > 0) {
          let timeline = gsap.timeline();
          if (sentenceB[key].display === 'flex') {
            timeline.to(characterB0[key].previousElements, {
              opacity: 0.4,
              duration: 1,
            });
          } else if (sentenceB[key].display === 'none') {
            timeline.to(characterB0[key].previousElements, {
              opacity: 0.4,
              duration: 1,
              delay: sentenceB[theIndex].runTime,
            });
          }
          characterB0[key].previousElementsTimeline = timeline;
        }
      }
    }
  }
};
