import gsap from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceC } from '/js/sentenceC/sentenceC.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';

export const setSentenceC0UnSelectElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  if (sentenceXState.P === true || sentenceXState.D === true) {
  }

  if (
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    for (const key in sentenceC) {
      if (sentenceC[key].display === 'flex') {
        if (sentenceC0[key].unSelectElements.length > 0) {
          let timeline = gsap.timeline();
          timeline.to(sentenceC0[key].unSelectElements, {
            opacity: 0.2,
            duration: 1,
          });
          sentenceC0[key].unSelectElementsTimeline = timeline;
        }
      }
    }
  }

  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true
  ) {
    if (sentenceX.selectIndex !== undefined) {
      for (const key in sentenceC) {
        if (sentenceC[key].display === 'flex') {
          if (sentenceC0[key].unSelectElements.length > 0) {
            if (sentenceC0[key].unSelectElements[0].style.opacity == 0) {
              let timeline = gsap.timeline();
              timeline.to(sentenceC0[key].unSelectElements, {
                opacity: 0.2,
                duration: 1,
                delay: theIndex - key - 1,
              });
              sentenceC0[key].unSelectElementsTimeline = timeline;
            } else {
              let timeline = gsap.timeline();
              timeline.to(sentenceC0[key].unSelectElements, {
                opacity: 0.2,
                duration: 1,
              });
              sentenceC0[key].unSelectElementsTimeline = timeline;
            }
          }
        }
      }
    } else if (sentenceX.inputIndex !== undefined) {
      for (const key in sentenceC) {
        if (sentenceC[key].display === 'flex') {
          if (sentenceC0[key].unSelectElements.length > 0) {
            if (sentenceC0[key].unSelectElements[0].style.opacity == 0) {
              let timeline = gsap.timeline();
              timeline.to(sentenceC0[key].unSelectElements, {
                opacity: 0,
                duration: 1,
                onComplete: () => {
                  gsap.to(sentenceC0[key].unSelectElements, {
                    opacity: 0.2,
                    duration: 1,
                    delay: 2,
                  });
                },
              });
              sentenceC0[key].unSelectElementsTimeline = timeline;
            } else {
              let timeline = gsap.timeline();
              timeline.to(sentenceC0[key].unSelectElements, {
                opacity: 0.2,
                duration: 1,
              });
              sentenceC0[key].unSelectElementsTimeline = timeline;
            }
          }
        }
      }
    }
  }
};
