import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const setCharacterA0CompleteElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (sentenceAState.A_C === true) {
    if (theCharacterA0.selectElements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterA0.completeElements, {
        opacity: 1,
        duration: 3,
        delay: 1,
      });
    } else {
      let timeline = gsap.timeline();
      timeline.to(theCharacterA0.completeElements, {
        opacity: 1,
        duration: 3,
      });
    }
  }

  if (sentenceAState.R_A_C === true || sentenceAState.R_I_C === true) {
    let timeline = gsap.timeline();
    timeline.to(theCharacterA0.completeElements, {
      opacity: 1,
      duration: 3,
      delay: 1,
    });
  }
};
