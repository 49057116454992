import { codeState } from '/js/codeState/codeState.js';
import { codeCharacter } from '/js/codeCharacter/codeCharacter.js';
import { code } from '/js/code/code.js';
export const setCodeValue = () => {
  if (codeState.A === true || codeState.D === true) {
    let str = '';
    for (const item of codeCharacter.values) {
      str += item;
    }
    code.value = str;
  }
};
