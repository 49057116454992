import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { handleCharacterA0ElementClick } from '/js/characterA0/handleCharacterA0ElementClick.js';
import { handleCharacterA0ElementWheel } from '/js/characterA0/handleCharacterA0ElementWheel.js';
import { handleCharacterA0ElementTouchStart } from '/js/characterA0/handleCharacterA0ElementTouchStart.js';
import { handleCharacterA0ElementTouchEnd } from '/js/characterA0/handleCharacterA0ElementTouchEnd.js';

export const addCharacterA0LastElementEventListener = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (sentenceAState.R_I === true || sentenceAState.R_A === true) {
    theCharacterA0.lastElement.addEventListener(
      'click',
      handleCharacterA0ElementClick
    );
    theCharacterA0.lastElement.addEventListener(
      'wheel',
      handleCharacterA0ElementWheel
    );
    theCharacterA0.lastElement.addEventListener(
      'touchstart',
      handleCharacterA0ElementTouchStart
    );
    theCharacterA0.lastElement.addEventListener(
      'touchend',
      handleCharacterA0ElementTouchEnd
    );
  }
};
