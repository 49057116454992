import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';

export const removeCharacterC0NextElements = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (
    sentenceCState.R_I === true ||
    sentenceCState.R_I_C === true ||
    sentenceCState.D
  ) {
    setTimeout(() => {
      for (const item of theCharacterC0.nextElements) {
        item.remove();
      }
    }, 1000);
  }
};
