import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setSentenceC0UnSelectElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  if (sentenceXState.P === true || sentenceXState.D === true) {
  }

  if (
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    for (const key in sentenceC0) {
      let arr = [];
      if (sentenceC0[key].selectElements.length > 0) {
        number = key;
      }
      if (key != number) {
        arr = Array.from(sentenceC0[key].element.children);
      }
      sentenceC0[key].unSelectElements = arr;
    }
  }
  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true
  ) {
    if (sentenceX.selectIndex !== undefined) {
      for (const key in sentenceC0) {
        let arr = [];
        if (sentenceC0[key].selectElements.length > 0) {
          number = key;
        }
        if (key != number) {
          arr = Array.from(sentenceC0[key].element.children);
        }
        sentenceC0[key].unSelectElements = arr;
      }
    } else if (sentenceX.inputIndex !== undefined) {
      for (const key in sentenceC0) {
        let number = undefined;
        let arr = [];
        if (
          characterC0[key].previousElements.length > 0 ||
          sentenceC0[key].omitElements.length > 0
        ) {
          number = key;
        }
        if (key != number) {
          arr = Array.from(sentenceC0[key].element.children);
        }
        sentenceC0[key].unSelectElements = arr;
        console.log(sentenceC0[key].unSelectElements);
      }
    }
  }

  if (
    characterAState.C === true ||
    characterBState.C === true ||
    characterCState.C === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true
  ) {
    for (const key in sentenceC0) {
      sentenceC0[key].unSelectElements = [];
    }
  }
};
