import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { userNameState } from '/js/userNameState/userNameState.js';

export const setUserNameState = (event) => {
  if (
    event.type === 'input' &&
    event.inputType === 'insertText' &&
    event.target.className === 'userNameA1' &&
    sentenceX.canInput === true
  ) {
    userNameState.A = true;
  } else {
    userNameState.A = false;
  }

  if (
    event.type === 'input' &&
    event.inputType === 'deleteContentBackward' &&
    event.target.className === 'userNameA1'
  ) {
    userNameState.D = true;
  } else {
    userNameState.D = false;
  }

  if (
    event.type === 'click' &&
    event.target.nodeName === 'DIV' &&
    event.target.className === 'userNameA0'
  ) {
    userNameState.C = true;
  } else {
    userNameState.C = false;
  }
};
