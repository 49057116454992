import { sentenceA1 } from '/js/sentenceA1/sentenceA1.js';
import { handleSentenceA1ElementInput } from '/js/sentenceA1/handleSentenceA1ElementInput.js';

export const addSentenceA1ElementEventListener = () => {
  for (const key in sentenceA1) {
    sentenceA1[key].element.addEventListener(
      'input',
      handleSentenceA1ElementInput
    );
  }
};
