import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentence } from '/js/sentence/sentence.js';

export const setSentenceXElementDisplay = () => {
  if (sentenceXState.L === true) {
    if (sentence[0].value === '') {
      sentenceX.element.style.display = 'none';
      sentenceX.display = 'none';
    } else {
      setTimeout(() => {
        sentenceX.element.style.display = 'none';
        sentenceX.display = 'none';
      }, 1000);
    }
  }
  if (logInState.V === true || confirmState.V === true) {
    sentenceX.element.style.display = 'flex';
    sentenceX.display = 'flex';
  }
};
