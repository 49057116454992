import { logInState } from '/js/logInState/logInState.js';
import { userNameA0 } from '/js/userNameA0/userNameA0.js';

export const setUserNameA0ElementOpacity = () => {
  if (logInState.C === true || logInState.RV === true) {
    gsap.to(userNameA0.element, {
      opacity: 0,
      duration: 1,
    });
  }
};
