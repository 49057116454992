import { logInState } from '/js/logInState/logInState.js';
import { passWordA0 } from '/js/passWordA0/passWordA0.js';

export const setPassWordA0ElementDisplay = () => {
  if (logInState.C === true || logInState.RV === true) {
    setTimeout(() => {
      passWordA0.element.style.display = 'none';
    }, 1000);
  }
};
