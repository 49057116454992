import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0ElementTimelines } from '/js/characterA0/killCharacterA0ElementTimelines.js';
import { killCharacterB0ElementTimelines } from '/js/characterB0/killCharacterB0ElementTimelines.js';
import { killCharacterC0ElementTimelines } from '/js/characterC0/killCharacterC0ElementTimelines.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { setSentenceXUserData } from '/js/sentenceX/setSentenceXUserData.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { setHeaderElementInnerHTML } from '/js/header/setHeaderElementInnerHTML.js';
import { setTitleElementValue } from '/js/title/setTitleElementValue.js';

export const sentenceXStream_P = () => {
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0ElementTimelines();
  killCharacterB0ElementTimelines();
  killCharacterC0ElementTimelines();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterA0ElementsOpacity();
  setCharacterB0ElementsOpacity();
  setCharacterC0ElementsOpacity();
  setSentenceXUserData();
  setHeaderElementInnerHTML();
  setTitleElementValue();
  let url = `https://vjv5etavy9.execute-api.us-east-1.amazonaws.com/${sentenceX.userId}/${sentenceX.PKA}/${sentenceX.PKB}/${sentenceX.SKA}/${sentenceX.SKB}`;
  axios({
    method: 'put',
    url: url,
  }).then((res) => {
    return;
  });
};
