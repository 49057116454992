import { sentenceX } from "/js/sentenceX/sentenceX.js";
import { setSentenceXStartX } from "/js/sentenceX/setSentenceXStartX.js";
import { setSentenceXStartY } from "/js/sentenceX/setSentenceXStartY.js";

export const handleSentenceXTouchStart = (event) => {
  event.preventDefault();
  event.stopPropagation();
  if (sentenceX.canTouch === true) {
    setSentenceXStartX(event);
    setSentenceXStartY(event);
  }
};
