import gsap from 'gsap';
import { device } from '/js/device/device.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceA } from '/js/sentenceA/sentenceA.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { sentenceA1 } from '/js/sentenceA1/sentenceA1.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setCharacterA0ElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (
    sentenceAState.A === true ||
    sentenceBState.A === true ||
    sentenceCState.A === true
  ) {
    for (const key in characterA0) {
      if (characterA0[key].elements.length > 0) {
        let timeline = gsap.timeline();
        if (sentenceX.userData[sentenceX.SKA] === undefined) {
          if (key != theIndex) {
            timeline.to(characterA0[key].elements, {
              opacity: 0.2,
              duration: 1,
            });
            characterA0[key].elementsTimeline = timeline;
          } else {
            if (
              characterA0[theIndex].selectElements.length === 0 &&
              characterB0[theIndex].selectElements.length === 0 &&
              characterC0[theIndex].selectElements.length === 0
            ) {
              timeline.to(characterA0[key].elements, {
                opacity: 0.5,
                duration: 1,
              });
              characterA0[key].elementsTimeline = timeline;
            }
          }
        } else {
          if (
            characterA0[theIndex].selectElements.length === 0 &&
            characterB0[theIndex].selectElements.length === 0 &&
            characterC0[theIndex].selectElements.length === 0
          ) {
            timeline.to(characterA0[key].elements, {
              opacity: 1,
              duration: 3,
            });
            characterA0[key].elementsTimeline = timeline;
          }
        }
      }
    }
  }
  if (
    sentenceAState.D === true ||
    sentenceBState.D === true ||
    sentenceCState.D === true
  ) {
    for (const key in characterA0) {
      if (characterA0[key].elements.length > 0) {
        if (key == theIndex) {
          for (const item of characterA0[key].elements) {
            if (item != characterA0[key].previousElement) {
              gsap.to(item, {
                opacity: 0.4,
                duration: 1,
              });
            }
          }
        } else if (key > theIndex) {
          gsap.to(characterA0[key].elements, {
            opacity: 0,
            duration: 1,
            delay: key - theIndex - 1,
            onComplete: () => {
              for (const item of characterA0[key].elements) {
                item.remove();
              }
              characterA0[key].values = [];
              characterA0[key].index = -1;
              characterA0[key].elements = [];
              sentenceA0[key].value = '';
              sentenceA1[key].element.value = '';
            },
          });
        }
      }
    }
  }

  if (
    sentenceAState.R_A === true ||
    sentenceBState.R_A === true ||
    sentenceCState.R_A === true ||
    sentenceAState.R_A_C === true ||
    sentenceBState.R_A_C === true ||
    sentenceCState.R_A_C === true ||
    sentenceAState.R_I === true ||
    sentenceBState.R_I === true ||
    sentenceCState.R_I === true ||
    sentenceAState.R_I_C === true ||
    sentenceBState.R_I_C === true ||
    sentenceCState.R_I_C === true
  ) {
    for (const key in characterA0) {
      if (key > theIndex) {
        if (characterA0[key].elements.length > 0) {
          gsap.to(characterA0[key].elements, {
            opacity: 0,
            duration: 1,
            delay: key - theIndex - 1,
            onComplete: () => {
              for (const item of characterA0[key].elements) {
                item.remove();
              }
              characterA0[key].values = [];
              characterA0[key].index = -1;
              characterA0[key].elements = [];
              sentenceA0[key].value = '';
              sentenceA1[key].element.value = '';
            },
          });
        }
      }
    }
  }
  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true
  ) {
    for (const key in characterA0) {
      if (characterA0[key].elements.length > 0) {
        if (key != theIndex) {
          let timeline = gsap.timeline();
          timeline.to(characterA0[key].elements, {
            opacity: 0.2,
            duration: 1,
          });
          characterA0[key].elementsTimeline = timeline;
        }
      }
    }
  }

  if (sentenceXState.P === true || sentenceXState.RP === true) {
    for (const key in characterA0) {
      if (characterA0[key].elements.length > 0) {
        let timeline = gsap.timeline();
        timeline.to(characterA0[key].elements, {
          opacity: 1,
          duration: 3,
        });
        characterA0[key].elementsTimeline = timeline;
      }
    }
  }

  if (sentenceXState.D === true) {
    for (const key in characterA0) {
      if (characterA0[key].elements.length > 0) {
        let timeline = gsap.timeline();
        timeline.to(characterA0[key].elements, {
          opacity: 0.2,
          duration: 1,
        });
        characterA0[key].elementsTimeline = timeline;
      }
    }
  }
  if (
    sentenceXState.W_U === true ||
    sentenceAState.W_U === true ||
    sentenceBState.W_U === true ||
    sentenceCState.W_U === true ||
    blankAState.W_U === true ||
    blankBState.W_U === true ||
    blankCState.W_U === true ||
    characterAState.W_U === true ||
    characterBState.W_U === true ||
    characterCState.W_U === true ||
    sentenceXState.T_U === true ||
    sentenceAState.T_U === true ||
    sentenceBState.T_U === true ||
    sentenceCState.T_U === true ||
    blankAState.T_U === true ||
    blankBState.T_U === true ||
    blankCState.T_U === true ||
    characterAState.T_U === true ||
    characterBState.T_U === true ||
    characterCState.T_U === true
  ) {
    if (device.target === 'Desk') {
      let number = 0;
      for (const key of Object.keys(characterA0).reverse()) {
        if (characterA0[key].elements.length > 0) {
          if (sentenceA[key].display === 'flex') {
            let timeline = gsap.timeline();
            timeline.to(characterA0[key].elements, {
              opacity: 0.2,
              duration: 1,
            });
            characterA0[key].elementsTimeline = timeline;
          } else if (sentenceA[key].display === 'none') {
            if (key != theIndex + 1) {
              console.log(number);
              let timeline = gsap.timeline();
              timeline.to(characterA0[key].elements, {
                opacity: 0.2,
                duration: 1,
                delay: number,
              });
              number += 1;
              characterA0[key].elementsTimeline = timeline;
            }
          }
        }
      }
    } else if (device.target === 'Mobile') {
      let number = 1;
      for (const key in sentenceB0) {
        if (key <= theIndex && sentenceB0[key].value !== '') {
          number += 0.5;
        }
      }
      for (const key in sentenceC0) {
        if (key <= theIndex && sentenceC0[key].value !== '') {
          number += 0.5;
        }
      }

      for (const key of Object.keys(characterA0).reverse()) {
        if (characterA0[key].elements.length > 0) {
          if (sentenceA[key].display === 'flex') {
            let timeline = gsap.timeline();
            timeline.to(characterA0[key].elements, {
              opacity: 0.2,
              duration: 1,
            });
            characterA0[key].elementsTimeline = timeline;
          } else if (sentenceA[key].display === 'none') {
            let timeline = gsap.timeline();
            timeline.to(characterA0[key].elements, {
              opacity: 0.2,
              duration: 1,
              delay: number,
            });
            number += 2;
            characterA0[key].elementsTimeline = timeline;
          }
        }
      }
    }
  }

  if (
    sentenceXState.W_D === true ||
    sentenceAState.W_D === true ||
    sentenceBState.W_D === true ||
    sentenceCState.W_D === true ||
    blankAState.W_D === true ||
    blankBState.W_D === true ||
    blankCState.W_D === true ||
    characterAState.W_D === true ||
    characterBState.W_D === true ||
    characterCState.W_D === true ||
    sentenceXState.T_D === true ||
    sentenceAState.T_D === true ||
    sentenceBState.T_D === true ||
    sentenceCState.T_D === true ||
    blankAState.T_D === true ||
    blankBState.T_D === true ||
    blankCState.T_D === true ||
    characterAState.T_D === true ||
    characterBState.T_D === true ||
    characterCState.T_D === true
  ) {
    for (const key in characterA0) {
      if (key > theIndex) {
        if (characterA0[key].elements.length > 0) {
          gsap.to(characterA0[key].elements, {
            opacity: 0,
            duration: 1,
            delay: key - theIndex - 1,
          });
        }
      }
    }
  }

  if (
    sentenceXState.W_L_VK_G === true ||
    sentenceAState.W_L_VK_G === true ||
    sentenceBState.W_L_VK_G === true ||
    sentenceCState.W_L_VK_G === true ||
    sentenceXState.T_L_VK_G === true ||
    sentenceAState.T_L_VK_G === true ||
    sentenceBState.T_L_VK_G === true ||
    sentenceCState.T_L_VK_G === true ||
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.W_L_VK === true ||
    sentenceAState.W_L_VK === true ||
    sentenceBState.W_L_VK === true ||
    sentenceCState.W_L_VK === true ||
    sentenceXState.T_L_VK === true ||
    sentenceAState.T_L_VK === true ||
    sentenceBState.T_L_VK === true ||
    sentenceCState.T_L_VK === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true ||
    sentenceXState.W_R_VK === true ||
    sentenceAState.W_R_VK === true ||
    sentenceBState.W_R_VK === true ||
    sentenceCState.W_R_VK === true ||
    sentenceXState.T_R_VK === true ||
    sentenceAState.T_R_VK === true ||
    sentenceBState.T_R_VK === true ||
    sentenceCState.T_R_VK === true ||
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true ||
    blankAState.W_R_SKA === true ||
    blankBState.W_R_SKA === true ||
    blankCState.W_R_SKA === true ||
    characterAState.W_R_SKA === true ||
    characterBState.W_R_SKA === true ||
    characterCState.W_R_SKA === true ||
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true
  ) {
    for (const key in characterA0) {
      if (characterA0[key].elements.length > 0) {
        if (sentenceA[key].display === 'flex') {
          if (key == theIndex) {
            for (const item of characterA0[key].elements) {
              item.style.opacity = 1;
            }
          } else {
            for (const item of characterA0[key].elements) {
              item.style.opacity = 0.2;
            }
          }
          // if (frontEnd.userSentences[sentences.userPK] !== undefined) {
          //   for (const item of textFrontCharacter[key].elements) {
          //     item.style.opacity = 1;
          //   }
          // } else {
          //   if (key == theIndex) {
          //     for (const item of textFrontCharacter[key].elements) {
          //       item.style.opacity = 1;
          //     }
          //   } else {
          //     for (const item of textFrontCharacter[key].elements) {
          //       item.style.opacity = 0.5;
          //     }
          //   }
          // }
        }
      }
    }
  }

  if (
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    blankAState.W_R_SKC === true ||
    blankBState.W_R_SKC === true ||
    blankCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true ||
    blankAState.T_R_SKC === true ||
    blankBState.T_R_SKC === true ||
    blankCState.T_R_SKC === true
  ) {
    for (const key in characterA0) {
      if (characterA0[key].elements.length > 0) {
        if (sentenceA[key].display === 'flex') {
          if (key == theIndex) {
            for (const item of characterA0[key].elements) {
              if (characterA0[key].selectElements.includes(item) == true) {
                item.style.opacity = 1;
              } else {
                item.style.opacity = 0.2;
              }
            }
          } else {
            for (const item of characterA0[key].elements) {
              item.style.opacity = 0.2;
            }
          }
          // if (frontEnd.userSentences[sentences.userPK] !== undefined) {
          //   for (const item of textFrontCharacter[key].elements) {
          //     item.style.opacity = 1;
          //   }
          // } else {
          //   if (key == theIndex) {
          //     for (const item of textFrontCharacter[key].elements) {
          //       item.style.opacity = 1;
          //     }
          //   } else {
          //     for (const item of textFrontCharacter[key].elements) {
          //       item.style.opacity = 0.5;
          //     }
          //   }
          // }
        }
      }
    }
  }
};
