import { device } from '/js/device/device.js';
import { agent } from '/js/agent/agent.js';
export const detectAgent = () => {
  const userAgent = navigator.userAgent;
  if (device.target === 'Desk') {
    if (userAgent.includes('Edg')) {
      agent.target = 'Edge';
    } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
      agent.target = 'Chrome';
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      agent.target = 'Safari';
    } else if (userAgent.includes('Firefox')) {
      agent.target = 'Firefox';
    } else {
      agent.target = 'unKnown';
    }
  } else if (device.target === 'Mobile') {
    if (userAgent.includes('Edg')) {
      agent.target = 'Edge';
    } else if (userAgent.includes('CriOS')) {
      agent.target = 'Chrome';
    } else if (userAgent.includes('Safari') && !userAgent.includes('CriOS')) {
      agent.target = 'Safari';
    } else if (userAgent.includes('Firefox')) {
      agent.target = 'Firefox';
    } else {
      agent.target = 'unKnown';
    }
  }
};
