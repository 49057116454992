import { logInState } from '/js/logInState/logInState.js';
import { passWordA0 } from '/js/passWordA0/passWordA0.js';

export const setPassWordA0ElementOpacity = () => {
  if (logInState.C === true || logInState.RV === true) {
    gsap.to(passWordA0.element, {
      opacity: 0,
      duration: 1,
    });
  }
};
