export const sentenceA = {
  0: {
    element: document.querySelector('#_0_sentenceA'),
    endY: 0,
    display: 'flex',
    transformTimeline: undefined,
  },
  1: {
    element: document.querySelector('#_1_sentenceA'),
    endY: 0,
    display: 'none',
    transformTimeline: undefined,
  },
  2: {
    element: document.querySelector('#_2_sentenceA'),
    endY: 0,
    display: 'none',
    transformTimeline: undefined,
  },
  3: {
    element: document.querySelector('#_3_sentenceA'),
    endY: 0,
    display: 'none',
    transformTimeline: undefined,
  },
  4: {
    element: document.querySelector('#_4_sentenceA'),
    endY: 0,
    display: 'none',
    transformTimeline: undefined,
  },
  5: {
    element: document.querySelector('#_5_sentenceA'),
    endY: 0,
    display: 'none',
    transformTimeline: undefined,
  },
  6: {
    element: document.querySelector('#_6_sentenceA'),
    endY: 0,
    display: 'none',
    transformTimeline: undefined,
  },
  7: {
    element: document.querySelector('#_7_sentenceA'),
    endY: 0,
    display: 'none',
    transformTimeline: undefined,
  },
  8: {
    element: document.querySelector('#_8_sentenceA'),
    endY: 0,
    display: 'none',
    transformTimeline: undefined,
  },
  9: {
    element: document.querySelector('#_9_sentenceA'),
    endY: 0,
    display: 'none',
    transformTimeline: undefined,
  },
};
