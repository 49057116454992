import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';

export const setCharacterC0PreviousElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];

  if (theCharacterC0.selectElements.length == 0) {
    theCharacterC0.previousElement =
      theCharacterC0.elements[theCharacterC0.index - 1];
  }
};
