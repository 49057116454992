import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterB0LastElementInnerHTML = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.R_A === true || sentenceBState.R_I === true) {
    if (theCharacterB0.value === ' ') {
      theCharacterB0.lastElement.innerHTML = '&nbsp;';
    } else {
      theCharacterB0.lastElement.innerHTML = theCharacterB0.value;
    }
  }
};
