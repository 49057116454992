import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';

import { setCharacterB0Values } from '/js/characterB0/setCharacterB0Values.js';
import { setCharacterB0Value } from '/js/characterB0/setCharacterB0Value.js';
import { setCharacterB0Index } from '/js/characterB0/setCharacterB0Index.js';

import { setCharacterB0SelectElementsOpacity } from '/js/characterB0/setCharacterB0SelectElementsOpacity.js';
import { removeCharacterB0SelectElements } from '/js/characterB0/removeCharacterB0SelectElements.js';
import { setCharacterB0SelectElements } from '/js/characterB0/setCharacterB0SelectElements.js';
import { setCharacterB0LastElement } from '/js/characterB0/setCharacterB0LastElement.js';
import { setCharacterB0LastElementInnerHTML } from '/js/characterB0/setCharacterB0LastElementInnerHTML.js';
import { addCharacterB0LastElementEventListener } from '/js/characterB0/addCharacterB0LastElementEventListener.js';
import { appendCharacterB0LastElement } from '/js/characterB0/appendCharacterB0LastElement.js';
import { setCharacterB0LastElementOpacity } from '/js/characterB0/setCharacterB0LastElementOpacity.js';
import { setCharacterB0Elements } from '/js/characterB0/setCharacterB0Elements.js';
import { setCharacterB0ElementsId } from '/js/characterB0/setCharacterB0ElementsId.js';
import { setSentenceB0Value } from '/js/sentenceB0/setSentenceB0Value.js';
import { setSentenceB0SplitValues } from '/js/sentenceB0/setSentenceB0SplitValues.js';
import { setSentenceB0SplitCounts } from '/js/sentenceB0/setSentenceB0SplitCounts.js';
import { setSentenceB0SplitInitials } from '/js/sentenceB0/setSentenceB0SplitInitials.js';
import { setCharacterB0ElementsClassName } from '/js/characterB0/setCharacterB0ElementsClassName.js';
import { setSentenceXCanClick } from '/js/sentenceX/setSentenceXCanClick.js';
import { setSentenceB0ElementHeight } from '/js/sentenceB0/setSentenceB0ElementHeight.js';
import { setBlankB0ElementHeight } from '/js/blankB0/setBlankB0ElementHeight.js';
import { setSentenceB1ElementValue } from '/js/sentenceB1/setSentenceB1ElementValue.js';
import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { removeCharacterA0Elements } from '/js/characterA0/removeCharacterA0Elements.js';
import { removeCharacterB0Elements } from '/js/characterB0/removeCharacterB0Elements.js';
import { removeCharacterC0Elements } from '/js/characterC0/removeCharacterC0Elements.js';
import { transformSentenceAElement } from '/js/sentenceA/transformSentenceAElement.js';
import { transformSentenceBElement } from '/js/sentenceB/transformSentenceBElement.js';
import { transformSentenceCElement } from '/js/sentenceC/transformSentenceCElement.js';
import { setSentenceAElementDisplay } from '/js/sentenceA/setSentenceAElementDisplay.js';
import { setSentenceBElementDisplay } from '/js/sentenceB/setSentenceBElementDisplay.js';
import { setSentenceCElementDisplay } from '/js/sentenceC/setSentenceCElementDisplay.js';

export const sentenceBStream_R_A = (event) => {
  setSentenceXCanClick(false);
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterB0Values();
  setCharacterB0Value();
  setCharacterB0Index();

  setCharacterB0SelectElementsOpacity();
  removeCharacterB0SelectElements();
  setCharacterB0SelectElements();

  setCharacterB0LastElement();
  setCharacterB0LastElementInnerHTML();
  addCharacterB0LastElementEventListener();
  appendCharacterB0LastElement();
  setCharacterB0LastElementOpacity();

  setCharacterB0Elements();
  setCharacterB0ElementsId();
  setSentenceB0Value(event);
  setSentenceB0SplitValues();
  setSentenceB0SplitCounts();
  setSentenceB0SplitInitials();
  setCharacterB0ElementsClassName();
  setSentenceB0ElementHeight();
  setBlankB0ElementHeight();
  setSentenceB1ElementValue();

  setCharacterA0ElementsOpacity();
  setCharacterB0ElementsOpacity();
  setCharacterC0ElementsOpacity();
  removeCharacterA0Elements();
  removeCharacterB0Elements();
  removeCharacterC0Elements();

  transformSentenceAElement();
  transformSentenceBElement();
  transformSentenceCElement();
  setSentenceAElementDisplay();
  setSentenceBElementDisplay();
  setSentenceCElementDisplay();
  setTimeout(() => {
    setSentenceXCanClick(true);
  }, 1000);
};
