import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { handleSentenceXElementKeyDown } from '/js/sentenceX/handleSentenceXElementKeyDown.js';
import { handleSentenceXElementClick } from '/js/sentenceX/handleSentenceXElementClick.js';
import { handleSentenceXElementWheel } from '/js/sentenceX/handleSentenceXElementWheel.js';
import { handleSentenceXTouchStart } from '/js/sentenceX/handleSentenceXTouchStart.js';
import { handleSentenceXTouchEnd } from '/js/sentenceX/handleSentenceXTouchEnd.js';
export const addSentenceXElementEventListener = () => {
  sentenceX.element.addEventListener('keydown', handleSentenceXElementKeyDown);
  sentenceX.element.addEventListener('click', handleSentenceXElementClick);
  sentenceX.element.addEventListener('wheel', handleSentenceXElementWheel);
  sentenceX.element.addEventListener('touchstart', handleSentenceXTouchStart);
  sentenceX.element.addEventListener('touchend', handleSentenceXTouchEnd);
};
