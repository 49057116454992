import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { handleSentenceC0ElementClick } from '/js/sentenceC0/handleSentenceC0ElementClick.js';
import { handleSentenceC0ElementWheel } from '/js/sentenceC0/handleSentenceC0ElementWheel.js';
import { handleSentenceC0ElementTouchStart } from '/js/sentenceC0/handleSentenceC0ElementTouchStart.js';
import { handleSentenceC0ElementTouchEnd } from '/js/sentenceC0/handleSentenceC0ElementTouchEnd.js';
export const addSentenceC0ElementEventListener = () => {
  for (const key in sentenceC0) {
    sentenceC0[key].element.addEventListener(
      'click',
      handleSentenceC0ElementClick
    );
    sentenceC0[key].element.addEventListener(
      'wheel',
      handleSentenceC0ElementWheel
    );
    sentenceC0[key].element.addEventListener(
      'touchstart',
      handleSentenceC0ElementTouchStart
    );
    sentenceC0[key].element.addEventListener(
      'touchend',
      handleSentenceC0ElementTouchEnd
    );
  }
};
