export const sentenceX = {
  element: document.querySelector('.sentenceX'),
  index: 0,
  inputIndex: 0,
  selectIndex: undefined,
  userId: '',
  // userId: '8c6e8c9f-fdb6-413b-97ec-670a46029823',
  PKA: '',
  SKA: '',
  // HKA: '',
  // dataRAW: [],
  dataVK: [],
  dataSKA: {
    'A&': [
      {
        0: 'A',
        1: `Who is your friend`,
        // 1: `Don't you mourn`,
        2: `Don't you pine`,
        3: 'Just Kick back and pour some wine',
        // 4: 'And remember that I lived a life that was truly mine',
      },
      {
        0: 'A',
        1: `Don't you mourn to worry or whine`,
        2: `I'll be soaring up high`,
        3: 'In the clouds of mine',
        2: 'In the clouds of mine',
        4: 'Leaving behind the sorrows and the grind of nine to five',
      },
    ],
  },
  dataSKB: {},
  dataSKC: {
    // 'Life is ': [
    //   {
    //     0: 'Life is hard',
    //     1: `Don't you mourn`,
    //   },
    //   {
    //     0: 'Life is beauty',
    //     1: `There's no need to worry or whine`,
    //   },
    // ],
  },
  dataSKD: {},
  data: [],
  historyData: {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
  },
  historyWord: [],
  userData: {},
  canWheel: true,
  canInput: true,
  canClick: true,
  canTouch: true,
  reClick: false,
  CD: false,
  GC: false,
  startX: 0,
  startY: 0,
  moveX: 0,
  moveY: 0,
};
