import { userNameState } from '/js/userNameState/userNameState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';
import { setSentenceXCanInput } from '/js/sentenceX/setSentenceXCanInput.js';

export const setUserNameA0CharacterElementOpacity = () => {
  if (userNameState.A === true) {
    let timeline = gsap.timeline();
    timeline.fromTo(
      userNameA0Character.element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
      }
    );
    userNameA0Character.elementTimelines.push(timeline);
  }

  if (userNameState.D === true) {
    let timeline = gsap.timeline();
    timeline.to(userNameA0Character.element, {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        userNameA0Character.deleteElementTimelines.pop();
        if (userNameA0Character.deleteElementTimelines.length === 0) {
          setSentenceXCanInput(true);
        }
      },
    });
    userNameA0Character.deleteElementTimelines.push(timeline);
  }
};
