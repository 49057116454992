export const characterC0 = {
  0: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
  1: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
  2: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
  3: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
  4: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
  5: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
  6: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
  7: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
  8: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
  9: {
    data: '',
    value: '',
    previousValue: ' ',
    index: -1,
    selectInitial: 0,
    selectEnd: 0,
    runTime: 0,
    element: undefined,
    referenceElement: undefined,
    elementsTimeline: undefined,
    previousElementsTimeline: undefined,
    values: [],
    elements: [],
    previousElements: [],
    selectElements: [],
    unSelectElements: [],
    nextElements: [],
    differElements: [],
    elementTimelines: [],
    previousElementTimelines: [],
    deleteTimelines: [],
    signElementTimelines: [],
  },
};
