import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { sentenceB } from '/js/sentenceB/sentenceB.js';
import { characterB0 } from '/js/characterB0/characterB0.js';

export const setCharacterB0UnSelectElements = () => {
  if (
    characterAState.C === true ||
    characterBState.C === true ||
    characterCState.C === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true
  ) {
    for (const key in characterB0) {
      if (sentenceB[key].display === 'flex') {
        if (characterB0[key].selectElements.length > 0) {
          let arr = characterB0[key].elements.filter(
            (item) => !characterB0[key].selectElements.includes(item)
          );
          characterB0[key].unSelectElements = arr;
        } else {
          characterB0[key].unSelectElements = characterB0[key].elements;
        }
      }
    }
  }
};
