import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const removeCharacterA0NextElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (
    sentenceAState.R_I === true ||
    sentenceAState.R_I_C === true ||
    sentenceAState.D === true
  ) {
    setTimeout(() => {
      for (const item of theCharacterA0.nextElements) {
        item.remove();
      }
    }, 1000);
  }
};
