import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';

export const setCharacterB0PreviousElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];

  if (theCharacterB0.selectElements.length == 0) {
    theCharacterB0.previousElement =
      theCharacterB0.elements[theCharacterB0.index - 1];
  }
};
