import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';

export const setCharacterC0Value = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (
    sentenceCState.A === true ||
    sentenceCState.R_I === true ||
    sentenceCState.R_A === true
  ) {
    theCharacterC0.value = theCharacterC0.values[theCharacterC0.index + 1];
  }
};
