export const characterA2 = {
  0: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
  1: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
  2: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
  3: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
  4: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
  5: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
  6: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
  7: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
  8: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
  9: {
    values: [],
    elements: [],
    selectElements: [],
    moveElements: [],
    moveElementsXs: [],
    moveElementsTx: 0,
    transferElements: [],
    transferElementsXs: [],
    transferElementsTx: 0,
    rightMoveTimes: [],
    runTime: 0,
  },
};
