import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const presetCharacterC0Index = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  // if (characterCState.C === true) {
  //   if (event.inputType === 'insertText' && event.data !== ' ') {
  //     theCharacterC0.index = theCharacterC0.selectInitial - 1;
  //   } else if (
  //     (event.inputType === 'insertText' && event.data === ' ') ||
  //     event.inputType === 'deleteContentBackward'
  //   ) {
  //     theCharacterC0.index = theCharacterC0.selectEnd;
  //   }
  // }
  if (event.data !== null) {
    theCharacterC0.index = theCharacterC0.values.length - 1;
  }
};
