import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { characterA0 } from '/js/characterA0/characterA0.js';

export const setCharacterA0UnSelectElementsOpacity = () => {
  if (
    characterAState.C === true ||
    characterBState.C === true ||
    characterCState.C === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true
  ) {
    for (const key in characterA0) {
      if (characterA0[key].unSelectElements.length > 0) {
        let timeline = gsap.timeline();
        timeline.to(characterA0[key].unSelectElements, {
          opacity: 0.2,
          duration: 1,
        });
        characterA0[key].unSelectElements = timeline;
      }
    }
  }
};
