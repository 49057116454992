import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { characterB0 } from '/js/characterB0/characterB0.js';

export const killCharacterB0LastElementTimeline = () => {
  if (
    sentenceAState.A === true ||
    sentenceBState.A === true ||
    sentenceCState.A === true ||
    sentenceAState.A_C === true ||
    sentenceBState.A_C === true ||
    sentenceCState.A_C === true ||
    sentenceXState.W === true ||
    sentenceAState.W === true ||
    sentenceBState.W === true ||
    sentenceCState.W === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true
  ) {
    for (const key in characterB0) {
      if (characterB0[key].lastElementTimeline !== undefined) {
        characterB0[key].lastElementTimeline.kill();
      }
    }
  }
};
