import { setSentenceXCanInput } from '/js/sentenceX/setSentenceXCanInput.js';
import { killCharacterA0PreviousElementTimelines } from '/js/characterA0/killCharacterA0PreviousElementTimelines.js';
import { killCharacterB0PreviousElementTimelines } from '/js/characterB0/killCharacterB0PreviousElementTimelines.js';
import { killCharacterC0PreviousElementTimelines } from '/js/characterC0/killCharacterC0PreviousElementTimelines.js';
import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0ElementTimelines } from '/js/characterA0/killCharacterA0ElementTimelines.js';
import { killCharacterB0ElementTimelines } from '/js/characterB0/killCharacterB0ElementTimelines.js';
import { killCharacterC0ElementTimelines } from '/js/characterC0/killCharacterC0ElementTimelines.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { setCharacterC0Element } from '/js/characterC0/setCharacterC0Element.js';
import { setCharacterC0ElementOpacity } from '/js/characterC0/setCharacterC0ElementOpacity.js';
import { removeCharacterC0Element } from '/js/characterC0/removeCharacterC0Element.js';
import { setCharacterC0NextElementsOpacity } from '/js/characterC0/setCharacterC0NextElementsOpacity.js';
import { removeCharacterC0NextElements } from '/js/characterC0/removeCharacterC0NextElements.js';
import { setCharacterC0Elements } from '/js/characterC0/setCharacterC0Elements.js';
import { setCharacterC0PreviousElement } from '/js/characterC0/setCharacterC0PreviousElement.js';
import { setCharacterC0PreviousElementOpacity } from '/js/characterC0/setCharacterC0PreviousElementOpacity.js';
import { setCharacterC0PreviousElements } from '/js/characterC0/setCharacterC0PreviousElements.js';
import { setCharacterC0PreviousElementsOpacity } from '/js/characterC0/setCharacterC0PreviousElementsOpacity.js';
import { setCharacterC0Values } from '/js/characterC0/setCharacterC0Values.js';
import { setCharacterC0Index } from '/js/characterC0/setCharacterC0Index.js';
import { setSentenceC0Value } from '/js/sentenceC0/setSentenceC0Value.js';
import { transformSentenceAElement } from '/js/sentenceA/transformSentenceAElement.js';
import { transformSentenceBElement } from '/js/sentenceB/transformSentenceBElement.js';
import { transformSentenceCElement } from '/js/sentenceC/transformSentenceCElement.js';
import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { setSentenceAElementDisplay } from '/js/sentenceA/setSentenceAElementDisplay.js';
import { setSentenceBElementDisplay } from '/js/sentenceB/setSentenceBElementDisplay.js';
import { setSentenceCElementDisplay } from '/js/sentenceC/setSentenceCElementDisplay.js';
import { setSentenceC1ElementValue } from '/js/sentenceC1/setSentenceC1ElementValue.js';
import { setSentenceB0ElementHeight } from '/js/sentenceB0/setSentenceB0ElementHeight.js';
import { setBlankB0ElementHeight } from '/js/blankB0/setBlankB0ElementHeight.js';
import { setSentenceB1ElementFocus } from '/js/sentenceB1/setSentenceB1ElementFocus.js';

export const sentenceCStream_D = (event) => {
  setSentenceXCanInput(false);

  killCharacterA0PreviousElementTimelines();
  killCharacterB0PreviousElementTimelines();
  killCharacterC0PreviousElementTimelines();
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0ElementTimelines();
  killCharacterB0ElementTimelines();
  killCharacterC0ElementTimelines();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterC0Element();
  setCharacterC0ElementOpacity();
  removeCharacterC0Element();
  setCharacterC0NextElementsOpacity();
  removeCharacterC0NextElements();
  setCharacterC0Elements();
  setCharacterC0PreviousElement();
  setCharacterC0PreviousElementOpacity();
  setCharacterC0Values();
  setCharacterC0Index();
  setSentenceC0Value(event);
  setSentenceC1ElementValue();
  setSentenceB0ElementHeight();
  setBlankB0ElementHeight();
  transformSentenceAElement();
  transformSentenceBElement();
  transformSentenceCElement();
  setCharacterA0ElementsOpacity();
  setCharacterB0ElementsOpacity();
  setCharacterC0ElementsOpacity();
  setSentenceAElementDisplay();
  setSentenceBElementDisplay();
  setSentenceCElementDisplay();
  setSentenceB1ElementFocus();
};
