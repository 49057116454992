import gsap from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const setCharacterA0PreviousElementOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];

  if (sentenceAState.A === true) {
    if (theCharacterA0.selectElements.length == 0) {
      if (theCharacterA0.previousElement !== undefined) {
        let element = theCharacterA0.previousElement;
        let timeline = gsap.timeline({ paused: true });
        timeline.to(element, {
          opacity: 0.4,
          duration: 1,
        });
        theCharacterA0.previousElementTimelines.push(timeline);
        const checkOpacity = () => {
          const number = parseInt(element.style.opacity);
          if (number === 1) {
            timeline.play();
            clearInterval(checkInterval);
          }
        };
        const checkInterval = setInterval(checkOpacity, 10);
      }
    }
  }

  if (sentenceAState.D === true) {
    if (theCharacterA0.previousElement !== undefined) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterA0.previousElement, {
        opacity: 1,
        duration: 3,
        delay: 1,
      });
      theCharacterA0.previousElementTimelines.push(timeline);
    }
  }
};
