import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { handleCharacterA0ElementClick } from '/js/characterA0/handleCharacterA0ElementClick.js';
import { handleCharacterA0ElementWheel } from '/js/characterA0/handleCharacterA0ElementWheel.js';
import { handleCharacterA0ElementTouchStart } from '/js/characterA0/handleCharacterA0ElementTouchStart.js';
import { handleCharacterA0ElementTouchEnd } from '/js/characterA0/handleCharacterA0ElementTouchEnd.js';

export const addCharacterB0LastElementEventListener = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.R_I === true || sentenceBState.R_A === true) {
    theCharacterB0.lastElement.addEventListener(
      'click',
      handleCharacterA0ElementClick
    );
    theCharacterB0.lastElement.addEventListener(
      'wheel',
      handleCharacterA0ElementWheel
    );
    theCharacterB0.lastElement.addEventListener(
      'touchstart',
      handleCharacterA0ElementTouchStart
    );
    theCharacterB0.lastElement.addEventListener(
      'touchend',
      handleCharacterA0ElementTouchEnd
    );
  }
};
