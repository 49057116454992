import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { code } from '/js/code/code.js';

export const setCodeElementDisplay = () => {
  if (logInState.C === true || logInState.RV === true) {
    code.element.style.display = 'flex';
  }

  if (confirmState.C === true) {
    setTimeout(() => {
      code.element.style.display = 'none';
    }, 1000);
  }
};
