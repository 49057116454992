import { setSentenceXState } from '/js/sentenceXState/setSentenceXState.js';
import { setLogInState } from '/js/logInState/setLogInState.js';
import { logInStream_C } from '/js/logInStream/logInStream_C.js';
import { logInState } from '/js/logInState/logInState.js';
import { setSentenceXCanClick } from '/js/sentenceX/setSentenceXCanClick.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';

export const handleLogInElementClick = (event) => {
  event.stopPropagation();
  if (sentenceX.canClick === true) {
    setSentenceXCanClick(false);
    setSentenceXState(event);
    setLogInState(event);
    if (logInState.C === true) {
      logInStream_C(event);
    }
  }
};
