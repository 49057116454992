import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { resetSentenceXState } from '/js/sentenceXState/resetSentenceXState.js';
import { resetSentenceAState } from '/js/sentenceAState/resetSentenceAState.js';
import { resetSentenceBState } from '/js/sentenceBState/resetSentenceBState.js';
import { resetSentenceCState } from '/js/sentenceCState/resetSentenceCState.js';
import { resetBlankAState } from '/js/blankAState/resetBlankAState.js';
import { resetBlankBState } from '/js/blankBState/resetBlankBState.js';
import { resetBlankCState } from '/js/blankCState/resetBlankCState.js';
import { resetCharacterAState } from '/js/characterAState/resetCharacterAState.js';
import { resetCharacterBState } from '/js/characterBState/resetCharacterBState.js';
import { resetCharacterCState } from '/js/characterCState/resetCharacterCState.js';
import { setSentenceBState } from '/js/sentenceBState/setSentenceBState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceBStream_C } from '/js/sentenceBStream/sentenceBStream_C.js';
export const handleSentenceB0ElementClick = (event) => {
  event.preventDefault();
  event.stopPropagation();
  if (sentenceX.canClick === true) {
    resetSentenceXState(event);
    resetSentenceAState(event);
    resetSentenceBState(event);
    resetSentenceCState(event);
    resetBlankAState(event);
    resetBlankBState(event);
    resetBlankCState(event);
    resetCharacterAState(event);
    resetCharacterBState(event);
    resetCharacterCState(event);
    setSentenceBState(event);
    if (sentenceBState.C === true) {
      sentenceBStream_C(event);
    }
  }
};
