import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { characterC0 } from '/js/characterC0/characterC0.js';

export const setCharacterC0NextElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (
    sentenceCState.R_I === true ||
    sentenceCState.R_I_C === true ||
    sentenceCState.D === true
  ) {
    if (characterC0[theIndex].nextElements.length > 0) {
      gsap.to(characterC0[theIndex].nextElements, {
        opacity: 0,
        duration: 1,
      });
    }
  }
};
