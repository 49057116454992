import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';

export const setCharacterA0ReferenceElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (characterAState.C === true || characterAState.T === true) {
    theCharacterA0.referenceElement =
      theCharacterA0.selectElements[
        theCharacterA0.selectElements.length - 1
      ].nextSibling;
    if (theCharacterA0.referenceElement === null) {
      theCharacterA0.referenceElement = undefined;
    }
  }
  if (sentenceAState.R_I === true || sentenceAState.R_I_C === true) {
    setTimeout(() => {
      theCharacterA0.referenceElement = undefined;
    }, 1000);
  }
  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    theCharacterA0.referenceElement = undefined;
  }
};
