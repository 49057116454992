import { logInState } from '/js/logInState/logInState.js';
import { codeA1 } from '/js/codeA1/codeA1.js';
import { codeState } from '/js/codeState/codeState.js';

export const setCodeA1ElementFocus = () => {
  if (logInState.C === true || logInState.RV === true) {
    setTimeout(() => {
      codeA1.element.focus();
    }, 1000);
  }

  if (codeState.C === true) {
    codeA1.element.focus();
  }
};
