import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { confirm } from '/js/confirm/confirm.js';

export const setConfirmElementDisplay = () => {
  if (logInState.UV === true || logInState.RV === true) {
    confirm.element.style.display = 'flex';
  }
  if (confirmState.V === true) {
    setTimeout(() => {
      confirm.element.style.display = 'none';
    }, 1000);
  }
};
