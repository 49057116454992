import { gsap } from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';

export const setCharacterC0CompleteElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (sentenceCState.A_C === true) {
    if (theCharacterC0.selectElements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterC0.completeElements, {
        opacity: 1,
        duration: 3,
        delay: 1,
      });
    } else {
      console.log(theCharacterC0.completeElements);
      let timeline = gsap.timeline();
      timeline.to(theCharacterC0.completeElements, {
        opacity: 1,
        duration: 3,
      });
    }
  }

  if (sentenceCState.R_A_C === true || sentenceCState.R_I_C === true) {
    let timeline = gsap.timeline();
    timeline.to(theCharacterC0.completeElements, {
      opacity: 1,
      duration: 3,
      delay: 1,
    });
  }
};
