import { userName } from '/js/userName/userName.js';
import { passWord } from '/js/passWord/passWord.js';
import { logInState } from '/js/logInState/logInState.js';

export const setLogInState = (event) => {
  for (const key in logInState) {
    logInState[key] = false;
  }
  if (
    event.target.className === 'logIn' &&
    userName.value !== '' &&
    passWord.value !== ''
  ) {
    logInState.C = true;
  }
};
