import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';

export const setCharacterC0SelectEnd = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (characterCState.C === true || characterCState.T === true) {
    theCharacterC0.selectEnd = parseInt(
      theCharacterC0.selectElements[
        theCharacterC0.selectElements.length - 1
      ].id.slice(15)
    );
  }

  if (
    sentenceXState.W === true ||
    sentenceAState.W === true ||
    sentenceBState.W === true ||
    sentenceCState.W === true ||
    blankAState.W === true ||
    blankBState.W === true ||
    blankCState.W === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    theCharacterC0.selectEnd = 0;
  }

  if (sentenceXState.W_L_SKC_G === true) {
    let number = 0;
    for (
      let i = characterC0[theIndex].selectInitial;
      i < sentenceC0[theIndex].value.length;
      i++
    ) {
      if (sentenceC0[theIndex].value[i] == ' ') {
        break;
      }
      number = i;
    }
    characterC0[theIndex].selectEnd = number;
  }
};
