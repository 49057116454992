import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { setSentenceXCanClick } from '/js/sentenceX/setSentenceXCanClick.js';
import { setSentenceXCanWheel } from '/js/sentenceX/setSentenceXCanWheel.js';
import { setSentenceXCanTouch } from '/js/sentenceX/setSentenceXCanTouch.js';
import { setSentenceSignElements } from '/js/sentence/setSentenceSignElements.js';
import { setSentenceSignElementsOpacity } from '/js/sentence/setSentenceSignElementsOpacity.js';
import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0ElementTimelines } from '/js/characterA0/killCharacterA0ElementTimelines.js';
import { killCharacterB0ElementTimelines } from '/js/characterB0/killCharacterB0ElementTimelines.js';
import { killCharacterC0ElementTimelines } from '/js/characterC0/killCharacterC0ElementTimelines.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { killCharacterA0PreviousElementTimelines } from '/js/characterA0/killCharacterA0PreviousElementTimelines.js';
import { killCharacterB0PreviousElementTimelines } from '/js/characterB0/killCharacterB0PreviousElementTimelines.js';
import { killCharacterC0PreviousElementTimelines } from '/js/characterC0/killCharacterC0PreviousElementTimelines.js';
import { setSentenceXHistoryData } from '/js/sentenceX/setSentenceXHistoryData.js';
import { setSentenceXData } from '/js/sentenceX/setSentenceXData.js';
import { setSentenceValue } from '/js/sentence/setSentenceValue.js';
import { setSentenceA2Value } from '/js/sentenceA2/setSentenceA2Value.js';
import { setSentenceB2Value } from '/js/sentenceB2/setSentenceB2Value.js';
import { setSentenceC2Value } from '/js/sentenceC2/setSentenceC2Value.js';
import { setCharacterA2Values } from '/js/characterA2/setCharacterA2Values.js';
import { setCharacterB2Values } from '/js/characterB2/setCharacterB2Values.js';
import { setCharacterC2Values } from '/js/characterC2/setCharacterC2Values.js';
import { setSentenceA2SplitValues } from '/js/sentenceA2/setSentenceA2SplitValues.js';
import { setSentenceB2SplitValues } from '/js/sentenceB2/setSentenceB2SplitValues.js';
import { setSentenceC2SplitValues } from '/js/sentenceC2/setSentenceC2SplitValues.js';
import { setSentenceA2SplitCounts } from '/js/sentenceA2/setSentenceA2SplitCounts.js';
import { setSentenceB2SplitCounts } from '/js/sentenceB2/setSentenceB2SplitCounts.js';
import { setSentenceC2SplitCounts } from '/js/sentenceC2/setSentenceC2SplitCounts.js';
import { setSentenceA2SplitInitials } from '/js/sentenceA2/setSentenceA2SplitInitials.js';
import { setSentenceB2SplitInitials } from '/js/sentenceB2/setSentenceB2SplitInitials.js';
import { setSentenceC2SplitInitials } from '/js/sentenceC2/setSentenceC2SplitInitials.js';
import { setCharacterA2Elements } from '/js/characterA2/setCharacterA2Elements.js';
import { setCharacterB2Elements } from '/js/characterB2/setCharacterB2Elements.js';
import { setCharacterC2Elements } from '/js/characterC2/setCharacterC2Elements.js';
import { setCharacterA2ElementsClassName } from '/js/characterA2/setCharacterA2ElementsClassName.js';
import { setCharacterB2ElementsClassName } from '/js/characterB2/setCharacterB2ElementsClassName.js';
import { setCharacterC2ElementsClassName } from '/js/characterC2/setCharacterC2ElementsClassName.js';
import { setSentenceA0WordNames } from '/js/sentenceA0/setSentenceA0WordNames.js';
import { setSentenceB0WordNames } from '/js/sentenceB0/setSentenceB0WordNames.js';
import { setSentenceC0WordNames } from '/js/sentenceC0/setSentenceC0WordNames.js';
import { setSentenceA2WordNames } from '/js/sentenceA2/setSentenceA2WordNames.js';
import { setSentenceB2WordNames } from '/js/sentenceB2/setSentenceB2WordNames.js';
import { setSentenceC2WordNames } from '/js/sentenceC2/setSentenceC2WordNames.js';
import { setSentenceA0DifferWordNames } from '/js/sentenceA0/setSentenceA0DifferWordNames.js';
import { setSentenceB0DifferWordNames } from '/js/sentenceB0/setSentenceB0DifferWordNames.js';
import { setSentenceC0DifferWordNames } from '/js/sentenceC0/setSentenceC0DifferWordNames.js';
import { setCharacterA0DifferElements } from '/js/characterA0/setCharacterA0DifferElements.js';
import { setCharacterB0DifferElements } from '/js/characterB0/setCharacterB0DifferElements.js';
import { setCharacterC0DifferElements } from '/js/characterC0/setCharacterC0DifferElements.js';
import { setCharacterA0DifferClassNames } from '/js/characterA0/setCharacterA0DifferClassNames.js';
import { setCharacterB0DifferClassNames } from '/js/characterB0/setCharacterB0DifferClassNames.js';
import { setCharacterC0DifferClassNames } from '/js/characterC0/setCharacterC0DifferClassNames.js';
import { preKillSentenceSignElementsTimelines } from '/js/sentence/preKillSentenceSignElementsTimelines.js';
import { setCharacterA0DifferElementsOpacity } from '/js/characterA0/setCharacterA0DifferElementsOpacity.js';
import { setCharacterB0DifferElementsOpacity } from '/js/characterB0/setCharacterB0DifferElementsOpacity.js';
import { setCharacterC0DifferElementsOpacity } from '/js/characterC0/setCharacterC0DifferElementsOpacity.js';
import { setSentenceA0SameWordNames } from '/js/sentenceA0/setSentenceA0SameWordNames.js';
import { setSentenceB0SameWordNames } from '/js/sentenceB0/setSentenceB0SameWordNames.js';
import { setSentenceC0SameWordNames } from '/js/sentenceC0/setSentenceC0SameWordNames.js';
import { setCharacterA0SameElements } from '/js/characterA0/setCharacterA0SameElements.js';
import { setCharacterB0SameElements } from '/js/characterB0/setCharacterB0SameElements.js';
import { setCharacterC0SameElements } from '/js/characterC0/setCharacterC0SameElements.js';
import { setCharacterA0SameClassNames } from '/js/characterA0/setCharacterA0SameClassNames.js';
import { setCharacterB0SameClassNames } from '/js/characterB0/setCharacterB0SameClassNames.js';
import { setCharacterC0SameClassNames } from '/js/characterC0/setCharacterC0SameClassNames.js';
import { setCharacterA0SameElementsXs } from '/js/characterA0/setCharacterA0SameElementsXs.js';
import { setCharacterB0SameElementsXs } from '/js/characterB0/setCharacterB0SameElementsXs.js';
import { setCharacterC0SameElementsXs } from '/js/characterC0/setCharacterC0SameElementsXs.js';
import { setSentenceA2SameWordNames } from '/js/sentenceA2/setSentenceA2SameWordNames.js';
import { setSentenceB2SameWordNames } from '/js/sentenceB2/setSentenceB2SameWordNames.js';
import { setSentenceC2SameWordNames } from '/js/sentenceC2/setSentenceC2SameWordNames.js';
import { setCharacterA2SameElements } from '/js/characterA2/setCharacterA2SameElements.js';
import { setCharacterB2SameElements } from '/js/characterB2/setCharacterB2SameElements.js';
import { setCharacterC2SameElements } from '/js/characterC2/setCharacterC2SameElements.js';
import { setCharacterA2SameClassNames } from '/js/characterA2/setCharacterA2SameClassNames.js';
import { setCharacterB2SameClassNames } from '/js/characterB2/setCharacterB2SameClassNames.js';
import { setCharacterC2SameClassNames } from '/js/characterC2/setCharacterC2SameClassNames.js';
import { setCharacterA2SameElementsXs } from '/js/characterA2/setCharacterA2SameElementsXs.js';
import { setCharacterB2SameElementsXs } from '/js/characterB2/setCharacterB2SameElementsXs.js';
import { setCharacterC2SameElementsXs } from '/js/characterC2/setCharacterC2SameElementsXs.js';
import { setCharacterA0SameElementsTx } from '/js/characterA0/setCharacterA0SameElementsTx.js';
import { setCharacterB0SameElementsTx } from '/js/characterB0/setCharacterB0SameElementsTx.js';
import { setCharacterC0SameElementsTx } from '/js/characterC0/setCharacterC0SameElementsTx.js';
import { setCharacterA0OverElements } from '/js/characterA0/setCharacterA0OverElements.js';
import { setCharacterB0OverElements } from '/js/characterB0/setCharacterB0OverElements.js';
import { setCharacterC0OverElements } from '/js/characterC0/setCharacterC0OverElements.js';
import { setCharacterA0OverElementsOpacity } from '/js/characterA0/setCharacterA0OverElementsOpacity.js';
import { setCharacterB0OverElementsOpacity } from '/js/characterB0/setCharacterB0OverElementsOpacity.js';
import { setCharacterC0OverElementsOpacity } from '/js/characterC0/setCharacterC0OverElementsOpacity.js';
import { transformCharacterA0SameElements } from '/js/characterA0/transformCharacterA0SameElements.js';
import { transformCharacterB0SameElements } from '/js/characterB0/transformCharacterB0SameElements.js';
import { transformCharacterC0SameElements } from '/js/characterC0/transformCharacterC0SameElements.js';
import { setBlankA0ElementHeight } from '/js/blankA0/setBlankA0ElementHeight.js';
import { setBlankB0ElementHeight } from '/js/blankB0/setBlankB0ElementHeight.js';
import { setSentenceA0ElementHeight } from '/js/sentenceA0/setSentenceA0ElementHeight.js';
import { setSentenceB0ElementHeight } from '/js/sentenceB0/setSentenceB0ElementHeight.js';
import { setSentenceA2ElementHeight } from '/js/sentenceA2/setSentenceA2ElementHeight.js';
import { setSentenceB2ElementHeight } from '/js/sentenceB2/setSentenceB2ElementHeight.js';
import { transformSentenceAElement } from '/js/sentenceA/transformSentenceAElement.js';
import { transformSentenceBElement } from '/js/sentenceB/transformSentenceBElement.js';
import { transformSentenceCElement } from '/js/sentenceC/transformSentenceCElement.js';
import { setSentenceAElementDisplay } from '/js/sentenceA/setSentenceAElementDisplay.js';
import { setSentenceBElementDisplay } from '/js/sentenceB/setSentenceBElementDisplay.js';
import { setSentenceCElementDisplay } from '/js/sentenceC/setSentenceCElementDisplay.js';
import { setSentenceA2DifferWordNames } from '/js/sentenceA2/setSentenceA2DifferWordNames.js';
import { setSentenceB2DifferWordNames } from '/js/sentenceB2/setSentenceB2DifferWordNames.js';
import { setSentenceC2DifferWordNames } from '/js/sentenceC2/setSentenceC2DifferWordNames.js';
import { setCharacterA2DifferElements } from '/js/characterA2/setCharacterA2DifferElements.js';
import { setCharacterB2DifferElements } from '/js/characterB2/setCharacterB2DifferElements.js';
import { setCharacterC2DifferElements } from '/js/characterC2/setCharacterC2DifferElements.js';
import { setCharacterA2DifferClassNames } from '/js/characterA2/setCharacterA2DifferClassNames.js';
import { setCharacterB2DifferClassNames } from '/js/characterB2/setCharacterB2DifferClassNames.js';
import { setCharacterC2DifferClassNames } from '/js/characterC2/setCharacterC2DifferClassNames.js';
import { resetCharacterA0OverElementsOpacity } from '/js/characterA0/resetCharacterA0OverElementsOpacity.js';
import { resetCharacterB0OverElementsOpacity } from '/js/characterB0/resetCharacterB0OverElementsOpacity.js';
import { resetCharacterC0OverElementsOpacity } from '/js/characterC0/resetCharacterC0OverElementsOpacity.js';
import { setCharacterA2DifferElementsOpacity } from '/js/characterA2/setCharacterA2DifferElementsOpacity.js';
import { setCharacterB2DifferElementsOpacity } from '/js/characterB2/setCharacterB2DifferElementsOpacity.js';
import { setCharacterC2DifferElementsOpacity } from '/js/characterC2/setCharacterC2DifferElementsOpacity.js';
import { killSentenceSignElementsTimelines } from '/js/sentence/killSentenceSignElementsTimelines.js';
import { resetSentenceSignElementsOpacity } from '/js/sentence/resetSentenceSignElementsOpacity.js';
import { setSentenceXCD } from '/js/sentenceX/setSentenceXCD.js';
import { removeCharacterA2Elements } from '/js/characterA2/removeCharacterA2Elements.js';
import { removeCharacterB2Elements } from '/js/characterB2/removeCharacterB2Elements.js';
import { removeCharacterC2Elements } from '/js/characterC2/removeCharacterC2Elements.js';
import { removeCharacterA0Elements } from '/js/characterA0/removeCharacterA0Elements.js';
import { removeCharacterB0Elements } from '/js/characterB0/removeCharacterB0Elements.js';
import { removeCharacterC0Elements } from '/js/characterC0/removeCharacterC0Elements.js';
import { setSentenceA0Value } from '/js/sentenceA0/setSentenceA0Value.js';
import { setSentenceB0Value } from '/js/sentenceB0/setSentenceB0Value.js';
import { setSentenceC0Value } from '/js/sentenceC0/setSentenceC0Value.js';
import { setSentenceA0SplitValues } from '/js/sentenceA0/setSentenceA0SplitValues.js';
import { setSentenceB0SplitValues } from '/js/sentenceB0/setSentenceB0SplitValues.js';
import { setSentenceC0SplitValues } from '/js/sentenceC0/setSentenceC0SplitValues.js';
import { setSentenceA0SplitCounts } from '/js/sentenceA0/setSentenceA0SplitCounts.js';
import { setSentenceB0SplitCounts } from '/js/sentenceB0/setSentenceB0SplitCounts.js';
import { setSentenceC0SplitCounts } from '/js/sentenceC0/setSentenceC0SplitCounts.js';
import { setSentenceA0SplitInitials } from '/js/sentenceA0/setSentenceA0SplitInitials.js';
import { setSentenceB0SplitInitials } from '/js/sentenceB0/setSentenceB0SplitInitials.js';
import { setSentenceC0SplitInitials } from '/js/sentenceC0/setSentenceC0SplitInitials.js';
import { setCharacterA0Elements } from '/js/characterA0/setCharacterA0Elements.js';
import { setCharacterB0Elements } from '/js/characterB0/setCharacterB0Elements.js';
import { setCharacterC0Elements } from '/js/characterC0/setCharacterC0Elements.js';
import { appendCharacterA0Elements } from '/js/characterA0/appendCharacterA0Elements.js';
import { appendCharacterB0Elements } from '/js/characterB0/appendCharacterB0Elements.js';
import { appendCharacterC0Elements } from '/js/characterC0/appendCharacterC0Elements.js';
import { setCharacterA0ElementsId } from '/js/characterA0/setCharacterA0ElementsId.js';
import { setCharacterB0ElementsId } from '/js/characterB0/setCharacterB0ElementsId.js';
import { setCharacterC0ElementsId } from '/js/characterC0/setCharacterC0ElementsId.js';
import { setCharacterA0Values } from '/js/characterA0/setCharacterA0Values.js';
import { setCharacterB0Values } from '/js/characterB0/setCharacterB0Values.js';
import { setCharacterC0Values } from '/js/characterC0/setCharacterC0Values.js';
import { setCharacterA0ElementsClassName } from '/js/characterA0/setCharacterA0ElementsClassName.js';
import { setCharacterB0ElementsClassName } from '/js/characterB0/setCharacterB0ElementsClassName.js';
import { setCharacterC0ElementsClassName } from '/js/characterC0/setCharacterC0ElementsClassName.js';

import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { addCharacterA0ElementsEventListener } from '/js/characterA0/addCharacterA0ElementsEventListener.js';
import { addCharacterB0ElementsEventListener } from '/js/characterB0/addCharacterB0ElementsEventListener.js';
import { addCharacterC0ElementsEventListener } from '/js/characterC0/addCharacterC0ElementsEventListener.js';
import { setCharacterA0Index } from '/js/characterA0/setCharacterA0Index.js';
import { setCharacterB0Index } from '/js/characterB0/setCharacterB0Index.js';
import { setCharacterC0Index } from '/js/characterC0/setCharacterC0Index.js';
import { setSentenceA1ElementFocus } from '/js/sentenceA1/setSentenceA1ElementFocus.js';
import { setSentenceB1ElementFocus } from '/js/sentenceB1/setSentenceB1ElementFocus.js';
import { setSentenceC1ElementFocus } from '/js/sentenceC1/setSentenceC1ElementFocus.js';
import { setSentenceA1ElementValue } from '/js/sentenceA1/setSentenceA1ElementValue.js';
import { setSentenceB1ElementValue } from '/js/sentenceB1/setSentenceB1ElementValue.js';
import { setSentenceC1ElementValue } from '/js/sentenceC1/setSentenceC1ElementValue.js';
import { presetSentenceValue } from '/js/sentence/presetSentenceValue.js';
import { sentence } from '/js/sentence/sentence.js';
export const sentenceCStream_T_L_SKA_G2 = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  let url = `https://ggem13xwva.execute-api.us-east-1.amazonaws.com/${sentenceX.PKA}/${sentenceX.SKA}`;

  const descending = (para) => {
    return function (objA, objB) {
      var valueA = objA[para];
      var valueB = objB[para];
      return valueB - valueA;
    };
  };
  setSentenceXCanClick(false);
  setSentenceXCanWheel(false);
  setSentenceXCanTouch(false);
  setSentenceSignElements();
  setSentenceSignElementsOpacity();
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0ElementTimelines();
  killCharacterB0ElementTimelines();
  killCharacterC0ElementTimelines();
  killCharacterA0PreviousElementTimelines();
  killCharacterB0PreviousElementTimelines();
  killCharacterC0PreviousElementTimelines();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  fetch(url)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      presetSentenceValue(data);
      if (Array.isArray(data) === false) {
        data = [];
      }
      if (data.length > 0) {
        sentenceX.rawData = data;
        for (const item of sentenceX.rawData) {
          let SK = item.SK.S;
          let arr = SK.split('&');
          arr.pop();
          let obj = arr.reduce((acc, currentValue, index) => {
            acc[index] = currentValue;
            return acc;
          }, {});
          if (sentence[theIndex].value !== obj[theIndex].value) {
            sentenceX.dataSKA[sentenceX.SKA].push(obj);
          }
        }
      }

      setSentenceXHistoryData(event);
      setSentenceXData(event);
      setSentenceValue(event);
      setSentenceA2Value(event);
      setSentenceB2Value(event);
      setSentenceC2Value(event);
      setCharacterA2Values(event);
      setCharacterB2Values(event);
      setCharacterC2Values(event);
      setSentenceA2SplitValues(event);
      setSentenceB2SplitValues(event);
      setSentenceC2SplitValues(event);
      setSentenceA2SplitCounts(event);
      setSentenceB2SplitCounts(event);
      setSentenceC2SplitCounts(event);
      setSentenceA2SplitInitials(event);
      setSentenceB2SplitInitials(event);
      setSentenceC2SplitInitials(event);
      setCharacterA2Elements(event);
      setCharacterB2Elements(event);
      setCharacterC2Elements(event);
      setCharacterA2ElementsClassName(event);
      setCharacterB2ElementsClassName(event);
      setCharacterC2ElementsClassName(event);
      setSentenceA0WordNames(event);
      setSentenceB0WordNames(event);
      setSentenceC0WordNames(event);
      setSentenceA2WordNames(event);
      setSentenceB2WordNames(event);
      setSentenceC2WordNames(event);
      setSentenceA0DifferWordNames(event);
      setSentenceB0DifferWordNames(event);
      setSentenceC0DifferWordNames(event);
      setCharacterA0DifferElements(event);
      setCharacterB0DifferElements(event);
      setCharacterC0DifferElements(event);
      setCharacterA0DifferClassNames(event);
      setCharacterB0DifferClassNames(event);
      setCharacterC0DifferClassNames(event);
      preKillSentenceSignElementsTimelines();
      setCharacterA0DifferElementsOpacity(event);
      setCharacterB0DifferElementsOpacity(event);
      setCharacterC0DifferElementsOpacity(event);
      setSentenceA0SameWordNames(event);
      setSentenceB0SameWordNames(event);
      setSentenceC0SameWordNames(event);
      setCharacterA0SameElements(event);
      setCharacterB0SameElements(event);
      setCharacterC0SameElements(event);
      setCharacterA0SameClassNames(event);
      setCharacterB0SameClassNames(event);
      setCharacterC0SameClassNames(event);
      setCharacterA0SameElementsXs(event);
      setCharacterB0SameElementsXs(event);
      setCharacterC0SameElementsXs(event);
      setSentenceA2SameWordNames(event);
      setSentenceB2SameWordNames(event);
      setSentenceC2SameWordNames(event);
      setCharacterA2SameElements();
      setCharacterB2SameElements();
      setCharacterC2SameElements();
      setCharacterA2SameClassNames();
      setCharacterB2SameClassNames();
      setCharacterC2SameClassNames();
      setCharacterA2SameElementsXs();
      setCharacterB2SameElementsXs();
      setCharacterC2SameElementsXs();
      setCharacterA0SameElementsTx();
      setCharacterB0SameElementsTx();
      setCharacterC0SameElementsTx();
      setCharacterA0OverElements();
      setCharacterB0OverElements();
      setCharacterC0OverElements();
      setCharacterA0OverElementsOpacity();
      setCharacterB0OverElementsOpacity();
      setCharacterC0OverElementsOpacity();
      setBlankA0ElementHeight();
      setBlankB0ElementHeight();
      setSentenceA0ElementHeight();
      setSentenceB0ElementHeight();
      transformCharacterA0SameElements();
      transformCharacterB0SameElements();
      transformCharacterC0SameElements();
      setSentenceA2ElementHeight();
      setSentenceB2ElementHeight();
      transformSentenceAElement(event);
      transformSentenceBElement(event);
      transformSentenceCElement(event);
      setSentenceAElementDisplay(event);
      setSentenceBElementDisplay(event);
      setSentenceCElementDisplay(event);
      setSentenceA2DifferWordNames(event);
      setSentenceB2DifferWordNames(event);
      setSentenceC2DifferWordNames(event);
      setCharacterA2DifferElements(event);
      setCharacterB2DifferElements(event);
      setCharacterC2DifferElements(event);
      setCharacterA2DifferClassNames(event);
      setCharacterB2DifferClassNames(event);
      setCharacterC2DifferClassNames(event);
      resetCharacterA0OverElementsOpacity(event);
      resetCharacterB0OverElementsOpacity(event);
      resetCharacterC0OverElementsOpacity(event);
      setCharacterA2DifferElementsOpacity(event);
      setCharacterB2DifferElementsOpacity(event);
      setCharacterC2DifferElementsOpacity(event);
      setSentenceXCD();
      killSentenceSignElementsTimelines(event);
      resetSentenceSignElementsOpacity(event);
      setTimeout(() => {
        removeCharacterA2Elements();
        removeCharacterB2Elements();
        removeCharacterC2Elements();
        removeCharacterA0Elements();
        removeCharacterB0Elements();
        removeCharacterC0Elements();
        setSentenceA0Value(event);
        setSentenceB0Value(event);
        setSentenceC0Value(event);
        setCharacterA0Values(event);
        setCharacterB0Values(event);
        setCharacterC0Values(event);
        setSentenceA0SplitValues(event);
        setSentenceB0SplitValues(event);
        setSentenceC0SplitValues(event);
        setSentenceA0SplitCounts(event);
        setSentenceB0SplitCounts(event);
        setSentenceC0SplitCounts(event);
        setSentenceA0SplitInitials(event);
        setSentenceB0SplitInitials(event);
        setSentenceC0SplitInitials(event);
        setCharacterA0Elements(event);
        setCharacterB0Elements(event);
        setCharacterC0Elements(event);
        appendCharacterA0Elements(event);
        appendCharacterB0Elements(event);
        appendCharacterC0Elements(event);
        setCharacterA0ElementsId(event);
        setCharacterB0ElementsId(event);
        setCharacterC0ElementsId(event);
        setCharacterA0ElementsClassName(event);
        setCharacterB0ElementsClassName(event);
        setCharacterC0ElementsClassName(event);
        setCharacterA0ElementsOpacity(event);
        setCharacterB0ElementsOpacity(event);
        setCharacterC0ElementsOpacity(event);
        addCharacterA0ElementsEventListener(event);
        addCharacterB0ElementsEventListener(event);
        addCharacterC0ElementsEventListener(event);
        setCharacterA0Index(event);
        setCharacterB0Index(event);
        setCharacterC0Index(event);
        setSentenceA1ElementValue();
        setSentenceB1ElementValue();
        setSentenceC1ElementValue();
        setSentenceA1ElementFocus(event);
        setSentenceB1ElementFocus(event);
        setSentenceC1ElementFocus(event);
        setSentenceXCanClick(true);
        setSentenceXCanWheel(true);
        setSentenceXCanTouch(true);
      }, sentenceX.CD * 1000);
    })
    .catch((error) => {
      return error;
    });
};
