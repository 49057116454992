import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
export const removeCharacterB0Element = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.D === true) {
    let element = theCharacterB0.element;
    if (element !== null) {
      setTimeout(() => {
        element.remove();
      }, 1000);
    }
  }

  if (characterBState.C === true || characterBState.T === true) {
    if (
      theCharacterB0.elements[theCharacterB0.elements.length - 1].innerHTML ===
      '&nbsp;'
    ) {
      theCharacterB0.element.remove();
    }
  }
};
