import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';

export const presetSentenceC0Value = (event) => {
  if (event.type === 'wheel' || event.type === 'touchend') {
    for (const key in sentenceC0) {
      let str = sentenceC0[key].value;
      if (str[str.length - 1] === ' ') {
        str = str.slice(0, -1);
        sentenceC0[key].value = str;
      }
    }
  }
};
