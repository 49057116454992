export const sentenceXState = {
  S: false,
  C: false,
  W_U: false,
  W_D: false,
  W_L_VA_G: false,
  W_L_VA: false,
  W_R_VA: false,
  W_L_SKA: false,
  W_R_SKA: false,
  T_L_SKA: false,
  T_R_SKA: false,
  W_L_SKA_G1: false,
  W_L_SKA_G2: false,
  T_L_SKA_G1: false,
  T_L_SKA_G2: false,
  W_L_SKC_G: false,
  T_L_SKC_G: false,
};
