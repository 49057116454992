import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { sentence } from '/js/sentence/sentence.js';
import { authentication } from '/js/authentication/authentication.js';

export const setAuthenticationElementOpacity = () => {
  if (sentenceXState.L === true) {
    if (sentence[0].value === '') {
      gsap.to(authentication.element, {
        opacity: 1,
        duration: 3,
      });
    } else {
      gsap.to(authentication.element, {
        opacity: 1,
        duration: 3,
        delay: 1,
      });
    }
  }

  if (logInState.V === true || confirmState.V === true) {
    gsap.to(authentication.element, {
      opacity: 0,
      duration: 1,
    });
  }
};
