import { setSentenceXCanWheel } from '/js/sentenceX/setSentenceXCanWheel.js';
import { setSentenceXCanInput } from '/js/sentenceX/setSentenceXCanInput.js';
import { setSentenceXCanClick } from '/js/sentenceX/setSentenceXCanClick.js';
import { setSentenceXCanTouch } from '/js/sentenceX/setSentenceXCanTouch.js';
import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0ElementTimelines } from '/js/characterA0/killCharacterA0ElementTimelines.js';
import { killCharacterB0ElementTimelines } from '/js/characterB0/killCharacterB0ElementTimelines.js';
import { killCharacterC0ElementTimelines } from '/js/characterC0/killCharacterC0ElementTimelines.js';
import { killCharacterA0PreviousElementTimelines } from '/js/characterA0/killCharacterA0PreviousElementTimelines.js';
import { killCharacterB0PreviousElementTimelines } from '/js/characterB0/killCharacterB0PreviousElementTimelines.js';
import { killCharacterC0PreviousElementTimelines } from '/js/characterC0/killCharacterC0PreviousElementTimelines.js';
import { killCharacterA0PreviousElementsTimeline } from '/js/characterA0/killCharacterA0PreviousElementsTimeline.js';
import { killCharacterB0PreviousElementsTimeline } from '/js/characterB0/killCharacterB0PreviousElementsTimeline.js';
import { killCharacterC0PreviousElementsTimeline } from '/js/characterC0/killCharacterC0PreviousElementsTimeline.js';
import { killCharacterA0LastElementTimeline } from '/js/characterA0/killCharacterA0LastElementTimeline.js';
import { killCharacterB0LastElementTimeline } from '/js/characterB0/killCharacterB0LastElementTimeline.js';
import { killCharacterC0LastElementTimeline } from '/js/characterC0/killCharacterC0LastElementTimeline.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { transformSentenceAElement } from '/js/sentenceA/transformSentenceAElement.js';
import { transformSentenceBElement } from '/js/sentenceB/transformSentenceBElement.js';
import { transformSentenceCElement } from '/js/sentenceC/transformSentenceCElement.js';
import { setCharacterA0SelectElements } from '/js/characterA0/setCharacterA0SelectElements.js';
import { setCharacterB0SelectElements } from '/js/characterB0/setCharacterB0SelectElements.js';
import { setCharacterC0SelectElements } from '/js/characterC0/setCharacterC0SelectElements.js';
import { setCharacterA0LastElement } from '/js/characterA0/setCharacterA0LastElement.js';
import { setCharacterB0LastElement } from '/js/characterB0/setCharacterB0LastElement.js';
import { setCharacterC0LastElement } from '/js/characterC0/setCharacterC0LastElement.js';
import { setCharacterA0LastElementOpacity } from '/js/characterA0/setCharacterA0LastElementOpacity.js';
import { setCharacterB0LastElementOpacity } from '/js/characterB0/setCharacterB0LastElementOpacity.js';
import { setCharacterC0LastElementOpacity } from '/js/characterC0/setCharacterC0LastElementOpacity.js';
import { setCharacterA0PreviousElements } from '/js/characterA0/setCharacterA0PreviousElements.js';
import { setCharacterB0PreviousElements } from '/js/characterB0/setCharacterB0PreviousElements.js';
import { setCharacterC0PreviousElements } from '/js/characterC0/setCharacterC0PreviousElements.js';
import { setCharacterA0PreviousElementsOpacity } from '/js/characterA0/setCharacterA0PreviousElementsOpacity.js';
import { setCharacterB0PreviousElementsOpacity } from '/js/characterB0/setCharacterB0PreviousElementsOpacity.js';
import { setCharacterC0PreviousElementsOpacity } from '/js/characterC0/setCharacterC0PreviousElementsOpacity.js';
import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { setCharacterA2ElementsOpacity } from '/js/characterA2/setCharacterA2ElementsOpacity.js';
import { setCharacterB2ElementsOpacity } from '/js/characterB2/setCharacterB2ElementsOpacity.js';
import { setCharacterC2ElementsOpacity } from '/js/characterC2/setCharacterC2ElementsOpacity.js';
import { setSentenceA0SelectElements } from '/js/sentenceA0/setSentenceA0SelectElements.js';
import { setSentenceB0SelectElements } from '/js/sentenceB0/setSentenceB0SelectElements.js';
import { setSentenceC0SelectElements } from '/js/sentenceC0/setSentenceC0SelectElements.js';
import { setSentenceA0SelectElementsOpacity } from '/js/sentenceA0/setSentenceA0SelectElementsOpacity.js';
import { setSentenceB0SelectElementsOpacity } from '/js/sentenceB0/setSentenceB0SelectElementsOpacity.js';
import { setSentenceC0SelectElementsOpacity } from '/js/sentenceC0/setSentenceC0SelectElementsOpacity.js';
import { setSentenceA0ElementHeight } from '/js/sentenceA0/setSentenceA0ElementHeight.js';
import { setSentenceAElementDisplay } from '/js/sentenceA/setSentenceAElementDisplay.js';
import { setSentenceBElementDisplay } from '/js/sentenceB/setSentenceBElementDisplay.js';
import { setSentenceCElementDisplay } from '/js/sentenceC/setSentenceCElementDisplay.js';
import { setSentenceXInputIndex } from '/js/sentenceX/setSentenceXInputIndex.js';
import { setSentenceXSelectIndex } from '/js/sentenceX/setSentenceXSelectIndex.js';
import { setSentenceA1ElementFocus } from '/js/sentenceA1/setSentenceA1ElementFocus.js';
import { setSentenceB1ElementFocus } from '/js/sentenceB1/setSentenceB1ElementFocus.js';
import { setSentenceC1ElementFocus } from '/js/sentenceC1/setSentenceC1ElementFocus.js';
import { setSentenceXCD } from '/js/sentenceX/setSentenceXCD.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';

export const characterAStream_W_U = (event) => {
  setSentenceXCanWheel(false);
  // setSentenceXCanClick(false);
  // setSentenceXCanTouch(false);
  killCharacterA0ElementTimelines();
  killCharacterB0ElementTimelines();
  killCharacterC0ElementTimelines();
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0PreviousElementTimelines();
  killCharacterB0PreviousElementTimelines();
  killCharacterC0PreviousElementTimelines();
  killCharacterA0PreviousElementsTimeline();
  killCharacterB0PreviousElementsTimeline();
  killCharacterC0PreviousElementsTimeline();
  killCharacterA0LastElementTimeline();
  killCharacterB0LastElementTimeline();
  killCharacterC0LastElementTimeline();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  transformSentenceAElement(event);
  transformSentenceBElement(event);
  transformSentenceCElement(event);
  setCharacterA0ElementsOpacity(event);
  setCharacterB0ElementsOpacity(event);
  setCharacterC0ElementsOpacity(event);
  setCharacterA2ElementsOpacity(event);
  setCharacterB2ElementsOpacity(event);
  setCharacterC2ElementsOpacity(event);
  setCharacterA0LastElement();
  setCharacterB0LastElement();
  setCharacterC0LastElement();
  setCharacterA0LastElementOpacity();
  setCharacterB0LastElementOpacity();
  setCharacterC0LastElementOpacity();
  setCharacterA0PreviousElements();
  setCharacterB0PreviousElements();
  setCharacterC0PreviousElements();
  setCharacterA0PreviousElementsOpacity();
  setCharacterB0PreviousElementsOpacity();
  setCharacterC0PreviousElementsOpacity();
  setCharacterA0SelectElements(event);
  setCharacterB0SelectElements(event);
  setCharacterC0SelectElements(event);
  setSentenceA0SelectElements(event);
  setSentenceB0SelectElements(event);
  setSentenceC0SelectElements(event);
  setSentenceA0SelectElementsOpacity(event);
  setSentenceB0SelectElementsOpacity(event);
  setSentenceC0SelectElementsOpacity(event);
  setSentenceA0ElementHeight();
  setSentenceAElementDisplay(event);
  setSentenceBElementDisplay(event);
  setSentenceCElementDisplay(event);
  setSentenceXSelectIndex(event);
  setSentenceXInputIndex(event);
  setSentenceA1ElementFocus(event);
  setSentenceB1ElementFocus(event);
  setSentenceC1ElementFocus(event);
  setSentenceXCD(event);
  setTimeout(() => {
    setSentenceXCanWheel(true);
    setSentenceXCanInput(true);
    // setSentenceXCanClick(true);
    // setSentenceXCanTouch(true);
  }, sentenceX.CD * 1000);
};
