import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterB0Value = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (
    sentenceBState.A === true ||
    sentenceBState.R_A === true ||
    sentenceBState.R_I === true
  ) {
    theCharacterB0.value = theCharacterB0.values[theCharacterB0.index + 1];
  }
  if (sentenceBState.A_BTC === true) {
    theCharacterB0.value =
      theCharacterB0.toCValues[theCharacterB0.toCValues.length - 1];
  }
};
