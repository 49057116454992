import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const removeCharacterB0ToCElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.A_BTC === true) {
    if (theCharacterB0.selectElements.length > 0) {
      setTimeout(() => {
        let element = theCharacterB0.toCElements[0].previousElementSibling;
        element.remove();
        for (const item of theCharacterB0.toCElements) {
          item.remove();
        }
        theCharacterB0.toCElements = [];
      }, 2000);
    } else {
      setTimeout(() => {
        let element = theCharacterB0.toCElements[0].previousElementSibling;
        element.remove();
        for (const item of theCharacterB0.toCElements) {
          item.remove();
        }
        theCharacterB0.toCElements = [];
      }, 1000);
    }
  }
};
