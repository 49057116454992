import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setCharacterB0Elements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  let theCharacterA0 = characterA0[theIndex];
  let theCharacterB0 = characterB0[theIndex];

  if (sentenceAState.A_ATB === true) {
    if (theCharacterA0.selectElements.length > 0) {
      setTimeout(() => {
        let arr = Array.from(
          document.querySelectorAll(`#_${theIndex}_sentenceB0 span`)
        );
        theCharacterB0.elements = arr;
      }, 1000);
    } else {
      let arr = Array.from(
        document.querySelectorAll(`#_${theIndex}_sentenceB0 span`)
      );
      theCharacterB0.elements = arr;
    }
  }
  if (sentenceBState.A === true) {
    if (
      theCharacterA0.selectElements.length > 0 ||
      theCharacterB0.selectElements.length > 0
    ) {
      theCharacterB0.elements.splice(
        theCharacterB0.index,
        0,
        theCharacterB0.element
      );
    } else {
      let arr = Array.from(
        document.querySelectorAll(`#_${theIndex}_sentenceB0 span`)
      );
      theCharacterB0.elements = arr;
    }
  }
  if (sentenceBState.A_C === true) {
    let arr = Array.from(
      document.querySelectorAll(`#_${theIndex}_sentenceB0 span`)
    );
    theCharacterB0.elements = arr;
  }

  if (sentenceBState.D === true) {
    let arr = [];
    for (let i = 0; i < theCharacterB0.values.length - 1; i++) {
      arr.push(theCharacterB0.elements[i]);
    }
    theCharacterB0.elements = arr;
  }

  if (sentenceBState.A_BTC === true) {
    if (theCharacterB0.selectElements.length > 0) {
      setTimeout(() => {
        let arrA = Array.from(
          document.querySelectorAll(`#_${theIndex}_sentenceB0 span`)
        );
        let arrB = arrA.slice(0, -theCharacterB0.toCElements.length - 1);
        theCharacterB0.elements = arrB;
      }, 1000);
    } else {
      let arrA = Array.from(
        document.querySelectorAll(`#_${theIndex}_sentenceB0 span`)
      );
      let arrB = arrA.slice(0, -theCharacterB0.toCElements.length - 1);
      theCharacterB0.elements = arrB;
    }
  }

  if (sentenceBState.R_A === true) {
    let arr = [];
    for (const item of theCharacterB0.elements) {
      if (theCharacterB0.selectElements.includes(item) === false) {
        arr.push(item);
      }
    }
    arr.push(theCharacterB0.lastElement);
    theCharacterB0.elements = arr;
  }

  if (sentenceBState.R_A_C === true) {
    let arr = [];
    for (const item of theCharacterB0.elements) {
      if (theCharacterB0.selectElements.includes(item) === false) {
        arr.push(item);
      }
    }
    for (const item of theCharacterB0.completeElements) {
      arr.push(item);
    }
    theCharacterB0.elements = arr;
  }

  if (sentenceBState.R_I === true) {
    let arr = [];
    for (const item of theCharacterB0.elements) {
      if (
        theCharacterB0.selectElements.includes(item) === false &&
        theCharacterB0.nextElements.includes(item) === false
      ) {
        arr.push(item);
      }
    }
    arr.splice(theCharacterB0.selectInitial, 0, theCharacterB0.element);
    theCharacterB0.elements = arr;
  }

  if (sentenceBState.R_I_C === true) {
    let arr = [];
    for (const item of theCharacterB0.elements) {
      if (
        theCharacterB0.selectElements.includes(item) === false &&
        theCharacterB0.nextElements.includes(item) === false
      ) {
        arr.push(item);
      }
    }
    for (const item of theCharacterB0.completeElements) {
      arr.push(item);
    }
    theCharacterB0.elements = arr;
  }

  if (
    sentenceXState.W_L_VK_G === true ||
    sentenceAState.W_L_VK_G === true ||
    sentenceBState.W_L_VK_G === true ||
    sentenceCState.W_L_VK_G === true ||
    sentenceXState.T_L_VK_G === true ||
    sentenceAState.T_L_VK_G === true ||
    sentenceBState.T_L_VK_G === true ||
    sentenceCState.T_L_VK_G === true ||
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.W_L_VK === true ||
    sentenceAState.W_L_VK === true ||
    sentenceBState.W_L_VK === true ||
    sentenceCState.W_L_VK === true ||
    sentenceXState.T_L_VK === true ||
    sentenceAState.T_L_VK === true ||
    sentenceBState.T_L_VK === true ||
    sentenceCState.T_L_VK === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true ||
    sentenceXState.W_R_VK === true ||
    sentenceAState.W_R_VK === true ||
    sentenceBState.W_R_VK === true ||
    sentenceCState.W_R_VK === true ||
    sentenceXState.T_R_VK === true ||
    sentenceAState.T_R_VK === true ||
    sentenceBState.T_R_VK === true ||
    sentenceCState.T_R_VK === true ||
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true ||
    blankAState.W_R_SKA === true ||
    blankBState.W_R_SKA === true ||
    blankCState.W_R_SKA === true ||
    characterAState.W_R_SKA === true ||
    characterBState.W_R_SKA === true ||
    characterCState.W_R_SKA === true ||
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true ||
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    blankAState.W_R_SKC === true ||
    blankBState.W_R_SKC === true ||
    blankCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true ||
    blankAState.T_R_SKC === true ||
    blankBState.T_R_SKC === true ||
    blankCState.T_R_SKC === true
  ) {
    for (const key in characterB0) {
      let arr = [];
      if (characterB0[key].values.length > 0) {
        characterB0[key].values.forEach((item) => {
          characterB0[key].element = document.createElement('span');
          if (item === ' ') {
            characterB0[key].element.innerHTML = '&nbsp;';
          } else {
            characterB0[key].element.innerHTML = item;
          }
          arr.push(characterB0[key].element);
        });
      }
      characterB0[key].elements = arr;
    }
  }
};
