import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
export const setSentenceXUserData = () => {
  if (sentenceXState.P === true) {
    let obj = {};
    for (const key in sentenceA0) {
      let str = '';
      if (sentenceA0[key].value !== '') {
        str += sentenceA0[key].value;
      }
      if (sentenceB0[key].value !== '') {
        str += ' ';
        str += sentenceB0[key].value;
      }
      if (sentenceC0[key].value !== '') {
        str += ' ';
        str += sentenceC0[key].value;
      }
      if (str !== '') {
        obj[key] = str;
      }
    }
    sentenceX.userData[sentenceX.SKA] = obj;
  } else if (sentenceXState.D === true) {
    delete sentenceX.userData[sentenceX.SKA];
  }
};
