import { sentenceB1 } from '/js/sentenceB1/sentenceB1.js';
import { handleSentenceB1ElementInput } from '/js/sentenceB1/handleSentenceB1ElementInput.js';

export const addSentenceB1ElementEventListener = () => {
  for (const key in sentenceB1) {
    sentenceB1[key].element.addEventListener(
      'input',
      handleSentenceB1ElementInput
    );
  }
};
