import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setCharacterB0SelectElements = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];

  if (characterBState.C === true || characterBState.T === true) {
    for (const key in characterB0) {
      let arr = [];
      if (key == theIndex) {
        arr = Array.from(
          document.querySelectorAll(`.${event.target.className}`)
        );
      }
      characterB0[key].selectElements = arr;
    }
  }
  if (
    sentenceBState.R_A === true ||
    sentenceBState.R_I === true ||
    sentenceBState.R_A_C === true ||
    sentenceBState.R_I_C === true
  ) {
    setTimeout(() => {
      theCharacterB0.selectElements = [];
    }, 1000);
  }
  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    characterAState.T === true ||
    characterCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true ||
    characterAState.C === true ||
    characterCState.C === true ||
    sentenceXState.W_U === true ||
    sentenceAState.W_U === true ||
    sentenceBState.W_U === true ||
    sentenceCState.W_U === true ||
    blankAState.W_U === true ||
    blankBState.W_U === true ||
    blankCState.W_U === true ||
    characterAState.W_U === true ||
    characterBState.W_U === true ||
    characterCState.W_U === true ||
    sentenceXState.T_U === true ||
    sentenceAState.T_U === true ||
    sentenceBState.T_U === true ||
    sentenceCState.T_U === true ||
    blankAState.T_U === true ||
    blankBState.T_U === true ||
    blankCState.T_U === true ||
    characterAState.T_U === true ||
    characterBState.T_U === true ||
    characterCState.T_U === true
  ) {
    for (const key in characterB0) {
      characterB0[key].selectElements = [];
    }
  }

  if (
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true
  ) {
    for (const key in characterB0) {
      let arr = [];
      if (characterB0[key].selectElements.length > 0) {
        for (
          let i = characterB0[key].selectInitial;
          i <= characterB0[key].selectEnd;
          i++
        ) {
          arr.push(characterB0[key].elements[i]);
        }
      }
      characterB0[key].selectElements = arr;
    }
  }
};
