import { codeState } from '/js/codeState/codeState.js';
import { codeCharacter } from '/js/codeCharacter/codeCharacter.js';

export const setCodeCharacterIndex = () => {
  if (codeState.A === true) {
    codeCharacter.index += 1;
  }

  if (codeState.D === true) {
    codeCharacter.index -= 1;
  }
};
