import { codeState } from '/js/codeState/codeState.js';
import { codeCharacter } from '/js/codeCharacter/codeCharacter.js';

export const setCodeCharacterElements = () => {
  if (codeState.A === true) {
    let arr = Array.from(document.querySelectorAll(`.codeA0 span`));
    codeCharacter.elements = arr;
  }

  if (codeState.D === true) {
    let arr = Array.from(document.querySelectorAll(`.codeA0 span`));
    arr.pop();
    codeCharacter.elements = arr;
  }
};
