import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';

export const appendCharacterC0Element = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  let theSentenceC0 = sentenceC0[theIndex];
  if (sentenceCState.A === true) {
    if (theCharacterC0.selectElements.length > 0) {
      let element = theCharacterC0.element;
      setTimeout(() => {
        theSentenceC0.element.appendChild(element);
      }, 1000);
    } else {
      theSentenceC0.element.appendChild(theCharacterC0.element);
    }
  }
};
