import { sentenceX } from '/js/sentenceX/sentenceX.js';

export const setSentenceXReClick = (event) => {
  if (event.type === 'click') {
    if (sentenceX.userData[sentenceX.SKA] !== undefined) {
      sentenceX.reClick = true;
    }
  } else {
    sentenceX.reClick = false;
  }
};
