export const sentenceA1 = {
  0: {
    element: document.querySelector('#_0_sentenceA1'),
    value: '',
  },
  1: {
    element: document.querySelector('#_1_sentenceA1'),
    value: '',
  },
  2: {
    element: document.querySelector('#_2_sentenceA1'),
    value: '',
  },
  3: {
    element: document.querySelector('#_3_sentenceA1'),
    value: '',
  },
  4: {
    element: document.querySelector('#_4_sentenceA1'),
    value: '',
  },
  5: {
    element: document.querySelector('#_5_sentenceA1'),
    value: '',
  },
  6: {
    element: document.querySelector('#_6_sentenceA1'),
    value: '',
  },
  7: {
    element: document.querySelector('#_7_sentenceA1'),
    value: '',
  },
  8: {
    element: document.querySelector('#_8_sentenceA1'),
    value: '',
  },
  9: {
    element: document.querySelector('#_9_sentenceA1'),
    value: '',
  },
};
