import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceA } from '/js/sentenceA/sentenceA.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setSentenceXInputIndex = (event) => {
  let theIndex = parseInt(event.target.id[1]);

  if (sentenceXState.C === true || sentenceXState.T === true) {
    if (sentenceX.selectIndex !== undefined) {
      sentenceX.inputIndex = undefined;
    } else {
      for (const key in sentenceA) {
        if (sentenceA[key].display === 'flex') {
          sentenceX.inputIndex = parseInt(key);
        }
      }
    }
  }

  if (
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true
  ) {
    if (sentenceX.selectIndex == undefined) {
      sentenceX.inputIndex = theIndex;
    } else {
      sentenceX.inputIndex = undefined;
    }
  }

  if (
    characterAState.C === true ||
    characterBState.C === true ||
    characterCState.C === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true
  ) {
    if (sentenceX.selectIndex !== undefined) {
      sentenceX.inputIndex = undefined;
    }

    if (sentenceX.selectIndex === undefined) {
      sentenceX.inputIndex = theIndex;
    }
  }

  if (
    sentenceXState.W_U === true ||
    sentenceAState.W_U === true ||
    sentenceBState.W_U === true ||
    sentenceCState.W_U === true ||
    blankAState.W_U === true ||
    blankBState.W_U === true ||
    blankCState.W_U === true ||
    characterAState.W_U === true ||
    characterBState.W_U === true ||
    characterCState.W_U === true ||
    sentenceXState.T_U === true ||
    sentenceAState.T_U === true ||
    sentenceBState.T_U === true ||
    sentenceCState.T_U === true ||
    blankAState.T_U === true ||
    blankBState.T_U === true ||
    blankCState.T_U === true ||
    characterAState.T_U === true ||
    characterBState.T_U === true ||
    characterCState.T_U === true
  ) {
    if (sentenceX.inputIndex !== undefined) {
      sentenceX.inputIndex += 1;
    }
  }

  if (
    sentenceAState.W_D === true ||
    sentenceBState.W_D === true ||
    sentenceCState.W_D === true ||
    blankAState.W_D === true ||
    blankBState.W_D === true ||
    blankCState.W_D === true ||
    characterAState.W_D === true ||
    characterBState.W_D === true ||
    characterCState.W_D === true
  ) {
    if (sentenceX.inputIndex !== undefined) {
      sentenceX.inputIndex = theIndex;
    } else {
      sentenceX.inputIndex = undefined;
    }
  }

  if (
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true
  ) {
    sentenceX.inputIndex = undefined;
  }

  // if (
  //   sentenceXState.T_L_SKA_G1 === true ||
  //   sentenceAState.T_L_SKA_G1 === true ||
  //   sentenceBState.T_L_SKA_G1 === true ||
  //   sentenceCState.T_L_SKA_G1 === true ||
  //   blankAState.T_L_SKA_G1 === true ||
  //   blankBState.T_L_SKA_G1 === true ||
  //   blankCState.T_L_SKA_G1 === true ||
  //   characterAState.T_L_SKA_G1 === true ||
  //   characterBState.T_L_SKA_G1 === true ||
  //   characterCState.T_L_SKA_G1 === true
  // ) {
  //   if (device.target === 'Mobile') {
  //     sentenceX.inputIndex = undefined;
  //   }
  // }
};
