import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { setCharacterB0CompleteValues } from '/js/characterB0/setCharacterB0CompleteValues.js';
import { setCharacterB0CompleteElements } from '/js/characterB0/setCharacterB0CompleteElements.js';
import { setCharacterB0CompleteElementsOpacity } from '/js/characterB0/setCharacterB0CompleteElementsOpacity.js';

import { setCharacterB0Elements } from '/js/characterB0/setCharacterB0Elements.js';
import { setCharacterB0ElementsId } from '/js/characterB0/setCharacterB0ElementsId.js';
import { setSentenceB0Value } from '/js/sentenceB0/setSentenceB0Value.js';
import { setSentenceB0SplitValues } from '/js/sentenceB0/setSentenceB0SplitValues.js';
import { setSentenceB0SplitCounts } from '/js/sentenceB0/setSentenceB0SplitCounts.js';
import { setSentenceB0SplitInitials } from '/js/sentenceB0/setSentenceB0SplitInitials.js';
import { setCharacterB0ElementsClassName } from '/js/characterB0/setCharacterB0ElementsClassName.js';
import { setCharacterB0LastElement } from '/js/characterB0/setCharacterB0LastElement.js';
import { setCharacterB0PreviousElements } from '/js/characterB0/setCharacterB0PreviousElements.js';
import { setCharacterB0PreviousElementsOpacity } from '/js/characterB0/setCharacterB0PreviousElementsOpacity.js';
import { setSentenceXPKA } from '/js/sentenceX//setSentenceXPKA.js';
import { setSentenceXSKA } from '/js/sentenceX//setSentenceXSKA.js';
import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { setSentenceB1ElementValue } from '/js/sentenceB1/setSentenceB1ElementValue.js';

export const sentenceBStream_A_C = (event) => {
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterB0CompleteValues(event);
  setCharacterB0CompleteElements();
  setCharacterB0CompleteElementsOpacity();
  setCharacterB0Elements();
  setCharacterB0ElementsId();
  setSentenceB0Value();
  setSentenceB0SplitValues();
  setSentenceB0SplitCounts();
  setSentenceB0SplitInitials();
  setCharacterB0ElementsClassName();
  setCharacterB0LastElement();
  setCharacterB0PreviousElements();
  setCharacterB0PreviousElementsOpacity();
  setSentenceXPKA();
  setSentenceXSKA(event);
  // // setCharacterA0ElementsOpacity(event);
  // // setCharacterB0ElementsOpacity(event);
  // // setCharacterC0ElementsOpacity(event);
  setSentenceB1ElementValue();
};
