import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { setSentenceARunTime } from '/js/sentenceA/setSentenceARunTime.js';
import { setSentenceBRunTime } from '/js/sentenceB/setSentenceBRunTime.js';
import { setSentenceCRunTime } from '/js/sentenceC/setSentenceCRunTime.js';
import { setCharacterA0RunTime } from '/js/characterA0/setCharacterA0RunTime.js';
import { setCharacterB0RunTime } from '/js/characterB0/setCharacterB0RunTime.js';
import { setCharacterC0RunTime } from '/js/characterC0/setCharacterC0RunTime.js';
import { setCharacterA2RunTime } from '/js/characterA2/setCharacterA2RunTime.js';
import { setCharacterB2RunTime } from '/js/characterB2/setCharacterB2RunTime.js';
import { setCharacterC2RunTime } from '/js/characterC2/setCharacterC2RunTime.js';
import { presetSentenceA0Value } from '/js/sentenceA0/presetSentenceA0Value.js';
import { presetSentenceB0Value } from '/js/sentenceB0/presetSentenceB0Value.js';
import { presetSentenceC0Value } from '/js/sentenceC0/presetSentenceC0Value.js';
import { setSentenceXVK } from '/js/sentenceX/setSentenceXVK.js';
import { setSentenceXPKA } from '/js/sentenceX/setSentenceXPKA.js';
import { setSentenceXSKA } from '/js/sentenceX/setSentenceXSKA.js';
import { setSentenceXPKC } from '/js/sentenceX/setSentenceXPKC.js';
import { setSentenceXSKC } from '/js/sentenceX/setSentenceXSKC.js';
import { resetSentenceXState } from '/js/sentenceXState/resetSentenceXState.js';
import { resetSentenceAState } from '/js/sentenceAState/resetSentenceAState.js';
import { resetSentenceBState } from '/js/sentenceBState/resetSentenceBState.js';
import { resetSentenceCState } from '/js/sentenceCState/resetSentenceCState.js';
import { resetBlankAState } from '/js/blankAState/resetBlankAState.js';
import { resetBlankBState } from '/js/blankBState/resetBlankBState.js';
import { resetBlankCState } from '/js/blankCState/resetBlankCState.js';
import { setBlankCState } from '/js/blankCState/setBlankCState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { blankCStream_W_U } from '/js/blankCStream/blankCStream_W_U.js';
import { blankCStream_W_D } from '/js/blankCStream/blankCStream_W_D.js';
import { blankCStream_W_L_VK_G } from '/js/blankCStream/blankCStream_W_L_VK_G.js';
import { blankCStream_W_L_VK } from '/js/blankCStream/blankCStream_W_L_VK.js';
import { blankCStream_W_R_VK } from '/js/blankCStream/blankCStream_W_R_VK.js';
import { blankCStream_W_L_SKA_G1 } from '/js/blankCStream/blankCStream_W_L_SKA_G1.js';
import { blankCStream_W_L_SKA_G2 } from '/js/blankCStream/blankCStream_W_L_SKA_G2.js';
import { blankCStream_W_L_SKA } from '/js/blankCStream/blankCStream_W_L_SKA.js';
import { blankCStream_W_R_SKA } from '/js/blankCStream/blankCStream_W_R_SKA.js';
import { blankCStream_W_L_SKC_G } from '/js/blankCStream/blankCStream_W_L_SKC_G.js';
import { blankCStream_W_L_SKC } from '/js/blankCStream/blankCStream_W_L_SKC.js';
import { blankCStream_W_R_SKC } from '/js/blankCStream/blankCStream_W_R_SKC.js';
export const handleBlankC0ElementWheel = (event) => {
  event.preventDefault();
  event.stopPropagation();
  if (sentenceX.canWheel === true) {
    setSentenceARunTime();
    setSentenceBRunTime();
    setSentenceCRunTime();
    setCharacterA0RunTime();
    setCharacterB0RunTime();
    setCharacterC0RunTime();
    setCharacterA2RunTime();
    setCharacterB2RunTime();
    setCharacterC2RunTime();
    presetSentenceA0Value(event);
    presetSentenceB0Value(event);
    presetSentenceC0Value(event);
    setSentenceXVK(event);
    setSentenceXPKA();
    setSentenceXPKC();
    setSentenceXSKA(event);
    setSentenceXSKC(event);
    resetSentenceXState(event);
    resetSentenceAState(event);
    resetSentenceBState(event);
    resetSentenceCState(event);
    resetBlankAState(event);
    resetBlankBState(event);
    resetBlankCState(event);
    setBlankCState(event);
    if (blankCState.W_U === true) {
      blankCStream_W_U(event);
    }
    if (blankCState.W_D === true) {
      blankCStream_W_D(event);
    }
    if (blankCState.W_L_VA_G === true) {
      blankCStream_W_L_VK_G(event);
    }
    if (blankCState.W_L_VK === true) {
      blankCStream_W_L_VK(event);
    }
    if (blankCState.W_R_VK === true) {
      blankCStream_W_R_VK(event);
    }
    if (blankCState.W_L_SKA_G1 === true) {
      blankCStream_W_L_SKA_G1(event);
    }
    if (blankCState.W_L_SKA_G2 === true) {
      blankCStream_W_L_SKA_G2(event);
    }
    if (blankCState.W_L_SKA === true) {
      blankCStream_W_L_SKA(event);
    }
    if (blankCState.W_R_SKA === true) {
      blankCStream_W_R_SKA(event);
    }
    if (blankCState.W_L_SKC_G === true) {
      blankCStream_W_L_SKC_G(event);
    }
    if (blankCState.W_L_SKC === true) {
      blankCStream_W_L_SKC(event);
    }
    if (blankCState.W_R_SKC === true) {
      blankCStream_W_R_SKC(event);
    }
  }
};
