import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterAState } from '/js/characterAState/characterAState.js';
export const presetCharacterA0Index = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  // if (characterAState.C === true) {
  //   if (event.inputType === 'insertText' && event.data !== ' ') {
  //     theCharacterA0.index = theCharacterA0.selectInitial - 1;
  //   } else if (
  //     (event.inputType === 'insertText' && event.data === ' ') ||
  //     event.inputType === 'deleteContentBackward'
  //   ) {
  //     theCharacterA0.index = theCharacterA0.selectEnd;
  //   }
  // }

  if (event.data !== null) {
    theCharacterA0.index = theCharacterA0.values.length - 1;
  }
};
