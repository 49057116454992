import { agent } from '/js/agent/agent.js';
export const detectAgent = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.includes('Edg')) {
    agent.target = 'Microsoft Edge';
  } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
    agent.target = 'Google Chrome';
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    agent.target = 'Safari';
  } else if (userAgent.includes('Firefox')) {
    agent.target = 'Firefox';
  } else {
    agent.target = 'unKnown';
  }
};
