export const characterBState = {
  C: false,
  T: false,
  W_U: false,
  W_D: false,
  W_L_SKA: false,
  W_R_SKA: false,
  W_L_SKA_G1: false,
  W_L_SKA_G2: false,
};
