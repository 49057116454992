import { setUserNameA0CharacterData } from '/js/userNameA0Character/setUserNameA0CharacterData.js';
import { setUserNameState } from '/js/userNameState/setUserNameState.js';
import { userNameState } from '/js/userNameState/userNameState.js';
import { userNameStream_A } from '/js/userNameStream/userNameStream_A.js';
import { userNameStream_D } from '/js/userNameStream/userNameStream_D.js';

export const handleUserNameA1ElementInput = (event) => {
  setUserNameA0CharacterData(event);
  setUserNameState(event);
  if (userNameState.A === true) {
    userNameStream_A(event);
  }
  if (userNameState.D === true) {
    userNameStream_D(event);
  }
};
