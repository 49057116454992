import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';

export const setCharacterA0Data = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (event.type === 'input') {
    if (
      event.inputType === 'insertText' ||
      event.inputType === 'insertReplacementText'
    ) {
      theCharacterA0.data = event.data.replace(/[^a-z0-9 '\-]/gi, '');
    }
  }
};
