import { setCodeCharacterValue } from '/js/codeCharacter/setCodeCharacterValue.js';
import { setCodeCharacterValues } from '/js/codeCharacter/setCodeCharacterValues.js';
import { setCodeCharacterIndex } from '/js/codeCharacter/setCodeCharacterIndex.js';
import { setCodeCharacterElement } from '/js/codeCharacter/setCodeCharacterElement.js';
import { setCodeCharacterElementInnerHTML } from '/js/codeCharacter/setCodeCharacterElementInnerHTML.js';
import { setCodeCharacterElementClassName } from '/js/codeCharacter/setCodeCharacterElementClassName.js';
import { addCodeCharacterElementEventListener } from '/js/codeCharacter/addCodeCharacterElementEventListener.js';
import { appendCodeCharacterElementToDiv } from '/js/codeCharacter/appendCodeCharacterElementToDiv.js';
import { setCodeCharacterElementOpacity } from '/js/codeCharacter/setCodeCharacterElementOpacity.js';
import { setCodeCharacterElements } from '/js/codeCharacter/setCodeCharacterElements.js';
import { setCodeCharacterElementsId } from '/js/codeCharacter/setCodeCharacterElementsId.js';
import { setCodeValue } from '/js/Code/setCodeValue.js';

export const codeStream_A = (event) => {
  setCodeCharacterValue(event);
  setCodeCharacterValues(event);
  setCodeCharacterIndex(event);
  setCodeCharacterElement();
  setCodeCharacterElementInnerHTML(event);
  setCodeCharacterElementClassName(event);
  addCodeCharacterElementEventListener(event);
  appendCodeCharacterElementToDiv(event);
  setCodeCharacterElementOpacity(event);
  setCodeCharacterElements(event);
  setCodeCharacterElementsId(event);
  setCodeValue();
};
