import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
export const insertCharacterB0LastElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theSentenceB0 = sentenceB0[theIndex];
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceBState.R_I === true) {
    let element = theCharacterB0.lastElement;
    setTimeout(() => {
      theSentenceB0.element.insertBefore(
        element,
        theCharacterB0.referenceElement
      );
    }, 1000);
  }
};
