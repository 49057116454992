import { passWordState } from '/js/passWordState/passWordState.js';
import { userNameState } from '/js/userNameState/userNameState.js';
import { message } from '/js/message/message.js';

export const killMessageElementTimeline = () => {
  if (userNameState.C === true || passWordState.C === true) {
    if (message.elementTimeline !== undefined) {
      message.elementTimeline.kill();
    }
  }
};
