import { userNameState } from '/js/userNameState/userNameState.js';
import { passWordState } from '/js/passWordState/passWordState.js';
import { logInState } from '/js/logInState/logInState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';

export const killPassWordA0CharacterElementsTimeline = () => {
  if (
    userNameState.C === true ||
    passWordState.C === true ||
    logInState.C === true
  ) {
    if (passWordA0Character.elementsTimeline !== undefined) {
      passWordA0Character.elementsTimeline.kill();
    }
  }
};
