import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { sentence } from '/js/sentence/sentence.js';
import { authentication } from '/js/authentication/authentication.js';

export const setAuthenticationElementDisplay = () => {
  if (sentenceXState.L === true) {
    if (sentence[0].value === '') {
      authentication.element.style.display = 'flex';
    } else {
      setTimeout(() => {
        authentication.element.style.display = 'flex';
      }, 1000);
    }
  }

  if (logInState.V === true || confirmState.V === true) {
    setTimeout(() => {
      authentication.element.style.display = 'none';
    }, 1000);
  }
};
