import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setCharacterC0SelectElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (characterCState.C === true || characterCState.T === true) {
    if (sentenceX.selectIndex !== undefined) {
      let timeline = gsap.timeline();
      timeline.to(theCharacterC0.selectElements, {
        opacity: 1,
        duration: 3,
      });
      theCharacterC0.selectElementsTimeline = timeline;
    }
    if (sentenceX.inputIndex !== undefined) {
      let timeline = gsap.timeline();
      let elements = theCharacterC0.selectElements.slice(0, -1);
      timeline.to(elements, {
        opacity: 0.4,
        duration: 1,
      });
      theCharacterC0.selectElementsTimeline = timeline;
    }
  }

  if (
    sentenceCState.R_A === true ||
    sentenceCState.R_I === true ||
    sentenceCState.R_A_C === true ||
    sentenceCState.R_I_C === true
  ) {
    let timeline = gsap.timeline();
    timeline.to(theCharacterC0.selectElements, {
      opacity: 0,
      duration: 1,
    });
  }
};
