import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setCharacterB0LastElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  let theCharacterC0 = characterC0[theIndex];

  if (
    sentenceBState.A_C === true ||
    sentenceBState.R_A_C === true ||
    sentenceBState.R_I_C === true
  ) {
    theCharacterB0.lastElement =
      theCharacterB0.completeElements[
        theCharacterB0.completeElements.length - 1
      ];
  }

  if (sentenceBState.R_A === true || sentenceBState.R_I === true) {
    theCharacterB0.lastElement = document.createElement('span');
  }

  if (
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true
  ) {
    if (sentenceX.inputIndex !== undefined) {
      for (const key in characterB0) {
        characterB0[key].lastElement = undefined;
      }
      if (theCharacterC0.elements.length === 0) {
        theCharacterB0.lastElement =
          theCharacterB0.elements[theCharacterB0.elements.length - 1];
      }
    } else if (sentenceX.selectIndex !== undefined) {
      for (const key in characterB0) {
        characterB0[key].lastElement = undefined;
      }
    }
  }

  if (
    sentenceXState.W_U === true ||
    sentenceAState.W_U === true ||
    sentenceBState.W_U === true ||
    sentenceCState.W_U === true ||
    blankAState.W_U === true ||
    blankBState.W_U === true ||
    blankCState.W_U === true ||
    characterAState.W_U === true ||
    characterBState.W_U === true ||
    characterCState.W_U === true ||
    sentenceXState.T_U === true ||
    sentenceAState.T_U === true ||
    sentenceBState.T_U === true ||
    sentenceCState.T_U === true ||
    blankAState.T_U === true ||
    blankBState.T_U === true ||
    blankCState.T_U === true ||
    characterAState.T_U === true ||
    characterBState.T_U === true ||
    characterCState.T_U === true
  ) {
    if (sentenceX.inputIndex !== undefined) {
      for (const key in characterB0) {
        characterB0[key].lastElement = undefined;
      }
      if (characterC0[theIndex + 1].elements.length === 0) {
        characterB0[theIndex + 1].lastElement =
          characterB0[theIndex + 1].elements[
            characterB0[theIndex + 1].elements.length - 1
          ];
      }
    } else if (sentenceX.selectIndex !== undefined) {
      for (const key in characterB0) {
        characterB0[key].lastElement = undefined;
      }
    }
  }
};
