import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { characterA0 } from '/js/characterA0/characterA0.js';

export const setCharacterA0NextElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (
    sentenceAState.R_I === true ||
    sentenceAState.R_I_C === true ||
    sentenceAState.D === true
  ) {
    if (characterA0[theIndex].nextElements.length > 0) {
      gsap.to(characterA0[theIndex].nextElements, {
        opacity: 0,
        duration: 1,
      });
    }
  }
};
