import { gsap } from 'gsap';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { sentenceA2 } from '/js/sentenceA2/sentenceA2.js';
import { characterA0 } from '/js/characterA0/characterA0.js';

export const setCharacterA0DifferElementsOpacity = () => {
  if (
    sentenceXState.W_L_VK_G === true ||
    sentenceAState.W_L_VK_G === true ||
    sentenceBState.W_L_VK_G === true ||
    sentenceCState.W_L_VK_G === true ||
    sentenceXState.T_L_VK_G === true ||
    sentenceAState.T_L_VK_G === true ||
    sentenceBState.T_L_VK_G === true ||
    sentenceCState.T_L_VK_G === true ||
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.W_L_VK === true ||
    sentenceAState.W_L_VK === true ||
    sentenceBState.W_L_VK === true ||
    sentenceCState.W_L_VK === true ||
    sentenceXState.T_L_VK === true ||
    sentenceAState.T_L_VK === true ||
    sentenceBState.T_L_VK === true ||
    sentenceCState.T_L_VK === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true ||
    sentenceXState.W_R_VK === true ||
    sentenceAState.W_R_VK === true ||
    sentenceBState.W_R_VK === true ||
    sentenceCState.W_R_VK === true ||
    sentenceXState.T_R_VK === true ||
    sentenceAState.T_R_VK === true ||
    sentenceBState.T_R_VK === true ||
    sentenceCState.T_R_VK === true ||
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true ||
    blankAState.W_R_SKA === true ||
    blankBState.W_R_SKA === true ||
    blankCState.W_R_SKA === true ||
    characterAState.W_R_SKA === true ||
    characterBState.W_R_SKA === true ||
    characterCState.W_R_SKA === true ||
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true ||
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    blankAState.W_R_SKC === true ||
    blankBState.W_R_SKC === true ||
    blankCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true ||
    blankAState.T_R_SKC === true ||
    blankBState.T_R_SKC === true ||
    blankCState.T_R_SKC === true
  ) {
    for (const key in characterA0) {
      if (characterA0[key].differElements.length > 0) {
        if (characterA0[key].differClassNames.length === 1) {
          let elements = Array.from(
            document.getElementsByClassName(
              `${characterA0[key].differClassNames[0]}`
            )
          );
          elements.reverse();
          gsap.to(elements, {
            opacity: 0,
            duration: 1,
          });
          characterA0[key].runTime += 1;
        } else if (characterA0[key].differClassNames.length > 1) {
          let arrA = [];
          for (let i = 0; i < characterA0[key].differClassNames.length; i++) {
            let numberA = Math.random();
            let elements = Array.from(
              document.getElementsByClassName(
                `${characterA0[key].differClassNames[i]}`
              )
            );
            arrA.push(numberA);
            elements.reverse();
            gsap.to(elements, {
              opacity: 0,
              duration: 1,
              delay: numberA,
            });
          }
          let numberB = Math.max(...arrA);
          characterA0[key].runTime += 1;
          characterA0[key].runTime += numberB;
        }
      }
    }

    // let number = 0;
    // for (const key in sentenceA2) {
    //   if (sentenceA2[key].value !== '') {
    //     number = parseInt(key);
    //   }
    // }

    // for (const key in characterA0) {
    //   if (characterA0[key].differElements.length > 0) {
    //     if (characterA0[key].differClassNames.length === 1) {
    //       let elements = Array.from(
    //         document.getElementsByClassName(
    //           `${characterA0[key].differClassNames}`
    //         )
    //       );
    //       elements.reverse();
    //       gsap.to(elements, {
    //         opacity: 0,
    //         duration: 1,
    //       });
    //       characterA0[key].runTime += 1;
    //     } else if (characterA0[key].differClassNames.length > 1) {
    //       if (key == number + 1) {
    //         for (let i = 0; i < characterA0[key].differClassNames.length; i++) {
    //           let elements = Array.from(
    //             document.getElementsByClassName(
    //               `${characterA0[key].differClassNames[i]}`
    //             )
    //           );
    //           elements.reverse();
    //           gsap.to(elements, {
    //             opacity: 0,
    //             duration: 1,
    //           });
    //         }
    //         characterA0[key].runTime += 1;
    //       } else {
    //         let arrA = [];
    //         for (let i = 0; i < characterA0[key].differClassNames.length; i++) {
    //           let numberA = Math.random();
    //           let elements = Array.from(
    //             document.getElementsByClassName(
    //               `${characterA0[key].differClassNames[i]}`
    //             )
    //           );
    //           arrA.push(numberA);
    //           elements.reverse();
    //           gsap.to(elements, {
    //             opacity: 0,
    //             duration: 1,
    //             delay: numberA,
    //           });
    //         }
    //         let numberB = Math.max(...arrA);
    //         characterA0[key].runTime += 1;
    //         characterA0[key].runTime += numberB;
    //       }
    //     }
    //   }
    // }
  }
};
