export const blankB0 = {
  0: {
    element: document.querySelector('#_0_blankB0'),
    height: '6vh',
  },
  1: {
    element: document.querySelector('#_1_blankB0'),
    height: '6vh',
  },
  2: {
    element: document.querySelector('#_2_blankB0'),
    height: '6vh',
  },
  3: {
    element: document.querySelector('#_3_blankB0'),
    height: '6vh',
  },
  4: {
    element: document.querySelector('#_4_blankB0'),
    height: '6vh',
  },
  5: {
    element: document.querySelector('#_5_blankB0'),
    height: '6vh',
  },
  6: {
    element: document.querySelector('#_6_blankB0'),
    height: '6vh',
  },
  7: {
    element: document.querySelector('#_7_blankB0'),
    height: '6vh',
  },
  8: {
    element: document.querySelector('#_8_blankB0'),
    height: '6vh',
  },
  9: {
    element: document.querySelector('#_9_blankB0'),
    height: '6vh',
  },
};
