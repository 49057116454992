import { blankC0 } from '/js/blankC0/blankC0.js';
import { handleBlankC0ElementClick } from '/js/blankC0/handleBlankC0ElementClick.js';
import { handleBlankC0ElementWheel } from '/js/blankC0/handleBlankC0ElementWheel.js';
import { handleBlankC0ElementTouchStart } from '/js/blankC0/handleBlankC0ElementTouchStart.js';
import { handleBlankC0ElementTouchEnd } from '/js/blankC0/handleBlankC0ElementTouchEnd.js';

export const addBlankC0ElementEventListener = () => {
  for (const key in blankC0) {
    blankC0[key].element.addEventListener(
      'click',
      handleBlankC0ElementClick
    );
    blankC0[key].element.addEventListener(
      'wheel',
      handleBlankC0ElementWheel
    );
    blankC0[key].element.addEventListener(
      'touchstart',
      handleBlankC0ElementTouchStart
    );
    blankC0[key].element.addEventListener(
      'touchend',
      handleBlankC0ElementTouchEnd
    );
  }
};
