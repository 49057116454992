import { setUserNameState } from '/js/userNameState/setUserNameState.js';
import { setPassWordState } from '/js/passWordState/setPassWordState.js';
import { setLogInState } from '/js/logInState/setLogInState.js';
import { userNameState } from '/js/userNameState/userNameState.js';
import { userNameStream_C } from '/js/userNameStream/userNameStream_C.js';

export const handleUserNameA0ElementClick = (event) => {
  setUserNameState(event);
  setPassWordState(event);
  setLogInState(event);
  if (userNameState.C === true) {
    userNameStream_C(event);
  }
};
