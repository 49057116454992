import { passWordState } from '/js/passWordState/passWordState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';

export const setPassWordA0CharacterValues = () => {
  if (passWordState.A === true) {
    passWordA0Character.values.splice(
      passWordA0Character.index + 1,
      0,
      passWordA0Character.data
    );
  }

  if (passWordState.D === true) {
    passWordA0Character.values.splice(passWordA0Character.index, 1);
  }
};
