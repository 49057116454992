import { logInState } from '/js/logInState/logInState.js';

export const resetLogInState = (data) => {
  if (data.UserConfirmed === false) {
    logInState.UV = true;
  }
  if (data.Username !== undefined) {
    logInState.V = true;
  }
  if (data.body !== undefined) {
    if (JSON.parse(data.body) === 'Incorrect username or password.') {
      logInState.I = true;
    }
    if (JSON.parse(data.body) === 'User is not confirmed.') {
      logInState.RV = true;
    }
  }
  console.log(logInState);
};
