import { codeState } from '/js/codeState/codeState.js';

export const setCodeState = (event) => {
  if (
    event.type === 'input' &&
    event.inputType === 'insertText' &&
    event.target.className === 'codeA1'
  ) {
    codeState.A = true;
  } else {
    codeState.A = false;
  }

  if (
    event.type === 'input' &&
    event.inputType === 'deleteContentBackward' &&
    event.target.className === 'codeA1'
  ) {
    codeState.D = true;
  } else {
    codeState.D = false;
  }

  if (
    event.type === 'click' &&
    event.target.nodeName === 'DIV' &&
    event.target.className === 'codeA0'
  ) {
    codeState.C = true;
  } else {
    codeState.C = false;
  }
};
