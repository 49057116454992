import { setUserNameState } from '/js/userNameState/setUserNameState.js';
import { setPassWordState } from '/js/passWordState/setPassWordState.js';
import { passWordState } from '/js/passWordState/passWordState.js';
import { passWordStream_C } from '/js/passWordStream/passWordStream_C.js';
import { setLogInState } from '/js/logInState/setLogInState.js';

export const handlePassWordA0ElementClick = (event) => {
  setUserNameState(event);
  setPassWordState(event);
  setLogInState(event);
  if (passWordState.C === true) {
    passWordStream_C(event);
  }
};
