import { sentenceX } from "/js/sentenceX/sentenceX.js";
import { characterA0 } from "/js/characterA0/characterA0.js";
import { sentenceAState } from "/js/sentenceAState/sentenceAState.js";
import { characterAState } from "/js/characterAState/characterAState.js";

export const removeCharacterA0Element = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (sentenceAState.D === true) {
    let element = theCharacterA0.element;
    if (element !== undefined) {
      setTimeout(() => {
        element.remove();
      }, 1000);
    }
  }

  if (characterAState.C === true || characterAState.T === true) {
    if (
      theCharacterA0.elements[theCharacterA0.elements.length - 1].innerHTML ===
      "&nbsp;"
    ) {
      theCharacterA0.element.remove();
    }
  }
};
