import { gsap } from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { sentenceA } from '/js/sentenceA/sentenceA.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceA2 } from '/js/sentenceA2/sentenceA2.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterA2 } from '/js/characterA2/characterA2.js';
export const setCharacterA2DifferElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (
    sentenceXState.W_L_VK_G === true ||
    sentenceAState.W_L_VK_G === true ||
    sentenceBState.W_L_VK_G === true ||
    sentenceCState.W_L_VK_G === true ||
    sentenceXState.T_L_VK_G === true ||
    sentenceAState.T_L_VK_G === true ||
    sentenceBState.T_L_VK_G === true ||
    sentenceCState.T_L_VK_G === true ||
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.W_L_VK === true ||
    sentenceAState.W_L_VK === true ||
    sentenceBState.W_L_VK === true ||
    sentenceCState.W_L_VK === true ||
    sentenceXState.T_L_VK === true ||
    sentenceAState.T_L_VK === true ||
    sentenceBState.T_L_VK === true ||
    sentenceCState.T_L_VK === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true ||
    sentenceXState.W_R_VK === true ||
    sentenceAState.W_R_VK === true ||
    sentenceBState.W_R_VK === true ||
    sentenceCState.W_R_VK === true ||
    sentenceXState.T_R_VK === true ||
    sentenceAState.T_R_VK === true ||
    sentenceBState.T_R_VK === true ||
    sentenceCState.T_R_VK === true ||
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true ||
    blankAState.W_R_SKA === true ||
    blankBState.W_R_SKA === true ||
    blankCState.W_R_SKA === true ||
    characterAState.W_R_SKA === true ||
    characterBState.W_R_SKA === true ||
    characterCState.W_R_SKA === true ||
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true
  ) {
    let arrA = [];
    let arrB = [];
    for (const key in sentenceA2) {
      if (sentenceA2[key].value !== '') {
        arrA.push(key);
      }
    }
    const calculateTime = (paraA, paraB) => {
      let elements = [];
      if (paraB - 1 == 0) {
        paraB = 2;
      }
      let step = (paraA - 1) / (paraB - 1);

      for (let i = 0; i < paraB; i++) {
        let value = paraA - i * step;
        elements.push({ [arrA[i]]: value });
      }

      return elements;
    };
    arrB = calculateTime(sentenceA[theIndex].runTime, arrA.length);

    for (const key of Object.keys(characterA2).reverse()) {
      if (characterA2[key].elements.length > 0) {
        if (key <= theIndex) {
          if (characterA2[key].differClassNames.length === 1) {
            let elements = Array.from(
              document.getElementsByClassName(
                `${characterA2[key].differClassNames[0]}`
              )
            );
            if (key == theIndex) {
              gsap.to(elements, {
                opacity: 1,
                duration: 3,
                delay: characterA0[key].runTime,
              });
            } else {
              gsap.to(elements, {
                opacity: 0.2,
                duration: 1,
                delay: characterA0[key].runTime,
              });
            }
            if (key == theIndex) {
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 3;
            } else {
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
            }
          } else if (characterA2[key].differClassNames.length > 1) {
            let arr = [];
            for (let i = 0; i < characterA2[key].differClassNames.length; i++) {
              let numberA = Math.random();
              arr.push(numberA);
              let elements = Array.from(
                document.getElementsByClassName(
                  `${characterA2[key].differClassNames[i]}`
                )
              );
              if (key == theIndex) {
                gsap.to(elements, {
                  opacity: 1,
                  duration: 3,
                  delay: characterA0[key].runTime + numberA,
                });
              } else {
                gsap.to(elements, {
                  opacity: 0.2,
                  duration: 1,
                  delay: characterA0[key].runTime + numberA,
                });
              }
            }
            let numberB = Math.max(...arr);
            if (key == theIndex) {
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 3;
              characterA2[key].runTime += numberB;
            } else {
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
              characterA2[key].runTime += numberB;
            }
          }
        } else {
          let number = 0;
          for (const key in sentenceA0) {
            if (sentenceA0[key].value !== '') {
              number = key;
            }
          }
          if (key <= number) {
            if (characterA2[key].differClassNames.length === 1) {
              let elements = Array.from(
                document.getElementsByClassName(
                  `${characterA2[key].differClassNames[0]}`
                )
              );
              gsap.to(elements, {
                opacity: 0.2,
                duration: 1,
                delay: characterA0[key].runTime,
              });
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
            } else if (characterA2[key].differClassNames.length > 1) {
              let arr = [];
              for (
                let i = 0;
                i < characterA2[key].differClassNames.length;
                i++
              ) {
                let numberA = Math.random();
                arr.push(numberA);
                let elements = Array.from(
                  document.getElementsByClassName(
                    `${characterA2[key].differClassNames[i]}`
                  )
                );
                gsap.to(elements, {
                  opacity: 0.2,
                  duration: 1,
                  delay: numberA + characterA0[key].runTime,
                });
              }
              let numberB = Math.max(...arr);
              characterA2[key].runTime += numberB;
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
            }
          } else {
            let number = 0;
            for (const item of arrB) {
              if (key == Object.keys(item)[0]) {
                number = Object.values(item)[0];
              }
            }
            if (characterA2[key].differClassNames.length === 1) {
              let elements = Array.from(
                document.getElementsByClassName(
                  `${characterA2[key].differClassNames[0]}`
                )
              );
              gsap.to(elements, {
                opacity: 0.2,
                duration: 1,
                delay: number + characterA0[key].runTime,
              });
              characterA2[key].runTime += number;
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
            } else if (characterA2[key].differClassNames.length > 1) {
              let arr = [];
              for (
                let i = 0;
                i < characterA2[key].differClassNames.length;
                i++
              ) {
                let numberA = Math.random();
                arr.push(numberA);
                let elements = Array.from(
                  document.getElementsByClassName(
                    `${characterA2[key].differClassNames[i]}`
                  )
                );
                gsap.to(elements, {
                  opacity: 0.2,
                  duration: 1,
                  delay: number + numberA + characterA0[key].runTime,
                });
              }
              let numberB = Math.max(...arr);
              characterA2[key].runTime += number;
              characterA2[key].runTime += numberB;
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
            }
          }
        }
      }
    }
  }

  if (
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    blankAState.W_R_SKC === true ||
    blankBState.W_R_SKC === true ||
    blankCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true ||
    blankAState.T_R_SKC === true ||
    blankBState.T_R_SKC === true ||
    blankCState.T_R_SKC === true
  ) {
    let arrA = [];
    let arrB = [];
    for (const key in sentenceA2) {
      if (sentenceA2[key].value !== '') {
        arrA.push(key);
      }
    }
    const calculateTime = (paraA, paraB) => {
      let elements = [];
      if (paraB - 1 == 0) {
        paraB = 2;
      }
      let step = (paraA - 1) / (paraB - 1);

      for (let i = 0; i < paraB; i++) {
        let value = paraA - i * step;
        elements.push({ [arrA[i]]: value });
      }

      return elements;
    };
    arrB = calculateTime(sentenceA[theIndex].runTime, arrA.length);

    for (const key of Object.keys(characterA2).reverse()) {
      if (characterA2[key].elements.length > 0) {
        let number = 0;
        for (const key in sentenceA0) {
          if (sentenceA0[key].value !== '') {
            number = key;
          }
        }
        if (key < theIndex) {
          if (characterA2[key].differClassNames.length === 1) {
            let elements = Array.from(
              document.getElementsByClassName(
                `${characterA2[key].differClassNames[0]}`
              )
            );
            gsap.to(elements, {
              opacity: 0.2,
              duration: 1,
              delay: characterA0[key].runTime,
            });
            characterA2[key].runTime += characterA0[key].runTime;
            characterA2[key].runTime += 1;
          } else if (characterA2[key].differClassNames.length > 1) {
            let arr = [];
            for (let i = 0; i < characterA2[key].differClassNames.length; i++) {
              let numberA = Math.random();
              arr.push(numberA);
              let elements = Array.from(
                document.getElementsByClassName(
                  `${characterA2[key].differClassNames[i]}`
                )
              );
              gsap.to(elements, {
                opacity: 0.2,
                duration: 1,
                delay: characterA0[key].runTime + numberA,
              });
            }
            let numberB = Math.max(...arr);
            characterA2[key].runTime += characterA0[key].runTime;
            characterA2[key].runTime += 1;
            characterA2[key].runTime += numberB;
          }
        } else if (key == theIndex) {
          if (characterA2[key].differClassNames.length === 1) {
            let arrA = Array.from(
              document.getElementsByClassName(
                `${characterA2[key].differClassNames[0]}`
              )
            );
            let arrB = arrA.filter(
              (item) => !characterA2[key].selectElements.includes(item)
            );
            gsap.to(arrB, {
              opacity: 0.2,
              duration: 1,
              delay: characterA0[key].runTime,
            });
          } else if (characterA2[key].differClassNames.length > 1) {
            for (let i = 0; i < characterA2[key].differClassNames.length; i++) {
              let numberA = Math.random();
              let arrA = Array.from(
                document.getElementsByClassName(
                  `${characterA2[key].differClassNames[i]}`
                )
              );
              let arrB = arrA.filter(
                (item) => !characterA2[key].selectElements.includes(item)
              );
              if (arrB.length > 0) {
                gsap.to(arrB, {
                  opacity: 0.2,
                  duration: 1,
                  delay: characterA0[key].runTime + numberA,
                });
              }
            }
          }
        } else {
          if (key <= number) {
            if (characterA2[key].differClassNames.length === 1) {
              let elements = Array.from(
                document.getElementsByClassName(
                  `${characterA2[key].differClassNames[0]}`
                )
              );
              gsap.to(elements, {
                opacity: 0.2,
                duration: 1,
                delay: characterA0[key].runTime,
              });
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
            } else if (characterA2[key].differClassNames.length > 1) {
              let arr = [];
              for (
                let i = 0;
                i < characterA2[key].differClassNames.length;
                i++
              ) {
                let numberA = Math.random();
                arr.push(numberA);
                let elements = Array.from(
                  document.getElementsByClassName(
                    `${characterA2[key].differClassNames[i]}`
                  )
                );
                gsap.to(elements, {
                  opacity: 0.2,
                  duration: 1,
                  delay: numberA + characterA0[key].runTime,
                });
              }
              let numberB = Math.max(...arr);
              characterA2[key].runTime += numberB;
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
            }
          } else {
            let number = 0;
            for (const item of arrB) {
              if (key == Object.keys(item)[0]) {
                number = Object.values(item)[0];
              }
            }
            if (characterA2[key].differClassNames.length === 1) {
              let elements = Array.from(
                document.getElementsByClassName(
                  `${characterA2[key].differClassNames[0]}`
                )
              );
              gsap.to(elements, {
                opacity: 0.2,
                duration: 1,
                delay: number + characterA0[key].runTime,
              });
              characterA2[key].runTime += number;
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
            } else if (characterA2[key].differClassNames.length > 1) {
              let arr = [];
              for (
                let i = 0;
                i < characterA2[key].differClassNames.length;
                i++
              ) {
                let numberA = Math.random();
                arr.push(numberA);
                let elements = Array.from(
                  document.getElementsByClassName(
                    `${characterA2[key].differClassNames[i]}`
                  )
                );
                gsap.to(elements, {
                  opacity: 0.2,
                  duration: 1,
                  delay: number + numberA + characterA0[key].runTime,
                });
              }
              let numberB = Math.max(...arr);
              characterA2[key].runTime += number;
              characterA2[key].runTime += numberB;
              characterA2[key].runTime += characterA0[key].runTime;
              characterA2[key].runTime += 1;
            }
          }
        }
      }
    }
  }
};
