import { device } from '/js/device/device.js';
import { sentenceA1 } from '/js/sentenceA1/sentenceA1.js';

export const presetSentenceA1ElementFocus = () => {
  if (device.target === 'Desk') {
    setTimeout(() => {
      sentenceA1[0].element.focus();
    }, 10);
  } else if (device.target === 'Mobile') {
    setTimeout(() => {
      sentenceA1[0].element.blur();
    }, 10);
  }
};
