import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0LastElementTimeline } from '/js/characterA0/killCharacterA0LastElementTimeline.js';
import { killCharacterB0LastElementTimeline } from '/js/characterB0/killCharacterB0LastElementTimeline.js';
import { killCharacterC0LastElementTimeline } from '/js/characterC0/killCharacterC0LastElementTimeline.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';

import { setCharacterB0Values } from '/js/characterB0/setCharacterB0Values.js';
import { setCharacterB0Value } from '/js/characterB0/setCharacterB0Value.js';
import { setCharacterB0Index } from '/js/characterB0/setCharacterB0Index.js';
import { setCharacterB0Element } from '/js/characterB0/setCharacterB0Element.js';
import { setCharacterB0ElementInnerHTML } from '/js/characterB0/setCharacterB0ElementInnerHTML.js';
import { addCharacterB0ElementEventListener } from '/js/characterB0/addCharacterB0ElementEventListener.js';
import { appendCharacterB0Element } from '/js/characterB0/appendCharacterB0Element.js';
import { setCharacterB0ElementOpacity } from '/js/characterB0/setCharacterB0ElementOpacity.js';
import { setCharacterB0Elements } from '/js/characterB0/setCharacterB0Elements.js';
import { setCharacterB0ElementsId } from '/js/characterB0/setCharacterB0ElementsId.js';
import { setSentenceB0Value } from '/js/sentenceB0/setSentenceB0Value.js';
import { setSentenceB0SplitValues } from '/js/sentenceB0/setSentenceB0SplitValues.js';
import { setSentenceB0SplitCounts } from '/js/sentenceB0/setSentenceB0SplitCounts.js';
import { setSentenceB0SplitInitials } from '/js/sentenceB0/setSentenceB0SplitInitials.js';
import { setSentenceValue } from '/js/sentence/setSentenceValue.js';
import { setCharacterB0ElementsClassName } from '/js/characterB0/setCharacterB0ElementsClassName.js';
import { setCharacterB0LastElementOpacity } from '/js/characterB0/setCharacterB0LastElementOpacity.js';
import { setCharacterB0PreviousElement } from '/js/characterB0/setCharacterB0PreviousElement.js';
import { setCharacterB0PreviousElementOpacity } from '/js/characterB0/setCharacterB0PreviousElementOpacity.js';
import { setCharacterB0PreviousElements } from '/js/characterB0/setCharacterB0PreviousElements.js';
import { setCharacterB0PreviousElementsOpacity } from '/js/characterB0/setCharacterB0PreviousElementsOpacity.js';
import { setSentenceXPKA } from '/js/sentenceX/setSentenceXPKA.js';
import { setSentenceXSKA } from '/js/sentenceX/setSentenceXSKA.js';
import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';
import { setSentenceB1ElementValue } from '/js/sentenceB1/setSentenceB1ElementValue.js';

export const sentenceBStream_A = (event) => {
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0LastElementTimeline();
  killCharacterB0LastElementTimeline();
  killCharacterC0LastElementTimeline();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterB0Values();
  setCharacterB0Value();
  setCharacterB0Index();
  setCharacterB0Element();
  setCharacterB0ElementInnerHTML();
  addCharacterB0ElementEventListener();
  appendCharacterB0Element();
  setCharacterB0ElementOpacity();
  setCharacterB0Elements();
  setCharacterB0ElementsId();
  setSentenceB0Value(event);
  setSentenceB0SplitValues();
  setSentenceB0SplitCounts();
  setSentenceB0SplitInitials();
  setCharacterB0ElementsClassName();
  setCharacterB0LastElementOpacity();
  setCharacterB0PreviousElement();
  setCharacterB0PreviousElementOpacity();
  setCharacterB0PreviousElements();
  setCharacterB0PreviousElementsOpacity();
  setSentenceXPKA(event);
  setSentenceXSKA(event);
  // setCharacterA0ElementsOpacity();
  // setCharacterB0ElementsOpacity();
  // setCharacterC0ElementsOpacity();
  setSentenceB1ElementValue();
};
