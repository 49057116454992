import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { handleSentenceB0ElementClick } from '/js/sentenceB0/handleSentenceB0ElementClick.js';
import { handleSentenceB0ElementWheel } from '/js/sentenceB0/handleSentenceB0ElementWheel.js';
import { handleSentenceB0ElementTouchStart } from '/js/sentenceB0/handleSentenceB0ElementTouchStart.js';
import { handleSentenceB0ElementTouchEnd } from '/js/sentenceB0/handleSentenceB0ElementTouchEnd.js';
export const addSentenceB0ElementEventListener = () => {
  for (const key in sentenceB0) {
    sentenceB0[key].element.addEventListener(
      'click',
      handleSentenceB0ElementClick
    );
    sentenceB0[key].element.addEventListener(
      'wheel',
      handleSentenceB0ElementWheel
    );
    sentenceB0[key].element.addEventListener(
      'touchstart',
      handleSentenceB0ElementTouchStart
    );
    sentenceB0[key].element.addEventListener(
      'touchend',
      handleSentenceB0ElementTouchEnd
    );
  }
};
