import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { code } from '/js/code/code.js';

export const setCodeElementOpacity = () => {
  if (logInState.C === true || logInState.RV === true) {
    gsap.to(code.element, {
      opacity: 1,
      duration: 3,
      delay: 1,
    });
  }

  if (confirmState.C === true) {
    gsap.to(code.element, {
      opacity: 0,
      duration: 1,
    });
  }
};
