import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { resetSentenceARunTime } from '/js/sentenceA/resetSentenceARunTime.js';
import { resetSentenceBRunTime } from '/js/sentenceB/resetSentenceBRunTime.js';
import { resetSentenceCRunTime } from '/js/sentenceC/resetSentenceCRunTime.js';
import { resetCharacterA0RunTime } from '/js/characterA0/resetCharacterA0RunTime.js';
import { resetCharacterB0RunTime } from '/js/characterB0/resetCharacterB0RunTime.js';
import { resetCharacterC0RunTime } from '/js/characterC0/resetCharacterC0RunTime.js';
import { resetCharacterA2RunTime } from '/js/characterA2/resetCharacterA2RunTime.js';
import { resetCharacterB2RunTime } from '/js/characterB2/resetCharacterB2RunTime.js';
import { resetCharacterC2RunTime } from '/js/characterC2/resetCharacterC2RunTime.js';
import { presetSentenceA0Value } from '/js/sentenceA0/presetSentenceA0Value.js';
import { presetSentenceB0Value } from '/js/sentenceB0/presetSentenceB0Value.js';
import { presetSentenceC0Value } from '/js/sentenceC0/presetSentenceC0Value.js';
import { setSentenceXVK } from '/js/sentenceX/setSentenceXVK.js';
import { setSentenceXPKA } from '/js/sentenceX/setSentenceXPKA.js';
import { setSentenceXSKA } from '/js/sentenceX/setSentenceXSKA.js';
import { setSentenceXPKC } from '/js/sentenceX/setSentenceXPKC.js';
import { setSentenceXSKC } from '/js/sentenceX/setSentenceXSKC.js';
import { resetSentenceXState } from '/js/sentenceXState/resetSentenceXState.js';
import { resetSentenceAState } from '/js/sentenceAState/resetSentenceAState.js';
import { resetSentenceBState } from '/js/sentenceBState/resetSentenceBState.js';
import { resetSentenceCState } from '/js/sentenceCState/resetSentenceCState.js';
import { resetBlankAState } from '/js/blankAState/resetBlankAState.js';
import { resetBlankBState } from '/js/blankBState/resetBlankBState.js';
import { resetBlankCState } from '/js/blankCState/resetBlankCState.js';
import { resetCharacterAState } from '/js/characterAState/resetCharacterAState.js';
import { resetCharacterBState } from '/js/characterBState/resetCharacterBState.js';
import { resetCharacterCState } from '/js/characterCState/resetCharacterCState.js';
import { setCharacterCState } from '/js/characterCState/setCharacterCState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { characterCStream_W_U } from '/js/characterCStream/characterCStream_W_U.js';
import { characterCStream_W_D } from '/js/characterCStream/characterCStream_W_D.js';
import { characterCStream_W_L_VK_G } from '/js/characterCStream/characterCStream_W_L_VK_G.js';
import { characterCStream_W_L_VK } from '/js/characterCStream/characterCStream_W_L_VK.js';
import { characterCStream_W_R_VK } from '/js/characterCStream/characterCStream_W_R_VK.js';
import { characterCStream_W_L_SKA_G1 } from '/js/characterCStream/characterCStream_W_L_SKA_G1.js';
import { characterCStream_W_L_SKA_G2 } from '/js/characterCStream/characterCStream_W_L_SKA_G2.js';
import { characterCStream_W_L_SKA } from '/js/characterCStream/characterCStream_W_L_SKA.js';
import { characterCStream_W_R_SKA } from '/js/characterCStream/characterCStream_W_R_SKA.js';
import { characterCStream_W_L_SKC_G } from '/js/characterCStream/characterCStream_W_L_SKC_G.js';
import { characterCStream_W_L_SKC } from '/js/characterCStream/characterCStream_W_L_SKC.js';
import { characterCStream_W_R_SKC } from '/js/characterCStream/characterCStream_W_R_SKC.js';

export const handleCharacterC0ElementWheel = (event) => {
  event.preventDefault();
  event.stopPropagation();
  if (sentenceX.canWheel === true) {
    resetSentenceARunTime();
    resetSentenceBRunTime();
    resetSentenceCRunTime();
    resetCharacterA0RunTime();
    resetCharacterB0RunTime();
    resetCharacterC0RunTime();
    resetCharacterA2RunTime();
    resetCharacterB2RunTime();
    resetCharacterC2RunTime();
    presetSentenceA0Value(event);
    presetSentenceB0Value(event);
    presetSentenceC0Value(event);
    setSentenceXVK(event);
    setSentenceXPKA();
    setSentenceXPKC();
    setSentenceXSKA(event);
    setSentenceXSKC(event);
    resetSentenceXState(event);
    resetSentenceAState(event);
    resetSentenceBState(event);
    resetSentenceCState(event);
    resetBlankAState(event);
    resetBlankBState(event);
    resetBlankCState(event);
    setCharacterCState(event);
    resetCharacterAState(event);
    resetCharacterBState(event);
    resetCharacterCState(event);
    if (characterCState.W_U === true) {
      characterCStream_W_U(event);
    }
    if (characterCState.W_D === true) {
      characterCStream_W_D(event);
    }
    if (characterCState.W_L_VA_G === true) {
      characterCStream_W_L_VK_G(event);
    }
    if (characterCState.W_L_VK === true) {
      characterCStream_W_L_VK(event);
    }
    if (characterCState.W_R_VK === true) {
      characterCStream_W_R_VK(event);
    }
    if (characterCState.W_L_SKA_G1 === true) {
      characterCStream_W_L_SKA_G1(event);
    }
    if (characterCState.W_L_SKA_G2 === true) {
      characterCStream_W_L_SKA_G2(event);
    }
    if (characterCState.W_L_SKA === true) {
      characterCStream_W_L_SKA(event);
    }
    if (characterCState.W_R_SKA === true) {
      characterCStream_W_R_SKA(event);
    }
    if (characterCState.W_L_SKC_G === true) {
      characterCStream_W_L_SKC_G(event);
    }
    if (characterCState.W_L_SKC === true) {
      characterCStream_W_L_SKC(event);
    }
    if (characterCState.W_R_SKC === true) {
      characterCStream_W_R_SKC(event);
    }
  }
};
