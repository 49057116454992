import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setCharacterC0ReferenceElement = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];

  if (characterCState.C === true || characterCState.T === true) {
    theCharacterC0.referenceElement =
      theCharacterC0.selectElements[
        theCharacterC0.selectElements.length - 1
      ].nextSibling;
    if (theCharacterC0.referenceElement === null) {
      theCharacterC0.referenceElement = undefined;
    }
  }
  if (sentenceCState.R_I === true || sentenceCState.R_I_C === true) {
    setTimeout(() => {
      theCharacterC0.referenceElement = undefined;
    }, 1000);
  }

  if (
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    theCharacterC0.referenceElement = undefined;
  }
};
