import gsap from 'gsap';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceA } from '/js/sentenceA/sentenceA.js';

export const setSentenceA0UnSelectElementsOpacity = () => {
  if (sentenceXState.P === true || sentenceXState.D === true) {
  }
  if (
    sentenceXState.W === true ||
    sentenceAState.W === true ||
    sentenceBState.W === true ||
    sentenceCState.W === true ||
    blankAState.W === true ||
    blankBState.W === true ||
    blankCState.W === true ||
    sentenceXState.T === true ||
    sentenceAState.T === true ||
    sentenceBState.T === true ||
    sentenceCState.T === true ||
    blankAState.T === true ||
    blankBState.T === true ||
    blankCState.T === true ||
    sentenceXState.C === true ||
    sentenceAState.C === true ||
    sentenceBState.C === true ||
    sentenceCState.C === true ||
    blankAState.C === true ||
    blankBState.C === true ||
    blankCState.C === true
  ) {
    for (const key in sentenceA) {
      if (sentenceA[key].display === 'flex') {
        if (sentenceA0[key].unSelectElements.length > 0) {
          let timeline = gsap.timeline();
          timeline.to(sentenceA0[key].unSelectElements, {
            opacity: 0.2,
            duration: 1,
          });
          sentenceA0[key].unSelectElementsTimeline = timeline;
        }
      }
    }
  }
};
