import { gsap } from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const setCharacterB0FromAElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  let theCharacterB0 = characterB0[theIndex];
  if (sentenceAState.A_ATB === true) {
    if (theCharacterA0.selectElements.length > 0) {
      setTimeout(() => {
        for (const item of theCharacterB0.fromAElements) {
          let timeline = gsap.timeline();
          timeline.to(item, {
            opacity: 1,
            duration: 3,
          });
          theCharacterB0.elementTimelines.push(timeline);
        }
      }, 1000);
    } else {
      for (const item of theCharacterB0.fromAElements) {
        let timeline = gsap.timeline();
        timeline.to(item, {
          opacity: 1,
          duration: 3,
        });
        theCharacterB0.elementTimelines.push(timeline);
      }
    }
  }
};
