import { userNameState } from '/js/userNameState/userNameState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';

export const setUserNameA0CharacterElement = () => {
  if (userNameState.A === true) {
    userNameA0Character.element = document.createElement('span');
  }

  if (userNameState.D === true) {
    userNameA0Character.element = document.querySelector(
      `#userNameA0Character_${userNameA0Character.index}`
    );
  }
};
