import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const setCharacterA0CompleteValues = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];

  if (sentenceAState.A_C === true) {
    let arr = [];
    let index = theCharacterA0.elements.length;
    console.log(index);
    console.log(theCharacterA0.elements);
    console.log(theCharacterA0.values);
    for (let i = index; i < theCharacterA0.values.length; i++) {
      arr.push(theCharacterA0.values[i]);
    }
    theCharacterA0.completeValues = arr;
    console.log(theCharacterA0.completeValues);
  }

  if (sentenceAState.R_A_C === true || sentenceAState.R_I_C === true) {
    let arr = [];
    for (
      let i = theCharacterA0.selectInitial;
      i < theCharacterA0.values.length;
      i++
    ) {
      arr.push(theCharacterA0.values[i]);
    }
    theCharacterA0.completeValues = arr;
  }
};
