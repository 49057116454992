import { detectDevice } from '/js/device/detectDevice.js';
import { detectAgent } from '/js/agent/detectAgent.js';
import { addSentenceXElementEventListener } from '/js/sentenceX/addSentenceXElementEventListener.js';
import { addSentenceA0ElementEventListener } from '/js/sentenceA0/addSentenceA0ElementEventListener.js';
import { addSentenceA1ElementEventListener } from '/js/sentenceA1/addSentenceA1ElementEventListener.js';
import { addSentenceB0ElementEventListener } from '/js/sentenceB0/addSentenceB0ElementEventListener.js';
import { addSentenceB1ElementEventListener } from '/js/sentenceB1/addSentenceB1ElementEventListener.js';
import { addSentenceC0ElementEventListener } from '/js/sentenceC0/addSentenceC0ElementEventListener.js';
import { addSentenceC1ElementEventListener } from '/js/sentenceC1/addSentenceC1ElementEventListener.js';
import { addBlankA0ElementEventListener } from '/js/blankA0/addBlankA0ElementEventListener.js';
import { addBlankB0ElementEventListener } from '/js/blankB0/addBlankB0ElementEventListener.js';
import { addBlankC0ElementEventListener } from '/js/blankC0/addBlankC0ElementEventListener.js';
import { addUserNameA0ElementEventListener } from '/js/userNameA0/addUserNameA0ElementEventListener.js';
import { addUserNameA1ElementEventListener } from '/js/userNameA1/addUserNameA1ElementEventListener.js';
import { addPassWordA0ElementEventListener } from '/js/passWordA0/addPassWordA0ElementEventListener.js';
import { addPassWordA1ElementEventListener } from '/js/passWordA1/addPassWordA1ElementEventListener.js';
import { addLogInElementEventListener } from '/js/logIn/addLogInElementEventListener.js';
import { addCodeA0ElementEventListener } from '/js/codeA0/addCodeA0ElementEventListener.js';
import { addCodeA1ElementEventListener } from '/js/codeA1/addCodeA1ElementEventListener.js';
import { addConfirmElementEventListener } from '/js/confirm/addConfirmElementEventListener.js';
import { presetSentenceAElementTop } from '/js/sentenceA/presetSentenceAElementTop.js';
import { presetSentenceBElementTop } from '/js/sentenceB/presetSentenceBElementTop.js';
import { presetSentenceCElementTop } from '/js/sentenceC/presetSentenceCElementTop.js';
import { presetSentenceXInputIndex } from '/js/sentenceX/presetSentenceXInputIndex.js';
import { presetSentenceXSelectIndex } from '/js/sentenceX/presetSentenceXSelectIndex.js';
import { presetSentenceA1ElementFocus } from '/js/sentenceA1/presetSentenceA1ElementFocus.js';

detectDevice();
detectAgent();
addSentenceXElementEventListener();
addSentenceA0ElementEventListener();
addSentenceA1ElementEventListener();
addSentenceB0ElementEventListener();
addSentenceB1ElementEventListener();
addSentenceC0ElementEventListener();
addSentenceC1ElementEventListener();
addBlankA0ElementEventListener();
addBlankB0ElementEventListener();
addBlankC0ElementEventListener();
addUserNameA0ElementEventListener();
addUserNameA1ElementEventListener();
addPassWordA0ElementEventListener();
addPassWordA1ElementEventListener();
addCodeA0ElementEventListener();
addCodeA1ElementEventListener();
addLogInElementEventListener();
addConfirmElementEventListener();
presetSentenceAElementTop();
presetSentenceBElementTop();
presetSentenceCElementTop();
presetSentenceXInputIndex();
presetSentenceXSelectIndex();
presetSentenceA1ElementFocus();
