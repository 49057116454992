import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';

export const setCharacterA0Element = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterA0 = characterA0[theIndex];
  if (sentenceAState.A === true || sentenceAState.A_ATB === true) {
    theCharacterA0.element = document.createElement('span');
  }

  if (sentenceAState.D === true) {
    theCharacterA0.element = theCharacterA0.elements[theCharacterA0.index];
  }
};
