export const sentenceA0 = {
  0: {
    element: document.querySelector('#_0_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  1: {
    element: document.querySelector('#_1_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  2: {
    element: document.querySelector('#_2_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  3: {
    element: document.querySelector('#_3_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  4: {
    element: document.querySelector('#_4_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  5: {
    element: document.querySelector('#_5_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  6: {
    element: document.querySelector('#_6_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  7: {
    element: document.querySelector('#_7_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  8: {
    element: document.querySelector('#_8_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  9: {
    element: document.querySelector('#_9_sentenceA0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    omitElements: [],
    selectElementsTimeline: undefined,
    omitElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
};
