import { codeState } from '/js/codeState/codeState.js';
import { codeCharacter } from '/js/codeCharacter/codeCharacter.js';

export const killCodeCharacterElementTimelines = () => {
  if (codeState.D === true) {
    for (const item of codeCharacter.elementTimelines) {
      item.kill();
    }
  }
};
