import { setConfirmElementDisplay } from '/js/confirm/setConfirmElementDisplay.js';
import { setConfirmElementOpacity } from '/js/confirm/setConfirmElementOpacity.js';
import { setSentenceXElementOpacity } from '/js/sentenceX/setSentenceXElementOpacity.js';
import { setSentenceXElementDisplay } from '/js/sentenceX/setSentenceXElementDisplay.js';
import { setSentenceA1ElementFocus } from '/js/sentenceA1/setSentenceA1ElementFocus.js';
import { setSentenceXUserId } from '/js/sentenceX/setSentenceXUserId.js';
import { setSentenceXUserName } from '/js/sentenceX/setSentenceXUserName.js';
import { setAuthenticationElementOpacity } from '/js/authentication/setAuthenticationElementOpacity.js';
import { setAuthenticationElementDisplay } from '/js/authentication/setAuthenticationElementDisplay.js';
import { setLogInMessageElementDisplay } from '/js/logInMessage/setLogInMessageElementDisplay.js';
import { setMessageElementOpacity } from '/js/message/setMessageElementOpacity.js';

export const confirmStream_V = (data) => {
  setSentenceXUserId(data);
  setSentenceXUserName();
  setAuthenticationElementOpacity();
  setAuthenticationElementDisplay();
  setConfirmElementOpacity();
  setConfirmElementDisplay();
  // setLogInMessageElementDisplay();
  // setMessageElementOpacity();
  setSentenceXElementOpacity();
  setSentenceXElementDisplay();
  setSentenceA1ElementFocus();
};
