export const sentenceB0 = {
  0: {
    element: document.querySelector('#_0_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  1: {
    element: document.querySelector('#_1_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  2: {
    element: document.querySelector('#_2_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  3: {
    element: document.querySelector('#_3_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  4: {
    element: document.querySelector('#_4_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  5: {
    element: document.querySelector('#_5_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  6: {
    element: document.querySelector('#_6_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  7: {
    element: document.querySelector('#_7_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  8: {
    element: document.querySelector('#_8_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
  9: {
    element: document.querySelector('#_9_sentenceB0'),
    value: '',
    splitValues: [],
    splitCounts: [],
    splitInitials: [],
    selectElements: [],
    unSelectElements: [],
    selectElementsTimeline: undefined,
    runTime: 0,
    height: '6vh',
  },
};
