import { resetSentenceXState } from '/js/sentenceXState/resetSentenceXState.js';
import { resetSentenceAState } from '/js/sentenceAState/resetSentenceAState.js';
import { resetSentenceBState } from '/js/sentenceBState/resetSentenceBState.js';
import { resetSentenceCState } from '/js/sentenceCState/resetSentenceCState.js';
import { resetBlankAState } from '/js/blankAState/resetBlankAState.js';
import { resetBlankBState } from '/js/blankBState/resetBlankBState.js';
import { resetBlankCState } from '/js/blankCState/resetBlankCState.js';
import { resetCharacterAState } from '/js/characterAState/resetCharacterAState.js';
import { resetCharacterBState } from '/js/characterBState/resetCharacterBState.js';
import { resetCharacterCState } from '/js/characterCState/resetCharacterCState.js';
import { setSentenceXState } from '/js/sentenceXState/setSentenceXState.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceXStream_C } from '/js/sentenceXStream/sentenceXStream_C.js';
export const handleSentenceXElementClick = (event) => {
  event.preventDefault();
  event.stopPropagation();
  resetSentenceXState(event);
  resetSentenceAState(event);
  resetSentenceBState(event);
  resetSentenceCState(event);
  resetBlankAState(event);
  resetBlankBState(event);
  resetBlankCState(event);
  resetCharacterAState(event);
  resetCharacterBState(event);
  resetCharacterCState(event);
  setSentenceXState(event);
  if (sentenceXState.C === true) {
    sentenceXStream_C(event);
  }
};
