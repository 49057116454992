import { gsap } from 'gsap';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';
import { sentenceB } from '/js/sentenceB/sentenceB.js';
import { characterB0 } from '/js/characterB0/characterB0.js';

export const setCharacterB0UnSelectElementsOpacity = () => {
  if (
    characterAState.C === true ||
    characterBState.C === true ||
    characterCState.C === true ||
    characterAState.T === true ||
    characterBState.T === true ||
    characterCState.T === true
  ) {
    for (const key in sentenceB) {
      if (characterB0[key].unSelectElements.length > 0) {
        let timeline = gsap.timeline();
        timeline.to(characterB0[key].unSelectElements, {
          opacity: 0.2,
          duration: 1,
        });
        characterB0[key].unSelectElements = timeline;
      }
    }
  }
};
