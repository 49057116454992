import { setSentenceXCanClick } from '/js/sentenceX/setSentenceXCanClick.js';
import { killCharacterA0ElementsTimeline } from '/js/characterA0/killCharacterA0ElementsTimeline.js';
import { killCharacterB0ElementsTimeline } from '/js/characterB0/killCharacterB0ElementsTimeline.js';
import { killCharacterC0ElementsTimeline } from '/js/characterC0/killCharacterC0ElementsTimeline.js';
import { killCharacterA0ElementTimelines } from '/js/characterA0/killCharacterA0ElementTimelines.js';
import { killCharacterB0ElementTimelines } from '/js/characterB0/killCharacterB0ElementTimelines.js';
import { killCharacterC0ElementTimelines } from '/js/characterC0/killCharacterC0ElementTimelines.js';
import { killCharacterA0SelectElementsTimeline } from '/js/characterA0/killCharacterA0SelectElementsTimeline.js';
import { killCharacterB0SelectElementsTimeline } from '/js/characterB0/killCharacterB0SelectElementsTimeline.js';
import { killCharacterC0SelectElementsTimeline } from '/js/characterC0/killCharacterC0SelectElementsTimeline.js';
import { killCharacterA0UnSelectElementsTimeline } from '/js/characterA0/killCharacterA0UnSelectElementsTimeline.js';
import { killCharacterB0UnSelectElementsTimeline } from '/js/characterB0/killCharacterB0UnSelectElementsTimeline.js';
import { killCharacterC0UnSelectElementsTimeline } from '/js/characterC0/killCharacterC0UnSelectElementsTimeline.js';
import { killSentenceA0SelectElementsTimeline } from '/js/sentenceA0/killSentenceA0SelectElementsTimeline.js';
import { killSentenceB0SelectElementsTimeline } from '/js/sentenceB0/killSentenceB0SelectElementsTimeline.js';
import { killSentenceC0SelectElementsTimeline } from '/js/sentenceC0/killSentenceC0SelectElementsTimeline.js';
import { killSentenceA0UnSelectElementsTimeline } from '/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js';
import { killSentenceB0UnSelectElementsTimeline } from '/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js';
import { killSentenceC0UnSelectElementsTimeline } from '/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js';
import { setCharacterC0Values } from '/js/characterC0/setCharacterC0Values.js';
import { setCharacterC0Value } from '/js/characterC0/setCharacterC0Value.js';
import { setCharacterC0Index } from '/js/characterC0/setCharacterC0Index.js';
import { setCharacterC0SelectElementsOpacity } from '/js/characterC0/setCharacterC0SelectElementsOpacity.js';
import { removeCharacterC0SelectElements } from '/js/characterC0/removeCharacterC0SelectElements.js';
import { setCharacterC0SelectElements } from '/js/characterC0/setCharacterC0SelectElements.js';
import { setCharacterC0LastElement } from '/js/characterC0/setCharacterC0LastElement.js';
import { setCharacterC0LastElementInnerHTML } from '/js/characterC0/setCharacterC0LastElementInnerHTML.js';
import { addCharacterC0LastElementEventListener } from '/js/characterC0/addCharacterC0LastElementEventListener.js';
import { insertCharacterC0LastElement } from '/js/characterC0/insertCharacterC0LastElement.js';
import { setCharacterC0LastElementOpacity } from '/js/characterC0/setCharacterC0LastElementOpacity.js';
import { setCharacterC0NextElementsOpacity } from '/js/characterC0/setCharacterC0NextElementsOpacity.js';
import { removeCharacterC0NextElements } from '/js/characterC0/removeCharacterC0NextElements.js';

import { setCharacterC0Elements } from '/js/characterC0/setCharacterC0Elements.js';
import { setCharacterC0ElementsId } from '/js/characterC0/setCharacterC0ElementsId.js';
import { setSentenceC0Value } from '/js/sentenceC0/setSentenceC0Value.js';
import { setSentenceC0SplitValues } from '/js/sentenceC0/setSentenceC0SplitValues.js';
import { setSentenceC0SplitCounts } from '/js/sentenceC0/setSentenceC0SplitCounts.js';
import { setSentenceC0SplitInitials } from '/js/sentenceC0/setSentenceC0SplitInitials.js';
import { setCharacterC0ElementsClassName } from '/js/characterC0/setCharacterC0ElementsClassName.js';
import { setSentenceC1ElementValue } from '/js/sentenceC1/setSentenceC1ElementValue.js';

import { setCharacterA0ElementsOpacity } from '/js/characterA0/setCharacterA0ElementsOpacity.js';
import { setCharacterB0ElementsOpacity } from '/js/characterB0/setCharacterB0ElementsOpacity.js';
import { setCharacterC0ElementsOpacity } from '/js/characterC0/setCharacterC0ElementsOpacity.js';

import { removeCharacterB0Elements } from '/js/characterB0/removeCharacterB0Elements.js';
import { removeCharacterC0Elements } from '/js/characterC0/removeCharacterC0Elements.js';
import { transformSentenceAElement } from '/js/sentenceA/transformSentenceAElement.js';
import { transformSentenceBElement } from '/js/sentenceB/transformSentenceBElement.js';
import { transformSentenceCElement } from '/js/sentenceC/transformSentenceCElement.js';

import { setSentenceCElementDisplay } from '/js/sentenceC/setSentenceCElementDisplay.js';
export const sentenceCStream_R_I = (event) => {
  setSentenceXCanClick(false);

  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0ElementTimelines();
  killCharacterB0ElementTimelines();
  killCharacterC0ElementTimelines();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterC0Values();
  setCharacterC0Value();
  setCharacterC0Index();

  setCharacterC0SelectElementsOpacity();
  removeCharacterC0SelectElements();
  setCharacterC0SelectElements();

  setCharacterC0LastElement();
  setCharacterC0LastElementInnerHTML();
  addCharacterC0LastElementEventListener();
  insertCharacterC0LastElement();

  setCharacterC0LastElementOpacity();
  setCharacterC0NextElementsOpacity();
  removeCharacterC0NextElements();

  setCharacterC0Elements();
  setCharacterC0ElementsId();
  setSentenceC0Value(event);
  setSentenceC0SplitValues();
  setSentenceC0SplitCounts();
  setSentenceC0SplitInitials();
  setCharacterC0ElementsClassName();
  setSentenceC1ElementValue();

  setCharacterA0ElementsOpacity();
  setCharacterB0ElementsOpacity();
  setCharacterC0ElementsOpacity();
  removeCharacterC0Elements();

  transformSentenceAElement();
  transformSentenceBElement();
  transformSentenceCElement();
  setSentenceCElementDisplay();
  setTimeout(() => {
    setSentenceXCanClick(true);
  }, 1000);
};
