import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceA0 } from '/js/sentenceA0/sentenceA0.js';
import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const setSentenceXSKC = (event) => {
  let str = '';
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (event.type === 'wheel') {
    if (
      characterAState.C === true ||
      characterBState.C === true ||
      characterCState.C === true
    ) {
      for (const key in sentenceA0) {
        if (theIndex > 0) {
          if (key < theIndex) {
            if (sentenceA0[key].value !== '') {
              str += sentenceA0[key].value;
              if (sentenceB0[key].value !== '') {
                str += ' ';
                str += sentenceB0[key].value;
              }
              if (sentenceC0[key].value !== '') {
                str += ' ';
                str += sentenceC0[key].value;
              }
            }
            str += '&';
          }
        }
      }
      if (characterAState.C === true) {
        let element =
          characterA0[theIndex].selectElements[
            characterA0[theIndex].selectElements.length - 1
          ];
        let arr = [];
        for (const item of characterA0[theIndex].elements) {
          arr.push(item);
          if (item == element) {
            break;
          }
        }
        for (const item of arr) {
          if (item.innerHTML === '&nbsp;') {
            str += ' ';
          } else {
            str += item.innerHTML;
          }
        }
      } else if (characterBState.C === true) {
        str += sentenceA0[theIndex].value;
        let element =
          characterB0[theIndex].selectElements[
            characterB0[theIndex].selectElements.length - 1
          ];
        let arr = [];
        for (const item of characterB0[theIndex].elements) {
          arr.push(item);
          if (item == element) {
            break;
          }
        }
        str += ` `;
        for (const item of arr) {
          if (item.innerHTML === '&nbsp;') {
            str += ' ';
          } else {
            str += item.innerHTML;
          }
        }
      } else if (characterCState.C === true) {
        str += sentenceA0[theIndex].value;
        str += ' ';
        str += sentenceB0[theIndex].value;
        let element =
          characterB0[theIndex].selectElements[
            characterB0[theIndex].selectElements.length - 1
          ];
        let arr = [];
        for (const item of characterC0[theIndex].elements) {
          arr.push(item);
          if (item == element) {
            break;
          }
        }
        for (const item of arr) {
          if (item.innerHTML === '&nbsp;') {
            str += ' ';
          } else {
            str += item.innerHTML;
          }
        }
      }
      sentenceX.SKC = str;
      sentenceX.SKC += ' ';
    }
  } else if (event.type === 'touchend') {
    let deltaX = sentenceX.endX - sentenceX.startX;
    let deltaY = sentenceX.endY - sentenceX.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < -20) {
      if (
        characterAState.C === true ||
        characterBState.C === true ||
        characterCState.C === true
      ) {
        for (const key in sentenceA0) {
          if (theIndex > 0) {
            if (key < theIndex) {
              if (sentenceA0[key].value !== '') {
                str += sentenceA0[key].value;
                if (sentenceB0[key].value !== '') {
                  str += ' ';
                  str += sentenceB0[key].value;
                }
                if (sentenceC0[key].value !== '') {
                  str += ' ';
                  str += sentenceC0[key].value;
                }
              }
              str += '&';
            }
          }
        }
        if (characterAState.C === true) {
          let element =
            characterA0[theIndex].selectElements[
              characterA0[theIndex].selectElements.length - 1
            ].nextElementSibling;
          let arr = [];
          for (const item of characterA0[theIndex].elements) {
            if (item == element) {
              break;
            }
            arr.push(item);
          }
          for (const item of arr) {
            if (item.innerHTML === '&nbsp;') {
              str += ' ';
            } else {
              str += item.innerHTML;
            }
          }
        } else if (characterBState.C === true) {
          str += sentenceA0[theIndex].value;
          let element =
            characterA0[theIndex].selectElements[0].previousElementSibling;
          let arr = [];
          for (const item of characterB0[theIndex].elements) {
            arr.push(item);
            if (item == element) {
              break;
            }
          }
          str += ` `;
          for (const item of arr) {
            if (item.innerHTML === '&nbsp;') {
              str += ' ';
            } else {
              str += item.innerHTML;
            }
          }
        } else if (characterCState.C === true) {
          str += sentenceA0[theIndex].value;
          str += ' ';
          str += sentenceB0[theIndex].value;
          let element =
            characterA0[theIndex].selectElements[0].previousElementSibling;
          let arr = [];
          for (const item of characterC0[theIndex].elements) {
            arr.push(item);
            if (item == element) {
              break;
            }
          }
          for (const item of arr) {
            if (item.innerHTML === '&nbsp;') {
              str += ' ';
            } else {
              str += item.innerHTML;
            }
          }
        }
        sentenceX.SKC = str;
        sentenceX.SKC += ' ';
      }
    }
  } else if (event.type === 'keydown') {
    // for (const key in sentenceA0) {
    //   if (sentenceA0[key].value !== '') {
    //     strA += `${key}_`;
    //     strA += sentenceA0[key].value;
    //     if (sentenceB0[key].value !== '') {
    //       strA += ' ';
    //       strA += sentenceB0[key].value;
    //     }
    //     if (sentenceC0[key].value !== '') {
    //       strA += ' ';
    //       strA += sentenceC0[key].value;
    //     }
    //     strA += '&';
    //   }
    // }
  }
};
