import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const presetCharacterC0Values = (event) => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (characterCState.C === true) {
    if (
      event.inputType === 'insertText' ||
      event.inputType === 'insertReplacementText'
    ) {
      let arr = [];
      for (const item of theCharacterC0.elements) {
        if (
          theCharacterC0.selectElements.includes(item) === false &&
          theCharacterC0.nextElements.includes(item) === false
        ) {
          if (item.innerHTML === '&nbsp;') {
            arr.push(' ');
          } else {
            arr.push(item.innerHTML);
          }
        }
      }
      theCharacterC0.values = arr;
    } else if (event.inputType === 'deleteContentBackward') {
      let arr = [];
      for (const item of theCharacterC0.elements) {
        if (theCharacterC0.nextElements.includes(item) === false) {
          if (item.innerHTML === '&nbsp;') {
            arr.push(' ');
          } else {
            arr.push(item.innerHTML);
          }
        }
      }
      theCharacterC0.values = arr;
    }
  }

  if (event.data !== null) {
    if (event.data.length > 1) {
      for (let i = theCharacterC0.values.length - 1; i >= 0; i--) {
        if (theCharacterC0.values[i] === ' ') {
          break;
        }
        theCharacterC0.values.pop();
      }
      for (const item of event.data) {
        theCharacterC0.values.push(item);
      }
    }
  }
};
