export const sentenceB1 = {
  0: {
    element: document.querySelector('#_0_sentenceB1'),
    focus: false,
  },
  1: {
    element: document.querySelector('#_1_sentenceB1'),
    focus: false,
  },
  2: {
    element: document.querySelector('#_2_sentenceB1'),
    focus: false,
  },
  3: {
    element: document.querySelector('#_3_sentenceB1'),
    focus: false,
  },
  4: {
    element: document.querySelector('#_4_sentenceB1'),
    focus: false,
  },
  5: {
    element: document.querySelector('#_5_sentenceB1'),
    focus: false,
  },
  6: {
    element: document.querySelector('#_6_sentenceB1'),
    focus: false,
  },
  7: {
    element: document.querySelector('#_7_sentenceB1'),
    focus: false,
  },
  8: {
    element: document.querySelector('#_8_sentenceB1'),
    focus: false,
  },
  9: {
    element: document.querySelector('#_9_sentenceB1'),
    focus: false,
  },
};
