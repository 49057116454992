import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceC0 } from '/js/sentenceC0/sentenceC0.js';
import { handleCharacterC0ElementClick } from '/js/characterC0/handleCharacterC0ElementClick.js';
import { handleCharacterC0ElementWheel } from '/js/characterC0/handleCharacterC0ElementWheel.js';
import { handleCharacterC0ElementTouchStart } from '/js/characterC0/handleCharacterC0ElementTouchStart.js';
import { handleCharacterC0ElementTouchEnd } from '/js/characterC0/handleCharacterC0ElementTouchEnd.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';

export const setCharacterC0CompleteElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  let theSentenceC0 = sentenceC0[theIndex];
  if (
    sentenceCState.A_C === true ||
    sentenceCState.R_A_C === true ||
    sentenceCState.R_I_C === true
  ) {
    let arr = [];
    for (const item of theCharacterC0.completeValues) {
      let element = document.createElement('span');
      if (item === ' ') {
        element.innerHTML = '&nbsp;';
      } else {
        element.innerHTML = item;
      }
      element.addEventListener('click', handleCharacterC0ElementClick);
      element.addEventListener('wheel', handleCharacterC0ElementWheel);
      element.addEventListener(
        'touchstart',
        handleCharacterC0ElementTouchStart
      );
      element.addEventListener('touchend', handleCharacterC0ElementTouchEnd);
      theSentenceC0.element.appendChild(element);
      arr.push(element);
    }
    theCharacterC0.completeElements = arr;
  }
};
