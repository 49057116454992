import { userNameState } from '/js/userNameState/userNameState.js';
import { passWordState } from '/js/passWordState/passWordState.js';
import { logInState } from '/js/logInState/logInState.js';
import { confirmState } from '/js/confirmState/confirmState.js';
import { message } from '/js/message/message.js';

export const setMessageElementOpacity = () => {
  if (userNameState.C === true || passWordState.C === true) {
    let timeline = gsap.timeline();
    timeline.to(message.element, {
      opacity: 0.2,
      duration: 1,
    });
    message.elementTimeline = timeline;
  }
  if (logInState.V === true) {
    let timeline = gsap.timeline();
    timeline.to(message.element, {
      opacity: 0,
      duration: 1,
    });
    message.elementTimeline = timeline;
  }
  if (logInState.I === true) {
    let timeline = gsap.timeline();
    timeline.fromTo(
      message.element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
      }
    );
    message.elementTimeline = timeline;
  }
  // if (confirmState.C === true) {
  //   let timeline = gsap.timeline();
  //   timeline.fromTo(
  //     message.element,
  //     { opacity: paraA },
  //     {
  //       opacity: paraB,
  //       duration: paraC,
  //     }
  //   );
  //   message.elementAnimation = timeline;
  // }
};
