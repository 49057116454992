import { gsap } from 'gsap';
import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterB0NextElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  if (
    sentenceBState.R_I === true ||
    sentenceBState.R_I_C === true ||
    sentenceBState.D === true
  ) {
    if (theCharacterB0.nextElements.length > 0) {
      gsap.to(theCharacterB0.nextElements, {
        opacity: 0,
        duration: 1,
      });
    }
  }
};
