import { sentenceB0 } from '/js/sentenceB0/sentenceB0.js';
export const presetSentenceB0Value = (event) => {
  if (event.type === 'wheel' || event.type === 'touchend') {
    for (const key in sentenceB0) {
      let str = sentenceB0[key].value;
      if (str[str.length - 1] === ' ') {
        str = str.slice(0, -1);
        sentenceB0[key].value = str;
      }
    }
  }
};
