import { passWordState } from '/js/passWordState/passWordState.js';
import { userNameState } from '/js/userNameState/userNameState.js';
import { logInState } from '/js/logInState/logInState.js';
import { passWordA0Character } from '/js/passWordA0Character/passWordA0Character.js';

export const setPassWordA0CharacterElementsOpacity = () => {
  if (passWordState.C === true) {
    if (passWordA0Character.elements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(passWordA0Character.elements, {
        opacity: 1,
        duration: 3,
      });
      passWordA0Character.elementsTimeline = timeline;
    }
  }
  if (userNameState.C === true) {
    if (passWordA0Character.elements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(passWordA0Character.elements, {
        opacity: 0.2,
        duration: 1,
      });
      passWordA0Character.elementsTimeline = timeline;
    }
  }

  if (logInState.C === true) {
    if (passWordA0Character.elements.length > 0) {
      let timeline = gsap.timeline();
      timeline.to(passWordA0Character.elements, {
        opacity: 0.2,
        duration: 1,
      });
      passWordA0Character.elementsTimeline = timeline;
    }
  }
};
