import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentence } from '/js/sentence/sentence.js';
import { logIn } from '/js/logIn/logIn.js';
import { logInState } from '/js/logInState/logInState.js';

export const setLogInElementDisplay = () => {
  if (sentenceXState.L === true) {
    if (sentence[0].value === '') {
      logIn.element.style.display = 'flex';
      logIn.display = 'flex';
    } else {
      setTimeout(() => {
        logIn.element.style.display = 'flex';
        logIn.display = 'flex';
      }, 1000);
    }
  }

  if (
    logInState.V === true ||
    logInState.UV === true ||
    logInState.RV === true
  ) {
    setTimeout(() => {
      logIn.element.style.display = 'none';
      logIn.display = 'none';
    }, 1000);
  }
};
