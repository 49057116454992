import { setSentenceXGC } from '/js/sentenceX/setSentenceXGC.js';
import { presetCharacterC0Values } from '/js/characterC0/presetCharacterC0Values.js';
import { presetCharacterC0Index } from '/js/characterC0/presetCharacterC0Index.js';
import { setCharacterC0Data } from '/js/characterC0/setCharacterC0Data.js';
import { setCharacterC0PreviousValue } from '/js/characterC0/setCharacterC0PreviousValue.js';
import { resetSentenceXState } from '/js/sentenceXState/resetSentenceXState.js';
import { resetSentenceAState } from '/js/sentenceAState/resetSentenceAState.js';
import { resetSentenceBState } from '/js/sentenceBState/resetSentenceBState.js';
import { resetSentenceCState } from '/js/sentenceCState/resetSentenceCState.js';
import { resetBlankAState } from '/js/blankAState/resetBlankAState.js';
import { resetBlankBState } from '/js/blankBState/resetBlankBState.js';
import { resetBlankCState } from '/js/blankCState/resetBlankCState.js';
import { resetCharacterAState } from '/js/characterAState/resetCharacterAState.js';
import { resetCharacterBState } from '/js/characterBState/resetCharacterBState.js';
import { resetCharacterCState } from '/js/characterCState/resetCharacterCState.js';
import { setSentenceCState } from '/js/sentenceCState/setSentenceCState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { sentenceCStream_A } from '/js/sentenceCStream/sentenceCStream_A.js';
import { sentenceCStream_A_C } from '/js/sentenceCStream/sentenceCStream_A_C.js';
import { sentenceCStream_R_A } from '/js/sentenceCStream/sentenceCStream_R_A.js';
import { sentenceCStream_R_I } from '/js/sentenceCStream/sentenceCStream_R_I.js';
import { sentenceCStream_D } from '/js/sentenceCStream/sentenceCStream_D.js';
import { sentenceCStream_R_A_C } from '/js/sentenceCStream/sentenceCStream_R_A_C.js';
import { sentenceCStream_R_I_C } from '/js/sentenceCStream/sentenceCStream_R_I_C.js';

export const handleSentenceC1ElementInput = (event) => {
  setSentenceXGC(event);
  presetCharacterC0Values(event);
  presetCharacterC0Index(event);
  setCharacterC0Data(event);
  setCharacterC0PreviousValue(event);
  resetSentenceXState(event);
  resetSentenceAState(event);
  resetSentenceBState(event);
  resetSentenceCState(event);
  resetBlankAState(event);
  resetBlankBState(event);
  resetBlankCState(event);
  setSentenceCState(event);
  resetCharacterAState(event);
  resetCharacterBState(event);
  resetCharacterCState(event);
  if (sentenceCState.A === true) {
    console.log('C_A');
    sentenceCStream_A(event);
  }
  if (sentenceCState.A_C === true) {
    console.log('A_C');
    sentenceCStream_A_C(event);
  }
  if (sentenceCState.R_A === true) {
    sentenceCStream_R_A(event);
  }
  if (sentenceCState.R_I === true) {
    sentenceCStream_R_I(event);
  }
  if (sentenceCState.R_A_C === true) {
    sentenceCStream_R_A_C(event);
  }
  if (sentenceCState.R_I_C === true) {
    sentenceCStream_R_I_C(event);
  }
  if (sentenceCState.D === true) {
    sentenceCStream_D(event);
  }
};
