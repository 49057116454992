import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';

export const setCharacterC0Element = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (sentenceCState.A === true) {
    theCharacterC0.element = document.createElement('span');
  }
  if (sentenceCState.D === true) {
    theCharacterC0.element = document.querySelector(
      `#_${theIndex}_characterC0_${theCharacterC0.index}`
    );
    console.log(theCharacterC0.index);
    console.log(theCharacterC0.element);
  }
};
