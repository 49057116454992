import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentence } from '/js/sentence/sentence.js';
import { sentenceA } from '/js/sentenceA/sentenceA.js';
import { sentenceB } from '/js/sentenceB/sentenceB.js';
import { sentenceC } from '/js/sentenceC/sentenceC.js';
import { characterA0 } from '/js/characterA0/characterA0.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { blankAState } from '/js/blankAState/blankAState.js';
import { blankBState } from '/js/blankBState/blankBState.js';
import { blankCState } from '/js/blankCState/blankCState.js';
import { characterAState } from '/js/characterAState/characterAState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterCState } from '/js/characterCState/characterCState.js';

export const killSentenceSignElementsTimelines = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  if (
    sentenceXState.W_L_RA_G1 === true ||
    sentenceAState.W_L_RA_G1 === true ||
    sentenceBState.W_L_RA_G1 === true ||
    sentenceCState.W_L_RA_G1 === true ||
    sentenceXState.T_L_RA_G1 === true ||
    sentenceAState.T_L_RA_G1 === true ||
    sentenceBState.T_L_RA_G1 === true ||
    sentenceCState.T_L_RA_G1 === true ||
    sentenceXState.W_L_SKA_G1 === true ||
    sentenceAState.W_L_SKA_G1 === true ||
    sentenceBState.W_L_SKA_G1 === true ||
    sentenceCState.W_L_SKA_G1 === true ||
    blankAState.W_L_SKA_G1 === true ||
    blankBState.W_L_SKA_G1 === true ||
    blankCState.W_L_SKA_G1 === true ||
    characterAState.W_L_SKA_G1 === true ||
    characterBState.W_L_SKA_G1 === true ||
    characterCState.W_L_SKA_G1 === true ||
    sentenceXState.T_L_SKA_G1 === true ||
    sentenceAState.T_L_SKA_G1 === true ||
    sentenceBState.T_L_SKA_G1 === true ||
    sentenceCState.T_L_SKA_G1 === true ||
    blankAState.T_L_SKA_G1 === true ||
    blankBState.T_L_SKA_G1 === true ||
    blankCState.T_L_SKA_G1 === true ||
    characterAState.T_L_SKA_G1 === true ||
    characterBState.T_L_SKA_G1 === true ||
    characterCState.T_L_SKA_G1 === true ||
    sentenceXState.W_L_SKA_G2 === true ||
    sentenceAState.W_L_SKA_G2 === true ||
    sentenceBState.W_L_SKA_G2 === true ||
    sentenceCState.W_L_SKA_G2 === true ||
    blankAState.W_L_SKA_G2 === true ||
    blankBState.W_L_SKA_G2 === true ||
    blankCState.W_L_SKA_G2 === true ||
    characterAState.W_L_SKA_G2 === true ||
    characterBState.W_L_SKA_G2 === true ||
    characterCState.W_L_SKA_G2 === true ||
    sentenceXState.T_L_SKA_G2 === true ||
    sentenceAState.T_L_SKA_G2 === true ||
    sentenceBState.T_L_SKA_G2 === true ||
    sentenceCState.T_L_SKA_G2 === true ||
    blankAState.T_L_SKA_G2 === true ||
    blankBState.T_L_SKA_G2 === true ||
    blankCState.T_L_SKA_G2 === true ||
    characterAState.T_L_SKA_G2 === true ||
    characterBState.T_L_SKA_G2 === true ||
    characterCState.T_L_SKA_G2 === true ||
    sentenceXState.W_L_RA === true ||
    sentenceAState.W_L_RA === true ||
    sentenceBState.W_L_RA === true ||
    sentenceCState.W_L_RA === true ||
    sentenceXState.T_L_RA === true ||
    sentenceAState.T_L_RA === true ||
    sentenceBState.T_L_RA === true ||
    sentenceCState.T_L_RA === true ||
    sentenceXState.W_L_SKA === true ||
    sentenceAState.W_L_SKA === true ||
    sentenceBState.W_L_SKA === true ||
    sentenceCState.W_L_SKA === true ||
    blankAState.W_L_SKA === true ||
    blankBState.W_L_SKA === true ||
    blankCState.W_L_SKA === true ||
    characterAState.W_L_SKA === true ||
    characterBState.W_L_SKA === true ||
    characterCState.W_L_SKA === true ||
    sentenceXState.T_L_SKA === true ||
    sentenceAState.T_L_SKA === true ||
    sentenceBState.T_L_SKA === true ||
    sentenceCState.T_L_SKA === true ||
    blankAState.T_L_SKA === true ||
    blankBState.T_L_SKA === true ||
    blankCState.T_L_SKA === true ||
    characterAState.T_L_SKA === true ||
    characterBState.T_L_SKA === true ||
    characterCState.T_L_SKA === true ||
    sentenceXState.W_R_RA === true ||
    sentenceAState.W_R_RA === true ||
    sentenceBState.W_R_RA === true ||
    sentenceCState.W_R_RA === true ||
    sentenceXState.T_R_RA === true ||
    sentenceAState.T_R_RA === true ||
    sentenceBState.T_R_RA === true ||
    sentenceCState.T_R_RA === true ||
    sentenceXState.W_R_SKA === true ||
    sentenceAState.W_R_SKA === true ||
    sentenceBState.W_R_SKA === true ||
    sentenceCState.W_R_SKA === true ||
    blankAState.W_R_SKA === true ||
    blankBState.W_R_SKA === true ||
    blankCState.W_R_SKA === true ||
    characterAState.W_R_SKA === true ||
    characterBState.W_R_SKA === true ||
    characterCState.W_R_SKA === true ||
    sentenceXState.T_R_SKA === true ||
    sentenceAState.T_R_SKA === true ||
    sentenceBState.T_R_SKA === true ||
    sentenceCState.T_R_SKA === true ||
    blankAState.T_R_SKA === true ||
    blankBState.T_R_SKA === true ||
    blankCState.T_R_SKA === true ||
    characterAState.T_R_SKA === true ||
    characterBState.T_R_SKA === true ||
    characterCState.T_R_SKA === true
  ) {
    setTimeout(() => {
      for (const item of sentence[theIndex - 1].signElementsTimelines) {
        item.timeline.kill();
      }
    }, (sentenceX.CD - 1) * 1000);
  }

  if (
    sentenceXState.W_L_RA_G1 === true ||
    sentenceAState.W_L_RA_G1 === true ||
    sentenceBState.W_L_RA_G1 === true ||
    sentenceCState.W_L_RA_G1 === true ||
    sentenceXState.T_L_RA_G1 === true ||
    sentenceAState.T_L_RA_G1 === true ||
    sentenceBState.T_L_RA_G1 === true ||
    sentenceCState.T_L_RA_G1 === true ||
    sentenceXState.W_L_RA === true ||
    sentenceAState.W_L_RA === true ||
    sentenceBState.W_L_RA === true ||
    sentenceCState.W_L_RA === true ||
    sentenceXState.T_L_RA === true ||
    sentenceAState.T_L_RA === true ||
    sentenceBState.T_L_RA === true ||
    sentenceCState.T_L_RA === true ||
    sentenceXState.W_R_RA === true ||
    sentenceAState.W_R_RA === true ||
    sentenceBState.W_R_RA === true ||
    sentenceCState.W_R_RA === true ||
    sentenceXState.T_R_RA === true ||
    sentenceAState.T_R_RA === true ||
    sentenceBState.T_R_RA === true ||
    sentenceCState.T_R_RA === true
  ) {
    setTimeout(() => {
      for (const item of sentence[0].signElementsTimelines) {
        item.timeline.kill();
      }
    }, (sentenceX.CD - 1) * 1000);
  }

  if (
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    blankAState.W_L_SKC_G === true ||
    blankBState.W_L_SKC_G === true ||
    blankCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    blankAState.T_L_SKC_G === true ||
    blankBState.T_L_SKC_G === true ||
    blankCState.T_L_SKC_G === true
  ) {
    for (const item of sentence[theIndex].signElementsTimelines) {
      item.timeline.kill();
    }
  }

  if (
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    blankAState.W_L_SKC === true ||
    blankBState.W_L_SKC === true ||
    blankCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    blankAState.T_L_SKC === true ||
    blankBState.T_L_SKC === true ||
    blankCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    blankAState.W_R_SKC === true ||
    blankBState.W_R_SKC === true ||
    blankCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true ||
    blankAState.T_R_SKC === true ||
    blankBState.T_R_SKC === true ||
    blankCState.T_R_SKC === true
  ) {
    let time = Math.max(
      sentenceA[theIndex].runTime,
      sentenceB[theIndex].runTime,
      sentenceC[theIndex].runTime,
      characterA0[theIndex].runTime,
      characterB0[theIndex].runTime,
      characterC0[theIndex].runTime
    );
    setTimeout(() => {
      for (const item of sentence[theIndex].signElementsTimelines) {
        item.timeline.kill();
      }
    }, time * 1000);
  }
};
