import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { handleCharacterC0ElementClick } from '/js/characterC0/handleCharacterC0ElementClick.js';
import { handleCharacterC0ElementWheel } from '/js/characterC0/handleCharacterC0ElementWheel.js';
import { handleCharacterC0ElementTouchStart } from '/js/characterC0/handleCharacterC0ElementTouchStart.js';
import { handleCharacterC0ElementTouchEnd } from '/js/characterC0/handleCharacterC0ElementTouchEnd.js';
export const addCharacterC0ElementEventListener = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterC0 = characterC0[theIndex];
  if (sentenceCState.A === true) {
    theCharacterC0.element.addEventListener(
      'click',
      handleCharacterC0ElementClick
    );
    theCharacterC0.element.addEventListener(
      'wheel',
      handleCharacterC0ElementWheel
    );
    theCharacterC0.element.addEventListener(
      'touchstart',
      handleCharacterC0ElementTouchStart
    );
    theCharacterC0.element.addEventListener(
      'touchend',
      handleCharacterC0ElementTouchEnd
    );
  }
};
