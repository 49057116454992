import { userNameState } from '/js/userNameState/userNameState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';

export const setUserNameA0CharacterValues = () => {
  if (userNameState.A === true) {
    userNameA0Character.values.splice(
      userNameA0Character.index + 1,
      0,
      userNameA0Character.data
    );
  }

  if (userNameState.D === true) {
    userNameA0Character.values.splice(userNameA0Character.index, 1);
  }
};
