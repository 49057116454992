import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { sentenceXState } from '/js/sentenceXState/sentenceXState.js';
import { sentenceAState } from '/js/sentenceAState/sentenceAState.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';
import { sentenceCState } from '/js/sentenceCState/sentenceCState.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { sentenceB } from '/js/sentenceB/sentenceB.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterB2 } from '/js/characterB2/characterB2.js';

export const setCharacterB2SelectElementsOpacity = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }

  if (
    sentenceXState.W_L_SKC_G === true ||
    sentenceAState.W_L_SKC_G === true ||
    sentenceBState.W_L_SKC_G === true ||
    sentenceCState.W_L_SKC_G === true ||
    sentenceXState.T_L_SKC_G === true ||
    sentenceAState.T_L_SKC_G === true ||
    sentenceBState.T_L_SKC_G === true ||
    sentenceCState.T_L_SKC_G === true ||
    sentenceXState.W_L_SKC === true ||
    sentenceAState.W_L_SKC === true ||
    sentenceBState.W_L_SKC === true ||
    sentenceCState.W_L_SKC === true ||
    sentenceXState.T_L_SKC === true ||
    sentenceAState.T_L_SKC === true ||
    sentenceBState.T_L_SKC === true ||
    sentenceCState.T_L_SKC === true ||
    sentenceXState.W_R_SKC === true ||
    sentenceAState.W_R_SKC === true ||
    sentenceBState.W_R_SKC === true ||
    sentenceCState.W_R_SKC === true ||
    sentenceXState.T_R_SKC === true ||
    sentenceAState.T_R_SKC === true ||
    sentenceBState.T_R_SKC === true ||
    sentenceCState.T_R_SKC === true
  ) {
    if (characterBState.C === true) {
      let time = Math.max(
        sentenceB[theIndex].runTime,
        characterB0[theIndex].runTime
      );
      gsap.to(characterB2[theIndex].selectElements, {
        opacity: 1,
        duration: 3,
        delay: time,
      });
      characterB2[theIndex].runTime += time;
      characterB2[theIndex].runTime += 3;
    }
  }
};
