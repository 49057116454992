import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { characterB0 } from '/js/characterB0/characterB0.js';
import { characterC0 } from '/js/characterC0/characterC0.js';
import { sentenceBState } from '/js/sentenceBState/sentenceBState.js';

export const setCharacterC0FromBElements = () => {
  let theIndex = undefined;
  if (sentenceX.selectIndex !== undefined) {
    theIndex = sentenceX.selectIndex;
  }
  if (sentenceX.inputIndex !== undefined) {
    theIndex = sentenceX.inputIndex;
  }
  let theCharacterB0 = characterB0[theIndex];
  let theCharacterC0 = characterC0[theIndex];
  if (sentenceBState.A_BTC === true) {
    let arr = [];
    for (let i = 0; i < theCharacterB0.toCValues.length; i++) {
      theCharacterC0.element = document.createElement('span');
      theCharacterC0.element.innerHTML = theCharacterB0.toCValues[i];
      arr.push(theCharacterC0.element);
    }
    theCharacterC0.fromBElements = arr;
  }
};
