import { killUserNameA0CharacterElementTimelines } from '/js/userNameA0Character/killUserNameA0CharacterElementTimelines.js';
import { killPassWordA0CharacterElementTimelines } from '/js/passWordA0Character/killPassWordA0CharacterElementTimelines.js';
import { killUserNameA0CharacterElementsTimeline } from '/js/userNameA0Character/killUserNameA0CharacterElementsTimeline.js';
import { killPassWordA0CharacterElementsTimeline } from '/js/passWordA0Character/killPassWordA0CharacterElementsTimeline.js';
import { killMessageElementTimeline } from '/js/message/killMessageElementTimeline.js';
import { setUserNameA0CharacterElementsOpacity } from '/js/userNameA0Character/setUserNameA0CharacterElementsOpacity.js';
import { setPassWordA0CharacterElementsOpacity } from '/js/passWordA0Character/setPassWordA0CharacterElementsOpacity.js';
import { setUserNameA1ElementFocus } from '/js/userNameA1/setUserNameA1ElementFocus.js';
import { setLogInElementOpacity } from '/js/logIn/setLogInElementOpacity.js';
import { setMessageElementOpacity } from '/js/message/setMessageElementOpacity.js';

export const userNameStream_C = (event) => {
  killUserNameA0CharacterElementTimelines();
  killPassWordA0CharacterElementTimelines();
  killUserNameA0CharacterElementsTimeline(event);
  killPassWordA0CharacterElementsTimeline(event);
  killMessageElementTimeline();
  setUserNameA0CharacterElementsOpacity(event);
  setPassWordA0CharacterElementsOpacity(event);
  setLogInElementOpacity();
  setMessageElementOpacity();
  setUserNameA1ElementFocus(event);
};
