import { codeState } from '/js/codeState/codeState.js';
import { codeCharacter } from '/js/codeCharacter/codeCharacter.js';

export const setCodeCharacterElement = () => {
  if (codeState.A === true) {
    codeCharacter.element = document.createElement('span');
  }
  if (codeState.D === true) {
    codeCharacter.element = document.querySelector(
      `#codeCharacter_${codeCharacter.index}`
    );
  }
};
