import { userNameState } from '/js/userNameState/userNameState.js';
import { userNameA0Character } from '/js/userNameA0Character/userNameA0Character.js';

export const setUserNameA0CharacterElements = () => {
  if (userNameState.A === true) {
    let arr = Array.from(document.querySelectorAll(`.userNameA0 span`));
    userNameA0Character.elements = arr;
  }
  if (userNameState.D === true) {
    let arr = Array.from(document.querySelectorAll(`.userNameA0 span`));
    arr.pop();
    userNameA0Character.elements = arr;
  }
};
