import { sentenceX } from '/js/sentenceX/sentenceX.js';
import { setSentenceXState } from '/js/sentenceXState/setSentenceXState.js';
import { setSentenceAState } from '/js/sentenceAState/setSentenceAState.js';
import { setSentenceBState } from '/js/sentenceBState/setSentenceBState.js';
import { setSentenceCState } from '/js/sentenceCState/setSentenceCState.js';
import { setCharacterAState } from '/js/characterAState/setCharacterAState.js';
import { setCharacterBState } from '/js/characterBState/setCharacterBState.js';
import { setCharacterCState } from '/js/characterCState/setCharacterCState.js';
import { setSentenceXStartX } from '/js/sentenceX/setSentenceXStartX.js';
import { setSentenceXStartY } from '/js/sentenceX/setSentenceXStartY.js';
import { characterBState } from '/js/characterBState/characterBState.js';
import { characterBStream_T } from '/js/characterBStream/characterBStream_T.js';

export const handleCharacterB0ElementTouchStart = (event) => {
  event.preventDefault();
  if (sentenceX.canTouch === true) {
    setSentenceXState(event);
    setSentenceAState(event);
    setSentenceBState(event);
    setSentenceCState(event);
    setCharacterAState(event);
    setCharacterBState(event);
    setCharacterCState(event);
    setSentenceXStartX(event);
    setSentenceXStartY(event);
    if (characterBState.T === true) {
      characterBStream_T(event);
    }
  }
};
