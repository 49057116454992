import { setSentenceXCanClick } from "/js/sentenceX/setSentenceXCanClick.js";
import { killCharacterA0ElementsTimeline } from "/js/characterA0/killCharacterA0ElementsTimeline.js";
import { killCharacterB0ElementsTimeline } from "/js/characterB0/killCharacterB0ElementsTimeline.js";
import { killCharacterC0ElementsTimeline } from "/js/characterC0/killCharacterC0ElementsTimeline.js";
import { killCharacterA0SelectElementsTimeline } from "/js/characterA0/killCharacterA0SelectElementsTimeline.js";
import { killCharacterB0SelectElementsTimeline } from "/js/characterB0/killCharacterB0SelectElementsTimeline.js";
import { killCharacterC0SelectElementsTimeline } from "/js/characterC0/killCharacterC0SelectElementsTimeline.js";
import { killCharacterA0UnSelectElementsTimeline } from "/js/characterA0/killCharacterA0UnSelectElementsTimeline.js";
import { killCharacterB0UnSelectElementsTimeline } from "/js/characterB0/killCharacterB0UnSelectElementsTimeline.js";
import { killCharacterC0UnSelectElementsTimeline } from "/js/characterC0/killCharacterC0UnSelectElementsTimeline.js";
import { killSentenceA0SelectElementsTimeline } from "/js/sentenceA0/killSentenceA0SelectElementsTimeline.js";
import { killSentenceB0SelectElementsTimeline } from "/js/sentenceB0/killSentenceB0SelectElementsTimeline.js";
import { killSentenceC0SelectElementsTimeline } from "/js/sentenceC0/killSentenceC0SelectElementsTimeline.js";
import { killSentenceA0UnSelectElementsTimeline } from "/js/sentenceA0/killSentenceA0UnSelectElementsTimeline.js";
import { killSentenceB0UnSelectElementsTimeline } from "/js/sentenceB0/killSentenceB0UnSelectElementsTimeline.js";
import { killSentenceC0UnSelectElementsTimeline } from "/js/sentenceC0/killSentenceC0UnSelectElementsTimeline.js";
import { setCharacterA0Values } from "/js/characterA0/setCharacterA0Values.js";
import { setCharacterA0Value } from "/js/characterA0/setCharacterA0Value.js";
import { setCharacterA0Index } from "/js/characterA0/setCharacterA0Index.js";
import { setCharacterA0SelectElementsOpacity } from "/js/characterA0/setCharacterA0SelectElementsOpacity.js";
import { removeCharacterA0SelectElements } from "/js/characterA0/removeCharacterA0SelectElements.js";
import { setCharacterA0SelectElements } from "/js/characterA0/setCharacterA0SelectElements.js";
import { setCharacterA0LastElement } from "/js/characterA0/setCharacterA0LastElement.js";
import { setCharacterA0LastElementInnerHTML } from "/js/characterA0/setCharacterA0LastElementInnerHTML.js";
import { addCharacterA0LastElementEventListener } from "/js/characterA0/addCharacterA0LastElementEventListener.js";
import { insertCharacterA0LastElement } from "/js/characterA0/insertCharacterA0LastElement.js";
import { setCharacterA0ReferenceElement } from "/js/characterA0/setCharacterA0ReferenceElement.js";
import { setCharacterA0LastElementOpacity } from "/js/characterA0/setCharacterA0LastElementOpacity.js";
import { setCharacterA0NextElementsOpacity } from "/js/characterA0/setCharacterA0NextElementsOpacity.js";
import { removeCharacterA0NextElements } from "/js/characterA0/removeCharacterA0NextElements.js";
import { setCharacterA0Elements } from "/js/characterA0/setCharacterA0Elements.js";
import { setCharacterA0ElementsId } from "/js/characterA0/setCharacterA0ElementsId.js";
import { setSentenceA0Value } from "/js/sentenceA0/setSentenceA0Value.js";
import { setSentenceA0SplitValues } from "/js/sentenceA0/setSentenceA0SplitValues.js";
import { setSentenceA0SplitCounts } from "/js/sentenceA0/setSentenceA0SplitCounts.js";
import { setSentenceA0SplitInitials } from "/js/sentenceA0/setSentenceA0SplitInitials.js";
import { setCharacterA0ElementsClassName } from "/js/characterA0/setCharacterA0ElementsClassName.js";
import { setBlankA0ElementHeight } from "/js/blankA0/setBlankA0ElementHeight.js";
import { setSentenceA0ElementHeight } from "/js/sentenceA0/setSentenceA0ElementHeight.js";
import { setSentenceA1ElementValue } from "/js/sentenceA1/setSentenceA1ElementValue.js";

import { setCharacterA0ElementsOpacity } from "/js/characterA0/setCharacterA0ElementsOpacity.js";
import { setCharacterB0ElementsOpacity } from "/js/characterB0/setCharacterB0ElementsOpacity.js";
import { setCharacterC0ElementsOpacity } from "/js/characterC0/setCharacterC0ElementsOpacity.js";
import { removeCharacterA0Elements } from "/js/characterA0/removeCharacterA0Elements.js";
import { removeCharacterB0Elements } from "/js/characterB0/removeCharacterB0Elements.js";
import { removeCharacterC0Elements } from "/js/characterC0/removeCharacterC0Elements.js";

import { transformSentenceAElement } from "/js/sentenceA/transformSentenceAElement.js";
import { transformSentenceBElement } from "/js/sentenceB/transformSentenceBElement.js";
import { transformSentenceCElement } from "/js/sentenceC/transformSentenceCElement.js";
import { setSentenceBElementDisplay } from "/js/sentenceB/setSentenceBElementDisplay.js";
import { setSentenceCElementDisplay } from "/js/sentenceC/setSentenceCElementDisplay.js";
import { setSentenceAElementDisplay } from "/js/sentenceA/setSentenceAElementDisplay.js";

export const sentenceAStream_R_I = (event) => {
  setSentenceXCanClick(false);
  killCharacterA0ElementsTimeline();
  killCharacterB0ElementsTimeline();
  killCharacterC0ElementsTimeline();
  killCharacterA0SelectElementsTimeline();
  killCharacterB0SelectElementsTimeline();
  killCharacterC0SelectElementsTimeline();
  killCharacterA0UnSelectElementsTimeline();
  killCharacterB0UnSelectElementsTimeline();
  killCharacterC0UnSelectElementsTimeline();
  killSentenceA0SelectElementsTimeline();
  killSentenceB0SelectElementsTimeline();
  killSentenceC0SelectElementsTimeline();
  killSentenceA0UnSelectElementsTimeline();
  killSentenceB0UnSelectElementsTimeline();
  killSentenceC0UnSelectElementsTimeline();

  setCharacterA0Values();
  setCharacterA0Value();
  setCharacterA0Index();

  setCharacterA0SelectElementsOpacity();
  removeCharacterA0SelectElements();
  setCharacterA0SelectElements();

  setCharacterA0LastElement();
  setCharacterA0LastElementInnerHTML();
  addCharacterA0LastElementEventListener();
  insertCharacterA0LastElement();

  setCharacterA0ReferenceElement();

  setCharacterA0LastElementOpacity();
  setCharacterA0NextElementsOpacity();
  removeCharacterA0NextElements();

  setCharacterA0Elements();
  setCharacterA0ElementsId();
  setSentenceA0Value(event);
  setSentenceA0SplitValues();
  setSentenceA0SplitCounts();
  setSentenceA0SplitInitials();
  setCharacterA0ElementsClassName();
  setSentenceA0ElementHeight();
  setBlankA0ElementHeight();
  setSentenceA1ElementValue();

  setCharacterA0ElementsOpacity();
  setCharacterB0ElementsOpacity();
  setCharacterC0ElementsOpacity();
  removeCharacterA0Elements();
  removeCharacterB0Elements();
  removeCharacterC0Elements();
  transformSentenceAElement();
  transformSentenceBElement();
  transformSentenceCElement();
  setSentenceAElementDisplay();
  setSentenceBElementDisplay();
  setSentenceCElementDisplay();
  setTimeout(() => {
    setSentenceXCanClick(true);
  }, 1000);
};
