import { logInState } from '/js/logInState/logInState.js';
import { userNameA0 } from '/js/userNameA0/userNameA0.js';

export const setUserNameA0ElementDisplay = () => {
  if (logInState.C === true || logInState.RV === true) {
    setTimeout(() => {
      userNameA0.element.style.display = 'none';
    }, 1000);
  }
};
